export default class ActivityLog {

  static EVENT_ORDER_CREATED = 100;
  static EVENT_ORDER_SUBMITTED = 101;
  static EVENT_ORDER_CANCELLED = 102;
  static EVENT_ORDER_DELETED = 103;
  static EVENT_ORDER_SEAL_STARTED = 105;
  static EVENT_ORDER_SEAL_FAILED = 106;
  static EVENT_ORDER_SEAL_SUCCESS = 107;
  static EVENT_ORDER_STATUS_CHANGED = 108;
  static EVENT_ORDER_CONTRACT_DETAILS_CHANGED = 109;
  static EVENT_ORDER_SCHEDULER_ASSIGNED = 110;
  static EVENT_ORDER_TERMS_ACCEPTED = 124;

  static EVENT_ORDER_DOC_CREATED = 200;
  static EVENT_ORDER_DOC_UPDATED = 201;
  static EVENT_ORDER_DOC_SIGNED = 202;
  static EVENT_ORDER_DOC_ACCEPTED = 203;
  static EVENT_ORDER_DOC_REJECTED = 204;
  static EVENT_ORDER_DOC_DELETED = 205;
  static EVENT_ORDER_DOC_STATUS_CHANGED = 206;

  static EVENT_ORDER_SIGNER_CREATED = 300;
  static EVENT_ORDER_SIGNER_UPDATED = 301;
  static EVENT_ORDER_SIGNER_DELETED = 302;
  static EVENT_ORDER_SIGNER_SMS_OPT_IN = 303;
  static EVENT_ORDER_SIGNER_SMS_OPT_OUT = 304;

  static EVENT_ORDER_NOTARY_ASSIGNED = 700;
  static EVENT_ORDER_NOTARY_VIEWED = 701;
  static EVENT_ORDER_NOTARY_DOCS_RECEIVED = 702;
  static EVENT_ORDER_NOTARY_APPOINTMENT_SET = 703;
  static EVENT_ORDER_NOTARY_APPOINTMENT_DECLINED = 704;
  static EVENT_ORDER_NOTARY_DOCS_PRINTED = 705;
  static EVENT_ORDER_NOTARY_DOCS_UPLOADED = 706;
  static EVENT_ORDER_NOTARY_ORDER_COMPLETED = 707;

  static EVENT_VID_AUTO_STARTED = 400;
  static EVENT_VID_MANUAL_STARTED = 401;
  static EVENT_VID_AUTO_FAILED = 402;
  static EVENT_VID_MANUAL_FAILED = 403;
  static EVENT_VID_AUTO_SUCCESSFUL = 404;
  static EVENT_VID_MANUAL_SUCCESSFUL = 405;

  static EVENT_MEETING_CREATED = 500;
  static EVENT_MEETING_STARTED = 501;
  static EVENT_MEETING_ENDED = 502;
  static EVENT_MEETING_PARTICIPANT_JOINED = 503;
  static EVENT_MEETING_PARTICIPANT_LEFT = 504;

  static EVENT_ORDER_VEHICLE_CREATED = 600;
  static EVENT_ORDER_VEHICLE_UPDATED = 601;
  static EVENT_ORDER_VEHICLE_DELETED = 602;
}