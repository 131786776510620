import React from 'react';
import OrderSigner from 'model/orderSigner';
import DocViewerSignersMenu from './RightBar/SignersMenu';

const DocViewerMenu = () => {

  return <div className="doc-viewer-right-bar">
    <DocViewerSignersMenu type={OrderSigner.TYPE_CUSTOMER} />
    <DocViewerSignersMenu type={OrderSigner.TYPE_DEALER} />
  </div>
}

export default DocViewerMenu;