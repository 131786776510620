import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

const ExpanderDetails = props => {

  const { log, noDetails, isExpanded } = props;

  const canExpand = () => !noDetails && !!log.newValues;

  return <React.Fragment>
    {canExpand() && <Collapse isOpen={isExpanded} className="ms-5 mt-1">
      {props.children}
    </Collapse>}
  </React.Fragment>
}

ExpanderDetails.propTypes = {
  children: PropTypes.any,
  log: PropTypes.object,
  noDetails: PropTypes.bool,
  isExpanded: PropTypes.bool,
}

export default ExpanderDetails;