/********* GROUP USAGE REPORT *********/

export const GET_GROUP_USAGE_REPORT = "GET_GROUP_USAGE_REPORT";
export const GET_GROUP_USAGE_REPORT_OK = "GET_GROUP_USAGE_REPORT_OK";
export const GET_GROUP_USAGE_REPORT_ERR = "GET_GROUP_USAGE_REPORT_ERR";
export const DO_GROUP_USAGE_REPORT_CLEANUP = "DO_GROUP_USAGE_REPORT_CLEANUP";

/********* GROUP USAGE REPORT FILTERS *********/

export const APPLY_GROUP_USAGE_REPORT_FILTERS = "APPLY_GROUP_USAGE_REPORT_FILTERS";
export const CLEAR_GROUP_USAGE_REPORT_FILTERS = "CLEAR_GROUP_USAGE_REPORT_FILTERS";
export const DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP = "DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP";

/********* DEALER SALES REPORT *********/

export const GET_DEALER_SALES_REPORT = "GET_DEALER_SALES_REPORT";
export const GET_DEALER_SALES_REPORT_OK = "GET_DEALER_SALES_REPORT_OK";
export const GET_DEALER_SALES_REPORT_ERR = "GET_DEALER_SALES_REPORT_ERR";
export const DO_DEALER_SALES_REPORT_CLEANUP = "DO_DEALER_SALES_REPORT_CLEANUP";

/********* DEALER SALES REPORT FILTERS *********/

export const APPLY_DEALER_SALES_REPORT_FILTERS = "APPLY_DEALER_SALES_REPORT_FILTERS";
export const CLEAR_DEALER_SALES_REPORT_FILTERS = "CLEAR_DEALER_SALES_REPORT_FILTERS";
export const DO_DEALER_SALES_REPORT_FILTERS_CLEANUP = "DO_DEALER_SALES_REPORT_FILTERS_CLEANUP";
