import { toUserModel } from "helpers/utilHelper";
import { combineReducers } from "redux"
import {
  GET_USER_DT,
  GET_USER_DT_OK,
  GET_USER_DT_ERR,
  DO_USER_DT_CLEANUP,
  APPLY_USER_DT_FILTERS,
  PATCH_USER_DT_FILTERS,
  CLEAR_USER_DT_FILTERS,
  UNSET_USER_DT_FILTERS,

  GET_DEALER_REGION_MANAGER_LIST,
  GET_DEALER_STORE_MANAGER_LIST,
  GET_USER_LIST_OK,
  GET_USER_LIST_ERR,
  DO_USER_LIST_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  users: [], // datatable rows
  userIds: [], // ids of datatable rows
  usersError: null, // datatable error
  totalCount: 0, // datatable total row count without limit needed for pagination
  listParams: {
    sortBy: 'id', // the default datatable sort column
    sortDir: 'desc', // the default datatable sort order
    pageSize: 50, // the default datatable page size
    page: 1, // the default datatable page
    search: null, // the default datatable search phrase
    filters: null, // the default datatable filters (do not set from here)
  }, // datatable params passed to backend
  isLoadInProgress: false, // whether a remote request is in progress
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_USER_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        usersError: null,
        isLoadInProgress: true,
      };
      break
    case GET_USER_DT_OK:
      state = {
        ...state,
        users: action.payload.response.users,
        userIds: action.payload.response.users.map(u => u.id),
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_USER_DT_ERR:
      state = {
        ...state,
        users: [],
        userIds: [],
        totalCount: 0,
        usersError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_USER_DT_CLEANUP:
      state = {
        ...state,
        users: [],
        userIds: [],
        usersError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

// default datatable filters to be applied as the visitor first enters the screen
// most of the time we do not want to have default filters so these values will be empty
// however there may be times when we want the list filtered by default
const defaultDtFiltersState = {
  name: '',
  email: '',
  role: '',
  region: '',
  store: '',
  isActive: '',
  _set: false,
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_USER_DT_FILTERS:
      state = {
        ...action.payload.filters,
        _set: true,
      };
      break
    case PATCH_USER_DT_FILTERS:
      state = {
        ...state,
        ...action.payload.filters,
        _set: true,
      };
      break
    case CLEAR_USER_DT_FILTERS:
      state = { ...defaultDtFiltersState };
      break
    case UNSET_USER_DT_FILTERS:
      state = {
        ...state,
        _set: false,
      };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
  DtFilters,
});
