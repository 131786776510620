import React, { useEffect, useState } from "react";
import { Alert, Button } from "reactstrap"
import PropTypes from "prop-types";
import { getServicedeskArticles } from "helpers/backendHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useDebounce } from "hooks/debounce";
import { articlesUrlMap } from "constants/help";

const HelpSidebarContent = ({ searchTerm }) => {

  const [articles, setArticles] = useState([]);
  const [articlesError, setArticlesError] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(false);
  const [isLoadInProgress, setIsLoadInProgress] = useState(true);

  // Debounce the value of the search
  const debouncedAddress = useDebounce(searchTerm, 500);

  useEffect(() => {
    getServicedeskArticles(searchTerm).then(res => {
      setArticles(res.articles?.values)
      setArticlesError(false)
      setSelectedArticle(null);
    }).catch(err => {
      setArticlesError(true)
    })
  }, [debouncedAddress]);

  const resetHelpContent = () => {
    setSelectedArticle(null)
    setIsLoadInProgress(true)
  }

  return (
    <React.Fragment>
      <div id="help_sidebar_list">
        {!articlesError && !selectedArticle &&
          <>{!!articles && !!articles.length ? <>
            <div className="help-title mb-4">Recommended for you</div>
            {articles.map((article, index) => {
              return <div key={index} className="article-item">
                <Button color="none" className="article-title" onClick={() => setSelectedArticle(article)}>{article.title}</Button>
                <i className="mdi mdi-chevron-right font-size-16" />
              </div>
            })}
          </>
            : <div>No articles found</div>
          }</>
        }
        {selectedArticle && <>
          <div className="article-item justify-content-start">
            <Button color="none" className="ps-0" onClick={resetHelpContent}>
              <i className="mdi mdi-chevron-left font-size-16 me-2" />
            </Button>
            <div className="article-header">{selectedArticle.title}</div>
          </div>
          {isLoadInProgress &&
            <SpinnerChase className="sm help-spinner" />
          }
          <div>
            <iframe style={{ width: "100%", height: `calc(100vh - ${!!articlesUrlMap[selectedArticle.source.pageId] ? "600px" : "200px"})` }} height="70%" src={selectedArticle.content?.iframeSrc} onLoad={() => setIsLoadInProgress(false)} />
            {
              !!articlesUrlMap[selectedArticle.source.pageId] && !isLoadInProgress &&
              <div className="d-flex flex-column w-100 mt-2">
                {
                  articlesUrlMap.map((video, idx) => {
                    return (
                      <div className="d-flex align-items-center flex-column pb-4" key={idx}>
                        {
                          idx === 0 &&
                          <>
                            <h3>Still Unsure?</h3>
                            <h6 className="text-muted mb-3">Watch the Step-by-Step Guide!</h6>
                          </>
                        }
                        {/* Video Iframe (if needed) */}
                        <iframe
                          src={`${video}&autoplay=0&showinfo=0&modestbranding=1&rel=0`}
                          title="preview"
                          allow="autoplay; encrypted-media"
                          width="100%"
                          height="300px"
                          allowFullScreen
                          style={{ border: 0 }}
                        />
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>
        </>}
        {!!articlesError && <Alert color="danger" className="fade show text-center">
          <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load articles
        </Alert>}
      </div>
    </React.Fragment>
  )
}

HelpSidebarContent.propTypes = {
  searchTerm: PropTypes.string,
}

export default HelpSidebarContent;