import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { doOrderDocFieldsCleanup, doOrderDocSingleCleanup, getDocViewerOrderDoc, updateOrderDocFields } from 'store/actions';
import { randomString, showBriefSuccess, showError } from 'helpers/utilHelper';
import OrderDoc from 'model/orderDoc';
import { ReactComponent as SignatureIcon } from 'assets/images/docviewer/signature.svg';
import { ReactComponent as InitialsIcon } from 'assets/images/docviewer/initials.svg';
import { ReactComponent as NameIcon } from 'assets/images/docviewer/name.svg';
import useBeforeUnload from 'hooks/beforeUnload';
import { ServerErrorException, UNABLE_SEND_UPLOADED_DOCS_NOTIF } from 'helpers/errorHelper';
import OrderSigner from 'model/orderSigner';

const DocViewerContext = React.createContext();

/**
 * Maximum value (percentage) allowed for zooming in
 * @type {int}
 */
const maxZoomLevel = 200;

/**
 * Zooming in/out will increase/decrease the zoom level with this number of units (percentage)
 * @type {int}
 */
const zoomStep = 10;

/**
 * The width (in px) of the page list that corresponds to a 100% zoom level
 * This needs to be chosen based on the resolution of the images (pages)
 * and needs to be correlated with the sizes of the signature fields
 * @type {int}
 */
const naturalPageListWidth = 1200;

const DocViewerProvider = props => {

  /**
   * Component props:
   * docId {int} the db id of the order document
   * closeHandler {func} callback to call when the user closes the viewer
   */
  const { docId, closeHandler } = props;

  /**
   * Redux action dispatcher
   */
  const dispatch = useDispatch();

  /********** STATE **********/

  /**
   * Store state vars:
   * orderDoc {object} the order doc fetched from the backend
   * orderDocError {object} error encountered while trying to fetch the order doc
   * isLoadInProgress {boolean} TRUE while fetch request is in progress
   */
  const { orderDoc, orderDocError, isLoadInProgress } = useSelector(state => state.OrderDoc.Single);

  /**
   * Store state vars:
   * saved {boolean} TRUE if the fields have been saved successfully
   * isSaveInProgress {boolean} TRUE while the save request is in progress
   * saveError {exception} exception encountered if the save request fails
   */
  const { saved, isSaveInProgress, isReadyToSignInProgress, saveError } = useSelector(state => state.OrderDoc.Fields);

  /**
   * List of fields that have been added to the document, for example by drag and drop
   * Each field is an object with multiple properties such as size, position, text, etc
   */
  const [addedFields, setAddedFields] = useState([]);

  /**
   * Bool flag that turns the left bar on and off
   * The left bar will be rendered or not based on this value
   * The setter of this state var is not exposed (see: numSizeChanges)
   */
  const [isLeftBarOpen, _setIsLeftBarOpen] = useState(true);

  /**
   * Bool flag that turns full screen mode on and off
   * Full screen will be activated or deactivated based on this value
   */
  const [isFullScreen, setIsFullScreen] = useState(false);

  /**
   * the number of the page that is to be shown to the user
   * when this changes, content will jump to the top of the respective page
   */
  const [activePageNum, _setActivePageNum] = useState(1);

  /**
   * The field that the user can click to insert
   * When this is set to an available field, clicking on pages will insert a copy of the respective field
   * This expects the full field object
   */
  const [selectedField, setSelectedField] = useState(null);

  /**
   * The current zoom level (percentage)
   * Content will be scaled based on this value
   * Initial value is 0 (not initialized) before the fit-width and fit-height zoom levels are determined
   * Then the zoom level will be automatically set to fit-width
   * Nested components know to skip certain processing while this value is 0
   */
  const [zoomLevel, setZoomLevel] = useState(0);

  /**
   * The zoom level at which page width fits perfectly inside the content
   * This value is determined on first render and then whenever there are size changes (see: numSizeChanges)
   * It is NOT however recalculated on window resize (for performance reasons)
   */
  const [fitWidthZoomLevel, setFitWidthZoomLevel] = useState(0);

  /**
   * The zoom level at which page height fits perfectly inside the content
   * This value is determined on first render and then whenever there are size changes (see: numSizeChanges)
   * It is NOT however recalculated on window resize (for performance reasons)
   */
  const [fitHeightZoomLevel, setFitHeightZoomLevel] = useState(0);

  /**
   * Counter that increments each time there is a layout change (other than zoom) that affects page size
   * Example of such changes: toggle left bar, toggle full screen
   * We want to be able to track changes in page size because fields sizes need to be recalculated accordingly
   * However listening for each change individually is tedious
   * So we listen to this counter and instruct all other size-changing state vars to update it
   */
  const [numSizeChanges, _setNumSizeChanges] = useState(0);

  /**
   * Bool flag that indicates whether the user has made changes since the last save
   * Warning message will be shown on close based on this value
   */
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  /**
   * Bool flag that indicates whether the "save before close" dialog is open
   * Save dialog will be shown on close based on this value
   */
  const [isCloseConfVisible, setIsCloseConfVisible] = useState(false);

  /**
   * Bool flag that indicates whether the viewer should be closed once the current action completes successfully
   * Used for saving and then immediately closing the viewer
   */
  const [shouldClose, setShouldClose] = useState(false);

  /**
   * Counter that increments each time there is a change of page that requires scrolling into view
   * We listen to this counter and know when to scroll
   */
  const [numPageScrollCommands, _setNumPageScrollCommands] = useState(0);

  /**
   * Bool flag that indicates whether doc pages are ready to receive dropped signature fields
   * Which happens when all zoom level calculations are complete
   * Used for enabling available fields
   */
  const [isDropReady, setIsDropReady] = useState(false);

  /**
   * Custom setter for the 'isLeftBarOpen' state var
   * Calls the 'native' setter but also increments 'numSizeChanges'
   * @param {bool|func} arg
   */
  const setIsLeftBarOpen = arg => {
    _setIsLeftBarOpen(arg);
    reportSizeChange();
  }

  /**
   * Custom setter for the 'activePageNum' state var
   * Calls the 'native' setter but also sets 'numPageScrollCommands'
   * @param {bool|func} arg
   * @param {bool} [shouldScroll] - whether the content should scroll to the newly activated page
   */
  const setActivePageNum = (arg, shouldScroll = true) => {
    _setActivePageNum(arg);
    if (shouldScroll) {
      _setNumPageScrollCommands(num => num + 1);
    }
  }

  /**
   * Custom setter for the 'numSizeChanges' state var
   * With each call, it increments the value by 1
   * @returns {void}
   */
  const reportSizeChange = () => _setNumSizeChanges(num => num + 1);

  /********** EFFECTS **********/

  /**
   * This effect runs whenever the doc id changes
   * Which only happens on the first render
   * We use it to fetch the order doc by id
   */
  useEffect(() => {
    // make the initial remote call to fetch the doc data
    refreshOrderDoc();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderDocSingleCleanup());
      dispatch(doOrderDocFieldsCleanup());
    }
  }, [docId]);

  /**
   * This effect runs whenever the orderDoc changes
   * Which happens whenever the data is refreshed
   * For example after each save
   * We use it to populate the added fields
   */
  useEffect(() => {
    // orderDoc is null before the fetch call completes
    // also if the doc has no added fields then there is nothing we need to do
    if (!orderDoc || !orderDoc.fields) {
      return;
    }
    // fields coming from backend only contain signerId
    // we need the full signer object attached to the field
    for (const index in orderDoc.fields) {
      const field = orderDoc.fields[index];
      const signer = orderDoc.signers.find(s => s.id == field.signerId);
      field.signer = signer;
      orderDoc.fields[index] = field;
    }
    // populate the added fields from the doc fields
    // so that the fields will be rendered visually
    setAddedFields(orderDoc.fields);
  }, [orderDoc]);

  /**
   * This effect runs whenever the 'saved' flag changes
   * Which happens after each save
   * We use it to display a notification and to refresh the orderDoc
   */
  useEffect(() => {
    if (saved === true) {
      showBriefSuccess('Document has been saved');
      // check if we are instructed to close the viewer upon success
      if (shouldClose) {
        closeViewer();
        return;
      }
      // changes have been saved so reset the flag
      setHasUnsavedChanges(false);
      refreshOrderDoc();
    } else if (saved === false) {
      if (saveError instanceof ServerErrorException) {
        if (saveError.code == UNABLE_SEND_UPLOADED_DOCS_NOTIF) {
          // doc has been saved but the notifications could not be sent (at least some of them)
          // we want to make this distinction because the owner might want to resend notifications manually
          showError('Unable to send notifications');
          // check if we are instructed to close the viewer upon success
          if (shouldClose) {
            closeViewer();
            return;
          }
        }
      }
      showError('Unable to save document');
      // the save action was not successfull so reset the closing flag
      setShouldClose(false);
    }
  }, [saved]);

  /**
   * This effect runs whenever the 'fit-width' zoom level is recalculated
   * Which happens after each layout change that affects page size
   * We use it to set the initial zoom level to fit-width
   */
  useEffect(() => {
    // fitWidthZoomLevel is 0 on first render
    // check if it has a value and if the zoom level has not yet been set
    // we want this to run only once, in the beginning
    if (!!fitWidthZoomLevel && !zoomLevel) {
      // set the content zoom level to fit-width
      // but not exceed 100%
      setZoomLevel(Math.min(fitWidthZoomLevel, 100));
      // fields can now be dropped on the pages
      setIsDropReady(true);
    }
  }, [fitWidthZoomLevel]);

  // show warning if there are unsaved changes and the user tries to leave the page
  useBeforeUnload(hasUnsavedChanges);

  /********** HANDLERS **********/

  /**
   * Updates a field (in the list of added fiels) with new data
   * Inserts the field if it does not exist
   * This is called whenever field properties change (ex. size, position, text, etc)
   * @param {object} field
   */
  const updateField = async field => {
    // if this field does not have an id then we know it is a new field
    if (!field.id) {
      // generate an unique id
      field.id = await randomString(10);
      // add the field to the list of added fields
      setAddedFields(fields => [...fields, field]);
      // if this field has an id then we know it is an existing field that has changed
    } else {
      setAddedFields(fields => {
        // find the field in the list of added fields by id
        const theField = fields.find(f => f.id == field.id);
        // get the field index
        const index = fields.indexOf(theField);
        // overwrite the field with the new data
        fields[index] = field;
        // return the new list
        // it is important to return a copy or the list and not the original
        // else react might fail to detect that the value has changed
        return [...fields];
      });
    }
    // note that changes have been made which the user might want to save
    setHasUnsavedChanges(true);
  }

  /**
   * Deletes a field from the list of added fields by id
   * @param {string} id
   */
  const deleteField = id => {
    setAddedFields(fields => {
      // find the field in the list of added fields by id
      const theField = fields.find(f => f.id == id);
      // get the field index
      const index = fields.indexOf(theField);
      // remove the field from the list
      fields.splice(index, 1);
      // return the new list
      // it is important to return a copy or the list and not the original
      // else react might fail to detect that the value has changed
      return [...fields];
    });
    // note that changes have been made which the user might want to save
    setHasUnsavedChanges(true);
  }

  /**
   * Performs a backend request to save the fields in the db
   * @param {boolean} isReady - whether the editing of this contract is complete and the document is ready for signing
   * @returns {void}
   */
  const saveFields = isReady => {
    // do not allow saving the document without signature fields
    if (!addedFields.length) {
      showError('No signature placements have been added to the document');
      return;
    }
    // extract the customer signers
    const customers = orderDoc.signers.filter(s => s.type === OrderSigner.TYPE_CUSTOMER);
    // check if every customer has at least one field
    const allCustomersOk = customers.every(c => addedFields.some(f => f.signer.id === c.id));
    // do not allow setting the document as ready-for-signing without customer fields
    if (isReady && !allCustomersOk) {
      if (customers.length > 1) {
        showError('Please add fields for all customers');
      } else {
        showError('Please add some customer fields');
      }
      return;
    }
    dispatch(updateOrderDocFields({ isReady, fields: addedFields }, orderDoc.id));
  }

  /**
   * Performs a backend request to save the fields in the db
   * Instructs the viewer to close upon successful save
   * @param {boolean} isReady - whether the editing of this contract is complete and the document is ready for signing
   */
  const saveFieldsAndClose = isReady => {
    setShouldClose(true);
    saveFields(isReady);
  }

  /**
   * Checks whether there are unsaved changes and alerts the user accordingly
   * Closes the document viewer
   */
  const tryCloseViewer = () => {
    if (hasUnsavedChanges) {
      setIsCloseConfVisible(true);
    } else {
      closeViewer();
    }
  }

  /**
   * Handler that is supposed to close the doc viewer
   */
  const closeViewer = closeHandler;

  /**
   * Performs a backend request to fetch the orderDoc
   * @returns {void}
   */
  const refreshOrderDoc = () => dispatch(getDocViewerOrderDoc(docId));

  /**
   * Returns the respective icon for a field type
   * @param {int} fieldType
   * @returns {ReactComponent}
   */
  const getFieldIcon = fieldType => {
    switch (fieldType) {
      case OrderDoc.FIELD_TYPE_SIGNATURE:
        return SignatureIcon;
      case OrderDoc.FIELD_TYPE_INITIALS:
        return InitialsIcon;
      case OrderDoc.FIELD_TYPE_NAME:
        return NameIcon;
      default:
        return <React.Fragment />
    }
  }

  /**
   * Returns the current scale factor based on the current zoom level
   * @returns {float}
   */
  const getZoomScaleFactor = () => zoomLevel / 100;

  /**
   * Minimum value (percentage) allowed for zooming out
   * @type {int}
   */
  const minZoomLevel = fitHeightZoomLevel;

  /**
   * Calculates the natural size of a scaled size based on the current zoom level
   * Natural size - size at 100% zoom
   * a.k.a. what would this size be if it weren't scaled
   * @param {number} size
   * @returns {float}
   */
  const getNaturalSize = size => size / getZoomScaleFactor();

  /**
   * Calculates the scaled size of a natural size based on the current zoom level
   * Natural size - size at 100% zoom
   * a.k.a. what would this size be if it were scaled by the current zoom level
   * @param {number} size
   * @returns {float}
   */
  const getZoomedSize = size => size * getZoomScaleFactor();

  /**
   * Calculates the width of the content based on the current zoom level
   * @returns {float}
   */
  const getZoomedPageListWidth = () => getZoomScaleFactor() * naturalPageListWidth;

  /**
   * Sets the zoom level to the value that would produce a fit-width effect
   * @returns {void}
   */
  const zoomToFitWidth = () => setZoomLevel(fitWidthZoomLevel);

  /**
   * Sets the zoom level to the value that would produce a fit-height effect
   * @returns {void}
   */
  const zoomToFitHeight = () => setZoomLevel(fitHeightZoomLevel);

  /**
   * Checks whether the current zoom conditions allow for a fit-width operation
   * Essentially checks if the zoom level is not fit-width already
   * @returns {void}
   */
  const canZoomToFitWidth = () => zoomLevel !== fitWidthZoomLevel;

  /**
   * Checks whether the current zoom conditions allow for a fit-height operation
   * Essentially checks if the zoom level is not fit-height already
   * @returns {void}
   */
  const canZoomToFitHeight = () => zoomLevel !== fitHeightZoomLevel;

  /**
   * Checks if a field is permanently signed
   * a.k.a. the signature is recorded in db and applied to the pdf doc
   * @param {object} field
   * @returns {boolean}
   */
  const fieldIsSigned = field => !!field.signingId;

  /**
   * Checks whether the order doc has been signed by at least one signer
   * @returns {boolean}
   */
  const docIsSigned = () => !!orderDoc && !!orderDoc.signings.length;

  return <DocViewerContext.Provider value={{
    orderDoc, orderDocError, isLoadInProgress, isSaveInProgress, isReadyToSignInProgress, docIsSigned,
    addedFields, updateField, saveFields, saveFieldsAndClose, deleteField,
    activePageNum, setActivePageNum, numPageScrollCommands,
    isLeftBarOpen, setIsLeftBarOpen,
    isFullScreen, setIsFullScreen,
    numSizeChanges, reportSizeChange,
    selectedField, setSelectedField,
    zoomLevel, setZoomLevel, maxZoomLevel, minZoomLevel, zoomStep,
    getZoomScaleFactor, getNaturalSize, getZoomedSize, getZoomedPageListWidth, naturalPageListWidth,
    setFitWidthZoomLevel, setFitHeightZoomLevel, zoomToFitWidth, zoomToFitHeight, canZoomToFitWidth, canZoomToFitHeight,
    getFieldIcon, fieldIsSigned, tryCloseViewer, closeViewer,
    isCloseConfVisible, setIsCloseConfVisible,
    isDropReady,
  }} {...props} />
}

DocViewerProvider.propTypes = {
  docId: PropTypes.number,
  closeHandler: PropTypes.func,
}

// helper hook that makes context data available
export const useDocViewer = () => React.useContext(DocViewerContext);

export default DocViewerProvider;