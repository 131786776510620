import React from 'react';
import PropTypes from 'prop-types';
import { Button } from "reactstrap";
import OrderDoc from 'model/orderDoc';
import { perms, useAccess } from 'context/access';
import { useSelector } from 'react-redux';

const InkSignPreviewModal = props => {

  const { docId, pageNum, onAcceptPage, onRejectPage, order } = props;

  const doc = useSelector(state => state.OrderDoc.NormList.orderDocs[docId]);
  const page = doc.pages?.[pageNum] || {};

  // hook that check permissions
  const { iAmGranted } = useAccess();

  /********** OTHER **********/

  const isAcceptingPage = page.isAcceptInProgress;

  const isAcceptedPage = page.isAccepted || page.status === OrderDoc.PAGE_STATUS_ACCEPTED;

  const isRejectingPage = page.isRejectInProgress;

  const isRejectedPage = page.isRejected || page.status === OrderDoc.PAGE_STATUS_REJECTED;

  const isNotReviewedPage = !page.isAccepted && !page.isRejected;

  return <div className={'ink-sign-gallery-item status-' + page.status}>
    <div className="ink-sign-gallery-item-preview">
      <img src={page.image} className="ink-sign-gallery-item-img" />
    </div>
    <div className="ink-sign-gallery-item-btns">
      {isAcceptedPage && <Button type="button" color="primary" className="cursor-default">
        Accepted page
        <i className="bx bx-check-circle ms-2 align-middle"></i>
      </Button>}
      {isRejectedPage && <Button type="button" color="danger" className="cursor-default">
        Rejected page
        <i className="bx bx-x-circle ms-2 align-middle"></i>
      </Button>}
      {iAmGranted(perms.edit_orders) && (page.status === OrderDoc.PAGE_STATUS_PENDING_REVIEW && isNotReviewedPage) && (doc.status !== OrderDoc.STATUS_COMPLETE) && <React.Fragment>
        <Button type="button" color="success" disabled={isAcceptingPage} onClick={onAcceptPage}>
          Accept page
          {isAcceptingPage && <i className="bx bx bx-loader-alt bx-spin ms-2 align-middle" />}
          {!isAcceptingPage && <i className="bx bx-check-circle ms-2 align-middle"></i>}
        </Button>
        {!order.isNotaryRequired && <Button type="button" color="danger" className="ms-2" disabled={isRejectingPage} onClick={onRejectPage}>
          Reject page
          {isRejectingPage && <i className="bx bx bx-loader-alt bx-spin ms-2 align-middle" />}
          {!isRejectingPage && <i className="bx bx-x-circle ms-2 align-middle"></i>}
        </Button>}
      </React.Fragment>}
    </div>
  </div>
}

InkSignPreviewModal.propTypes = {
  docId: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  onAcceptPage: PropTypes.func.isRequired,
  onRejectPage: PropTypes.func.isRequired,
  order: PropTypes.object,
};

export default InkSignPreviewModal;