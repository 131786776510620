import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { get as objGet } from 'lodash';
import { Row, Col, Button, Form, Label, Input, FormFeedback } from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import { nullsToEmptyStrings, toSelectOptions } from "helpers/utilHelper";
import { useAuth } from "context/auth";
import { getDealerGroupRegions } from "helpers/backendHelper";

const FormInfoNew = props => {

  const { defaultValues, forceValues, tabId, nextHandler, prevHandler } = props;

  // authenticated user info
  const { user: authUser } = useAuth();

  /********** STATE **********/

  const [regions, setRegions] = useState([]);
  const [regionsError, setRegionsError] = useState(null);

  // this is the default form validation schema
  // we store this shema in state because it is meant to be a dynamic schema
  // which we will to update conditionally
  const [validationSchema, setValidationSchema] = useState({
    name: Yup.string().trim().required('Field is required'),
    isActive: Yup.boolean(),
    generalManagerName: Yup.string().trim(),
    generalManagerEmail: Yup.string().trim().email('Invalid email address'),
    salesManagerName: Yup.string().trim(),
    salesManagerEmail: Yup.string().trim().email('Invalid email address'),
    preoManagerName: Yup.string().trim(),
    preoManagerEmail: Yup.string().trim().email('Invalid email address'),
  });

  /********** FORM CONFIG **********/

  const formInitialValues = {
    name: '',
    dealerGroupId: '',
    dealerRegionId: '',
    isActive: false,
    generalManagerName: '',
    generalManagerEmail: '',
    salesManagerName: '',
    salesManagerEmail: '',
    preoManagerName: '',
    preoManagerEmail: '',
    ...nullsToEmptyStrings(defaultValues),
    ...nullsToEmptyStrings(forceValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      formik.setSubmitting(false);
      nextHandler(tabId, values);
    },
  });

  // validation rules for the 'region' field
  // this will be added dynamically to the form validation schema
  const regionValidation = Yup.number().required('Field is required');

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // if the region is not forced by a parent component
    if (!objGet(props, 'forceValues.dealerRegionId')) {
      // make the initial remote call to get the region field options
      getDealerRegionList(authUser.dealerGroupId);
    }
  }, []);

  // runs whenever 'regionsError' changes
  useEffect(() => {
    if (regionsError) {
      // set an error on the form field
      formik.setFieldError('dealerRegionId', 'Unable to load regions');
    }
  }, [regionsError]);

  // runs whenever the list of regions changes
  useEffect(() => {
    // make the regions field required if the group contains regions
    setValidationSchema(schema => ({
      ...schema,
      dealerRegionId: regions.length > 0 ? regionValidation : null,
    }));
  }, [regions]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  // prepares the list of regions to be used as select options
  const getRegionOptions = () => [{ label: 'Choose', value: '' }, ...toSelectOptions(regions)];

  const getDealerRegionList = (groupId) => {
    // make the initial remote call to get the user data
    getDealerGroupRegions(groupId)
      .then(response => {
        setRegions(response.dealerRegions);
      })
      .catch(ex => {
        setRegionsError(ex);
      })
  };

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <div className="text-end">
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={prevHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Quit
            </Button>
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              <i className="mdi mdi-check me-1" />
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Name *</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. Best Auto Store" name="name" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.name} invalid={!!formik.errors.name} />
              {!!formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
            </Col>
          </Row>
          {regions.length > 0 && <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Region</Label>
            <Col sm={8}>
              <Select
                classNamePrefix="select2-selection"
                name="dealerRegionId"
                options={getRegionOptions()}
                onChange={selected => formik.setFieldValue('dealerRegionId', selected.value)}
                onFocus={e => onFieldFocused(e, 'dealerRegionId')}
                value={getRegionOptions().find(option => option.value === formik.values.dealerRegionId)}
                className={!!formik.errors.dealerRegionId && 'is-invalid'} />
              {!!formik.errors.dealerRegionId && <FormFeedback type="invalid">{formik.errors.dealerRegionId}</FormFeedback>}
            </Col>
          </Row>}
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Active</Label>
            <Col sm={8}>
              <div className={classnames({ 'is-invalid': !!formik.errors.isActive }, 'form-check form-switch form-switch-lg mb-3')}>
                <Input type="checkbox" className="form-check-input" id="activeSwitch" name="isActive" onChange={formik.handleChange} defaultChecked={formik.values.isActive} />
                <Label className="form-check-label" htmlFor="activeSwitch" />
              </div>
              {!!formik.errors.isActive && <FormFeedback type="invalid">{formik.errors.isActive}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">General manager</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="generalManagerName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.generalManagerName} invalid={!!formik.errors.generalManagerName} />
              {!!formik.errors.generalManagerName && <FormFeedback type="invalid">{formik.errors.generalManagerName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">General manager email</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="generalManagerEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.generalManagerEmail} invalid={!!formik.errors.generalManagerEmail} />
              {!!formik.errors.generalManagerEmail && <FormFeedback type="invalid">{formik.errors.generalManagerEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Sales manager</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="salesManagerName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.salesManagerName} invalid={!!formik.errors.salesManagerName} />
              {!!formik.errors.salesManagerName && <FormFeedback type="invalid">{formik.errors.salesManagerName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Sales manager email</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="salesManagerEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.salesManagerEmail} invalid={!!formik.errors.salesManagerEmail} />
              {!!formik.errors.salesManagerEmail && <FormFeedback type="invalid">{formik.errors.salesManagerEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Pre-owned manager</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="preoManagerName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.preoManagerName} invalid={!!formik.errors.preoManagerName} />
              {!!formik.errors.preoManagerName && <FormFeedback type="invalid">{formik.errors.preoManagerName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Pre-owned manager email</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="preoManagerEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.preoManagerEmail} invalid={!!formik.errors.preoManagerEmail} />
              {!!formik.errors.preoManagerEmail && <FormFeedback type="invalid">{formik.errors.preoManagerEmail}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormInfoNew.propTypes = {
  defaultValues: PropTypes.object,
  forceValues: PropTypes.object,
  tabId: PropTypes.number,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
};

export default FormInfoNew;