import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col, CardHeader, Form, Label, Input, FormFeedback, Button } from "reactstrap"
import { openInNewWindow, phoneHasNoOfDigits, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import { createCustomerCall } from "helpers/backendHelper";
import regx from "constants/regx";

const CustomerCall = ({ onClose }) => {

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().trim().required('Field is required'),
      lastName: Yup.string().trim().required('Field is required'),
      phone: Yup.string().trim().required('Field is required').matches(regx.phone, 'Invalid phone number').test('phone',
        'Field requires exactly 10 digits',
        ((value) => phoneHasNoOfDigits(value))
      ),
      email: Yup.string().trim().required('Field is required'),
    }),
    onSubmit: values => saveCall(values),
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveCall = values => {
    setIsSaveInProgress(true);
    createCustomerCall(values)
      .then(response => {
        showSuccess(`Call with "${values.firstName} ${values.lastName}" has been initiated`);
        openInNewWindow(response.meetingLink);
        onClose();
      })
      .catch(ex => {
        showError('Unable to initiate call');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <Card className="call-a-customer-card">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <div className="text-end">
            <Button className="btn-close" onClick={onClose} />
          </div>
        </Col>
      </Row>
    </CardHeader>
    <CardBody className="pt-0">
      <Row>
        <Col>
          <Form>
            <Row className="mb-1">
              <Col xs={12}>
                <Label className="col-sm-3 col-form-label">First name</Label>
                <Input type="text" className="form-control" placeholder="ex. John" name="firstName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.firstName} invalid={!!formik.errors.firstName} />
                {!!formik.errors.firstName && <FormFeedback type="invalid">{formik.errors.firstName}</FormFeedback>}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col xs={12}>
                <Label className="col-sm-3 col-form-label">Last name</Label>
                <Input type="text" className="form-control" placeholder="ex. Doe" name="lastName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.lastName} invalid={!!formik.errors.lastName} />
                {!!formik.errors.lastName && <FormFeedback type="invalid">{formik.errors.lastName}</FormFeedback>}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col xs={12}>
                <Label className="col-sm-3 col-form-label">Phone</Label>
                <Input type="text" className="form-control" placeholder="ex. 0975683578" name="phone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.phone} invalid={!!formik.errors.phone} />
                {!!formik.errors.phone && <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={12}>
                <Label className="col-sm-3 col-form-label">Email</Label>
                <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="email" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.email} invalid={!!formik.errors.email} />
                {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={8}>
                <Button type="button" color="primary" className="w-100" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>Initiate Call {isSaveInProgress && <i className="mdi mdi-spin mdi-loading ms-1" />}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </CardBody>
  </Card>
}

CustomerCall.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CustomerCall;