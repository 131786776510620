import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewCustomer from '../View/Customer';
import FormEditCustomer from '../Form/Edit/Customer';

const SectionCustomer = props => {

  const { isLoadBusy, id, order, refreshHandler, isLocked } = props;

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // TRUE after a save and untill the data is refreshed
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormEditCustomer defaultValues={order} id={id} finishedHandler={toggleEditMode} />}
    {!isEditModeActive && <ViewCustomer id={id} order={order} isRefreshing={isRefreshing} isLocked={isLocked} />}
  </React.Fragment>
}

SectionCustomer.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  order: PropTypes.object,
  refreshHandler: PropTypes.func,
  isLocked: PropTypes.bool,
};

export default SectionCustomer;
