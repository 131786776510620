import React from 'react';
import ViewPaymentInfo from '../View/PaymentInfo';
import { useSubscription } from 'context/subscription';
import SubscribedBy from './SubscribedBy';

const SectionActivePayment = () => {

  const { dealerStore } = useSubscription();

  return (<React.Fragment>
    {!!dealerStore?.paymentPlanChangedBy && <SubscribedBy dealerStore={dealerStore} />}
    <ViewPaymentInfo dealerStore={dealerStore} hasEdit={false} />
  </React.Fragment>)
}

export default SectionActivePayment;
