export default class VidRequest {

  static STATUS_ERROR = -1;
  static STATUS_CREATED = 0;
  static STATUS_PENDING = 1;
  static STATUS_FAILED = 2;
  static STATUS_ESCALATED = 3;
  static STATUS_MITEK_API_FAIL = 4;
  static STATUS_SKIPPED = 5;
  static STATUS_PASSED = 6;
  static STATUS_PENDING_FAILED = 20;

  static SKIP_REASON_CUSTOMER_UNCOOPERATIVE = 1;
  static SKIP_REASON_CUSTOMER_UNABLE_TO_COMPLETE = 2;
  static SKIP_REASON_DEALERSHIP_OVERRIDE = 3;
  static SKIP_REASON_MANUAL_APPROVAL = 4;

  static SIGNER_FIRST = 1;
  static SIGNER_SECOND = 2;

  static ID_TYPE_DRIVER_LICENSE = 'DRIVER_LICENSE';
  static ID_TYPE_PASSPORT = 'PASSPORT';

  static getStatusColor(status) {
    switch (status) {
      case this.STATUS_FAILED:
      case this.STATUS_ERROR:
      case this.STATUS_MITEK_API_FAIL:
        return 'danger';
      case this.STATUS_PENDING:
      case this.STATUS_ESCALATED:
        return 'warning';
      case this.STATUS_CREATED:
        return 'primary';
      case this.STATUS_PASSED:
      case this.STATUS_SKIPPED:
        return 'success';
      default:
        return 'dark';
    }
  }

  static getStatuses() {
    return {
      [this.STATUS_CREATED]: 'Pending verify ID',
      [this.STATUS_PENDING]: 'Pending verify ID',
      [this.STATUS_FAILED]: 'Failed',
      [this.STATUS_ESCALATED]: 'Escalated',
      [this.STATUS_MITEK_API_FAIL]: 'Mitek API fail',
      [this.STATUS_SKIPPED]: 'Skipped',
      [this.STATUS_PASSED]: 'Passed',
      [this.STATUS_PENDING_FAILED]: 'Pending Failed',
    };
  }

  static getStatusName(id) {
    return this.getStatuses()[id];
  }

  static getSkipReasons() {
    return {
      [this.SKIP_REASON_CUSTOMER_UNCOOPERATIVE]: 'Customer Uncooperative',
      [this.SKIP_REASON_CUSTOMER_UNABLE_TO_COMPLETE]: 'Customer Unable to complete (tech/ability)',
      [this.SKIP_REASON_DEALERSHIP_OVERRIDE]: 'Dealership Override',
      [this.SKIP_REASON_MANUAL_APPROVAL]: 'Manual Approval (Images provided)',
    };
  }

  static getSkipReason(id) {
    return this.getSkipReasons()[id];
  }

  static getVidStatusesForOrders() {
    return {
      [this.STATUS_PASSED]: 'Passed',
      [this.STATUS_FAILED]: 'Failed',
      [this.STATUS_PENDING_FAILED]: 'Pending Failed',
      [this.STATUS_PENDING]: 'Pending',
      [this.STATUS_SKIPPED]: 'Skipped ',
    };
  }
}