import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import classnames from 'classnames';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDocViewer } from '../../Context';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { ResizableBox } from 'react-resizable';
import "react-resizable/css/styles.css";
import EditableContent from 'components/Shared/EditableContent';
import OrderDoc from 'model/orderDoc';

const DocViewerAddedField = props => {

  /**
   * Component props:
   * field {object} the signature field
   * pageRef {ref} ref to the page this field was added to
   */
  const { field, pageRef } = props;

  /**
   * Ref to the field label div
   */
  const fieldLabel = useRef(null);

  /**
   * The 'pageRef' prop is not available in useLayoutEffect
   * So we need to store it in a ref
   */
  const pageRef2 = useRef(null);
  pageRef2.current = pageRef?.current;

  /**
   * Context vars (see: Context.js)
   */
  const { updateField, deleteField, numSizeChanges, getFieldIcon, naturalPageListWidth, getNaturalSize } = useDocViewer();

  /**
   * Bool flag that turns the field menu on and off
   * Menu will be visible or not based on this value
   */
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  /**
   * This hook wires in the component as a drag source
   * {@link https://react-dnd.github.io/react-dnd/docs/api/use-drag}
   */
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'order-doc-added-field',
    item: field,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    // make sure to add the field as dependency
    // so this hook gets recreated whenever the field changes
    // otherwise 'item' will contain a stale value
  }), [field]);

  /**
   * This effect runs once on component mount
   * We use it to prepare the custom drag preview
   * {@link https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer}
   */
  useEffect(() => {
    // replace the default drag preview with an empty image
    // this is so we can later add our own custom preview via a drag layer
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  /**
   * This effect runs whenever there is a change in the layout that affects the page size
   * We use it to re/calculate field sizes based on the new page size
   * This is because the Resizable component prevents us from storing width/height as percentages
   */
  useLayoutEffect(() => {
    // get the new size of the page
    const pageRect = pageRef2.current?.getBoundingClientRect();
    // on first mount the ref is not available
    if (!pageRect) {
      return;
    }
    // field.widthPc and field.heightPc are relative to the natural size
    // so be careful to calculate pixels relative to the natural width/height too
    // else we will get wrong values when content is zoomed
    updateField({
      ...field,
      widthPx: field.widthPc / 100 * naturalPageListWidth,
      heightPx: field.heightPc / 100 * getNaturalSize(pageRect.height),
    });
  }, [numSizeChanges]);

  /**
   * Event handler called whenever the user resizes the field by dragging
   * We use it to recalculate size percentages based on the new width
   * @param {event} e
   * @param {object} data
   */
  const fieldResized = (e, data) => {
    const pageRect = pageRef.current?.getBoundingClientRect();
    // data.size.width and data.size.height refer to the natural size of the fields
    // so be careful to calculate percentages relative to the natural width/height too
    // else we will get wrong values when content is zoomed
    updateField({
      ...field,
      widthPx: data.size.width,
      heightPx: data.size.height,
      widthPc: data.size.width / naturalPageListWidth * 100,
      heightPc: data.size.height / getNaturalSize(pageRect.height) * 100,
    });
  }

  /**
   * Event handler called whenever the user finishes editing the field text
   * We use it to update the field with the new text
   * @param {string} newText
   */
  const fieldTextChanged = newText => {
    updateField({ ...field, text: newText });
    // this fixes a weird behaviour
    // when entering text that overflows beyond the available space
    // when editing is finished the container remains scrolled to the bottom
    // and we need to scroll it back to the top manually
    fieldLabel.current.scrollTop = 0;
  }

  /**
   * The icon for this field type
   * @type {ReactComponent}
   */
  const IconComponent = getFieldIcon(field.type);

  /**
   * The default field size before any custom user resize
   * Format: [width, height]
   */
  const defaultSize = [OrderDoc.getFields()[field.type].widthPx, OrderDoc.getFields()[field.type].heightPx];

  /**
   * The style of the field, mainly establishing position
   * We want to use percentage position so it works with every zoom level
   */
  const fieldStyle = {
    position: 'absolute',
    top: `${field.topPc}%`,
    left: `${field.leftPc}%`,
  };

  /**
   * Field css classes
   */
  const cssClass = classnames(
    'doc-viewer-field',
    'doc-viewer-field-added',
    `field-type-${field.type}`,
    `signer-type-${field.signer.type}`,
    `signer-position-${field.signer.position}`,
    {
      'is-dragged': isDragging,
    }
  );

  return <React.Fragment>
    <div className={cssClass} style={fieldStyle}>
      <ResizableBox width={field.widthPx} height={field.heightPx} onResizeStop={fieldResized} minConstraints={defaultSize}>
        {/* there is a bug in the dnd library
        that makes the drag preview jump to wrong possition
        if the drag handle is not placed at the very top left of the drag item */}
        <div className="doc-viewer-field-body" ref={drag}>
          <IconComponent className="doc-viewer-field-icon" />
          <div className="doc-viewer-field-content">
            <div className="doc-viewer-field-signer-name">{field.signer.fullName}</div>
            <div className="doc-viewer-field-label" ref={fieldLabel}>
              <EditableContent defaultText={field.text} onEditFinished={fieldTextChanged} />
            </div>
          </div>
          <Dropdown isOpen={isMenuOpen} toggle={() => setIsMenuOpen(isOpen => !isOpen)} className="doc-viewer-field-menu btn-group" direction="right">
            <DropdownToggle tag="button" className="btn dropdown-toggle">
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu data-popper-placement="right-start">
              <DropdownItem tag="button" onClick={() => deleteField(field.id)}>Delete</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </ResizableBox>
    </div>
  </React.Fragment>
};

DocViewerAddedField.propTypes = {
  field: PropTypes.object,
  pageRef: PropTypes.object,
}

export default DocViewerAddedField;