import React from 'react';
import DocViewerContent from './Body/Content';
import DocViewerDragLayer from './Body/DragLayer';
import DocViewerLeftBar from './Body/LeftBar';
import DocViewerRightBar from './Body/RightBar';
import { useDocViewer } from './Context';

const DocViewerBody = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { docIsSigned } = useDocViewer();

  return <div className="doc-viewer-body">
    <DocViewerLeftBar />
    <DocViewerContent />
    {!docIsSigned() && <React.Fragment>
      <DocViewerRightBar />
      <DocViewerDragLayer />
    </React.Fragment>}
  </div>
}

export default DocViewerBody;