import React from 'react';
import MyProfile from './Section/MyProfile';
import { Row, Col } from 'reactstrap';
import Stats from './Section/Stats';
import Actions from './Section/ActionsStoreManager';
import Carousel from './Section/ResponsiveCarousel';
import Leaderboard from './Section/LeaderboardStore';
import TimeToCompletion from './Section/TimeToCompletion';
import CustomerPerformance from './Section/CustomerPerformance';

const StoreManager = () => {

  return <>
    <Row className="d-flex align-items-stretch">
      <Col xs={12} xl={4} className="mb-4"><MyProfile /></Col>
      <Col xs={12} xl={8} className="mb-4 mb-xl-0"><CustomerPerformance /></Col>
    </Row>
    <Row className="d-flex align-items-stretch">
      <Stats />
    </Row>
    <Row className="d-flex align-items-stretch">
      <Col xs={12} xl={4}><Actions /></Col>
      <Col xs={12} xl={8}><TimeToCompletion /></Col>
    </Row>
    <Row>
      <Col xs={12} xl={4}><Leaderboard /></Col>
      <Col xs={12} xl={8}><Carousel /></Col>
    </Row>
  </>
}

export default StoreManager;
