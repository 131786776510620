import {
  GET_ORDER_DOC_LIST,
  GET_UPLOADED_ORDER_DOC_LIST,
  GET_SHIPPED_ORDER_DOC_LIST,
  GET_ORDER_DOC_LIST_OK,
  GET_ORDER_DOC_LIST_ERR,
  DO_ORDER_DOC_LIST_CLEANUP,

  GET_ORDER_DOC_NORM_LIST,
  GET_UPLOADED_ORDER_DOC_NORM_LIST,
  GET_INK_SIGN_ORDER_DOC_NORM_LIST,
  GET_E_SIGN_ORDER_DOC_NORM_LIST,
  GET_FINANCE_ORDER_DOC_NORM_LIST,
  GET_SUPPORTING_ORDER_DOC_NORM_LIST,
  GET_ORDER_DOC_NORM_LIST_OK,
  GET_ORDER_DOC_NORM_LIST_ERR,
  GET_ORDER_DOC_NORM_LIST_ITEM,
  GET_ORDER_DOC_NORM_LIST_ITEM_OK,
  GET_ORDER_DOC_NORM_LIST_ITEM_ERR,
  DELETE_ORDER_DOC_NORM_LIST_ITEM,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_OK,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR,
  ACCEPT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  ACCEPT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_OK,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_ERR,
  REJECT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  REJECT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_OK,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_ERR,
  ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM,
  ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_OK,
  ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_ERR,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  DO_ORDER_DOC_NORM_LIST_CLEANUP,

  GET_DOC_VIEWER_ORDER_DOC,
  GET_DOC_VIEWER_ORDER_DOC_OK,
  GET_ORDER_DOC_ERR,
  DO_ORDER_DOC_SINGLE_CLEANUP,

  UPLOAD_ORDER_DOC,
  UPLOAD_ORDER_DOC_OK,
  UPLOAD_ORDER_DOC_ERR,
  ADD_ORDER_DOC,
  ADD_ORDER_DOC_OK,
  ADD_ORDER_DOC_ERR,
  DO_ORDER_DOC_FORM_CLEANUP,

  UPDATE_ORDER_DOC_FIELDS,
  SAVE_ORDER_DOC_FIELDS_OK,
  SAVE_ORDER_DOC_FIELDS_ERR,
  DO_ORDER_DOC_FIELDS_CLEANUP,

  ACCEPT_ALL_INK_SIGN_DOCS,
  ACCEPT_ALL_INK_SIGN_DOCS_OK,
  ACCEPT_ALL_INK_SIGN_DOCS_ERR,
  DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP,
} from './actionTypes';

/********** LIST **********/

export const getOrderDocList = orderId => ({
  type: GET_ORDER_DOC_LIST,
  payload: { orderId },
});

export const getUploadedOrderDocList = orderId => ({
  type: GET_UPLOADED_ORDER_DOC_LIST,
  payload: { orderId },
});

export const getShippedOrderDocList = orderId => ({
  type: GET_SHIPPED_ORDER_DOC_LIST,
  payload: { orderId },
});

export const getOrderDocListOk = response => ({
  type: GET_ORDER_DOC_LIST_OK,
  payload: { response },
});

export const getOrderDocListErr = error => ({
  type: GET_ORDER_DOC_LIST_ERR,
  payload: { error },
});

export const doOrderDocListCleanup = () => ({
  type: DO_ORDER_DOC_LIST_CLEANUP,
});

/********** NORMALIZED LIST **********/

export const getOrderDocNormList = orderId => ({
  type: GET_ORDER_DOC_NORM_LIST,
  payload: { orderId },
});

export const getUploadedOrderDocNormList = orderId => ({
  type: GET_UPLOADED_ORDER_DOC_NORM_LIST,
  payload: { orderId },
});

export const getInkSignOrderDocNormList = orderId => ({
  type: GET_INK_SIGN_ORDER_DOC_NORM_LIST,
  payload: { orderId },
});

export const getESignOrderDocNormList = orderId => ({
  type: GET_E_SIGN_ORDER_DOC_NORM_LIST,
  payload: { orderId },
});

export const getFinanceOrderDocNormList = orderId => ({
  type: GET_FINANCE_ORDER_DOC_NORM_LIST,
  payload: { orderId },
})

export const getSupportingOrderDocNormList = orderId => ({
  type: GET_SUPPORTING_ORDER_DOC_NORM_LIST,
  payload: { orderId },
})

export const getOrderDocNormListOk = response => ({
  type: GET_ORDER_DOC_NORM_LIST_OK,
  payload: { response },
});

export const getOrderDocNormListErr = error => ({
  type: GET_ORDER_DOC_NORM_LIST_ERR,
  payload: { error },
});


export const getOrderDocNormListItem = id => ({
  type: GET_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id },
});

export const getOrderDocNormListItemOk = (id, response) => ({
  type: GET_ORDER_DOC_NORM_LIST_ITEM_OK,
  payload: { id, response },
});

export const getOrderDocNormListItemErr = (id, error) => ({
  type: GET_ORDER_DOC_NORM_LIST_ITEM_ERR,
  payload: { id, error },
});


export const deleteOrderDocNormListItem = id => ({
  type: DELETE_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id },
});

export const deleteOrderDocNormListItemOk = (id, response) => ({
  type: DELETE_ORDER_DOC_NORM_LIST_ITEM_OK,
  payload: { id, response },
});

export const deleteOrderDocNormListItemErr = (id, error) => ({
  type: DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR,
  payload: { id, error },
});


export const reprocessOrderDocNormListItem = id => ({
  type: REPROCESS_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id },
});

export const reprocessOrderDocNormListItemOk = (id, response) => ({
  type: REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK,
  payload: { id, response },
});

export const reprocessOrderDocNormListItemErr = (id, error) => ({
  type: REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR,
  payload: { id, error },
});


export const acceptESignOrderDocNormListItem = id => ({
  type: ACCEPT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id },
});

export const acceptInkSignOrderDocNormListItem = id => ({
  type: ACCEPT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id },
});

export const acceptOrderDocNormListItemOk = (id, response) => ({
  type: ACCEPT_ORDER_DOC_NORM_LIST_ITEM_OK,
  payload: { id, response },
});

export const acceptOrderDocNormListItemErr = (id, error) => ({
  type: ACCEPT_ORDER_DOC_NORM_LIST_ITEM_ERR,
  payload: { id, error },
});


export const rejectESignOrderDocNormListItem = (id, data) => ({
  type: REJECT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id, data },
});

export const rejectInkSignOrderDocNormListItem = (id, data) => ({
  type: REJECT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id, data },
});

export const rejectOrderDocNormListItemOk = (id, response) => ({
  type: REJECT_ORDER_DOC_NORM_LIST_ITEM_OK,
  payload: { id, response },
});

export const rejectOrderDocNormListItemErr = (id, error) => ({
  type: REJECT_ORDER_DOC_NORM_LIST_ITEM_ERR,
  payload: { id, error },
});


export const addPageToOrderDocNormListItem = id => ({
  type: ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id },
});

export const addPageToOrderDocNormListItemOk = (id, response) => ({
  type: ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_OK,
  payload: { id, response },
});

export const addPageToOrderDocNormListItemErr = (id, error) => ({
  type: ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_ERR,
  payload: { id, error },
});


export const acceptOrderDocNormListItemPage = (orderDocId, pageNum) => ({
  type: ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  payload: { orderDocId, pageNum },
});

export const acceptOrderDocNormListItemPageOk = (orderDocId, pageNum, response) => ({
  type: ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  payload: { orderDocId, pageNum, response },
});

export const acceptOrderDocNormListItemPageErr = (orderDocId, pageNum, error) => ({
  type: ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  payload: { orderDocId, pageNum, error },
});


export const rejectOrderDocNormListItemPage = (orderDocId, pageNum, data) => ({
  type: REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  payload: { orderDocId, pageNum, data },
});

export const rejectOrderDocNormListItemPageOk = (orderDocId, pageNum, response) => ({
  type: REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  payload: { orderDocId, pageNum, response },
});

export const rejectOrderDocNormListItemPageErr = (orderDocId, pageNum, error) => ({
  type: REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  payload: { orderDocId, pageNum, error },
});


export const deleteOrderDocNormListItemPage = (orderDocId, pageNum) => ({
  type: DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  payload: { orderDocId, pageNum },
});

export const deleteOrderDocNormListItemPageOk = (orderDocId, pageNum, response) => ({
  type: DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  payload: { orderDocId, pageNum, response },
});

export const deleteOrderDocNormListItemPageErr = (orderDocId, pageNum, error) => ({
  type: DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  payload: { orderDocId, pageNum, error },
});


export const addPageBeforeOrderDocNormListItemPage = (orderDocId, pageNum) => ({
  type: ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  payload: { orderDocId, pageNum },
});

export const addPageBeforeOrderDocNormListItemPageOk = (orderDocId, pageNum, response) => ({
  type: ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  payload: { orderDocId, pageNum, response },
});

export const addPageBeforeOrderDocNormListItemPageErr = (orderDocId, pageNum, error) => ({
  type: ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  payload: { orderDocId, pageNum, error },
});


export const doOrderDocNormListCleanup = () => ({
  type: DO_ORDER_DOC_NORM_LIST_CLEANUP,
});

/********** SINGLE **********/

export const getDocViewerOrderDoc = id => ({
  type: GET_DOC_VIEWER_ORDER_DOC,
  payload: { id },
});

export const getDocViewerOrderDocOk = (docResponse, signersResponse, signingsResponse) => ({
  type: GET_DOC_VIEWER_ORDER_DOC_OK,
  payload: { docResponse, signersResponse, signingsResponse },
});

export const getOrderDocErr = error => ({
  type: GET_ORDER_DOC_ERR,
  payload: { error },
});

export const doOrderDocSingleCleanup = () => ({
  type: DO_ORDER_DOC_SINGLE_CLEANUP,
});

/********** FORM **********/

export const uploadOrderDoc = (data, orderId) => ({
  type: UPLOAD_ORDER_DOC,
  payload: { data, orderId },
});

export const uploadOrderDocOk = response => ({
  type: UPLOAD_ORDER_DOC_OK,
  payload: { response },
});

export const uploadOrderDocErr = error => ({
  type: UPLOAD_ORDER_DOC_ERR,
  payload: { error },
});

export const addOrderDoc = (data, orderId) => ({
  type: ADD_ORDER_DOC,
  payload: { data, orderId },
});

export const addOrderDocOk = response => ({
  type: ADD_ORDER_DOC_OK,
  payload: { response },
});

export const addOrderDocErr = error => ({
  type: ADD_ORDER_DOC_ERR,
  payload: { error },
});

export const doOrderDocFormCleanup = () => ({
  type: DO_ORDER_DOC_FORM_CLEANUP,
});

/********** FIELDS **********/

export const updateOrderDocFields = (data, id) => ({
  type: UPDATE_ORDER_DOC_FIELDS,
  payload: { data, id },
});

export const saveOrderDocFieldsOk = response => ({
  type: SAVE_ORDER_DOC_FIELDS_OK,
  payload: { response },
});

export const saveOrderDocFieldsErr = error => ({
  type: SAVE_ORDER_DOC_FIELDS_ERR,
  payload: { error },
});

export const doOrderDocFieldsCleanup = () => ({
  type: DO_ORDER_DOC_FIELDS_CLEANUP,
});

/************* ACCEPT ALL *************/

export const acceptAllInkSignDocuments = (orderId) => ({
  type: ACCEPT_ALL_INK_SIGN_DOCS,
  payload: { orderId },
});

export const acceptAllInkSignDocumentsOk = response => ({
  type: ACCEPT_ALL_INK_SIGN_DOCS_OK,
  payload: { response },
});

export const acceptAllInkSignDocumentsErr = error => ({
  type: ACCEPT_ALL_INK_SIGN_DOCS_ERR,
  payload: { error },
});

export const doAcceptAllInkSignDocumentsCleanup = () => ({
  type: DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP,
});