import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import classnames from 'classnames';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDocViewer } from '../../Context';
import { isEqual } from 'lodash';

const DocViewerAvailableField = props => {

  /**
   * Component props:
   * field {object} the signature field
   */
  const { field } = props;

  /**
   * Context vars (see: Context.js)
   */
  const { selectedField, setSelectedField, getFieldIcon, isDropReady } = useDocViewer();

  /**
   * This hook wires in the component as a drag source
   * {@link https://react-dnd.github.io/react-dnd/docs/api/use-drag}
   */
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'order-doc-available-field',
    item: field,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [field]);

  /**
   * This effect runs once on component mount
   * We use it to prepare the custom drag preview
   * {@link https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer}
   */
  useEffect(() => {
    // replace the default drag preview with an empty image
    // this is so we can later add our own custom preview via a drag layer
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  /**
   * Event handler called whenever the user clicks on an available field
   * Selects/deselects the respective field so it can/cannot be inserted via click
   */
  const toggleSelectedField = () => setSelectedField(selected => isEqual(selected, field) ? null : field);

  /**
   * The icon for this field type
   * @type {ReactComponent}
   */
  const IconComponent = getFieldIcon(field.type);

  /**
   * Field css classes
   */
  const cssClass = classnames(
    'doc-viewer-field',
    'doc-viewer-field-available',
    `signer-type-${field.signer.type}`,
    `signer-position-${field.signer.position}`,
    {
      'is-dragged': isDragging,
      selected: isEqual(selectedField, field),
      disabled: !isDropReady,
    }
  );

  return <div ref={drag} className={cssClass} onClick={toggleSelectedField}>
    <div className="doc-viewer-field-body">
      <IconComponent className="doc-viewer-field-icon" />
      <div className="doc-viewer-field-content">
        <div className="doc-viewer-field-label">
          <div className="doc-viewer-field-text">{field.text}</div>
        </div>
      </div>
    </div>
  </div>
}

DocViewerAvailableField.propTypes = {
  field: PropTypes.object,
}

export default DocViewerAvailableField;