import { combineReducers } from "redux"
import {
  GET_PAYMENT_DT,
  GET_PAYMENT_DT_OK,
  GET_PAYMENT_DT_ERR,
  DO_PAYMENT_DT_CLEANUP,

  APPLY_PAYMENT_DT_FILTERS,
  PATCH_PAYMENT_DT_FILTERS,
  CLEAR_PAYMENT_DT_FILTERS,
  UNSET_PAYMENT_DT_FILTERS,
  DO_PAYMENT_DT_FILTERS_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  payments: [], // datatable rows
  paymentsError: null, // datatable error
  totalCount: 0, // datatable total row count without limit needed for pagination
  listParams: {
    sortBy: "id", // the default datatable sort column
    sortDir: "desc", // the default datatable sort order
    pageSize: 50, // the default datatable page size
    page: 1, // the default datatable page
    search: null, // the default datatable search phrase
    filters: null, // the default datatable filters (do not set from here)
  }, // datatable params passed to backend
  isLoadInProgress: false, // whether a remote request is in progress
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_PAYMENT_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        paymentsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_PAYMENT_DT_OK:
      state = {
        ...state,
        payments: action.payload.response.payments,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_PAYMENT_DT_ERR:
      state = {
        ...state,
        payments: [],
        totalCount: 0,
        paymentsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_PAYMENT_DT_CLEANUP:
      state = {
        ...state,
        payments: [],
        paymentsError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

// default datatable filters to be applied as the visitor first enters the screen
// most of the time we do not want to have default filters so these values will be empty
// however there may be times when we want the list filtered by default
const defaultDtFiltersState = {
  status: "",
  createdBetween: "",
  _set: false,
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_PAYMENT_DT_FILTERS:
      state = {
        ...action.payload.filters,
        _set: true,
      };
      break
    case PATCH_PAYMENT_DT_FILTERS:
      state = {
        ...state,
        ...action.payload.filters,
        _set: true,
      };
      break
    case CLEAR_PAYMENT_DT_FILTERS:
      state = {
        ...defaultDtFiltersState,
        createdBetween: state.createdBetween,
        _set: true,
      };
      break
    case UNSET_PAYMENT_DT_FILTERS:
      state = {
        ...state,
        _set: false,
      };
      break
    case DO_PAYMENT_DT_FILTERS_CLEANUP:
      state = {
        ...defaultDtFiltersState,
      };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
  DtFilters,
});
