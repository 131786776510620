import React from 'react';
import PropTypes from 'prop-types';
import { useDocViewer } from '../../Context';
import { UncontrolledAccordion } from 'reactstrap';
import DocViewerSignerMenu from './SignerMenu';
import OrderSigner from 'model/orderSigner';

const DocViewerSignersMenu = props => {

  /**
   * Component props:
   * type {number} the orderSigner type
   */
  const { type } = props;

  /**
   * Context vars (see: Context.js)
   */
  const { orderDoc } = useDocViewer();

  /**
   * Returns a list of signers of the current type
   * @returns {array}
   */
  const getSigners = () => orderDoc.signers.filter(s => s.type == type);

  /**
   * Returns a list of accordion item ids that should be open by default
   * At the moment this contains only the primary signer
   * @returns {array}
   */
  const getDefaultOpenSigners = () => {
    if (type != OrderSigner.TYPE_CUSTOMER) {
      return [];
    }
    const firstSignerId = getSigners()[0].id;
    return ['signer' + firstSignerId];
  }

  return <div className="doc-viewer-signers-menu">
    <UncontrolledAccordion stayOpen flush defaultOpen={getDefaultOpenSigners()}>
      {getSigners().map(signer => <DocViewerSignerMenu key={signer.id} signer={signer} />)}
    </UncontrolledAccordion>
  </div>
}

DocViewerSignersMenu.propTypes = {
  type: PropTypes.number,
}

export default DocViewerSignersMenu;