import { route, routes } from "helpers/routeHelper";
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

const ActionsRegional = () => {

  return <>
    <Card className="action-card">
      <CardBody className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="icon-circle avatar-sm align-self-center">
            <Link to={route(routes.new_user)}>
              <span className="stats-icon purple-icon-color">
                <i className="bx bx-user font-size-24" />
              </span>
            </Link>
          </div>
          <Link to={route(routes.new_user)} className="action-link">
            <p className="font-size-24 mb-0">Create new user</p>
          </Link>
        </div>
      </CardBody>
    </Card>
    <Card className="action-card">
      <CardBody className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="icon-circle avatar-sm align-self-center">
            <Link to={route(routes.new_dealer_store)}>
              <span className="stats-icon primary-icon-color-light">
                <i className="mdi mdi-plus font-size-24" />
              </span>
            </Link>
          </div>
          <Link to={route(routes.new_dealer_store)} className="action-link">
            <p className="font-size-24 mb-0">Create new store</p>
          </Link>
        </div>
      </CardBody>
    </Card>
    {/* <Card className="action-card">
      <CardBody className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="icon-circle avatar-sm align-self-center">
            <span className="stats-icon green-icon-color">
              <i className="mdi mdi-clipboard-edit-outline font-size-24" />
            </span>
          </div>
          <Link to="/" className="action-link">
            <p className="font-size-24 mb-0">Create reports</p>
          </Link>
        </div>
      </CardBody>
    </Card> */}
  </>
}

export default ActionsRegional;