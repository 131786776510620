import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DocViewerFieldSignature from './SignedField/Signature';

const DocViewerSignedField = props => {

  /**
   * Component props:
   * field {object} the signature field
   */
  const { field } = props;

  /**
   * The style of the field, mainly establishing position and size
   * We want to use percentage position/size so it works with every zoom level
   */
  const fieldStyle = {
    position: 'absolute',
    top: `${field.topPc}%`,
    left: `${field.leftPc}%`,
    width: `${field.widthPc}%`,
    height: `${field.heightPc}%`,
  };

  /**
   * Field css classes
   */
  const cssClass = classnames(
    'doc-viewer-field',
    'doc-viewer-field-signed',
    'user-select-none',
    'pe-none'
  );

  return <React.Fragment>
    <div className={cssClass} style={fieldStyle}>
      <div className="doc-viewer-field-body">
        <div className="doc-viewer-field-content">
          <DocViewerFieldSignature field={field} />
        </div>
      </div>
    </div>
  </React.Fragment>
};

DocViewerSignedField.propTypes = {
  field: PropTypes.object,
}

export default DocViewerSignedField;