import { combineReducers } from "redux";

import {
  APPLY_GROUP_USAGE_REPORT_FILTERS,
  DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP,
  CLEAR_GROUP_USAGE_REPORT_FILTERS,
  GET_GROUP_USAGE_REPORT,
  GET_GROUP_USAGE_REPORT_OK,
  GET_GROUP_USAGE_REPORT_ERR,
  DO_GROUP_USAGE_REPORT_CLEANUP,

  GET_DEALER_SALES_REPORT,
  GET_DEALER_SALES_REPORT_OK,
  GET_DEALER_SALES_REPORT_ERR,
  DO_DEALER_SALES_REPORT_CLEANUP,
  APPLY_DEALER_SALES_REPORT_FILTERS,
  CLEAR_DEALER_SALES_REPORT_FILTERS,
  DO_DEALER_SALES_REPORT_FILTERS_CLEANUP,
} from "./actionTypes";

/********** GROUP USAGE REPORT **********/

const defaultGroupUsageReportState = {
  stores: [],
  stats: {},
  reportError: null,
  listParams: {
    filters: null,
    pageSize: 10,
    page: 1,
  },
  totalCount: 0,
  isLoadInProgress: false,
};

const GroupUsage = (state = defaultGroupUsageReportState, action) => {
  switch (action.type) {
    case GET_GROUP_USAGE_REPORT:
      state = {
        ...state,
        listParams: action.payload.params,
        reportError: null,
        isLoadInProgress: true,
      };
      break
    case GET_GROUP_USAGE_REPORT_OK:
      state = {
        ...state,
        stores: action.payload.response.stores,
        stats: action.payload.response.stats,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_GROUP_USAGE_REPORT_ERR:
      state = {
        ...state,
        stores: [],
        stats: {},
        totalCount: 0,
        reportError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_GROUP_USAGE_REPORT_CLEANUP:
      state = {
        ...state,
        stores: [],
        stats: {},
        reportError: null,
      };
      break
  }
  return state;
}

/********** GROUP USAGE REPORT FILTERS **********/

const defaultGroupUsageFiltersState = {
  startTs: '',
  endTs: '',
  region: '',
  store: ''
};

const GroupUsageFilters = (state = defaultGroupUsageFiltersState, action) => {
  switch (action.type) {
    case APPLY_GROUP_USAGE_REPORT_FILTERS:
      state = {
        ...action.payload.filters,
      };
      break
    case CLEAR_GROUP_USAGE_REPORT_FILTERS:
      state = {
        ...state,
        region: '',
        store: ''
      };
      break
    case DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP:
      state = {
        ...defaultGroupUsageFiltersState,
      };
      break
  }
  return state;
}


/********** DEALER SALES REPORT **********/

const defaultDealerSalesReportState = {
  stores: [],
  reportError: null,
  listParams: {
    filters: null,
    pageSize: 10,
    page: 1,
  },
  totalCount: 0,
  isLoadInProgress: false,
};

const DealerSales = (state = defaultDealerSalesReportState, action) => {
  switch (action.type) {
    case GET_DEALER_SALES_REPORT:
      state = {
        ...state,
        listParams: action.payload.params,
        reportError: null,
        isLoadInProgress: true,
      };
      break
    case GET_DEALER_SALES_REPORT_OK:
      state = {
        ...state,
        stores: action.payload.response.stores,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_DEALER_SALES_REPORT_ERR:
      state = {
        ...state,
        stores: [],
        totalCount: 0,
        reportError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_DEALER_SALES_REPORT_CLEANUP:
      state = {
        ...state,
        stores: [],
        reportError: null,
      };
      break
  }
  return state;
}

/********** DEALER SALES REPORT FILTERS **********/

const defaultDealerSalesFiltersState = {
  startTs: '',
  endTs: '',
  region: '',
  store: ''
};

const DealerSalesFilters = (state = defaultDealerSalesFiltersState, action) => {
  switch (action.type) {
    case APPLY_DEALER_SALES_REPORT_FILTERS:
      state = {
        ...action.payload.filters,
      };
      break
    case CLEAR_DEALER_SALES_REPORT_FILTERS:
      state = {
        ...state,
        region: '',
        store: ''
      };
      break
    case DO_DEALER_SALES_REPORT_FILTERS_CLEANUP:
      state = {
        ...defaultDealerSalesFiltersState,
      };
      break
  }
  return state;
}

export default combineReducers({
  GroupUsage,
  GroupUsageFilters,
  DealerSales,
  DealerSalesFilters
});