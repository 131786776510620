import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback, CardTitle } from "reactstrap";
import Select from "react-select";
import { nullsToEmptyStrings, phoneHasNoOfDigits, showBriefError, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import UsStates from "constants/usState";
import { ValidationException } from "helpers/errorHelper";
import regx from "constants/regx";
import { updateDealerStoreContact } from "helpers/backendHelper";

const FormContactEdit = props => {

  const { defaultValues, id, finishedHandler } = props;

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    phone2: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      phone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone1',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      phone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone2',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      state: Yup.string().trim().required('Field is required'),
    }),
    onSubmit: values => saveDealerStoreContact(values, id),
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveDealerStoreContact = (values, id) => {
    setIsSaveInProgress(true);
    updateDealerStoreContact(values, id)
      .then(response => {
        showSuccess(`Store "${values.name}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save store');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <CardTitle>Contact</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Address</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 2273 Bel Meadow Drive" name="address" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.address} invalid={!!formik.errors.address} />
              {!!formik.errors.address && <FormFeedback type="invalid">{formik.errors.address}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">City</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. New Jersey" name="city" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.city} invalid={!!formik.errors.city} />
              {!!formik.errors.city && <FormFeedback type="invalid">{formik.errors.city}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">State *</Label>
            <Col sm={8}>
              <Select
                classNamePrefix="select2-selection"
                name="state"
                options={usStates}
                onChange={selected => formik.setFieldValue('state', selected.value)}
                onFocus={e => onFieldFocused(e, 'state')}
                value={usStates.find(option => option.value === formik.values.state)}
                className={!!formik.errors.state && 'is-invalid'} />
              {!!formik.errors.state && <FormFeedback type="invalid">{formik.errors.state}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Zip</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 90017" name="zip" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.zip} invalid={!!formik.errors.zip} />
              {!!formik.errors.zip && <FormFeedback type="invalid">{formik.errors.zip}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="phone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.phone} invalid={!!formik.errors.phone} />
              {!!formik.errors.phone && <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Alternate phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="phone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.phone2} invalid={!!formik.errors.phone2} />
              {!!formik.errors.phone2 && <FormFeedback type="invalid">{formik.errors.phone2}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

const usStates = toSelectOptions(UsStates);

FormContactEdit.propTypes = {
  defaultValues: PropTypes.object,
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
};

export default FormContactEdit;