import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Button, CardTitle, Table } from "reactstrap";
import Col from "components/Shared/Col";
import { perms, useAccess } from 'context/access';
import CardPreloader from 'components/Shared/CardPreloader';
import { formatPhone } from 'helpers/utilHelper';

const ViewBilling = props => {

  const { isRefreshing, dealerStore, toggleEditMode, hasEdit } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  return <React.Fragment>
    <Card className="no-shadow">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Billing Information</CardTitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {hasEdit && iAmGranted(perms.edit_dealer_stores) && <Button type="button" color="light" className="btn-sm" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit
              </Button>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Table className="section-rows bt-1 bb-0">
          <tbody>
            <tr>
              <td>Billing contact</td>
              <td className="font-weight-500">{dealerStore.billingName || '--'}</td>
            </tr>
            <tr>
              <td>Account number</td>
              <td className="font-weight-500">{dealerStore.billingAccountNo || '--'}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td className="font-weight-500">{dealerStore.billingEmail || '--'}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td className="font-weight-500">{dealerStore.billingPhone ? formatPhone(dealerStore.billingPhone) : '--'}</td>
            </tr>
            <tr>
              <td>Alternate phone</td>
              <td className="font-weight-500">{dealerStore.billingPhone2 ? formatPhone(dealerStore.billingPhone2) : '--'}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewBilling.propTypes = {
  isRefreshing: PropTypes.bool,
  dealerStore: PropTypes.object,
  toggleEditMode: PropTypes.func,
  hasEdit: PropTypes.bool,
};

export default ViewBilling;
