import {
  GET_ORDERS_STATS,
  GET_ORDERS_STATS_OK,
  GET_ORDER_STATS_ERR,
  DO_ORDER_STATS_CLEANUP,

  GET_USER_LEADERBOARD,
  GET_STORE_LEADERBOARD,
  GET_USER_LEADERBOARD_OK,
  GET_STORE_LEADERBOARD_OK,
  GET_LEADERBOARD_ERR,
  DO_LEADERBOARD_CLEANUP,

  GET_USER_LEADERBOARD_LIST,
  GET_STORE_LEADERBOARD_LIST,
  GET_USER_LEADERBOARD_LIST_OK,
  GET_STORE_LEADERBOARD_LIST_OK,
  GET_LEADERBOARD_LIST_ERR,
  DO_LEADERBOARD_LIST_CLEANUP,

  GET_CUSTOMER_PERFORMANCE,
  GET_CUSTOMER_PERFORMANCE_ERR,
  GET_CUSTOMER_PERFORMANCE_OK,
  DO_CUSTOMER_PERFORMANCE_CLEANUP,

  GET_TIME_TO_COMPLETION,
  GET_TIME_TO_COMPLETION_OK,
  GET_TIME_TO_COMPLETION_ERR,
  DO_TIME_TO_COMPLETION_CLEANUP,
} from "./actionTypes";

/********** STATS **********/

export const getOrderStats = () => ({
  type: GET_ORDERS_STATS,
});

export const getOrderStatsOk = response => ({
  type: GET_ORDERS_STATS_OK,
  payload: { response },
});

export const getOrderStatsErr = error => ({
  type: GET_ORDER_STATS_ERR,
  payload: { error },
});

export const doOrderStatsCleanup = () => ({
  type: DO_ORDER_STATS_CLEANUP,
})

/********** LEADERBOARD **********/

export const getUserLeaderboard = params => ({
  type: GET_USER_LEADERBOARD,
  payload: { params },
});

export const getStoreLeaderboard = params => ({
  type: GET_STORE_LEADERBOARD,
  payload: { params },
});

export const getUserLeaderboardOk = response => ({
  type: GET_USER_LEADERBOARD_OK,
  payload: { response },
});

export const getStoreLeaderboardOk = response => ({
  type: GET_STORE_LEADERBOARD_OK,
  payload: { response },
});

export const getLeaderboardErr = error => ({
  type: GET_LEADERBOARD_ERR,
  payload: { error },
});

export const doLeaderboardCleanup = () => ({
  type: DO_LEADERBOARD_CLEANUP,
});

/********** LEADERBOARD LIST **********/

export const getUserLeaderboardList = params => ({
  type: GET_USER_LEADERBOARD_LIST,
  payload: { params },
});

export const getStoreLeaderboardList = params => ({
  type: GET_STORE_LEADERBOARD_LIST,
  payload: { params },
});

export const getUserLeaderboardListOk = response => ({
  type: GET_USER_LEADERBOARD_LIST_OK,
  payload: { response },
});

export const getStoreLeaderboardListOk = response => ({
  type: GET_STORE_LEADERBOARD_LIST_OK,
  payload: { response },
});

export const getLeaderboardListErr = error => ({
  type: GET_LEADERBOARD_LIST_ERR,
  payload: { error },
});

export const doLeaderboardListCleanup = () => ({
  type: DO_LEADERBOARD_LIST_CLEANUP,
});

/********** CUSTOMER PERFORMANCE **********/

export const getCustomerPerformance = (unit, customerLocation) => ({
  type: GET_CUSTOMER_PERFORMANCE,
  payload: { unit, customerLocation },
});

export const getCustomerPerformanceOk = response => ({
  type: GET_CUSTOMER_PERFORMANCE_OK,
  payload: { response },
});

export const getCustomerPerformanceErr = error => ({
  type: GET_CUSTOMER_PERFORMANCE_ERR,
  payload: { error },
});

export const doCustomerPerformanceCleanup = () => ({
  type: DO_CUSTOMER_PERFORMANCE_CLEANUP,
});

/********** TIME TO COMPLETION **********/

export const getTimeToCompletion = (unit, params) => ({
  type: GET_TIME_TO_COMPLETION,
  payload: { unit, params },
});

export const getTimeToCompletionOk = response => ({
  type: GET_TIME_TO_COMPLETION_OK,
  payload: { response },
});

export const getTimeToCompletionErr = error => ({
  type: GET_TIME_TO_COMPLETION_ERR,
  payload: { error },
});

export const doTimeToCompletionCleanup = () => ({
  type: DO_TIME_TO_COMPLETION_CLEANUP,
});