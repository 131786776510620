import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormInfoEditOwnGroup from '../Form/Info/Edit/OwnGroup';
import FormInfoEditOwnRegion from '../Form/Info/Edit/OwnRegion';
import FormInfoEditOwnStore from '../Form/Info/Edit/OwnStore';
import ViewInfo from '../View/Info';
import { perms, useAccess } from 'context/access';

const SectionInfo = props => {

  const { isLoadBusy, id, user, refreshHandler } = props;

  const { iAmGranted } = useAccess();

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // TRUE after a save and untill the data is refreshed
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  let UserForm;
  if (iAmGranted(perms.view_own_group_users)) {
    UserForm = FormInfoEditOwnGroup;
  } else if (iAmGranted(perms.view_own_region_users)) {
    UserForm = FormInfoEditOwnRegion;
  } else {
    UserForm = FormInfoEditOwnStore;
  }

  return <React.Fragment>
    {isEditModeActive && <UserForm id={id} defaultValues={user} finishedHandler={toggleEditMode} />}
    {!isEditModeActive && <ViewInfo id={id} user={user} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

SectionInfo.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  user: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default SectionInfo;
