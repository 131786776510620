import React from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Modal, Alert, FormFeedback, Form, Row, Button } from "reactstrap";
import classnames from "classnames";
import TextareaAutosize from 'react-textarea-autosize';
import { useFormik } from "formik";
import * as Yup from "yup";
import Col from 'components/Shared/Col';

const InkSignRejectModal = props => {

  const { isOpen, onCancel, onReject, order, orderDoc } = props;

  /********** FORM CONFIG **********/

  const formInitialValues = {
    reason: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      reason: Yup.string().trim().required('Field is required'),
    }),
    onSubmit: values => {
      onReject(values.reason);
      formik.setFieldValue('reason', '');
      formik.setSubmitting(false);
    },
  });

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  };

  const onClose = () => {
    onCancel();
    formik.setFieldValue('reason', '');
  }

  return <Modal centered isOpen={isOpen} toggle={onClose} className="reject-doc-page-modal">
    <div className="modal-header">
      <h5 className="modal-title mt-0">Reject page reason</h5>
      <button type="button" onClick={onClose} className="close" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <Form>
        <Row className="mb-3">
          <Col>
            <Alert color="danger" className="fade show text-center">Please let the {!order?.isNotaryRequired ? 'customer' : 'notary '} know why he needs to {orderDoc?.isCapturedAsPdf ? 'reupload the pdf:' : 'retake the picture:'}</Alert>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <TextareaAutosize maxRows={15} className={classnames('form-control', { 'is-invalid': !!formik.errors.reason })} name="reason" placeholder="ex. Missing initials" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.reason} />
            {!!formik.errors.reason && <FormFeedback type="invalid">{formik.errors.reason}</FormFeedback>}
          </Col>
        </Row>
        <Row>
          <Col className="text-end">
            <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>Send</Button>
          </Col>
        </Row>
      </Form>
    </div>
  </Modal>
}

InkSignRejectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['doc', 'page']),
  order: PropTypes.object,
  id: PropTypes.number,
  orderDoc: PropTypes.object
};

export default InkSignRejectModal;