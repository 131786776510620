import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import Col from "components/Shared/Col";
import { route, routes } from 'helpers/routeHelper';
import ViewPaymentInfo from '../View/PaymentInfo';
import { useSubscriptionSetup } from 'context/subscriptionSetup';

const SectionPaymentInfo = props => {

  const { navRoutes, toggleEditMode } = props;

  const { dealerStore, isPaymentSetupRequired } = useSubscriptionSetup();

  return <React.Fragment>
    <Row>
      <Col xl="4">
        <ViewPaymentInfo dealerStore={dealerStore} hasEdit={true} toggleEditMode={toggleEditMode} />
      </Col>
    </Row>
    <Row className="mb-2">
      <Col className="text-end">
        {!isPaymentSetupRequired() && <Link to={route(routes.view_subscription)} className="btn btn-secondary btn-faded">Quit</Link>}
        {navRoutes.prev && <Link to={route(navRoutes.prev)} className="btn btn-primary btn-faded ms-2">Previous</Link>}
        {navRoutes.next && <Link to={route(navRoutes.next)} className="btn btn-primary ms-2">Next</Link>}
      </Col>
    </Row>
  </React.Fragment>
}

SectionPaymentInfo.propTypes = {
  navRoutes: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default SectionPaymentInfo;
