import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';
import Auth from './auth/reducer';
import User from './user/reducer';
import UserRole from './userRole/reducer';
import DealerRegion from './dealerRegion/reducer';
import DealerStore from './dealerStore/reducer';
import Order from './order/reducer';
import OrderDoc from './orderDoc/reducer';
import Settings from './settings/reducer';
import CustomerCall from './customerCall/reducer';
import Dashboard from './dashboard/reducer';
import Notification from './notification/reducer';
import Statement from './statement/Dealer/reducer';
import Subscription from './subscription/reducer';
import Message from './message/reducer';
import VidRequest from './vidRequest/reducer';
import Payment from './payment/reducer';
import Report from './report/reducer';

export default combineReducers({
  Layout,
  Auth,
  User,
  UserRole,
  DealerRegion,
  DealerStore,
  Order,
  OrderDoc,
  Settings,
  CustomerCall,
  Dashboard,
  Notification,
  Statement,
  Subscription,
  Message,
  VidRequest,
  Payment,
  Report
})
