import {
  GET_DEALER_REGION_DT,
  GET_DEALER_REGION_DT_OK,
  GET_DEALER_REGION_DT_ERR,
  DO_DEALER_REGION_DT_CLEANUP,
  APPLY_DEALER_REGION_DT_FILTERS,
  CLEAR_DEALER_REGION_DT_FILTERS,

  GET_DEALER_GROUP_REGION_LIST,
  GET_DEALER_REGION_LIST_OK,
  GET_DEALER_REGION_LIST_ERR,
  DO_DEALER_REGION_LIST_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

export const getDealerRegionDt = params => ({
  type: GET_DEALER_REGION_DT,
  payload: { params },
});

export const getDealerRegionDtOk = response => ({
  type: GET_DEALER_REGION_DT_OK,
  payload: { response },
});

export const getDealerRegionDtErr = error => ({
  type: GET_DEALER_REGION_DT_ERR,
  payload: { error },
});

export const doDealerRegionDtCleanup = () => ({
  type: DO_DEALER_REGION_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyDealerRegionDtFilters = filters => ({
  type: APPLY_DEALER_REGION_DT_FILTERS,
  payload: { filters },
});

export const clearDealerRegionDtFilters = () => ({
  type: CLEAR_DEALER_REGION_DT_FILTERS,
});

/********* LIST *********/

export const getDealerGroupRegionList = groupId => ({
  type: GET_DEALER_GROUP_REGION_LIST,
  payload: { groupId }
});

export const getDealerRegionListOk = response => ({
  type: GET_DEALER_REGION_LIST_OK,
  payload: { response },
});

export const getDealerRegionListErr = error => ({
  type: GET_DEALER_REGION_LIST_ERR,
  payload: { error },
});

export const doDealerRegionListCleanup = () => ({
  type: DO_DEALER_REGION_LIST_CLEANUP,
});