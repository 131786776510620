import {
  GET_USER_DT,
  GET_USER_DT_OK,
  GET_USER_DT_ERR,
  DO_USER_DT_CLEANUP,
  APPLY_USER_DT_FILTERS,
  PATCH_USER_DT_FILTERS,
  CLEAR_USER_DT_FILTERS,
  UNSET_USER_DT_FILTERS,
} from "./actionTypes";

/********** DATATABLE **********/

export const getUserDt = params => ({
  type: GET_USER_DT,
  payload: { params },
});

export const getUserDtOk = response => ({
  type: GET_USER_DT_OK,
  payload: { response },
});

export const getUserDtErr = error => ({
  type: GET_USER_DT_ERR,
  payload: { error },
});

export const doUserDtCleanup = () => ({
  type: DO_USER_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyUserDtFilters = filters => ({
  type: APPLY_USER_DT_FILTERS,
  payload: { filters },
});

export const patchUserDtFilters = filters => ({
  type: PATCH_USER_DT_FILTERS,
  payload: { filters },
});

export const clearUserDtFilters = () => ({
  type: CLEAR_USER_DT_FILTERS,
});

export const unsetUserDtFilters = () => ({
  type: UNSET_USER_DT_FILTERS,
});