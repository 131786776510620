import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { route, routes } from "helpers/routeHelper";
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import { doOrderSingleCleanup, getFullOrder, sealOrder } from "store/actions";
import shield from 'assets/images/shield-white.svg';
import VidInformation from "../Partial/Seal/VidInformation";
import ESignInformation from "../Partial/Seal/ESignInformation";
import InkSignInformation from "../Partial/Seal/InkSignInformation";
import CustomerInfo from "../Partial/Seal/CustomerInfo";
import ESignDocs from "../Partial/Seal/ESignDocs";
import InkSignDocs from "../Partial/Seal/InkSignDocs";
import Order from "model/order";
import OrderDoc from "model/orderDoc";
import { getBeUrl, orderIsLocked, showError, showSuccess } from "helpers/utilHelper";
import Confirmation from "components/Shared/Confirmation";
import classnames from "classnames";
import SealBadge from "../Partial/Seal/Badge";
import { useSocketOn, useSubscribeToOrder } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import SupportingInformation from "../Partial/Seal/SupportingInformation";
import sealIcon from "assets/images/seal-icon-popup.svg";
import ESignForm from "../Partial/Seal/Forms/ESignDocs";
import SupportingForm from "../Partial/Seal/Forms/SupportingDocs";
import InkSignForm from "../Partial/Seal/Forms/InkSignDocs";
import PreviewForm from "../Partial/Seal/Forms/PreviewDocs";

const Seal = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress, sealed, isSealInProgress } = useSelector(state => state.Order.Single);

  // is the confirmation dialog visible or not
  // used to show/hide the seal warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isConfirmationDocumentsVisible, setIsConfirmationDocumentsVisible] = useState(false);

  // documents selected from imported Forms
  const [selectedEsignDocs, setSelectedEsignDocs] = useState({});
  const [selectedInkSignDocs, setSelectedInkSignDocs] = useState({});
  const [selectedSupportingDocs, setSelectedSupportingDocs] = useState({});
  const [selectedPreviewDocs, setSelectedPreviewDocs] = useState({});

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getFullOrder(id));
    return () => {
      dispatch(doOrderSingleCleanup());
    };
  }, [id]);

  // runs whenever the 'sealedTs' flag changes
  // which happens after a seal-order attempt
  useEffect(() => {
    if (sealed === true) {
      showSuccess(`Order "#${order.id}" has been queued for sealing`);
      refreshOrder();
    } else if (sealed === false) {
      showError('Unable to seal order');
    }
  }, [sealed]);

  const refreshOrder = useCallback(() => dispatch(getFullOrder(id)), [id]);

  /********** SOCKET **********/

  // start receiving updates about this particular document
  useSubscribeToOrder(order?.id);

  const onOrderChanged = useCallback(data => {
    //   // this socket client is shared by the entire app
    //   // and here we are listening for an event that might be triggered by multiple orders
    //   // therefore we need to check whether this update refers to the right order
    if (data.id === order.id) {
      refreshOrder();
    }
  }, [order?.id, id, refreshOrder]);

  const condition = useCallback(() => !!order?.id, [order?.id]);

  // listen for changes on order documents
  useSocketOn(socketEvent.orderChanged, onOrderChanged, condition);

  /********** OTHER **********/

  const isLocked = () => orderIsLocked(order.sealStatus);

  const isReadyForSealing = () => order.status == Order.STATUS_COMPLETED && !isLocked();

  const isQueuedForSealing = () => order.sealStatus == Order.SEAL_STATUS_QUEUED;

  const isSealed = () => order.sealStatus == Order.SEAL_STATUS_SEALED;

  const sealFailed = () => order.sealStatus == Order.SEAL_STATUS_FAILED;

  const isAnySealStage = () => isReadyForSealing() || isQueuedForSealing() || sealFailed() || isSealed();

  const getSealExportLink = () => getBeUrl(`/order/${order.id}/seal/view`);

  const downloadSealFile = () => getBeUrl(`/order/${order.id}/seal/download`);

  const hasDocsToSeal = () => !!(order?.isEsignRequired || order?.isInkSignRequired)

  const handleDocsSelectionChange = (docType, selectedDocs) => {
    switch (docType) {
      case 'ESign':
        setSelectedEsignDocs(selectedDocs);
        break;
      case 'InkSign':
        setSelectedInkSignDocs(selectedDocs);
        break;
      case 'Supporting':
        setSelectedSupportingDocs(selectedDocs);
        break;
      case 'Preview':
        setSelectedPreviewDocs(selectedDocs);
        break;
      default:
        break;
    }
  };

  const initiateOrderSeal = () => {
    const selectedDocs = {
      ESign: Object.entries(selectedEsignDocs).filter(([key, value]) => value).map(([key, value]) => parseInt(key)),
      InkSign: Object.entries(selectedInkSignDocs).filter(([key, value]) => value).map(([key, value]) => parseInt(key)),
      Preview: Object.entries(selectedPreviewDocs).filter(([key, value]) => value).map(([key, value]) => parseInt(key)),
      Supporting: Object.entries(selectedSupportingDocs).filter(([key, value]) => value).map(([key, value]) => parseInt(key)),
    };

    const docIds = [
      ...selectedDocs.ESign,
      ...selectedDocs.InkSign,
      ...selectedDocs.Preview,
      ...selectedDocs.Supporting,
    ];

    if (docIds.length === 0 && !!(order?.isEsignRequired || order?.isInkSignRequired)) {
      showError('At least one document is required');
      return;
    }
    dispatch(sealOrder(id, docIds));
  };

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && isAnySealStage() && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
            </Col>
            <SealBadge order={order} />
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody className="order-seal-body">
                  <div className="text-end align-self-end">
                    {iAmGranted(perms.edit_orders) && isReadyForSealing() && <Button color="primary" className="ms-3" onClick={() => setIsConfirmationDocumentsVisible(true)} disabled={isSealInProgress}>Initiate Mavsign Seal<img src={shield} className="ms-2 seal-shield-icon" /></Button>}
                    {iAmGranted(perms.export_order_seal) && isSealed() && <a href={getSealExportLink()} target="_blank" className={classnames('btn btn-success ms-3', { disabled: order.sealStatus != Order.SEAL_STATUS_SEALED })} rel="noreferrer">Preview<i className="mdi mdi-export ms-1"></i></a>}
                    {iAmGranted(perms.export_order_seal) && isSealed() && <a href={downloadSealFile()} target="_blank" className={classnames('btn btn-primary ms-3', { disabled: order.sealStatus != Order.SEAL_STATUS_SEALED })} rel="noreferrer">Download<i className="mdi mdi-download ms-1"></i></a>}
                  </div>
                  <CustomerInfo order={order} />
                  {order.isVidRequired &&
                    order?.signers?.map((signer, index) =>
                      <VidInformation orderId={order.id} signerNum={signer.position} key={signer.position} order={order} />
                    )}
                  {order.isEsignRequired &&
                    <ESignInformation orderId={order.id} />
                  }
                  {order.isInkSignRequired &&
                    <InkSignInformation orderId={order.id} />
                  }
                  {!!(order.isEsignRequired || order.isInkSignRequired) && <>
                    <SupportingInformation orderId={order.id} />
                  </>
                  }
                  {order.isEsignRequired &&
                    <ESignDocs orderId={order.id} />
                  }
                  {order.isInkSignRequired &&
                    <InkSignDocs orderId={order.id} />
                  }
                  <div className="text-end align-self-end mt-4">
                    {iAmGranted(perms.edit_orders) && isReadyForSealing() && <Button color="primary" className="ms-3" onClick={() => setIsConfirmationDocumentsVisible(true)} disabled={isSealInProgress}>Initiate Mavsign Seal<img src={shield} className="ms-2 seal-shield-icon" /></Button>}
                    {iAmGranted(perms.export_order_seal) && isSealed() && <a href={getSealExportLink()} target="_blank" className={classnames('btn btn-success ms-3', { disabled: order.sealStatus != Order.SEAL_STATUS_SEALED })} rel="noreferrer">Preview<i className="mdi mdi-export ms-1"></i></a>}
                    {iAmGranted(perms.export_order_seal) && isSealed() && <a href={downloadSealFile()} target="_blank" className={classnames('btn btn-primary ms-3', { disabled: order.sealStatus != Order.SEAL_STATUS_SEALED })} rel="noreferrer">Download<i className="mdi mdi-download ms-1"></i></a>}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isConfirmationVisible && <Confirmation
          confirmBtnText="Confirm"
          onConfirm={() => {
            setIsConfirmationVisible(false);
            initiateOrderSeal();
            window.scrollTo({ top: 0, left: 0 });
          }}
          reverseButtons={false}
          onCancel={() => setIsConfirmationVisible(false)}>
          <span style={{ color: '#556EE6' }}>Are you sure you want to seal order &quot;#{order.id}&quot;? Changes will not be possible past this point!</span>
        </Confirmation>}
        {isConfirmationDocumentsVisible && <Confirmation
          confirmBtnText={hasDocsToSeal() ? 'Generate Seal Document' : 'Yes'}
          customIcon={sealIcon}
          onConfirm={() => {
            setIsConfirmationDocumentsVisible(false);
            setIsConfirmationVisible(true);
          }}
          reverseButtons={false}
          style={{ backgroundColor: '#FFF', width: '600px' }}
          onCancel={() => setIsConfirmationDocumentsVisible(false)}>
          {hasDocsToSeal() ?
            <p style={{ color: '#556EE6', fontWeight: '400' }}>Please review and select/deselect the documents you want included in the Seal Document. After the Seal Document is generated, <b>no additional changes </b>can be done to it. </p>
            : <span style={{ color: '#556EE6' }}>Are you sure you want to seal order &quot;#{order.id}&quot;? </span>
          }
          {hasDocsToSeal() &&
            <div className="select-parent">
              {order.isEsignRequired && <>
                <ESignForm orderId={order.id} onDocsSelectionChange={(selectedDocs) => handleDocsSelectionChange('ESign', selectedDocs)} />
              </>
              }
              {!!(order.isEsignRequired || order.isInkSignRequired) && <>
                <SupportingForm orderId={order.id} onDocsSelectionChange={(selectedDocs) => handleDocsSelectionChange('Supporting', selectedDocs)} />
              </>
              }
              {!!order.isInkSignRequired && <>
                <InkSignForm orderId={order.id} onDocsSelectionChange={(selectedDocs) => handleDocsSelectionChange('InkSign', selectedDocs)} />
              </>
              }
              {!!(order.isEsignRequired || order.isInkSignRequired) && <>
                <PreviewForm orderId={order.id} onDocsSelectionChange={(selectedDocs) => handleDocsSelectionChange('Preview', selectedDocs)} />
              </>
              }
            </div>}
        </Confirmation>}
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>
    }
    {iAmNotGranted(perms.view_orders) || (order && !isAnySealStage()) && <AccessDenied />}
  </React.Fragment >
}

const breadcrumbs = order => [{
  title: `${order.signers[0].fullName}`,
  url: route(routes.view_order, order.id),
}, {
  title: 'Edit order',
  url: route(routes.view_order, order.id),
}, {
  title: 'Seal Deal',
}];

export default Seal;
