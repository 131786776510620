export default class Meeting {

  static STATUS_CREATED = 0;
  static STATUS_STARTED = 1;
  static STATUS_ENDED = 2;

  static getStatusName(status) {
    switch (status) {
      case this.STATUS_CREATED:
        return 'Created';
      case this.STATUS_STARTED:
        return 'Started';
      case this.STATUS_ENDED:
        return 'Ended';
    }
  }
}