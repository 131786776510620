import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_SUBSCRIPTION,
  INIT_PAYMENT_METHOD,
} from "./actionTypes"

import {
  getSubscriptionOk,
  getSubscriptionErr,
  initPaymentMethodOk,
  initPaymentMethodErr,
} from "./actions"

import {
  getMySubscription,
  initPaymentMethod,
} from "helpers/backendHelper";

/********** SUBSCRIPTION **********/

function* onGetSubscription() {
  try {
    const response = yield call(getMySubscription);
    yield put(getSubscriptionOk(response));
  } catch (error) {
    yield put(getSubscriptionErr(error));
  }
}

function* onInitPaymentMethod() {
  try {
    const response = yield call(initPaymentMethod);
    yield put(initPaymentMethodOk(response));
  } catch (error) {
    yield put(initPaymentMethodErr(error));
  }
}

function* authSaga() {
  yield takeEvery(GET_SUBSCRIPTION, onGetSubscription);
  yield takeEvery(INIT_PAYMENT_METHOD, onInitPaymentMethod);
}

export default authSaga;
