import React from 'react';
import placeholderImage from '../../../../assets/images/chart-placeholder-image.svg';

const ChartPlaceholder = () => {
  return(<div className='chart-placeholder'>
    <div className='placeholder-description mb-4'>Sorry, <br />
      there are no orders to display at the moment.</div>
    <img src={placeholderImage} alt='placeholder-image' />
  </div>)
}

export default ChartPlaceholder;