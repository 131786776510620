import { all, fork } from 'redux-saga/effects';

import AuthSaga from './auth/saga';
import LayoutSaga from './layout/saga';
import UserSaga from './user/saga';
import UserRoleSaga from './userRole/saga';
import DealerRegionSaga from './dealerRegion/saga';
import DealerStoreSaga from './dealerStore/saga';
import OrderSaga from './order/saga';
import OrderDocSaga from './orderDoc/saga';
import SettingsSaga from './settings/saga';
import CustomerCallSaga from './customerCall/saga';
import DashboardSaga from './dashboard/saga';
import NotificationSaga from './notification/saga';
import StatementSaga from './statement/Dealer/saga';
import Subscription from './subscription/saga';
import Message from './message/saga';
import VidRequest from './vidRequest/saga';
import PaymentSaga from './payment/saga';
import ReportSaga from './report/saga';

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(UserSaga),
    fork(UserRoleSaga),
    fork(DealerRegionSaga),
    fork(DealerStoreSaga),
    fork(OrderSaga),
    fork(OrderDocSaga),
    fork(SettingsSaga),
    fork(CustomerCallSaga),
    fork(DashboardSaga),
    fork(NotificationSaga),
    fork(StatementSaga),
    fork(Subscription),
    fork(Message),
    fork(VidRequest),
    fork(PaymentSaga),
    fork(ReportSaga),
  ])
}
