import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, Label, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody, Row, Col } from "reactstrap";
import DateRangePicker from "components/Shared/DateRangePicker";
import { useFormik } from "formik";
import { hasNonEmpty, getBeUrl, toSelectOptions, isNotAllOrNone } from "helpers/utilHelper";
import {
  applyDealerSalesReportFilters,
  clearDealerSalesReportFilters,
  doDealerRegionListCleanup,
  doDealerStoreListCleanup,
  getDealerGroupRegionList,
  getDealerGroupStoreList,
  getDealerRegionStoreList
} from "store/actions";
import { omit } from "lodash";
import { getEndOfPrevMonthTs, getStartOfPrevMonthTs } from "helpers/dateHelper";
import downloadIcon from 'assets/images/download-icon.svg';
import { useAuth } from "context/auth";

const DealerSalesOwnGroupFilters = () => {

  const dispatch = useDispatch();
  const { user: authUser } = useAuth();

  /********** STATE **********/

  const filters = useSelector(state => state.Report.DealerSalesFilters);
  const { stores: rows } = useSelector(state => state.Report.DealerSales);
  const { regions, regionsError } = useSelector(state => state.DealerRegion.List);
  const { stores, storesError } = useSelector(state => state.DealerStore.List);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getDealerGroupRegionList(authUser.dealerGroupId));
    return () => {
      // state cleanup on component unmount
      dispatch(doDealerRegionListCleanup());
      dispatch(doDealerStoreListCleanup());
    }
  }, [authUser.dealerGroupId]);

  // default date range to previous month
  useEffect(() => {
    if (!filters.startTs && !filters.endTs) {
      applyFilters({
        startTs: getStartOfPrevMonthTs(),
        endTs: getEndOfPrevMonthTs(),
      })
    }
  }, []);

  useEffect(() => {
    if (regionsError) {
      // set an error on the form field
      formik.setFieldError("region", "Unable to load region");
    }
  }, [regionsError]);

  useEffect(() => {
    if (storesError) {
      // set an error on the form field
      formik.setFieldError("store", "Unable to load stores");
    }
  }, [storesError]);

  // runs whenever the region changes
  useEffect(() => {
    // clear the value store field
    //formik.setFieldValue('store', '');
    const regionId = formik.values.region;
    // if the selected region is not All or None
    if (isNotAllOrNone(regionId) && regionId != undefined) {
      // if region defined, search stores by region
      dispatch(getDealerRegionStoreList(regionId));
    } else if (regionId == '') {
      // if all regions selected, search stores by group
      dispatch(getDealerGroupStoreList(authUser.dealerGroupId));
    }
    else {
      dispatch(doDealerStoreListCleanup());
    }
  }, [formik.values.region]);

  /********** EVENT HANDLERS **********/

  const handleChangeRange = selected => {
    if (selected[0]) {
      formik.setFieldValue("startTs", selected[0]);
    } else {
      formik.setFieldValue("startTs", null);
    }

    if (selected[1]) {
      formik.setFieldValue("endTs", selected[1]);
    } else {
      formik.setFieldValue("endTs", null);
    }

    // if the range is complete, apply the changes
    if (selected?.length === 2) {
      applyFilters({ ...filters, startTs: selected[0], endTs: selected[1] });
    }
  };

  const handleClearRange = () => {
    formik.setFieldValue("startTs", null);
    formik.setFieldValue("endTs", null);
    applyFilters({ ...filters, startTs: null, endTs: null });
  };

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the "apply-filters" button
  const applyFilters = values => dispatch(applyDealerSalesReportFilters(values));

  // event handler for the "clear-filters" button
  const clearFilters = () => {
    formik.setFieldValue('region', '');
    formik.setFieldValue('store', '');
    // reset state
    dispatch(clearDealerSalesReportFilters());
  }

  /************* HELPERS ***********/

  const setStoresOptions = (storeList) => {
    const selectedValues = storeList.map((storeItem) => storeItem.value);
    formik.setFieldValue("store", selectedValues);
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(filters);

  const hasFilters = () => hasNonEmpty(omit(filters, ["startTs", "endTs"]));

  const hasTs = formik.values.startTs && formik.values.endTs;

  // adds 'All' to the list of regions
  // prepares the list to be used as select options
  const getRegionOptions = () => [{ label: 'All', value: '' }, ...toSelectOptions(regions)];

  // prepares the list to be used as select options
  const getStoreOptions = () => [...toSelectOptions(stores)];

  const getDownloadDetailsUrl = () => {
    let baseUrl = getBeUrl(`/report/dealer-sales/download?startTs=${formik.values.startTs}&endTs=${formik.values.endTs}`);
    if (formik.values?.store) {
      (formik.values?.store).map(store => {
        baseUrl += `&store[]=${store}`;
      })
    }
    if (formik.values?.region) {
      baseUrl += `&region=${formik.values?.region}`;
    }
    return baseUrl;
  }

  return (
    <Row className="justify-content-between">
      <Col xs={6} sm={"auto"}>
        <DateRangePicker
          onChange={handleChangeRange}
          value={[formik.values.startTs, formik.values.endTs].filter(ts => !!ts)}
          onClear={handleClearRange}
        />
      </Col>
      <Col xs={6} sm={"auto"}>
        <div className="ms-2 mb-4" >
          {!!rows.length &&
            <a className={`btn btn-primary ${hasTs ? '' : 'disabled'}`} href={getDownloadDetailsUrl()} target="_blank" rel="noreferrer">
              <span>Download</span>
              <img className='ps-2' src={downloadIcon} />
            </a>
          }
          <div className="btn-group ms-2" >
            <Button type="button" color="dark" className='ms-1' onClick={toggleFilters}>
              <i className="mdi mdi-filter-variant me-1" />Filters
            </Button>
            {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
              <i className="mdi mdi-close" />
            </Button>}
          </div>
        </div>
      </Col>
      <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
        <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
        <OffcanvasBody>
          <Form>
            {regions.length > 0 &&
              <div className="mb-3">
                <Label>Region</Label>
                <Select
                  key={formik.values.region || "select"}
                  classNamePrefix="select2-selection"
                  name="region"
                  options={getRegionOptions()}
                  onChange={selected => formik.setFieldValue('region', selected.value)}
                  value={getRegionOptions().find(option => option.value === formik.values.region)}
                  className={!!formik.errors.region && 'is-invalid'} />
                {!!formik.errors.region && <FormFeedback type="invalid">{formik.errors.region}</FormFeedback>}
              </div>}
            {regions.length > 0 && stores.length > 0 && <div className="mb-3">
              <Label>Store</Label>
              <Select
                key={formik.values.store || "select"}
                classNamePrefix="select2-selection"
                isMulti
                name="store"
                options={getStoreOptions()}
                onChange={selected => setStoresOptions(selected)}
                value={getStoreOptions().filter(option => formik.values.store ? formik.values.store.includes(option.value) : false)}
                className={!!formik.errors.store && 'is-invalid'} />
              {!!formik.errors.store && <FormFeedback type="invalid">{formik.errors.store}</FormFeedback>}
            </div>}
            <div className="text-end">
              <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
                <i className="mdi mdi-filter me-1" />Apply
              </Button>
              <Button type="button" color="warning" onClick={clearFilters}>
                <i className="mdi mdi-eraser me-1" />Clear
              </Button>
            </div>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </Row>
  )
}

export default DealerSalesOwnGroupFilters;