import React from "react";
import SubscriptionSetupContext from "context/subscriptionSetup";

const withSubscriptionSetup = Component => {

  const SubscriptionSetupWrapper = () => {

    return <SubscriptionSetupContext>
      <Component />
    </SubscriptionSetupContext>
  }

  return SubscriptionSetupWrapper;
}

export default withSubscriptionSetup;