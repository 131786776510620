import React from "react";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import SectionActivePlan from "./Partial/Section/ActivePlan";
import SectionActivePayment from "./Partial/Section/ActivePayment";
import SectionRemainingTransactions from "./Partial/Section/RemainingTransactions";

const View = () => {

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** OTHER **********/

  return <React.Fragment>
    {iAmGranted(perms.view_subscription) && <div className="page-content">
      <React.Fragment>
        <MetaTitle>Subscription</MetaTitle>
        <Container fluid>
          <Breadcrumbs title="SUBSCRIPTION" />
          <Row>
            <Col xs="12" lg="8" xl="6" xxl="4">
              <SectionActivePlan />
            </Col>
            <Col xs="12" lg="8" xl="6" xxl="4">
              <SectionActivePayment />
              <SectionRemainingTransactions />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>}
    {iAmNotGranted(perms.view_subscription) && <AccessDenied />}
  </React.Fragment>
}

export default View;
