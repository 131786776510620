import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, CardTitle, Table } from "reactstrap";
import Col from "components/Shared/Col";

const ViewSubscriptionSummary = props => {

  const { dealerStore } = props;

  return <React.Fragment>
    <Card className="no-shadow mb-0">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Subscription Summary</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Table className="section-rows bt-1 bb-0">
          <tbody>
            <tr>
              <td>Payment plan</td>
              <td className="font-weight-500">{dealerStore.paymentPlan.name}</td>
            </tr>
            {!dealerStore.paymentPlan.isFree && <>
              <tr>
                <td>Subscription Fee</td>
                <td className="font-weight-500">${dealerStore.paymentPlan.subscriptionPrice}</td>
              </tr>
              <tr>
                <td>Remote Transaction Fee</td>
                <td className="font-weight-500">${dealerStore.paymentPlan.remotePrice}</td>
              </tr>
              <tr>
                <td>InStore Transaction Fee</td>
                <td className="font-weight-500">${dealerStore.paymentPlan.instorePrice}</td>
              </tr>
            </>}
            {dealerStore.paymentPlan.isFree && <tr>
              <td>Price</td>
              <td className="font-weight-500">FREE</td>
            </tr>}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </React.Fragment>
}

ViewSubscriptionSummary.propTypes = {
  dealerStore: PropTypes.object,
};

export default ViewSubscriptionSummary;
