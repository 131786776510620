import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ExpanderToggle = props => {

  const { log, noDetails, isExpanded, setIsExpanded } = props;

  const canExpand = () => !noDetails && !!log.newValues;

  return <React.Fragment>
    {canExpand() && <>
      <span className="ms-3">|</span>
      {!isExpanded && <Button type="button" color="link" className="pt-1 pb-1" onClick={() => setIsExpanded(true)}>View changes</Button>}
      {isExpanded && <Button type="button" color="link" className="pt-1 pb-1" onClick={() => setIsExpanded(false)}>Hide changes</Button>}
    </>}
  </React.Fragment>
}

ExpanderToggle.propTypes = {
  log: PropTypes.object,
  noDetails: PropTypes.bool,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
}

export default ExpanderToggle;