import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_CUSTOMER_CALL_DT,
} from "./actionTypes";

import {
  getCustomerCallDtOk,
  getCustomerCallDtErr,
} from "./actions";

import {
  getCustomerCallDt,
} from "helpers/backendHelper";

/********** DATATABLE **********/

function* onGetCustomerCallDt({ payload: { params } }) {
  try {
    const response = yield call(getCustomerCallDt, params);
    yield put(getCustomerCallDtOk(response));
  } catch (error) {
    yield put(getCustomerCallDtErr(error));
  }
}

function* customerCallSaga() {
  yield takeEvery(GET_CUSTOMER_CALL_DT, onGetCustomerCallDt);
}

export default customerCallSaga;