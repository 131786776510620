import React, { useEffect } from "react";
import inkSignServiceIcon from 'assets/images/ink-sign-service-icon.svg';
import eSignServiceIcon from 'assets/images/e-sign-service-icon.svg';
import vidServiceIcon from 'assets/images/verify-id-service-icon.svg';
import notaryServiceIcon from 'assets/images/notary-service-icon.svg';
import rushOrderServiceIcon from 'assets/images/rush-order-icon.svg';
import coSigners from 'assets/images/co-signer-icon.svg';
import Order from "model/order";
import { Card, CardBody, Row, Col, Alert, CardHeader, CardTitle, UncontrolledTooltip } from "reactstrap"
import { formats, formatTimestamp } from "helpers/dateHelper";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useDispatch, useSelector } from "react-redux";
import { doOrderListCleanup, getInProgressOrderList } from "store/actions";
import { getGranularStatusName } from "helpers/utilHelper";

const WIPOrders = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { orders, ordersError, isLoadInProgress } = useSelector(state => state.Order.List);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get regions list
    dispatch(getInProgressOrderList());
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderListCleanup());
    }
  }, []);

  return <>
    <Card className="wip-orders-card">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Work in progress</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1 horizontal-scroll-table">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Customer name</th>
              <th>Services included</th>
              <th>Date ordered</th>
              <th>Status</th>
              <th>Signing ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.length > 0 && orders.map((entry, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{entry.customerName}</td>
                <td>
                  <div>
                    {entry.isVidRequired && <><img id="verify-id" src={vidServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target="verify-id">Verify ID</UncontrolledTooltip></>}
                    {entry.isEsignRequired && <><img id="e-sign" src={eSignServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target="e-sign">E sign</UncontrolledTooltip></>}
                    {entry.isInkSignRequired && <><img id="ink-sign" src={inkSignServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target="ink-sign">Ink sign</UncontrolledTooltip></>}
                    {entry.isNotaryRequired && <><img id="notary-service" src={notaryServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target="notary-service">Notary service</UncontrolledTooltip></>}
                    {entry.hasAdditionalSigner && <><img id={`co-signers${entry.id}`} src={coSigners} className="me-2" /><UncontrolledTooltip placement="top" target={`co-signers${entry.id}`}>Co-signers</UncontrolledTooltip></>}
                    {entry.isRushRequired && <><img id="rush-order" src={rushOrderServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target="rush-order">Rush order</UncontrolledTooltip></>}
                  </div>
                </td>
                <td>{formatTimestamp(entry.createdTs, formats.DATETIME)}</td>
                <td>
                  <span className={`badge badge-lg rounded-pill bg-${Order.getGranularStatusColor(entry.granularStatus)}`}>{getGranularStatusName(entry.granularStatus)}</span>
                </td>
                <td>
                  {entry.id}
                </td>
                <td>
                  <Link to={route(routes.view_order, entry.id)}><span className="badge badge-lg rounded-pill bg-primary">View Details</span></Link>
                </td>
              </tr>
            ))}
            {orders.length === 0 && !ordersError && !isLoadInProgress && <tr>
              <td className="table-empty" colSpan="7">No orders found</td>
            </tr>
            }
            {!!ordersError && <tr>
              <td className="table-empty" colSpan="7">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load orders
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="7">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
      </CardBody>
    </Card>
  </>
}

export default WIPOrders;