import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_USER_DT,
} from "./actionTypes";

import {
  getUserDtOk,
  getUserDtErr,
} from "./actions";

import {
  getUserDt,
} from "helpers/backendHelper";

/********** DATATABLE **********/

function* onGetUserDt({ payload: { params } }) {
  try {
    const response = yield call(getUserDt, params);
    yield put(getUserDtOk(response));
  } catch (error) {
    yield put(getUserDtErr(error));
  }
}

function* userSaga() {
  yield takeEvery(GET_USER_DT, onGetUserDt);
}

export default userSaga;
