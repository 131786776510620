import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import UserFormInfoNewOwnGroup from "pages/User/Partial/Form/Info/New/OwnGroup";
import UserFormInfoNewOwnRegion from "pages/User/Partial/Form/Info/New/OwnRegion";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import UserRole from "model/userRole";
import { getDealerStore } from "helpers/backendHelper";

const NewManager = () => {

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // read route parameters
  const { id } = useParams();

  let FormComponent;
  if (iAmGranted(perms.view_own_group_users)) {
    FormComponent = UserFormInfoNewOwnGroup;
  } else {
    FormComponent = UserFormInfoNewOwnRegion;
  }

  /********** STATE **********/

  const [dealerStore, setDealerStore] = useState(null);
  const [dealerStoreError, setDealerStoreError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the group data
    refreshDealerStore();
  }, [id]);

  /********** OTHER **********/

  const refreshDealerStore = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getDealerStore(id)
      .then(response => {
        setDealerStore(response.dealerStore);
      })
      .catch(ex => {
        setDealerStoreError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.create_dealer_store_managers) && <div className="page-content">
      {dealerStore && <React.Fragment>
        <MetaTitle>New store manager</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(dealerStore)} />
          <Row>
            <Col xl="8" xxl="6">
              <FormComponent
                forceValues={{
                  userRoleId: UserRole.ID_DEALER_STORE_MANAGER,
                  dealerStoreId: parseInt(id),
                }}
                successRoute={route(routes.view_dealer_store, id)}
                cancelRoute={route(routes.view_dealer_store, id)} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {isLoadInProgress && <Preloader className="inner" />}
      {dealerStoreError && <Error error={dealerStoreError} title404="Dealer store not found" />}
    </div>}
    {iAmNotGranted(perms.create_dealer_store_managers) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = dealerStore => [{
  title: 'STORES',
  url: route(routes.list_dealer_stores),
}, {
  title: dealerStore.name,
  url: route(routes.view_dealer_store, dealerStore.id),
}, {
  title: 'New manager',
}];

export default NewManager;
