import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from "components/Shared/UserAvatar";
import { getInitialsFromName, getLogAuthorFullName } from 'helpers/utilHelper';
import schedulerEmailIcon from 'assets/images/scheduler-email.svg';
import schedulerPhoneIcon from 'assets/images/scheduler-phone.svg';

const SectionOrderSchedulerInfo = ({order}) => {

  return <React.Fragment>
    <div className="scheduler-info d-flex">
      <UserAvatar size='sm' id={order.schedulerId} image={order.schedulerImage} initials={getInitialsFromName(order.schedulerFullName)} />
      <div className='px-3'>
      <div>Scheduler Name:</div>
      <div className='font-weight-500'>{order.schedulerFullName || '---'}</div>
      </div>

      <div className='d-flex px-3'>
        <img className="scheduler-info-icon" src={schedulerEmailIcon} />
        <div className='ms-2'>
          <div>Email:</div>
          <div className='blue-label font-weight-500'>{order.schedulerEmail || '---'}</div>
        </div>
      </div>

      <div className='d-flex px-3'>
        <img className="scheduler-info-icon" src={schedulerPhoneIcon} />
        <div className='ms-2'>
          <div>Phone:</div>
          <div className='blue-label font-weight-500'>{order.schedulerPhone || '---'}</div>
        </div>
      </div>
    </div>
  </React.Fragment>
}

SectionOrderSchedulerInfo.propTypes = {
  order: PropTypes.object,
};

export default SectionOrderSchedulerInfo;