import React from "react"
import { Container, Row, Col } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import StoreManager from "./Partial/Leaderboard/StoreManager";
import RegionalManager from "./Partial/Leaderboard/RegionalManager";
import GroupManager from "./Partial/Leaderboard/GroupManager";

const List = () => {

  const {
    iAmDealerStoreManager,
    iAmDealerRegionalManager,
    iAmDealerGroupManager,
  } = useAccess();

  let UserRoleLeaderboard;

  if (iAmDealerStoreManager()) {
    UserRoleLeaderboard = StoreManager;
  } else if (iAmDealerRegionalManager()) {
    UserRoleLeaderboard = RegionalManager;
  } else if (iAmDealerGroupManager()) {
    UserRoleLeaderboard = GroupManager;
  } else {
    return <AccessDenied />
  }

  return <div className="page-content">
    <MetaTitle>Leaderboard</MetaTitle>
    <Container fluid>
      <Breadcrumbs title="LEADERBOARD" />
      <Row>
        <Col>
          <UserRoleLeaderboard />
        </Col>
      </Row>
    </Container>
  </div>
}

export default List;
