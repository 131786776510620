import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, } from "react-router-dom"
import { Card, CardBody, Alert } from "reactstrap"
import { doLeaderboardListCleanup, getUserLeaderboardList } from "store/actions";
import { route, routes } from "helpers/routeHelper";

const DataTable = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { leaderboard: rows, leaderboardError: rowsError, isLoadInProgress } = useSelector(state => state.Dashboard.LeaderboardList);

  /********** EFFECTS **********/

  // runs on component mount
  useEffect(() => {
    return () => {
      // state cleanup on component unmount
      dispatch(doLeaderboardListCleanup());
    }
  }, []);

  // runs once on component mount
  useEffect(() => {
    dispatch(getUserLeaderboardList({ limit: null }));
  }, []);

  return <React.Fragment>
    <Card>
      <CardBody className="pt-3">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Orders</th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((entry, index) => (<tr key={index}>
              <td>{index + 1}</td>
              <td><Link to={route(routes.view_user, entry.id)}>{entry.fullName}</Link></td>
              <td>{(entry.orders).toLocaleString()}</td>
            </tr>))}
            {rows.length === 0 && !rowsError && !isLoadInProgress && <tr>
              <td className="table-empty" colSpan="4">No agents found</td>
            </tr>
            }
            {!!rowsError && <tr>
              <td className="table-empty" colSpan="7">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load agents
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="7">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
      </CardBody>
    </Card>
  </React.Fragment>
}

export default DataTable;
