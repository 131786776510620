import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { NavItem, NavLink } from "reactstrap";
import { route, routes } from 'helpers/routeHelper';
import classnames from "classnames";
import { getOrderSteps } from 'helpers/utilHelper';

const FormSteps = props => {

  const { currentStep, order, setNavRoutes } = props;

  // router hook that helps redirect
  const navigate = useNavigate();

  useEffect(() => {
    setNavRoutes(routes => ({
      next: steps[currentIndex() + 1]?.route || routes.next,
      prev: steps[currentIndex() - 1]?.route || routes.prev,
    }));
  }, [currentStep, order.id]);

  /********** WIZARD CONFIG **********/

  // the list of wizard steps to display
  const steps = getOrderSteps(order);

  const goToStep = index => navigate(route(steps[index].route, order.id));

  const currentIndex = () => steps.findIndex(s => s.key == currentStep);

  return <React.Fragment>
    <div className="wizard clearfix">
      <div className="steps clearfix">
        <ul>
          {steps.map((step, index) => {
            return <NavItem className={`${classnames({ current: currentStep === step.key })} col-xl-2`} key={index}>
              <NavLink className={classnames({ active: currentStep === step.key })} onClick={() => goToStep(index)} disabled={currentIndex() <= index}>
                <span className="number">{index + 1}</span> {step.name}
              </NavLink>
            </NavItem>
          })}
        </ul>
      </div>
    </div>
  </React.Fragment>
}

FormSteps.propTypes = {
  currentStep: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  setNavRoutes: PropTypes.func,
}

export default FormSteps;