import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Row, CardHeader, Button } from "reactstrap";
import Col from "components/Shared/Col";
import { perms, useAccess } from 'context/access';
import { route, routes } from 'helpers/routeHelper';
import { formatTimestamp, formats } from "helpers/dateHelper";
import Confirmation from "components/Shared/Confirmation";
import { showSuccess, showError } from "helpers/utilHelper";
import CardPreloader from 'components/Shared/CardPreloader';
import { deleteDealerRegion } from "helpers/backendHelper";

const ViewInfo = props => {

  const { isRefreshing, id, dealerRegion, toggleEditMode } = props;

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  // delete-region event handler
  // make the remote call to delete the region
  const removeDealerRegion = () => {
    setIsDeleteInProgress(true);
    deleteDealerRegion(id)
      .then(response => {
        const name = dealerRegion.name;
        showSuccess(`Region "${name}" has been deleted`);
        navigate(route(routes.list_dealer_regions));
      })
      .catch(ex => {
        showError('Unable to delete region');
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  };

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{dealerRegion.name}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_dealer_regions) && <Button type="button" color="primary" className="ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit region
              </Button>}
              {iAmGranted(perms.delete_dealer_regions) && <Button type="button" color="danger" className="ms-2 mb-2" onClick={() => { setIsConfirmationVisible(true) }} disabled={isDeleteInProgress}>
                {isDeleteInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isDeleteInProgress && <i className="mdi mdi-delete me-1" />}
                Delete region
              </Button>}
              {iAmNotGranted(perms.view_own_dealer_region) && <Link to={route(routes.list_dealer_regions, dealerRegion.dealerGroupId)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="mt-4">
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Created</h5>
            <p className="text-muted mb-0">{formatTimestamp(dealerRegion.createdTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Updated</h5>
            <p className="text-muted mb-0">{formatTimestamp(dealerRegion.updatedTs, formats.DATETIME)}</p>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsConfirmationVisible(false);
        removeDealerRegion();
      }}
      reverseButtons={false}
      onCancel={() => setIsConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete region &quot;{dealerRegion.name}&quot;?</span>
    </Confirmation>}
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  id: PropTypes.number,
  dealerRegion: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewInfo;
