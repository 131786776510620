import React from "react";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { getSsoAppUrl } from "helpers/utilHelper";

const CheckInformationAlert = () => {
  return (
    <Alert color="danger">
      <span className="font-size-13">Please make sure all your information is up to date so we can reach you about your signings. <Link className="alert-danger text-decoration-underline info-alert-link" to={getSsoAppUrl('me')}>Click here to update</Link></span>
    </Alert>)
}

export default CheckInformationAlert;