import { takeEvery, put, call, all } from 'redux-saga/effects';

import {
  GET_ORDER_DT,

  GET_IN_PROGRESS_ORDER_LIST,

  GET_ORDER,
  GET_ORDER_WITH_CUSTOMER_SIGNERS,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES,
  GET_ORDER_WITH_VEHICLES,
  GET_FULL_ORDER,
  SEAL_ORDER,
  RESEND_CUSTOMER_NOTIFICATIONS,
  START_CUSTOMER_MEETING,
  START_SCHEDULER_MEETING,

  CREATE_ORDER,
  UPDATE_ORDER_CUSTOMER,
  UPDATE_ORDER_VEHICLE,
  UPDATE_ORDER_CONTRACT,
  UPDATE_ORDER_DOCS,
  SUBMIT_ORDER,

  GET_ORDER_VID,

  GET_ORDER_INK_SIGN_DOCS,
  GET_ORDER_E_SIGN_DOCS,

  GET_ORDER_ACTIVITY,

  GET_ORDER_MESSAGES,
  GET_LAST_ORDER_MESSAGE,
  GET_CHAT_MEMBERS,
  GET_ORDER_SUPPORTING_DOCS,
  GET_ORDER_PREVIEW_DOCS,
} from './actionTypes';

import {
  getOrderDtOk,
  getOrderDtErr,

  getOrderListOk,
  getOrderListErr,

  getOrderOk,
  getOrderWithCustomerSignersOk,
  getOrderWithCustomerSignersAndNotariesOk,
  getOrderWithVehiclesOk,
  getFullOrderOk,
  getOrderErr,
  sealOrderOk,
  sealOrderErr,
  resendCustomerNotificationsOk,
  resendCustomerNotificationsErr,
  startCustomerMeetingOk,
  startCustomerMeetingErr,
  startSchedulerMeetingOk,
  startSchedulerMeetingErr,

  saveOrderOk,
  saveOrderErr,

  getOrderVidOk,
  getOrderVidErr,

  getOrderInkSignDocsOk,
  getOrderInkSignDocsErr,
  getOrderESignDocsOk,
  getOrderESignDocsErr,

  getOrderActivityOk,
  getOrderActivityErr,

  getOrderMessagesOk,
  getOrderMessagesErr,
  getLastOrderMessageOk,
  getLastOrderMessageErr,
  getChatMembersOk,
  getChatMembersErr,
  getOrderSupportingDocsOk,
  getOrderSupportingDocsErr,
  getOrderPreviewDocsOk,
  getOrderPreviewDocsErr,
} from './actions';

import {
  getOrderDt,
  getInProgressOrders,
  getOrder,
  getOrderSigners,
  getOrderNotaries,
  getOrderVehicles,
  sealOrder,
  resendCustomerNotifications,
  createOrderMeeting,
  createOrder,
  updateOrderCustomer,
  updateOrderVehicle,
  updateOrderContract,
  updateOrderDocs,
  submitOrder,
  getOrderVid,
  getInkSignOrderDocs,
  getESignOrderDocs,
  getOrderActivity,
  getOrderMessages,
  getAllOrderMessages,
  getChatMembers,
  getSupportingOrderDocs,
  getFinanceOrderDocs,
} from 'helpers/backendHelper';

import OrderSigner from 'model/orderSigner';

/********** DATATABLE **********/

function* onGetOrderDt({ payload: { params } }) {
  try {
    const { filters } = params;

    const { includeService, includeStatus, incAlert, vidStatuses, ...rest } = filters;
    // parse arrays to strings
    const incService = includeService.join(",");
    const incStatus = includeStatus.join(",");
    const alert = incAlert.join(",");
    const vidStatus = vidStatuses.join(",");

    const response = yield call(getOrderDt, { ...params, filters: { incService, incStatus, alert, vidStatus, ...rest } });
    yield put(getOrderDtOk(response));
  } catch (error) {
    yield put(getOrderDtErr(error));
  }
}

/********** LIST **********/

function* onGetInProgressOrders() {
  try {
    const response = yield call(getInProgressOrders);
    yield put(getOrderListOk(response))
  } catch (error) {
    yield put(getOrderListErr(error))
  }
}

/********** SINGLE **********/

function* onGetOrder({ payload: { id } }) {
  try {
    const response = yield call(getOrder, id);
    yield put(getOrderOk(response));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* onGetOrderWithCustomerSigners({ payload: { id } }) {
  try {
    const [orderResponse, signersResponse] = yield all([
      call(getOrder, id),
      call(getOrderSigners, id, OrderSigner.TYPE_CUSTOMER),
    ]);
    yield put(getOrderWithCustomerSignersOk(orderResponse, signersResponse));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* onGetOrderWithCustomerSignersAndNotaries({ payload: { id } }) {
  try {
    const [orderResponse, signersResponse, notariesResponse] = yield all([
      call(getOrder, id),
      call(getOrderSigners, id, OrderSigner.TYPE_CUSTOMER),
      call(getOrderNotaries, id),
    ]);
    yield put(getOrderWithCustomerSignersAndNotariesOk(orderResponse, signersResponse, notariesResponse));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* onGetOrderWithVehicles({ payload: { id } }) {
  try {
    const [orderResponse, vehiclesResponse] = yield all([
      call(getOrder, id),
      call(getOrderVehicles, id),
    ]);
    yield put(getOrderWithVehiclesOk(orderResponse, vehiclesResponse));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* onGetFullOrder({ payload: { id } }) {
  try {
    const [orderResponse, signersResponse, vehiclesResponse, notariesResponse] = yield all([
      call(getOrder, id),
      call(getOrderSigners, id, OrderSigner.TYPE_CUSTOMER),
      call(getOrderVehicles, id),
      call(getOrderNotaries, id),
    ]);
    yield put(getFullOrderOk(orderResponse, signersResponse, vehiclesResponse, notariesResponse));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* onSealOrder({ payload: { id, docIds } }) {
  try {
    yield call(sealOrder, id, docIds);
    yield put(sealOrderOk());
  } catch (error) {
    yield put(sealOrderErr(error));
  }
}

function* onResendCustomerNotifications({ payload: { id } }) {
  try {
    yield call(resendCustomerNotifications, id);
    yield put(resendCustomerNotificationsOk());
  } catch (error) {
    yield put(resendCustomerNotificationsErr(error));
  }
}

function* onStartCustomerMeeting({ payload: { orderId } }) {
  try {
    const response = yield call(createOrderMeeting, orderId, "customer");
    yield put(startCustomerMeetingOk(response));
  } catch (error) {
    yield put(startCustomerMeetingErr(error));
  }
}

function* onStartSchedulerMeeting({ payload: { orderId } }) {
  try {
    const response = yield call(createOrderMeeting, orderId, "scheduler");
    yield put(startSchedulerMeetingOk(response));
  } catch (error) {
    yield put(startSchedulerMeetingErr(error));
  }
}


/********** FORM **********/

function* onCreateOrder({ payload: { data } }) {
  try {
    const response = yield call(createOrder, data);
    yield put(saveOrderOk(response));
  } catch (error) {
    yield put(saveOrderErr(error));
  }
}

function* onUpdateOrderCustomer({ payload: { data, id } }) {
  try {
    const response = yield call(updateOrderCustomer, data, id);
    yield put(saveOrderOk(response));
  } catch (error) {
    yield put(saveOrderErr(error));
  }
}

function* onUpdateOrderVehicle({ payload: { data, id } }) {
  try {
    const response = yield call(updateOrderVehicle, data, id);
    yield put(saveOrderOk(response));
  } catch (error) {
    yield put(saveOrderErr(error));
  }
}

function* onUpdateOrderContract({ payload: { data, id } }) {
  try {
    const response = yield call(updateOrderContract, data, id);
    yield put(saveOrderOk(response));
  } catch (error) {
    yield put(saveOrderErr(error));
  }
}

function* onUpdateOrderDocs({ payload: { data, id } }) {
  try {
    const response = yield call(updateOrderDocs, data, id);
    yield put(saveOrderOk(response));
  } catch (error) {
    yield put(saveOrderErr(error));
  }
}

function* onSubmitOrder({ payload: { id } }) {
  try {
    const response = yield call(submitOrder, id);
    yield put(saveOrderOk(response));
  } catch (error) {
    yield put(saveOrderErr(error));
  }
}

/********** VID **********/

function* onGetOrderVid({ payload: { id, signer } }) {
  try {
    const response = yield call(getOrderVid, id, signer);
    yield put(getOrderVidOk(response, signer));
  } catch (error) {
    yield put(getOrderVidErr(error, signer));
  }
}

/********** INK-SIGN DOCS **********/

function* onGetOrderInkSignDocs({ payload: { id } }) {
  try {
    const response = yield call(getInkSignOrderDocs, id);
    yield put(getOrderInkSignDocsOk(response));
  } catch (error) {
    yield put(getOrderInkSignDocsErr(error));
  }
}

/********** E-SIGN DOCS **********/

function* onGetOrderESignDocs({ payload: { id } }) {
  try {
    const response = yield call(getESignOrderDocs, id);
    yield put(getOrderESignDocsOk(response));
  } catch (error) {
    yield put(getOrderESignDocsErr(error));
  }
}

/********** SUPPORTING DOCS **********/

function* onGetOrderSupportingDocs({ payload: { id } }) {
  try {
    const response = yield call(getSupportingOrderDocs, id);
    yield put(getOrderSupportingDocsOk(response));
  } catch (error) {
    yield put(getOrderSupportingDocsErr(error));
  }
}

/********** PREVIEW DOCS **********/

function* onGetOrderPreviewDocs({ payload: { id } }) {
  try {
    const response = yield call(getFinanceOrderDocs, id);
    yield put(getOrderPreviewDocsOk(response));
  } catch (error) {
    yield put(getOrderPreviewDocsErr(error));
  }
}

/********** ACTIVITY LOG **********/

function* onGetOrderActivity({ payload: { id, limit } }) {
  try {
    const response = yield call(getOrderActivity, id, limit);
    yield put(getOrderActivityOk(response));
  } catch (error) {
    yield put(getOrderActivityErr(error));
  }
}

/********** MESSAGES **********/

function* onGetOrderMessages({ payload: { orderId, channelId, params } }) {
  try {
    const response = yield call(getOrderMessages, orderId, channelId, params);
    yield put(getOrderMessagesOk(response, channelId));
  } catch (error) {
    yield put(getOrderMessagesErr(error));
  }
}

function* onGetLastMessage({ payload: { orderId } }) {
  try {
    const response = yield call(getAllOrderMessages, orderId, { page: 1, pageSize: 1, sortBy: 'id', sortDir: 'desc' });
    const message = response.messages[0];
    yield put(getLastOrderMessageOk(message));
  } catch (error) {
    yield put(getLastOrderMessageErr(error));
  }
}

function* onGetChatMembers({ payload: { orderId, channelId } }) {
  try {
    const response = yield call(getChatMembers, orderId, channelId);
    yield put(getChatMembersOk(response, channelId));
  } catch (error) {
    yield put(getChatMembersErr(error));
  }
}

function* orderSaga() {
  yield takeEvery(GET_ORDER_DT, onGetOrderDt);

  yield takeEvery(GET_IN_PROGRESS_ORDER_LIST, onGetInProgressOrders);

  yield takeEvery(GET_ORDER, onGetOrder);
  yield takeEvery(GET_ORDER_WITH_CUSTOMER_SIGNERS, onGetOrderWithCustomerSigners);
  yield takeEvery(GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES, onGetOrderWithCustomerSignersAndNotaries);
  yield takeEvery(GET_ORDER_WITH_VEHICLES, onGetOrderWithVehicles);
  yield takeEvery(GET_FULL_ORDER, onGetFullOrder);
  yield takeEvery(SEAL_ORDER, onSealOrder);
  yield takeEvery(RESEND_CUSTOMER_NOTIFICATIONS, onResendCustomerNotifications);
  yield takeEvery(START_CUSTOMER_MEETING, onStartCustomerMeeting);
  yield takeEvery(START_SCHEDULER_MEETING, onStartSchedulerMeeting);

  yield takeEvery(CREATE_ORDER, onCreateOrder);
  yield takeEvery(UPDATE_ORDER_CUSTOMER, onUpdateOrderCustomer);
  yield takeEvery(UPDATE_ORDER_VEHICLE, onUpdateOrderVehicle);
  yield takeEvery(UPDATE_ORDER_CONTRACT, onUpdateOrderContract);
  yield takeEvery(UPDATE_ORDER_DOCS, onUpdateOrderDocs);
  yield takeEvery(SUBMIT_ORDER, onSubmitOrder);

  yield takeEvery(GET_ORDER_VID, onGetOrderVid);

  yield takeEvery(GET_ORDER_INK_SIGN_DOCS, onGetOrderInkSignDocs);
  yield takeEvery(GET_ORDER_E_SIGN_DOCS, onGetOrderESignDocs);
  yield takeEvery(GET_ORDER_SUPPORTING_DOCS, onGetOrderSupportingDocs);
  yield takeEvery(GET_ORDER_PREVIEW_DOCS, onGetOrderPreviewDocs);

  yield takeEvery(GET_ORDER_ACTIVITY, onGetOrderActivity);

  yield takeEvery(GET_ORDER_MESSAGES, onGetOrderMessages);
  yield takeEvery(GET_LAST_ORDER_MESSAGE, onGetLastMessage);
  yield takeEvery(GET_CHAT_MEMBERS, onGetChatMembers);
}

export default orderSaga;