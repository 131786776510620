import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ChartPlaceholder from "./ChartPlaceholder";
import {
  Card, CardBody, Row, Col, CardHeader, Nav, NavLink, NavItem, Carousel,
  CarouselItem, CarouselControl, CarouselIndicators, Progress
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import Order from "model/order";
import { doCustomerPerformanceCleanup, getCustomerPerformance } from "store/actions";
import { getGranularStatusName } from "helpers/utilHelper";

const CustomerPerformance = () => {

  const dispatch = useDispatch();
  const { stats } = useSelector(state => state.Dashboard.CustomerPerformance);

  /********** STATE **********/
  const [menuActiveTab, setMenuActiveTab] = useState("year");
  const [sliderActiveTab, setSliderActiveTab] = useState(Order.CUSTOMER_LOCATION_REMOTE);
  const [activeIndex, setActiveIndex] = useState(0);

  const isInstoreTab = sliderActiveTab === Order.CUSTOMER_LOCATION_IN_STORE;

  // Extract data
  const orders = stats?.statusesOrderCount || {};
  const totalOrders = stats?.totalCount || 0;

  const ordersArr = Object.values(orders);
  const statusesArr = Object.keys(orders);
  const ordersNumber = ordersArr.map(item => item.number || 0);
  const orderStatuses = statusesArr.map(status => getGranularStatusName(status));

  /********** DONUT CHART CONFIG **********/
  const progressBarColors = {
    inStoreColors: ["#FEC949", "#34C38F", "#556EE6", "#F46A6A"],
    firstHalf: [
      "#BEB5FB", "#FEC949", "#70C5EA", "#9989FC", "#FF853D",
      "#F4708F", "#34C38F", "#556EE6", "#F46A6A",
    ],
    secondHalf: [
      "#156FE5", "#1B4085", "#F8D1EB", "#F7E0B3",
      "#0AA3E7", "#2E7990", "#A9EFC7", "#5E6474",
    ]
  };

  const donutChartColors = isInstoreTab ? progressBarColors.inStoreColors : [...progressBarColors.firstHalf, ...progressBarColors.secondHalf];

  const donutChartOptions = {
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 0.75,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10
        },
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Poppins',
              fontWeight: 600,
              color: '#495057',
              offsetY: 0,
              formatter: function (val) {
                return val;
              }
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Poppins',
              fontWeight: 600,
              color: '#495057',
              offsetY: 16,
              formatter: function (val, opts) {
                // Safely access series data
                if (opts && opts.w && opts.w.globals && opts.seriesIndex !== undefined) {
                  return opts.w.globals.series[opts.seriesIndex];
                }
                // Fallback to default value
                return val;
              }
            },
            total: {
              show: true,
              showAlways: false,
              label: 'Total Orders',
              fontSize: '16px',
              fontFamily: 'Poppins',
              fontWeight: 600,
              color: '#495057',
              formatter: function (w) {
                // Calculate the total of all slices
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
              }
            }
          }
        }
      }
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return `${val} Orders`; // Custom text to show on hover
        }
      }
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: donutChartColors,
  };

/********** END - DONUT CHART CONFIG **********/

  // Add granular status on the orders array
  const customerPerformanceData = ordersArr.map((item, index) => ({
    status: statusesArr[index],
    ...item
  }));

  // get only the statuses for "In-Store Orders"
  const inStoreStatuses = ["1_1", "1_6", "1_7", "1_-1"];
  const inStoreData = customerPerformanceData.filter(item => inStoreStatuses.includes(item.status));

  // Split data for carousel in two parts ( two slides )
  const halfwayThrough = Math.floor(customerPerformanceData.length / 2 + 1);
  // if the order is In-store show only one slider with four statuses (PENDING VID/ CANCELED/ COMPLETED/ SEALD)
  // if the order is Remote show two sliders with all the statuse (order statuses and notary statuses)
  const items = isInstoreTab ? [{ id: 1, data: inStoreData, barColor: progressBarColors.inStoreColors}] :
    [
      { id: 1, data: customerPerformanceData.slice(0, halfwayThrough), barColor: progressBarColors.firstHalf },
      { id: 2, data: customerPerformanceData.slice(halfwayThrough), barColor: progressBarColors.secondHalf },
    ];

  /********** EFFECTS **********/
  useEffect(() => {
    dispatch(getCustomerPerformance(menuActiveTab, sliderActiveTab));
    return () => dispatch(doCustomerPerformanceCleanup());
  }, [menuActiveTab, sliderActiveTab]);

  /********** HANDLERS **********/
  const next = () => setActiveIndex(activeIndex === items.length - 1 ? 0 : activeIndex + 1);
  const previous = () => setActiveIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1);
  const goToIndex = (newIndex) => setActiveIndex(newIndex);

  /********** RENDER **********/
  // Filter only the relevant statuses for In-Store Orders
  const filteredOrderStatuses = orderStatuses.filter((status, index) => inStoreStatuses.includes(statusesArr[index]));
  const filteredOrdersNumber = ordersNumber.filter((number, index) => inStoreStatuses.includes(statusesArr[index]));

  const series = isInstoreTab ? filteredOrdersNumber : ordersNumber;
  const labels = isInstoreTab ? filteredOrderStatuses : orderStatuses;

  const chartOptions = {
    ...donutChartOptions,
    labels: labels,
  };

  const renderChart = () => {
    return (<ReactApexChart
      options={chartOptions}
      series={series}
      type="donut"
      height={430}
    />)
  }

  const renderChartPlaceholder = () => {
    return (<div className="mt-4"><ChartPlaceholder /></div>)
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem className="custom-tag" tag="div" key={item.id} slide={false}>
        {item.data?.map((order, index) => {
          const percent = parseFloat(order?.percent) || 0;

          return <div key={index} className="align-middle">
            <div className="py-2">
              <div className="d-flex justify-content-between">
                <span className="font-size-13">{getGranularStatusName(order.status)} ({order.number})</span>
                <span className="font-size-11">{`${percent.toFixed(1)}%`}</span>
              </div>
              <Progress style={{ backgroundColor: '#F6F6F6', height: '5px' }}
                barStyle={{ background: item.barColor[index] }}
                value={percent}
              />
            </div>
          </div>
        })}
      </CarouselItem>
    );
  });

  const renderCarousel = () => {
    return <Carousel className="px-3" activeIndex={activeIndex} next={next} previous={previous} interval={false} >
      {slides}
      {sliderActiveTab === Order.CUSTOMER_LOCATION_REMOTE && <div className="carousel-controls d-flex align-items-center justify-content-center mt-2">
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </div>}
    </Carousel>
  }

  return (
    <Card className="remote-customer-perf-card mb-4">
      <CardHeader className="bg-transparent pt-3 d-block d-xl-flex mb-0 pb-0">
        <Nav pills className="navtab-bg">
          <NavItem>
            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: menuActiveTab === "day", })} onClick={() => setMenuActiveTab("day")}>Day</NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: menuActiveTab === "week", })} onClick={() => setMenuActiveTab("week")}>Week</NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: menuActiveTab === "month", })} onClick={() => setMenuActiveTab("month")}>Month</NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: menuActiveTab === "year", })} onClick={() => setMenuActiveTab("year")}>Year</NavLink>
          </NavItem>
        </Nav>
      </CardHeader>

      <CardBody className="pt-0">
        <Row>
          <Col className="orders-status-slider" xs={12} lg={6}>
            <Nav tabs className="mb-2">
              <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: sliderActiveTab === Order.CUSTOMER_LOCATION_REMOTE })} onClick={() => setSliderActiveTab(Order.CUSTOMER_LOCATION_REMOTE)}>Remote Orders</NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: sliderActiveTab === Order.CUSTOMER_LOCATION_IN_STORE })} onClick={() => setSliderActiveTab(Order.CUSTOMER_LOCATION_IN_STORE)}>In-Store Orders</NavLink>
              </NavItem>
            </Nav>

            {sliderActiveTab === Order.CUSTOMER_LOCATION_REMOTE && renderCarousel()}
            {sliderActiveTab === Order.CUSTOMER_LOCATION_IN_STORE && renderCarousel()}
          </Col>

          <Col className="d-flex align-items-center justify-content-center" xs={12} lg={6}>
            {totalOrders ? renderChart() : renderChartPlaceholder()}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default CustomerPerformance;
