import React from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Card, CardBody } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Breadcrumbs from "components/Common/Breadcrumb2";
import AccessDenied from "pages/Error/AccessDenied";
import { useSelector } from "react-redux";
import { perms, useAccess } from "context/access";
import OrderCountReport from "./Partial/Section/OrderCount";
import StatisticsReport from "./Partial/Section/Statistics";
import GroupUsageOwnGroupFilters from "./Partial/GroupUsageOwnGroupFilters";
import GroupUsageOwnRegionFilters from "./Partial/GroupUsageOwnRegionFilters";
import GroupUsageOwnStoreFilters from "./Partial/GroupUsageOwnStoreFilters";

const GroupUsageReport = () => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const filters = useSelector(state => state.Report.GroupUsageFilters);
  const { stores: rows, reportError: rowsError, isLoadInProgress } = useSelector(state => state.Report.GroupUsage);

  /*********** FILTERS *********/

  let FiltersComponent;
  if (iAmGranted(perms.view_dealer_groups)) {
    FiltersComponent = GroupUsageOwnGroupFilters;
  } else if (iAmGranted(perms.view_own_region_dealer_stores)) {
    FiltersComponent = GroupUsageOwnRegionFilters;
  } else {
    FiltersComponent = GroupUsageOwnStoreFilters;
  }

  // range is ready if both start and end dates have been selected
  const isRangeSet = !!(filters.startTs && filters.endTs);

  return (
    <React.Fragment>
      {iAmGranted(perms.view_group_usage_reports) && (
        <div className="page-content">
          <MetaTitle>Group Usage Report</MetaTitle>
          <Container fluid>
            <Breadcrumbs title="GROUP USAGE REPORT" />
            <Card>
              <CardBody>
                <FiltersComponent />
                {isRangeSet && <>
                  <OrderCountReport />
                  {(!isLoadInProgress && !rowsError && !!rows.length) &&
                    <StatisticsReport />
                  }
                </>}
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
      {iAmNotGranted(perms.view_group_usage_reports) && <AccessDenied />}
    </React.Fragment>
  )
}

export default GroupUsageReport;