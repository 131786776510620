import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from "react-bootstrap-sweetalert";

const ModalAlert = props => {

  return <SweetAlert
    customClass=""
    title=""
    confirmBtnBsStyle="primary"
    confirmBtnCssClass="button-alert"
    btnSize="default"
    style={{ backgroundColor: 'white'}}
    {...props}>
    {props.children}
  </SweetAlert>
}

ModalAlert.propTypes = {
  children: PropTypes.any,
}

export default ModalAlert;