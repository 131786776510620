import React from 'react';
import { Button } from 'reactstrap';
import { useDocViewer } from './Context';
import DocViewerPageNav from './Header/PageNav';
import DocViewerZoom from './Header/Zoom';
import { getBeUrl } from 'helpers/utilHelper';

const DocViewerHeader = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { orderDoc, setIsLeftBarOpen, setIsFullScreen, tryCloseViewer,
    zoomToFitWidth, zoomToFitHeight, canZoomToFitWidth, canZoomToFitHeight } = useDocViewer();

  const getDownloadUrl = () => getBeUrl(`order-doc/${orderDoc.id}/pdf/download`);

  const getViewUrl = () => getBeUrl(`order-doc/${orderDoc.id}/pdf`);

  return <div className="doc-viewer-header">
    <div className="doc-viewer-header-col text-start">
      <Button type="button" color="default" onClick={() => setIsLeftBarOpen(val => !val)}><i className="bx bx-menu"></i></Button>
      <h3 className="doc-viewer-title">{orderDoc.name}</h3>
    </div>
    <div className="doc-viewer-header-col text-center user-select-none">
      <DocViewerPageNav />
      <span className="doc-viewer-header-sep ms-3"></span>
      <DocViewerZoom />
      <span className="doc-viewer-header-sep"></span>
      <Button type="button" color="default" onClick={() => zoomToFitWidth()} disabled={!canZoomToFitWidth()}><i className="mdi mdi-arrow-expand-horizontal"></i></Button>
      <Button type="button" color="default" onClick={() => zoomToFitHeight()} disabled={!canZoomToFitHeight()}><i className="mdi mdi-arrow-expand-vertical"></i></Button>
      <Button type="button" color="default" onClick={() => setIsFullScreen(val => !val)}><i className="mdi mdi-fullscreen"></i></Button>
    </div>
    <div className="doc-viewer-header-col text-end">
      <a href={getDownloadUrl()} className="btn btn-default"><i className="bx bx-download"></i></a>
      <a href={getViewUrl()} className="btn btn-default" target="_blank" rel="noreferrer"><i className="mdi mdi-printer"></i></a>
      <Button type="button" color="default" onClick={tryCloseViewer}><i className="mdi mdi-close"></i></Button>
    </div>
  </div>
}

export default DocViewerHeader;