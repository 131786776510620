import { CREATE_MESSAGE, CREATE_MESSAGE_ERR, CREATE_MESSAGE_OK, DO_MESSAGES_CLEANUP, GET_MESSAGES, GET_MESSAGES_ERR, GET_MESSAGES_OK, GET_ORDER_MESSAGES, GET_ORDER_MESSAGES_ERR, GET_ORDER_MESSAGES_OK } from "./actionTypes";

export const getMessages = params => ({
  type: GET_MESSAGES,
  payload: { params }
});

export const getMessagesOk = response => ({
  type: GET_MESSAGES_OK,
  payload: { response }
});

export const getMessagesErr = error => ({
  type: GET_MESSAGES_ERR,
  payload: { error }
});

export const createMessage = data => ({
  type: CREATE_MESSAGE,
  payload: { data }
});

export const createMessageOk = () => ({
  type: CREATE_MESSAGE_OK
});

export const createMessageErr = error => ({
  type: CREATE_MESSAGE_ERR,
  payload: { error }
});

export const doMessagesCleanup = () => ({
  type: DO_MESSAGES_CLEANUP,
});