import React from "react";
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from "config";
import SpinnerChase from "components/Shared/SpinnerChase";

const stripe = loadStripe(config.STRIPE_PUBLIC_KEY);

const withStripeElements = Component => {

  const StripeElements = props => {

    const isStripeReady = () => !!stripe;

    return <React.Fragment>
      {isStripeReady && <Elements stripe={stripe} options={props.options}>
        <Component {...props} />
      </Elements>}
      {!isStripeReady() && <SpinnerChase />}
    </React.Fragment>
  }

  StripeElements.propTypes = {
    options: PropTypes.object,
  }

  return StripeElements;
}

export default withStripeElements;