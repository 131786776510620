import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Alert, Card, CardBody, CardHeader, CardTitle, Nav, NavLink, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Label } from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getTimeToCompletion } from "store/actions";
import { isEmpty } from "lodash";
import SpinnerChase from "components/Shared/SpinnerChase";
import { formatChartTimeLabel } from "helpers/dateHelper";

const columnChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "20%",
      borderRadius: 10,
      borderRadiusApplication: "end",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  colors: ["#556EE6", "#F1B44C", "#34C38F"],
  grid: {
    borderColor: "#f1f1f1",
  },
  fill: {
    opacity: 1,
  },
};

// receives the time in seconds
const getTickAmount = maxTime => {
  const timePerUnitArr = [86400, 3600, 60]; // => [days, hours, minutes]
  let i = 0;
  let timeUnits = 0;

  // for maxTime < 3 h, display 5 ticks
  if (maxTime < 10800) return 5;

  // calculate the number of units (min/h/d) of max time
  while (timeUnits < 1) {
    timeUnits = maxTime / timePerUnitArr[i];
    i++;
  }

  // number of ticks will be the rounded number of units (min/h/d)
  if (timeUnits > 2 && timeUnits <= 4) return Math.ceil(timeUnits);

  return 5; // let the charts library set the amount of ticks
};

const TimeToCompletion = () => {

  const dispatch = useDispatch()

  /********** STATE **********/

  const [activeTab, setActiveTab] = useState("all");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEsignRequired, setIsEsignRequired] = useState(true);
  const [isInkSignRequired, setIsInkSignRequired] = useState(true);
  const [isVidRequired, setIsVidRequired] = useState(true);

  // used to make sure that at least 1 box stays checked
  const checkCount = [isEsignRequired, isInkSignRequired, isVidRequired].filter(s => s).length

  const timeToCompletion = useSelector(state => state.Dashboard.TimeToCompletion);
  const { isLoadInProgress, timeToCompletionError, seal: { best: fastestTime, worst: longestTime } } = timeToCompletion;

  const services = {
    vid: "Identity Verification",
    eSign: "E-Sign Contracts",
    inkSign: "Ink-Sign Contracts",
    seal: "Sealed"
  }

  // building the series dynamically
  const series = [
    {
      name: "Fastest time to completion",
      data: Object.keys(services).map(serviceKey => ({
        x: services[serviceKey],
        y: !isEmpty(timeToCompletion[serviceKey]) ? Math.floor(timeToCompletion[serviceKey].best.time / 60) : null,
        userFullName: timeToCompletion[serviceKey].best?.userFullName
      }))
    },
    {
      name: "Longest time to completion",
      data: Object.keys(services).map(serviceKey => ({
        x: services[serviceKey],
        y: !isEmpty(timeToCompletion[serviceKey]) ? Math.floor(timeToCompletion[serviceKey].worst.time / 60) : null,
        userFullName: timeToCompletion[serviceKey].worst?.userFullName
      }))
    },
  ];

  const maxTime = Math.max(...series[1].data.map(o => o.y));

  /********** CHART CONFIG **********/

  const options = {
    ...columnChartOptions,
    xaxis: {
      categories: Object.values(services),
    },
    yaxis: {
      labels: {
        formatter: formatChartTimeLabel(maxTime),
      },
      showForNullSeries: false,
      tickAmount: getTickAmount(maxTime * 60),
      forceNiceScale: true,
    },
    legend: {
      show: true,
      position: 'top',
      formatter: function (seriesName, opts) {
        const timeObj = opts.seriesIndex === 0 ? fastestTime : longestTime;
        const timeLabel = timeObj && formatChartTimeLabel(maxTime)(timeObj.time / 60)
        return seriesName + (timeObj ? (` (${timeObj.userFullName})` + " - <strong>" + timeLabel + "</strong>") : "");
      }
    }
  }

  /********** EFFECTS **********/

  // runs whenever query params change, including at component mount
  useEffect(() => {
    dispatch(getTimeToCompletion(activeTab, { isVidRequired: +isVidRequired, isInkSignRequired: +isInkSignRequired, isEsignRequired: +isEsignRequired }))
  }, [activeTab, isVidRequired, isInkSignRequired, isEsignRequired])

  /********** HANDLERS **********/

  const toggleTab = tab => () => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(current => !current);
  }

  return (<Card className="time-to-completion-card">
    <CardHeader className="bg-transparent pt-3 pb-0 d-block d-xl-flex justify-content-between">
      <CardTitle className="mb-0">
        Time to completion*
        <p className="text-muted font-size-12 mb-0">(From order submission to Sealed status)</p>
      </CardTitle>
      <div className="d-flex">
        <Nav pills className="navtab-bg">
          <NavItem>
            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "all", }, "btn-light")} onClick={toggleTab("all")}>
              All
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1m", }, "btn btn-light")} onClick={toggleTab("1m")}>
              1M
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "6m", }, "btn btn-light")} onClick={toggleTab("6m")}>
              6M
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1y", }, "btn btn-light")} onClick={toggleTab("1y")}>
              1Y
            </NavLink>
          </NavItem>
        </Nav>
        <Dropdown isOpen={isMenuOpen} toggle={toggleMenu} direction="right">
          <DropdownToggle tag="button" className="btn dropdown-toggle">
            <i className="bx bx-dots-vertical-rounded" />
          </DropdownToggle>
          <DropdownMenu data-popper-placement="right-start">
            <DropdownItem tag="button">
              <div onClick={e => { e.stopPropagation() }}>
                <Input type="checkbox" id="isVidRequired" className="me-4" onChange={e => { setIsVidRequired(e.target.checked) }} defaultChecked={isVidRequired} disabled={isVidRequired && checkCount === 1} />
                <Label htmlFor="isVidRequired">Identity Verification</Label>
              </div>
            </DropdownItem>
            <DropdownItem tag="button">
              <div onClick={e => { e.stopPropagation() }}>
                <Input type="checkbox" id="isEsignRequired" className="me-4" onChange={e => { setIsEsignRequired(e.target.checked) }} defaultChecked={isEsignRequired} disabled={isEsignRequired && checkCount === 1} />
                <Label htmlFor="isEsignRequired">E-Sign Contracts</Label>
              </div>
            </DropdownItem>
            <DropdownItem tag="button">
              <div onClick={e => { e.stopPropagation() }}>
                <Input type="checkbox" id="isInkSignRequired" className="me-4" onChange={e => { setIsInkSignRequired(e.target.checked) }} defaultChecked={isInkSignRequired} disabled={isInkSignRequired && checkCount === 1} />
                <Label htmlFor="isInkSignRequired">Ink-Sign Contracts</Label>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </CardHeader>
    <CardBody>
      {!timeToCompletionError && !isLoadInProgress && <ReactApexChart options={options} series={series} type="bar" height={350} />}
      {isLoadInProgress && <div className="d-flex align-items-center h-100"><SpinnerChase className="sm" /></div>}
      {timeToCompletionError && <Alert color="danger" className="fade show text-center mb-0">
        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load data
      </Alert>}
    </CardBody>
  </Card>
  );
};

export default TimeToCompletion;