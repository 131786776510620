import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Card, CardBody, CardHeader, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import FormSteps from "../Partial/Form/Steps";
import { useAuth } from "context/auth";
import SectionInfo from "../Partial/Section/Info";
import SectionContact from "../Partial/Section/Contact";
import SectionBilling from "../Partial/Section/Billing";
import { route, routes } from "helpers/routeHelper";
import { showError, showSuccess } from "helpers/utilHelper";
import usePaymentSetupCheck from "hooks/subscription/paymentSetupCheck";
import useFirstRender from "hooks/firstRender";
import withSubscriptionSetup from "hoc/subscriptionSetup";
import { useSubscriptionSetup } from "context/subscriptionSetup";
import { getDealerStore, startPaymentSetup } from "helpers/backendHelper";
import { ValidationException } from "helpers/errorHelper";

const SubscriptionSetupStore = () => {

  // check if payment setup is possible
  usePaymentSetupCheck();

  // router hook that helps redirect
  const navigate = useNavigate();
  // hook that gets the authenticated user from context
  const { user: authUser } = useAuth();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // hook that gets the subscription info from context
  const { dealerStore: authDealerStore, refreshSubscription, isPaymentSetupRequired } = useSubscriptionSetup();
  // hooks that helps determine if a component render is the first render or not
  const { isNotFirstRender } = useFirstRender();

  /********** STATE **********/

  const [dealerStore, setDealerStore] = useState(null);
  const [dealerStoreError, setDealerStoreError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isBillingValidationInProgress, setIsBillingValidationInProgress] = useState(false);

  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({});

  // whether a child section is being edited
  const [isChildEditModeActive, setIsChildEditModeActive] = useState(false);

  // flag signaling that a subscription context refresh is in progress
  const [isSubRefInProgress, setIsSubRefInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // makes a call to bakend to fetch the dealer store
    refreshDealerStore();
  }, [authUser.dealerStoreId]);

  // runs when the subscription info from context changes
  // this happens twice:
  // 1. on the first render (we do not care about this case)
  // 2. when the user clicks NEXT and the store info is updated
  useEffect(() => {
    if (isNotFirstRender) {
      navigate(route(navRoutes.next));
    }
  }, [authDealerStore]);

  /********** OTHER **********/

  /**
   * Makes a call to backend to fetch the dealer store info
   */
  const refreshDealerStore = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getDealerStore(authUser.dealerStoreId)
      .then(response => {
        setDealerStore(response.dealerStore);
      })
      .catch(ex => {
        setDealerStoreError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  /**
   * Changes the in-progress flag and triggers a context refresh
   */
  const doSubscriptionRefresh = () => {
    setIsSubRefInProgress(true);
    refreshSubscription();
  }

  /**
   * Handler called when the user presses NEXT
   */
  const handleSubmit = () => {
    setIsBillingValidationInProgress(true);
    // make the initial remote call to get the user data
    startPaymentSetup()
      .then(response => {
        showSuccess('Billing profile saved');
        // since we have added new information to the dealer store
        // we have to reload the subscription context
        // else the new info will not be available in the next steps
        doSubscriptionRefresh();
      })
      .catch(ex => {
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          showError('Please complete your billing profile');
        } else {
          showError('Unable to create billing profile');
        }
      })
      .finally(() => {
        setIsBillingValidationInProgress(false);
      });
  }

  /**
   * Returns TRUE if the submit button should show a loading icon
   * @returns bool
   */
  const isSubmitBusy = () => isBillingValidationInProgress || isSubRefInProgress;

  return <React.Fragment>
    {iAmGranted(perms.set_up_payment) && <div className="page-content">
      {!!dealerStore && <React.Fragment>
        <MetaTitle>Subscription - Store information</MetaTitle>
        <Container fluid className="subscription-wizard">
          <Breadcrumbs title="Manage Subscription" />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title pt-1 mb-0">Dealership: {dealerStore.name}</div>
                      <p className="mt-2 mb-4">Billed: <strong>Monthly</strong></p>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <FormSteps currentStep="store" setNavRoutes={setNavRoutes} paymentPlan={authDealerStore.paymentPlan} className="mb-4" />
                  <Row>
                    <Col xl="4">
                      <SectionInfo dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} onEditModeChanged={setIsChildEditModeActive} />
                    </Col>
                    <Col xl="4">
                      <SectionContact dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} onEditModeChanged={setIsChildEditModeActive} />
                    </Col>
                    <Col xl="4">
                      <SectionBilling dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} onEditModeChanged={setIsChildEditModeActive} />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="text-end">
                      {!isPaymentSetupRequired() && <Link to={route(routes.view_subscription)} className="btn btn-secondary btn-faded">Quit</Link>}
                      <Button type="button" color="primary" onClick={handleSubmit} disabled={isChildEditModeActive || isSubmitBusy()} className="ms-2">
                        {isSubmitBusy() && <i className="mdi mdi-spin mdi-loading me-1" />}
                        Next
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this 'main' prealoder only on the first fetch
      There may be other fetches if the user edits and saves the information
      We do not want to show this preloader in those cases because each info section has its own preloader */}
      {isLoadInProgress && !dealerStore && <Preloader className="inner" />}
      {dealerStoreError && <Error error={dealerStoreError} title404="Store not found" />}
    </div>}
    {iAmNotGranted(perms.set_up_payment) && <AccessDenied />}
  </React.Fragment>
}

export default withSubscriptionSetup(SubscriptionSetupStore);