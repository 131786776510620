import { takeEvery, put, call, all } from 'redux-saga/effects';

import {
  GET_VID_REQUEST_NORM_LIST,
  GET_VID_REQUEST_NORM_LIST_ITEM,
} from './actionTypes';

import {
  getVidRequestNormListOk,
  getVidRequestNormListErr,
  getVidRequestNormListItemOk,
  getVidRequestNormListItemErr,
} from './actions';

import {
  getVidRequests,
  getVidRequest,
} from 'helpers/backendHelper';

/********** NORMALIZED LIST **********/

function* onGetVidRequestNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getVidRequests, orderId);
    yield put(getVidRequestNormListOk(response));
  } catch (error) {
    yield put(getVidRequestNormListErr(error));
  }
}

function* onGetVidRequestNormListItem({ payload: { id } }) {
  try {
    const response = yield call(getVidRequest, id);
    yield put(getVidRequestNormListItemOk(id, response));
  } catch (error) {
    yield put(getVidRequestNormListItemErr(id, error));
  }
}

function* orderDocSaga() {
  yield takeEvery(GET_VID_REQUEST_NORM_LIST, onGetVidRequestNormList);
  yield takeEvery(GET_VID_REQUEST_NORM_LIST_ITEM, onGetVidRequestNormListItem);
}

export default orderDocSaga;