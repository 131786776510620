import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * This component enables the wrapped element to enter/exit full screen mode
 * @param {object} props 
 */
const FullScreen = props => {

  /**
   * Component props:
   * className {string} css class name
   * isFullScreen {boolean} flag that instructs to activate or deactivate full screen
   * onChange {func} callback to be called when the full screen state changes
   */
  const { className, isFullScreen, onChange } = props;

  /**
   * Flag that tells whether the full screen is active or not
   * We use it to ensure that the activation/deactivation instruction is only executed once
   * Even though the component may be rendered multiple times
   */
  const [isFullScreenActive, setIsFullScreenActive] = useState(false);

  /**
   * Ref to the element that will be displayed in full screen
   */
  const fsWrapper = useRef();

  // Activate or deactivate full screen mode
  // But only if the new requested state is different from the current state
  if (isFullScreenActive !== isFullScreen) {
    setIsFullScreenActive(isFullScreen);
  }

  /**
   * This effect runs once on component mount
   * We use it to add/remove event listeners needed for the 'onChange' callback
   */
  useEffect(() => {
    document.addEventListener('fullscreenchange', fullScreenChanged);
    return () => {
      document.removeEventListener('fullscreenchange', fullScreenChanged);
    }
  }, []);

  /**
   * This effect runs every time we get an instruction to activate/deactivate full screen
   * We use it to actually turn full screen ON/OFF
   */
  useEffect(() => {
    // if we have been instructed to turn full screen ON
    if (isFullScreenActive) {
      // make sure we are not already in full screen
      if (!document.fullscreenElement) {
        fsWrapper.current.requestFullscreen();
      }
    // if we have been instructed to turn full screen OFF
    } else {
      // make sure we are in full screen
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    }
  }, [isFullScreenActive]);

  /**
   * Event handler called when the full screen mode gets activated or deactivated
   * We use it to call the 'onChange' callback
   * @param {event} e 
   * @returns {void}
   */
  const fullScreenChanged = e => onChange(document.fullscreenElement !== null);

  return <div className={className} ref={fsWrapper}>
    {props.children}
  </div>;
}

FullScreen.propTypes = {
  isFullScreen: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
}

export default FullScreen;