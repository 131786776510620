import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";
import { useAuth } from 'context/auth';
import { perms, useAccess } from 'context/access';
import FormInfoEdit from '../Form/Info/Edit';
import ViewInfo from '../View/Info';

const SectionInfo = props => {

  const { isLoadBusy, id, dealerStore, refreshHandler } = props;

  // hooks that check permissions
  const { iAmGranted, iAmGrantedAny } = useAccess();
  // authenticated user
  const { user: authUser } = useAuth();

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // TRUE after a save and untill the data is refreshed
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  const forceValues = {
    // one must not be allowed to move a store from one region/group to another
    // because store users are also linked to the region and group
    dealerGroupId: authUser.dealerGroupId,
  };
  // see who can edit 'region'
  if (iAmGrantedAny([perms.view_own_region_dealer_stores, perms.view_own_dealer_store])) {
    forceValues.dealerRegionId = authUser.dealerRegionId;
  }
  // see who can edit 'active'
  if (iAmGranted(perms.view_own_dealer_store)) {
    forceValues.isActive = dealerStore?.isActive;
  }

  return <React.Fragment>
    {isEditModeActive && <Card>
      <CardBody className="pt-3">
        <FormInfoEdit id={id} defaultValues={dealerStore} finishedHandler={toggleEditMode} forceValues={forceValues} />
      </CardBody>
    </Card>}
    {!isEditModeActive && <ViewInfo id={id} dealerStore={dealerStore} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

SectionInfo.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  dealerStore: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default SectionInfo;