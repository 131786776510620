import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Table } from "reactstrap";
import Col from "components/Shared/Col";
import OrderNotary from "../../../../model/orderNotary";
import { formats, formatTimestamp } from "../../../../helpers/dateHelper";

const SectionNotaryInfo = props => {

  const { order } = props;

  const notariesAssigned = order?.notaries?.length;

  const displayAppointmentDate = (date, format) => {
    // Display the Appointment Date/ Time if it was set by notary
    if (date >= OrderNotary.STATUS_APPOINTMENT_SET) return formatTimestamp(order.notaries?.[notariesAssigned - 1]?.meetingTs, format)
    return 'Not set'
  }

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Notary</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        <Table className="section-rows table bt-1 text-break">
          <tbody>
            <tr>
              <td className="section-row-label">Name</td>
              <td className="section-row-value">{notariesAssigned > 0 ? order?.notaries[notariesAssigned - 1]?.notaryFullName : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Phone</td>
              <td className="section-row-value">(877) 708-4383</td>
            </tr>
            <tr>
              <td className="section-row-label">Address</td>
              <td className="section-row-value">{notariesAssigned > 0 ? order?.notaries[notariesAssigned - 1]?.notaryAddress : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">5 digit Zip Code</td>
              <td className="section-row-value">{notariesAssigned > 0 ? order?.notaries[notariesAssigned - 1]?.notaryZip : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">City</td>
              <td className="section-row-value">{notariesAssigned > 0 ? order?.notaries[notariesAssigned - 1]?.notaryCity : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">State</td>
              <td className="section-row-value">{notariesAssigned > 0 ? order?.notaries[notariesAssigned - 1]?.notaryState : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Appointment Date</td>
              <td className="section-row-value ps-4">{displayAppointmentDate(order.notaries?.[notariesAssigned - 1]?.status, formats.LONG_DATE)}
              </td>
            </tr>
            <tr>
              <td className="section-row-label">Appointment Time</td>
              <td className="section-row-value ps-4">{displayAppointmentDate(order.notaries?.[notariesAssigned - 1]?.status, formats.APPOINTMENT_TIME_FORMAT)}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionNotaryInfo.propTypes = {
  order: PropTypes.object,
};

export default SectionNotaryInfo;