import React from 'react';
import { useDocViewer } from '../Context';
import classnames from "classnames";
import DocViewerPageThumb from './LeftBar/PageThumb';

const DocViewerLeftBar = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { orderDoc, isLeftBarOpen } = useDocViewer();

  return <div className={classnames('doc-viewer-left-bar', { open: isLeftBarOpen })}>
    {[...Array(orderDoc.numOfPages).keys()].map(pageNum => <DocViewerPageThumb key={pageNum} pageNum={pageNum + 1} />)}
  </div>
}

export default DocViewerLeftBar;