import React, { useState, useEffect } from "react";
import { route, routes } from "helpers/routeHelper";
import { useParams } from "react-router-dom";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import SectionBilling from "./Partial/Section/Billing";
import SectionContact from "./Partial/Section/Contact";
import SectionContact2 from "./Partial/Section/Contact2";
import SectionFinance from "./Partial/Section/Finance";
import SectionInfo from "./Partial/Section/Info";
import SectionManagers from "./Partial/Section/Managers";
import SectionSigners from "./Partial/Section/Signers";
import SectionSpecialInstructions from "./Partial/Section/SpecialInstructions";
import { getDealerStore } from "helpers/backendHelper";
import DealerGroup from "model/dealerGroup";

const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [dealerStore, setDealerStore] = useState(null);
  const [dealerStoreError, setDealerStoreError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  const isGroupSpecialInstructionsVisibile = dealerStore?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_DEALER);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the store data
    refreshDealerStore();
  }, [id]);

  /********** OTHER **********/

  const refreshDealerStore = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getDealerStore(id)
      .then(response => {
        setDealerStore(response.dealerStore);
      })
      .catch(ex => {
        setDealerStoreError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_dealer_stores) && <div className="page-content">
      {dealerStore && <React.Fragment>
        <MetaTitle>{dealerStore.name} | Stores</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(dealerStore, iAmGranted)} />
          <Row>
            <Col xs="12" xxxl="8">
              <SectionInfo id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xs="12" xl="6" xxxl="4">
              <SectionContact id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xs="12" xl="6" xxxl="4">
              <SectionContact2 id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xs="12" xl="6" xxxl="4">
              <SectionFinance id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xs="12" xl="6" xxxl="4">
              <SectionBilling id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            {iAmNotGranted(perms.view_own_dealer_store) && <Col xxxl="8">
              <SectionManagers id={id} />
            </Col>}
            <Col xxxl="8">
              <SectionSigners id={id} />
            </Col>
            {isGroupSpecialInstructionsVisibile && <Col xs="12" xxxxl="4">
              <SectionSpecialInstructions dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>}
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !dealerStore && <Preloader className="inner" />}
      {dealerStoreError && <Error error={dealerStoreError} title404="Store not found" />}
    </div>}
    {iAmNotGranted(perms.view_dealer_stores) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (dealerStore, iAmGranted) => {
  if (iAmGranted(perms.view_own_dealer_store)) {
    return [{ title: 'STORE' }];
  }
  return [{
    title: 'STORES',
    url: route(routes.list_dealer_stores),
  }, {
    title: dealerStore.name,
  }];
}

export default View;
