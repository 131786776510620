import {
  GET_GROUP_USAGE_REPORT,
  GET_GROUP_USAGE_REPORT_OK,
  GET_GROUP_USAGE_REPORT_ERR,
  DO_GROUP_USAGE_REPORT_CLEANUP,

  APPLY_GROUP_USAGE_REPORT_FILTERS,
  CLEAR_GROUP_USAGE_REPORT_FILTERS,
  DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP,

  GET_DEALER_SALES_REPORT,
  GET_DEALER_SALES_REPORT_OK,
  GET_DEALER_SALES_REPORT_ERR,
  DO_DEALER_SALES_REPORT_CLEANUP,

  APPLY_DEALER_SALES_REPORT_FILTERS,
  CLEAR_DEALER_SALES_REPORT_FILTERS,
  DO_DEALER_SALES_REPORT_FILTERS_CLEANUP,
} from "./actionTypes";

/********** GROUP USAGE REPORT **********/

export const getGroupUsageReport = params => ({
  type: GET_GROUP_USAGE_REPORT,
  payload: { params },
});

export const getGroupUsageReportOk = response => ({
  type: GET_GROUP_USAGE_REPORT_OK,
  payload: { response },
});

export const getGroupUsageReportErr = error => ({
  type: GET_GROUP_USAGE_REPORT_ERR,
  payload: { error },
});

export const doGroupUsageReportCleanup = () => ({
  type: DO_GROUP_USAGE_REPORT_CLEANUP,
});

/********** GROUP USAGE REPORT FILTERS **********/

export const applyGroupUsageReportFilters = filters => ({
  type: APPLY_GROUP_USAGE_REPORT_FILTERS,
  payload: { filters },
});

export const clearGroupUsageReportFilters = () => ({
  type: CLEAR_GROUP_USAGE_REPORT_FILTERS,
});

export const doGroupUsageReportFiltersCleanup = () => ({
  type: DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP,
});

/********** DEALER SALES REPORT **********/

export const getDealerSalesReport = params => ({
  type: GET_DEALER_SALES_REPORT,
  payload: { params },
});

export const getDealerSalesReportOk = response => ({
  type: GET_DEALER_SALES_REPORT_OK,
  payload: { response },
});

export const getDealerSalesReportErr = error => ({
  type: GET_DEALER_SALES_REPORT_ERR,
  payload: { error },
});

export const doDealerSalesReportCleanup = () => ({
  type: DO_DEALER_SALES_REPORT_CLEANUP,
});

/********** DEALER SALES REPORT FILTERS **********/

export const applyDealerSalesReportFilters = filters => ({
  type: APPLY_DEALER_SALES_REPORT_FILTERS,
  payload: { filters },
});

export const clearDealerSalesReportFilters = () => ({
  type: CLEAR_DEALER_SALES_REPORT_FILTERS,
});

export const doDealerSalesReportFiltersCleanup = () => ({
  type: DO_DEALER_SALES_REPORT_FILTERS_CLEANUP,
});