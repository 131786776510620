import React from 'react';
import { useDragLayer } from 'react-dnd';
import { useDocViewer } from '../Context';
import classnames from 'classnames';

/**
 * This component renders an invisible layer on top of the editor
 * In which it renders a preview 'version' of the dragged fields
 * {@link https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer}
 */
const DocViewerDragLayer = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { getZoomScaleFactor, getFieldIcon } = useDocViewer();

  /**
   * This hook wires in the component as a drag layer
   * {@link https://react-dnd.github.io/react-dnd/docs/api/use-drag-layer}
   */
  const { isDragging, field, currentOffset } = useDragLayer(monitor => ({
    field: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  /**
   * Returns the dragged field css styles
   * @param {object} currentOffset
   * @returns {object}
   */
  const getFieldStyles = (currentOffset) => {
    if (!currentOffset) {
      return {
        display: 'none',
      };
    }
    // get current drag position
    const { x, y } = currentOffset;
    // account for the zoom when applying field transformation
    const transform = `translate(${x}px, ${y}px) scale(${getZoomScaleFactor()})`;
    return {
      transform,
      WebkitTransform: transform,
      width: `${field.widthPx}px`,
      height: `${field.heightPx}px`,
    };
  }

  /**
   * The icon for this field type
   * @type {ReactComponent}
   */
  const IconComponent = getFieldIcon(field?.type);

  /**
   * Field css classes
   * Keep in mind that 'field' is NULL when not dragging
   */
  const cssClass = classnames(
    'doc-viewer-field',
    'doc-viewer-field-dragged',
    `field-type-${field?.type}`,
    `signer-type-${field?.signer.type}`,
    `signer-position-${field?.signer.position}`,
  );

  return <React.Fragment>
    {isDragging && <div className="doc-viewer-drag-layer">
      <div className={cssClass} style={getFieldStyles(currentOffset)}>
        <div className="doc-viewer-field-body">
          <IconComponent className="doc-viewer-field-icon" />
          <div className="doc-viewer-field-content">
            <div className="doc-viewer-field-signer-name">{field.signer.fullName}</div>
            <div className="doc-viewer-field-label">
              <div className="doc-viewer-field-text">{field.text}</div>
            </div>
          </div>
          <div className="doc-viewer-field-menu">
            <button tag="button" className="btn dropdown-toggle">
              <i className="bx bx-dots-vertical-rounded" />
            </button>
          </div>
        </div>
      </div>
    </div>}
  </React.Fragment>
}

export default DocViewerDragLayer;