import React, { useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { doVidRequestNormListCleanup, getVidRequestNormList } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import VidRequest from "./Request";
import { useSocketOn, useSubscribeToOrder } from "hooks/socket";
import socketEvent from "constants/socketEvent";

const VidRequests = props => {

  const { order } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const vidRequestIds = useSelector(state => state.VidRequest.NormList.vidRequestIds);
  const vidRequestsError = useSelector(state => state.VidRequest.NormList.vidRequestsError);
  const isLoadInProgress = useSelector(state => state.VidRequest.NormList.isLoadInProgress);

  /********** OTHER **********/

  const refreshVidRequests = useCallback(() => dispatch(getVidRequestNormList(order.id)), [order.id]);

  /********** SOCKET **********/

  // start receiving updates about this particular order
  useSubscribeToOrder(order.id);

  const onVidRequestCreated = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple orders
    // therefore we need to check whether this update refers to the current order
    if (data.orderId == order.id) {
      refreshVidRequests();
    }
  }, [order.id, refreshVidRequests]);

  // listen for changes on order documents
  useSocketOn(socketEvent.vidReqCreated, onVidRequestCreated);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    refreshVidRequests();
    return () => {
      // state cleanup on component unmount
      dispatch(doVidRequestNormListCleanup());
    }
  }, [order.id]);

  return <React.Fragment>
    {!!vidRequestIds.length && <React.Fragment>
      {vidRequestIds.map((reqId, index) => <VidRequest
        key={reqId}
        id={reqId}
        num={index + 1}
        order={order} />)}
    </React.Fragment>}
    {!isLoadInProgress && !vidRequestsError && !vidRequestIds.length && <p className="text-center">No identity verification attempts found</p>}
    {isLoadInProgress && <SpinnerChase className="sm" />}
    {vidRequestsError && <Alert color="danger" className="fade show text-center mb-0">
      <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load identity verification attempts
    </Alert>}
  </React.Fragment>
}

VidRequests.propTypes = {
  order: PropTypes.object.isRequired,
};

export default VidRequests;
