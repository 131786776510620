import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { route, routes } from "helpers/routeHelper";
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Row, Col, Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import { doOrderSingleCleanup, getOrderWithCustomerSigners } from "store/actions";
import ESignDocs from "../Partial/ESign/Docs";
import OrderDoc from "model/orderDoc";
import { orderIsLocked } from "helpers/utilHelper";
import shield from 'assets/images/shield-blue.svg';
import { getOrderSignerLink } from "helpers/backendHelper";
import { useAuth } from "context/auth";
import FormNewDocumentsFile from "../Partial/Form/New/Documents/File";

const ViewESign = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // hook that gets the user from context
  const { user: authUser } = useAuth();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);

  // card dropdown menu state
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // add-new-doc form state
  const [addFormIsOpen, setAddFormIsOpen] = useState(false);

  // we use this as a way to refresh the list of docs after adding a new doc
  // also to reset the add-new-doc form after uploading a new doc
  // we increase this value each time a new document is added
  // forcing the respective components to re-render
  const [nonce, setNonce] = useState(0);

  // signer app link for the current user
  const [signerLink, setSignerLink] = useState();

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    dispatch(getOrderWithCustomerSigners(id));
    // get the signer link for the current user
    getOrderSignerLink(id, authUser.email).then(response => {
      setSignerLink(response.link);
    }).catch(ex => {
      console.error(ex);
    });
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  /********** OTHER **********/

  const isLocked = () => orderIsLocked(order.sealStatus);

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title mt-2 mb-0">E-Signed Documents</div>
                    </Col>
                    {iAmGranted(perms.edit_orders) && !isLocked() && <Col xs="auto" className="text-end">
                      <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
                        <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                          <i className="bx bx-dots-horizontal-rounded" />
                        </DropdownToggle>
                        <DropdownMenu end>
                          <DropdownItem onClick={() => setAddFormIsOpen(true)}>Add new document</DropdownItem>
                          {!!signerLink && <DropdownItem tag="a" href={signerLink} target="_blank">Sign documents</DropdownItem>}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>}
                    {isLocked() && <Col xs="auto" className="text-end">
                      <img src={shield} className="mt-1" />
                    </Col>}
                  </Row>
                </CardHeader>
                <CardBody>
                  {addFormIsOpen && <FormNewDocumentsFile
                    key={'FormEditDocuments' + nonce}
                    id={order.id}
                    finishedHandler={() => setNonce(n => n + 1)}
                    cancelHandler={() => setAddFormIsOpen(false)}
                    hasNotary={order.isNotaryRequired}
                    docDeliveryOption={order.docDeliveryOption}
                    forceDocType={OrderDoc.TYPE_E_SIGNED} />}
                  <ESignDocs key={'ESignDocs' + nonce} order={order} orderIsLocked={isLocked()} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: `${order.signers[0].fullName}`,
  url: route(routes.view_order, order.id),
}, {
  title: 'Edit order',
  url: route(routes.view_order, order.id),
}, {
  title: 'E-Signed contracts',
}];

export default ViewESign;
