import React from 'react';
import PropTypes from 'prop-types';
import { AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import OrderDoc from 'model/orderDoc';
import DocViewerAvailableField from './AvailableField';
import { isEqual } from 'lodash';
import { useDocViewer } from '../../Context';
import classnames from 'classnames';

const DocViewerSignerMenu = props => {

  /**
   * Component props:
   * signer {object} the orderSigner this menu belongs to
   */
  const { signer } = props;

  /**
   * Context vars (see: Context.js)
   */
  const { selectedField, setSelectedField } = useDocViewer();

  /**
   * Checks if we need to clear the selected field and, if so, clears it
   * A selected field needs to be cleared when the uses collapses its respective menu
   */
  const checkClearSelectedField = () => {
    if (!!selectedField && isEqual(selectedField.signer, signer)) {
      setSelectedField(null);
    }
  }

  /**
   * Menu css classes
   */
  const cssClass = classnames(
    'doc-viewer-signer-menu',
    `signer-type-${signer.type}`,
    `signer-position-${signer.position}`
  );

  return <AccordionItem className={cssClass}>
    <AccordionHeader targetId={'signer' + signer.id} onClick={checkClearSelectedField}>
      <span>{signer.fullName}</span>
    </AccordionHeader>
    <AccordionBody accordionId={'signer' + signer.id}>
      {Object.entries(OrderDoc.getFields()).map(entry => {
        const field = {
          ...entry[1],
          type: parseInt(entry[0]),
          signer,
        };
        return <DocViewerAvailableField key={`avfld-${signer.id}-${entry[0]}`} field={field} />;
      })}
    </AccordionBody>
  </AccordionItem>
}

DocViewerSignerMenu.propTypes = {
  signer: PropTypes.object,
}

export default DocViewerSignerMenu;