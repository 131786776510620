import React from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback } from "reactstrap";
import { nullsToEmptyStrings, phoneHasNoOfDigits } from "helpers/utilHelper";
import regx from "constants/regx";

const FormContact2New = props => {

  const { defaultValues, tabId, nextHandler, prevHandler } = props;

  /********** FORM CONFIG **********/

  const formInitialValues = {
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactPhone2: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      contactEmail: Yup.string().trim().email('Invalid email address'),
      contactPhone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone1',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      contactPhone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone2',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
    }),
    onSubmit: values => {
      formik.setSubmitting(false);
      nextHandler(tabId, values);
    },
  });

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <div className="text-end">
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={prevHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Back
            </Button>
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              <i className="mdi mdi-check me-1" />
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Contact name</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="contactName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.contactName} invalid={!!formik.errors.contactName} />
              {!!formik.errors.contactName && <FormFeedback type="invalid">{formik.errors.contactName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Email</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="contactEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.contactEmail} invalid={!!formik.errors.contactEmail} />
              {!!formik.errors.contactEmail && <FormFeedback type="invalid">{formik.errors.contactEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="contactPhone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.contactPhone} invalid={!!formik.errors.contactPhone} />
              {!!formik.errors.contactPhone && <FormFeedback type="invalid">{formik.errors.contactPhone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Alternate phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="contactPhone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.contactPhone2} invalid={!!formik.errors.contactPhone2} />
              {!!formik.errors.contactPhone2 && <FormFeedback type="invalid">{formik.errors.contactPhone2}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormContact2New.propTypes = {
  defaultValues: PropTypes.object,
  tabId: PropTypes.number,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
};

export default FormContact2New;