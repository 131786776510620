import { combineReducers } from "redux";
import {
  GET_ORDERS_STATS,
  GET_ORDERS_STATS_OK,
  GET_ORDER_STATS_ERR,
  DO_ORDER_STATS_CLEANUP,

  GET_CUSTOMER_PERFORMANCE,
  GET_CUSTOMER_PERFORMANCE_OK,
  GET_CUSTOMER_PERFORMANCE_ERR,

  GET_USER_LEADERBOARD,
  GET_STORE_LEADERBOARD,
  GET_USER_LEADERBOARD_OK,
  GET_STORE_LEADERBOARD_OK,
  GET_LEADERBOARD_ERR,
  DO_LEADERBOARD_CLEANUP,

  GET_USER_LEADERBOARD_LIST,
  GET_STORE_LEADERBOARD_LIST,
  GET_USER_LEADERBOARD_LIST_OK,
  GET_STORE_LEADERBOARD_LIST_OK,
  GET_LEADERBOARD_LIST_ERR,
  DO_LEADERBOARD_LIST_CLEANUP,

  GET_TIME_TO_COMPLETION,
  GET_TIME_TO_COMPLETION_OK,
  GET_TIME_TO_COMPLETION_ERR,
  DO_TIME_TO_COMPLETION_CLEANUP,
} from "./actionTypes"

/********** STATS **********/

const defaultStatsState = {
  workInProgress: null,
  remote: null,
  instore: null,
  remoteVid: null,
  sealed: null,
  isLoadInProgress: false,
  statsError: null
}

const Stats = (state = defaultStatsState, action) => {
  switch (action.type) {
    case GET_ORDERS_STATS:
      state = { ...state, statsError: null, isLoadInProgress: true };
      break
    case GET_ORDERS_STATS_OK:
      state = {
        ...state,
        isLoadInProgress: false,
        workInProgress: action.payload.response.workInProgress,
        remote: action.payload.response.remote,
        instore: action.payload.response.instore,
        remoteVid: action.payload.response.remoteVid,
        sealed: action.payload.response.sealed
      };
      break
    case GET_ORDER_STATS_ERR:
      state = { ...state, isLoadInProgress: false, statsError: action.payload.error };
      break
    case DO_ORDER_STATS_CLEANUP:
      state = { ...defaultStatsState };
      break
  }

  return state;
}

/********** LEADERBOARD **********/

const defaultLeaderboardState = {
  leaderboard: [],
  isLoadInProgress: false,
  leaderboardError: null,
  listParams: {
    limit: 4
  }
}

const Leaderboard = (state = defaultLeaderboardState, action) => {
  switch (action.type) {
    case GET_USER_LEADERBOARD:
    case GET_STORE_LEADERBOARD:
      state = {
        ...state,
        listParams: action.payload.params,
        isLoadInProgress: true,
        leaderboardError: null
      };
      break
    case GET_USER_LEADERBOARD_OK:
      state = {
        ...state,
        leaderboard: action.payload.response.users,
        isLoadInProgress: false
      };
      break
    case GET_STORE_LEADERBOARD_OK:
      state = {
        ...state,
        leaderboard: action.payload.response.dealerStores,
        isLoadInProgress: false
      };
      break
    case GET_LEADERBOARD_ERR:
      state = {
        ...state,
        leaderboardError: action.payload.error,
        isLoadInProgress: false
      };
      break
    case DO_LEADERBOARD_CLEANUP:
      state = { ...defaultLeaderboardState };
      break
  }

  return state;
}

/********** LEADERBOARD LIST **********/

const defaultLeaderboardListState = {
  leaderboard: [],
  isLoadInProgress: false,
  leaderboardError: null,
  totalCount: 0,
  listParams: {
    limit: null
  },
}

export const LeaderboardList = (state = defaultLeaderboardListState, action) => {
  switch (action.type) {
    case GET_USER_LEADERBOARD_LIST:
    case GET_STORE_LEADERBOARD_LIST:
      state = {
        ...state,
        listParams: action.payload.params,
        isLoadInProgress: true,
        leaderboardError: null
      };
      break
    case GET_USER_LEADERBOARD_LIST_OK:
      state = {
        ...state,
        leaderboard: action.payload.response.users,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false
      };
      break
    case GET_STORE_LEADERBOARD_LIST_OK:
      state = {
        ...state,
        leaderboard: action.payload.response.dealerStores,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false
      };
      break
    case GET_LEADERBOARD_LIST_ERR:
      state = {
        ...state,
        leaderboard: [],
        totalCount: 0,
        leaderboardError: action.payload.error,
        isLoadInProgress: false
      };
      break
    case DO_LEADERBOARD_LIST_CLEANUP:
      state = { ...defaultLeaderboardListState };
      break
  }

  return state;
}

/********** CUSTOMER PERFORMANCE **********/

const defaultCustomerPerformanceState = {
  stats: null,
  isLoadInProgress: false,
  customerPerformanceError: null,
}

export const CustomerPerformance = (state = defaultCustomerPerformanceState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_PERFORMANCE:
      state = { ...state, isLoadInProgress: true, customerPerformanceError: null };
      break
    case GET_CUSTOMER_PERFORMANCE_OK:
      state = { ...state, stats: action.payload.response, isLoadInProgress: false };
      break
    case GET_CUSTOMER_PERFORMANCE_ERR:
      state = { ...state, customerPerformanceError: action.payload.error, isLoadInProgress: false };
      break
  }

  return state;
}

/********** TIME TO COMPLETION **********/

const defaultTimeToCompletionState = {
  vid: {},
  eSign: {},
  inkSign: {},
  seal: {},
  isLoadInProgress: false,
  timeToCompletionError: null,
}

const TimeToCompletion = (state = defaultTimeToCompletionState, action) => {
  switch (action.type) {
    case GET_TIME_TO_COMPLETION:
      state = {
        ...state,
        isLoadInProgress: true,
        timeToCompletionError: null,
      };
      break
    case GET_TIME_TO_COMPLETION_OK:
      state = {
        ...state,
        isLoadInProgress: false,
        vid: action.payload.response.vid,
        eSign: action.payload.response.eSign,
        inkSign: action.payload.response.inkSign,
        seal: action.payload.response.seal,
      };
      break
    case GET_TIME_TO_COMPLETION_ERR:
      state = {
        ...state,
        isLoadInProgress: false,
        timeToCompletionError: action.payload.error,
      };
      break
    case DO_TIME_TO_COMPLETION_CLEANUP:
      state = { ...defaultTimeToCompletionState };
      break
  }
  return state;
}

export default combineReducers({ Stats, Leaderboard, CustomerPerformance, TimeToCompletion, LeaderboardList });
