import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";
import { useAuth } from 'context/auth';
import ViewInfo from '../View/Info';
import FormInfoEdit from 'pages/DealerStore/Partial/Form/Info/Edit';

const SectionInfo = props => {

  const { isLoadBusy, dealerStore, refreshHandler, onEditModeChanged } = props;

  // authenticated user
  const { user: authUser } = useAuth();

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // TRUE after a save and untill the data is refreshed
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  useEffect(() => {
    onEditModeChanged(isEditModeActive);
  }, [isEditModeActive]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  const forceValues = {
    // one must not be allowed to move a store from one region/group to another
    // because store users are also linked to the region and group
    dealerGroupId: authUser.dealerGroupId,
    dealerRegionId: authUser.dealerRegionId,
    // must not change own active state
    isActive: dealerStore.isActive,
  };

  return <React.Fragment>
    {isEditModeActive && <Card className="subscription-store-info-form no-shadow mb-0">
      <CardBody className="pt-3">
        <FormInfoEdit id={dealerStore.id} defaultValues={dealerStore} finishedHandler={toggleEditMode} forceValues={forceValues} />
      </CardBody>
    </Card>}
    {!isEditModeActive && <ViewInfo dealerStore={dealerStore} hasEdit={true} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

SectionInfo.propTypes = {
  isLoadBusy: PropTypes.bool,
  dealerStore: PropTypes.object,
  refreshHandler: PropTypes.func,
  onEditModeChanged: PropTypes.func,
};

export default SectionInfo;