import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { perms, useAccess } from "context/access";
import { route, routes } from "helpers/routeHelper";

const usePaymentSetupCheck = () => {

  const navigate = useNavigate();
  const { iAmGranted } = useAccess();

  useEffect(() => {
    if (!iAmGranted(perms.set_up_payment)) {
      // user does not have permission to configure payment
      navigate(route(routes.setup_subscription_forbidden));
      return;
    }
  }, []);
}

export default usePaymentSetupCheck;