import { Table } from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import { formatTimestamp, formats } from "helpers/dateHelper";
import Statement from "model/statement";
import { getSigningFeeServiceDesc } from "helpers/utilHelper";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";

const TransactionTable = ({ statement }) => {

  const isCollectiveDescription = Statement.isCollectiveType(statement.type);
  const isNotSubscriptionMethod = statement.type !== Statement.TYPE_DEALER_SUBSCRIPTION;
  const subscriptionClass = !isNotSubscriptionMethod ? 'col-3' : '';
  const descriptionLabel = isNotSubscriptionMethod ? 'Service Description' : 'Subscription type';

  const displayPrice = (feeCount, price) => {
    if(feeCount > 1) return `${feeCount} x $${price}`;
    return `$${price}`;
  }

  return <React.Fragment>
    {statement && <React.Fragment>
      <h3 className="font-size-15 fw-bold">Transaction detail</h3>
      <div className="table-responsive mb-3">
        <Table className="table-nowrap">
          <thead>
            <tr>
              <th className={`${subscriptionClass} ps-0`} style={{ width: "70px" }}>No.</th>
              {isNotSubscriptionMethod && <th>Order ID</th>}
              <th className={subscriptionClass}>Date</th>
              <th className={subscriptionClass}>{descriptionLabel}</th>
              {isNotSubscriptionMethod && <>
                <th className={subscriptionClass}>Customer Name</th>
                <th className={subscriptionClass}>VIN</th>
                <th className={subscriptionClass}>Stock Number</th>
              </>}
              <th className={`${subscriptionClass} text-end`}>Total</th>
            </tr>
          </thead>
          <tbody>
            {statement.lines.map((line, index) => <tr key={index}>
              <td className="ps-0">{index + 1}</td>
              {isNotSubscriptionMethod && <td><Link to={route(routes.view_order, line.orderId)}>{line.orderId}</Link></td>}
              <td>{formatTimestamp(line.date, formats.LONG_DATE)}</td>
              <td>{
                isCollectiveDescription
                  ? getSigningFeeServiceDesc(line.description)
                  : (isNaN(line.description) ? line.description : getSigningFeeServiceDesc(line.description))
              }</td>
              {isNotSubscriptionMethod && <>
                <td>{line.customerName}</td>
                <td>{line.vin}</td>
                <td>{line.stockNum || '--'}</td>
              </>}
              <td className="text-end">{line.additionalFeeCount ?
                displayPrice(line.additionalFeeCount, line.additionalFeePrice) :
                `$${line.total}`}
              </td>
            </tr>)}
          </tbody>
        </Table>
      </div>
      <div className='mb-2'>
        <div className='total-price-wrapper d-flex justify-content-between align-items-center'>
          <div>Sub Total Price</div>
          <h4 className="m-0 font-size-13">${statement.amount}</h4>
        </div>
      </div>
      <div className='mb-2'>
        <div className='total-price-wrapper d-flex justify-content-between align-items-center'>
          <div><strong>Total Price</strong></div>
          <h4 className="m-0">${statement.amount}</h4>
        </div>
      </div>
    </React.Fragment>}
  </React.Fragment>
}

TransactionTable.propTypes = {
  statement: PropTypes.object,
};

export default TransactionTable;