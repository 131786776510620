import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import PropTypes from 'prop-types';
import { route, routes } from "helpers/routeHelper";

const Actions = ({ onCallCustomer }) => {

  return <>
    <Card className="action-card">
      <CardBody className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="icon-circle avatar-sm align-self-center">
            <Link to={route(routes.new_order)}>
              <span className="stats-icon primary-icon-color-light">
                <i className="mdi mdi-plus font-size-24" />
              </span>
            </Link>
          </div>
          <Link to={route(routes.new_order)} className="action-link create-order-dashboard">
            <p className="font-size-24 mb-0">Create new order</p>
          </Link>
        </div>
      </CardBody>
    </Card>
    <Card className="action-card">
      <CardBody className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="icon-circle avatar-sm align-self-center">
            <span className="stats-icon green-icon-color" onClick={onCallCustomer} >
              <i className="mdi mdi-video-outline font-size-24" />
            </span>
          </div>
          <div>
            <p className="font-size-24 mb-0" onClick={onCallCustomer}>Call a customer</p>
          </div>
        </div>
      </CardBody>
    </Card>
  </>
}

Actions.propTypes = {
  onCallCustomer: PropTypes.func.isRequired,
};

export default Actions;