import React from 'react';
import { useDocViewer } from './Context';
import SpinnerChase from '../SpinnerChase';
import Error from 'pages/Error';
import DocViewerHeader from './Header';
import DocViewerBody from './Body';
import DocViewerFooter from './Footer';
import FullScreen from '../FullScreen';
import OrderDoc from 'model/orderDoc';
import { LocalException } from 'helpers/errorHelper';
import DocViewerCloseConf from './CloseConf';

const DocViewerLayout = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { orderDoc, orderDocError, isLoadBusy, isFullScreen, setIsFullScreen, reportSizeChange } = useDocViewer();

  /**
   * Event handler called when the full screen mode gets activated or deactivated
   * @param {boolean} isActive
   */
  const handleFullScreenChanged = isActive => {
    // if the full screen mode has just been deactivated
    if (!isActive) {
      // the user might have exited full screen not by clicking the button but by pressing the ESC key
      // in which case the state has not been updated and we have to do it manually
      // note that if the user exited full screen by clicking the button the state will be updated twice
      // which is not a big deal hence the choice for this easier solution
      setIsFullScreen(false);
    }
    // broadcast that the content size has changed so listeners can make the necessary recalculations
    // it looks like this event is raised before the full screen layout is fully painted
    // so we send the notification with a delay, allowing the browser to finish painting the layout
    // else some size computations might produce wrong results
    setTimeout(() => reportSizeChange(), 250);
  }

  return <div className="doc-viewer">
    {orderDoc && <React.Fragment>
      {orderDoc.status > OrderDoc.STATUS_PENDING_INITIAL_PROCESSING && <FullScreen isFullScreen={isFullScreen} onChange={handleFullScreenChanged} className="doc-viewer-fs-wrapper">
        <div className="doc-viewer-layout">
          <DocViewerHeader />
          <DocViewerBody />
          <DocViewerFooter />
          <DocViewerCloseConf />
        </div>
      </FullScreen>}
      {/* Do not open viewer before page images are generated */}
      {orderDoc.status == OrderDoc.STATUS_PENDING_INITIAL_PROCESSING && <Error error={new LocalException('Document not ready')} />}
    </React.Fragment>}
    {isLoadBusy && <SpinnerChase className="doc-viewer-loader" />}
    {orderDocError && <Error error={orderDocError} title404="Document not found" />}
  </div>
}

export default DocViewerLayout;