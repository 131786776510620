import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button, Row, Col, Card, CardHeader, CardTitle, CardBody, Alert, Label, Input } from "reactstrap";
import { getDealerAppUrl, showError } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import withStripeElements from "hoc/stripeElements";
import SpinnerChase from "components/Shared/SpinnerChase";
import classnames from "classnames";
import { useSubscriptionSetup } from "context/subscriptionSetup";

const FormPaymentInfoStripe = props => {

  const { navRoutes, toggleEditMode, togglePayWithCheck } = props;

  const stripe = useStripe();
  const elements = useElements();

  const { dealerStore, isPaymentSetupRequired } = useSubscriptionSetup();

  const [isFormFrameReady, setIsFormReady] = useState(false);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const [screenError, setScreenError] = useState();

  const isStripeReady = () => !!stripe && !!elements;

  const handleSubmit = async () => {
    setIsSubmitInProgress(true);
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: getDealerAppUrl(route(routes.setup_subscription_payment_conf)),
      },
    });
    if (error) {
      // This point will only be reached if there is an immediate error (before redirect)
      showError(error.message);
      setIsSubmitInProgress(false);
    }
    // user will be redirected to `return_url`
  }

  const hasPaymentMethod = () => !!dealerStore.stripePaymentMethodId;

  return <React.Fragment>
    <Row>
      <Col xl="6">
        <Card className="no-shadow mb-0">
          <CardHeader className="bg-transparent pt-3 pb-0">
            <Row>
              <Col>
                <CardTitle>Payment Information</CardTitle>
              </Col>
              {/* Show the cancel button only if the store already has a payment method defined
              otherwise there is nothing to return to */}
              {hasPaymentMethod() && <Col xs="auto">
                <div className="text-end">
                  <Button type="button" color="light" className="btn-sm" onClick={() => toggleEditMode(false)}>
                    <i className="mdi mdi-pencil me-1" />Cancel
                  </Button>
                </div>
              </Col>}
            </Row>
          </CardHeader>
          <CardBody>
            {!!screenError && <Alert color="danger" className="fade show text-center mt-1 mb-5">
              <i className="mdi mdi-alert-circle-outline me-2"></i>{screenError}
            </Alert>}
            <PaymentElement onReady={() => setIsFormReady(true)} onLoadError={() => setScreenError('Oops! Unable to load payment info form')} />
            {dealerStore.canPayWithCheck && <div className="mb-4 mt-3">
              <div className="pay-with-check-label">Pay with Check</div>
              <div className="form-check form-switch form-switch-lg d-inline-block">
                <Input type="checkbox" className="form-check-input" id="payWithCheckSwitch" onChange={e => togglePayWithCheck(e.target.checked)} />
                <Label className="form-check-label" htmlFor="payWithCheckSwitch" />
              </div>
            </div>}
            {!isFormFrameReady && !screenError && <SpinnerChase />}
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col className="text-end">
        {!isPaymentSetupRequired() && <Link to={route(routes.view_subscription)} className="btn btn-secondary btn-faded">Quit</Link>}
        {navRoutes.prev && <Link to={route(navRoutes.prev)} className={classnames('btn btn-primary btn-faded ms-2', { disabled: isSubmitInProgress })}>Previous</Link>}
        <Button color="primary" className="ms-2" onClick={handleSubmit} disabled={!isFormFrameReady || !isStripeReady() || isSubmitInProgress}>
          {isSubmitInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
          Next
        </Button>
      </Col>
    </Row>
  </React.Fragment>
};

FormPaymentInfoStripe.propTypes = {
  navRoutes: PropTypes.object,
  toggleEditMode: PropTypes.func,
  togglePayWithCheck: PropTypes.func,
}

export default withStripeElements(FormPaymentInfoStripe);
