import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Container, Row, Col, Card } from 'reactstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ResponsiveCarousel = () => {

  return (
    <Card className="carousel-mav">
      <Carousel showThumbs={false}>
        <div className="item">
          <Container fluid id="slide-1" className="slide-responsive slide-background">
            <Row className="position-slide-text long-text-resize">
              <Col xs={12} lg={5}>
                <h1 className="slide-title">ID Checks with Biometrics</h1>
                <p className="slide-content mb-0">{"The advantage of biometric ID check is that it's easy to use, it can be done in seconds, and it can be done remotely."}</p>
                <p className="slide-content">{"This eliminates the need to travel to a physical location, reduces the risk of fraud and other malicious activities."}</p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="item">
          <Container fluid id="slide-2" className="slide-responsive slide-background">
            <Row className="position-slide-text long-text-resize">
              <Col xs={12} lg={5}>
                <h1 className="slide-title slide-title-esign mb-0">The convenience of</h1>
                <h1 className="slide-title slide-title-esign">e-signatures</h1>
                <p className="slide-content font-size-20">{"E-signatures offer a number of advantages over traditional pen-and-paper signatures, allowing for documents to be signed electronically, eliminating the need for physical mail or in-person meetings."}</p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="item">
          <Container fluid id="slide-3" className="slide-responsive slide-background">
            <Row className="position-slide-text">
              <Col xs={12} lg={6}>
                <h1 className="slide-title slide-title-ink text-black">The authenticity of ink paper signatures</h1>
                <p className="slide-content text-black">{"There are a dozen of states that still require the traditional way of signing documents, or based on certain Red Flag Compliance programs your dealership may prefer to go with a more formal signing such as wet signatures."}</p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="item">
          <Container fluid id="slide-4" className="slide-responsive slide-background">
            <Row className="position-slide-text">
              <Col xs={12} lg={5}>
                <h1 className="slide-title">Mavsign Digital Seal</h1>
                <p className="slide-content">{'The latest improvement of security lays in attesting the authenticity of the electronic signature by sealing the document once all the required steps are completed.'}</p>
              </Col>
            </Row>
          </Container>
        </div>
      </Carousel>
    </Card>
  )
}

export default ResponsiveCarousel