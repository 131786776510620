/********** GET SUBSCRIPTION **********/

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION"
export const GET_SUBSCRIPTION_OK = "GET_SUBSCRIPTION_OK"
export const GET_SUBSCRIPTION_ERR = "GET_SUBSCRIPTION_ERR"

/********** COLLECT PAYMENT INFO **********/

export const INIT_PAYMENT_METHOD = 'INIT_PAYMENT_METHOD'
export const INIT_PAYMENT_METHOD_OK = 'INIT_PAYMENT_METHOD_OK'
export const INIT_PAYMENT_METHOD_ERR = 'INIT_PAYMENT_METHOD_ERR'
export const DO_INIT_PAYMENT_METHOD_CLEANUP = 'DO_INIT_PAYMENT_METHOD_CLEANUP'