import React from "react";
import PropTypes from "prop-types";
import {getPaymentMethod} from "../../../../../helpers/utilHelper";

const PaymentMethod = ({paymentMethod, paymentRef}) => {

  return <React.Fragment>
    <div className="font-size-15 mb-2"><strong>Payment</strong></div>
    <div className="font-weight-600 mb-2">Payment Method</div>
    <div className='mb-2'>{getPaymentMethod(paymentMethod)}</div>
    <div className="font-weight-600 mb-2">Reference</div>
    <div className='mb-2'>{paymentRef || '--'}</div>
  </React.Fragment>
}

PaymentMethod.propTypes = {
  paymentMethod: PropTypes.string,
  paymentRef: PropTypes.string,
}

export default PaymentMethod;