import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import SectionInfo from "./Partial/Section/Info";
import SectionManagers from "./Partial/Section/Managers";
import { route, routes } from "helpers/routeHelper";
import { getDealerRegion } from "helpers/backendHelper";

const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [dealerRegion, setDealerRegion] = useState(null);
  const [dealerRegionError, setDealerRegionError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the region data
    refreshDealerRegion();
  }, [id]);

  /********** OTHER **********/

  const refreshDealerRegion = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getDealerRegion(id)
      .then(response => {
        setDealerRegion(response.dealerRegion);
      })
      .catch(ex => {
        setDealerRegionError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_dealer_regions) && <div className="page-content">
      {!!dealerRegion && <React.Fragment>
        <MetaTitle>{dealerRegion.name} | Regions</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(dealerRegion, iAmGranted)} />
          <Row>
            <Col xxxl="8">
              <SectionInfo id={id} dealerRegion={dealerRegion} refreshHandler={refreshDealerRegion} isLoadBusy={isLoadInProgress} />
            </Col>
            {iAmNotGranted(perms.view_own_dealer_region) && <Col xxxl="8">
              <SectionManagers id={id} />
            </Col>}
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !dealerRegion && <Preloader className="inner" />}
      {!!dealerRegionError && <Error error={dealerRegionError} title404="Region not found" />}
    </div>}
    {iAmNotGranted(perms.view_dealer_regions) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (dealerRegion, iAmGranted) => {
  if (iAmGranted(perms.view_own_dealer_region)) {
    return [{ title: 'REGION' }];
  }
  return [{
    title: 'REGIONS',
    url: route(routes.list_dealer_regions),
  }, {
    title: dealerRegion.name,
  }];
}

export default View;
