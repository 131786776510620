import React from 'react';
import PropTypes from 'prop-types';
import { useDocViewer } from 'components/Shared/DocViewer/Context';
import { formatTimestamp, formats } from 'helpers/dateHelper';

const DocViewerFieldSignature = props => {

  /**
   * Component props:
   * field {object} the signature field
   */
  const { field } = props;

  /**
   * Context vars (see: Context.js)
   */
  const { orderDoc } = useDocViewer();

  /**
   * Returns the orderDocSigning object this signature belongs to
   * @returns {object}
   */
  const getSigning = () => orderDoc.signings.find(s => s.id == field.signingId);

  return <React.Fragment>
    <div className="doc-viewer-signature-element">
      <img src={field.signature} className="doc-viewer-signature-image" />
    </div>
    <div className="doc-viewer-signature-metadata">{formatTimestamp(getSigning().createdTs, formats.SIGNATURE_DATE)}</div>
  </React.Fragment>
}

DocViewerFieldSignature.propTypes = {
  field: PropTypes.object,
}

export default DocViewerFieldSignature;