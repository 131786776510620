import {
  GET_CUSTOMER_CALL_DT,
  GET_CUSTOMER_CALL_DT_OK,
  GET_CUSTOMER_CALL_DT_ERR,
  DO_CUSTOMER_CALL_DT_CLEANUP,
  APPLY_CUSTOMER_CALL_DT_FILTERS,
  CLEAR_CUSTOMER_CALL_DT_FILTERS,
} from "./actionTypes";

/********** DATATABLE **********/

export const getCustomerCallDt = params => ({
  type: GET_CUSTOMER_CALL_DT,
  payload: { params }
})

export const getCustomerCallDtOk = response => ({
  type: GET_CUSTOMER_CALL_DT_OK,
  payload: { response },
});

export const getCustomerCallDtErr = error => ({
  type: GET_CUSTOMER_CALL_DT_ERR,
  payload: { error },
});

export const doCustomerCallDtCleanup = () => ({
  type: DO_CUSTOMER_CALL_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyCustomerCallDtFilters = filters => ({
  type: APPLY_CUSTOMER_CALL_DT_FILTERS,
  payload: { filters },
});

export const clearCustomerCallDtFilters = () => ({
  type: CLEAR_CUSTOMER_CALL_DT_FILTERS,
});