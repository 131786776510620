import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";
import { initPaymentMethod, doInitPaymentMethodCleanup } from "store/actions";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import FormSteps from "../Partial/Form/Steps";
import usePaymentSetupCheck from "hooks/subscription/paymentSetupCheck";
import useBillingCheck from "hooks/subscription/billingCheck";
import SpinnerChase from "components/Shared/SpinnerChase";
import { showError } from "helpers/utilHelper";
import SectionPaymentInfo from "../Partial/Section/PaymentInfo";
import FormPaymentInfo from "../Partial/Form/PaymentInfo";
import usePaymentSkipCheck from "hooks/subscription/paymentSkipCheck";
import withSubscriptionSetup from "hoc/subscriptionSetup";
import { useSubscriptionSetup } from "context/subscriptionSetup";

const SubscriptionSetupPayment = () => {

  // check if payment setup is possible
  usePaymentSetupCheck();
  // check if billing has been set up
  useBillingCheck();
  // check if payment method is needed
  usePaymentSkipCheck();

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // hook that gets the subscription info from context
  const { dealerStore } = useSubscriptionSetup();

  /********** STATE **********/

  // get redux state from the store
  const { setupIntent, isInProgress: isIntentInProgress, error: setupIntentError } = useSelector(state => state.Subscription.InitPaymentMethod);

  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({});

  // whether the edit mode for the payment info is active
  // the edit mode is activated automatically if the dealer store does not have a payment method
  // else it is activated when user clicks on EDIT
  const [isEditActive, setIsEditActive] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // if the payment method of the dealer store has not been configured
    if (!dealerStore.stripePaymentMethodId) {
      // turn on edit mode to add a payment method
      setIsEditActive(true);
    }
    return () => {
      // state cleanup on component unmount
      dispatch(doInitPaymentMethodCleanup());
    }
  }, []);

  useEffect(() => {
    if (isEditActive) {
      // make a call to bakend to create a setup intent
      dispatch(initPaymentMethod());
    } else {
      // clear the setup intent
      dispatch(doInitPaymentMethodCleanup());
    }
  }, [isEditActive]);

  useEffect(() => {
    if (!!setupIntentError) {
      showError('Unable to change payment info');
      setIsEditActive(false);
    }
  }, [setupIntentError]);

  /********** OTHER **********/

  const getStripeOptions = () => ({
    clientSecret: setupIntent?.clientSecret,
    appearance: {
      theme: 'stripe',
    },
  });

  return <React.Fragment>
    {iAmGranted(perms.set_up_payment) && <div className="page-content">
      <MetaTitle>Subscription - Payment information</MetaTitle>
      <Container fluid className="subscription-wizard">
        <Breadcrumbs title="Manage Subscription" />
        <Row>
          <Col>
            <Card>
              <CardHeader className="bg-transparent pt-3 pb-0">
                <Row>
                  <Col>
                    <div className="card-title pt-1 mb-0">Dealership: {dealerStore.name}</div>
                    <p className="mt-2 mb-4">Billed: <strong>Monthly</strong></p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <FormSteps currentStep="payment" setNavRoutes={setNavRoutes} paymentPlan={dealerStore.paymentPlan} className="mb-4" />
                {isEditActive && !!setupIntent && <FormPaymentInfo options={getStripeOptions()} navRoutes={navRoutes} toggleEditMode={setIsEditActive} />}
                {!isEditActive && !!dealerStore.stripePaymentMethodId && <SectionPaymentInfo navRoutes={navRoutes} toggleEditMode={setIsEditActive} />}
                {isIntentInProgress && <SpinnerChase className="mt-5 mb-4" />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>}
    {iAmNotGranted(perms.set_up_payment) && <AccessDenied />}
  </React.Fragment >
}

export default withSubscriptionSetup(SubscriptionSetupPayment);