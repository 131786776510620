import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { NavItem, NavLink } from "reactstrap";
import { route, routes } from 'helpers/routeHelper';
import classnames from "classnames";

const FormSteps = props => {

  const { currentStep, setNavRoutes, paymentPlan, className } = props;

  // router hook that helps redirect
  const navigate = useNavigate();

  useEffect(() => {
    // set the 'prev' and 'next' rountes of the parent component
    setNavRoutes({
      next: steps[currentIndex() + 1]?.route,
      prev: steps[currentIndex() - 1]?.route,
    });
  }, [currentStep, paymentPlan]);

  /********** WIZARD CONFIG **********/

  // the list of wizard steps to display
  const steps = [];
  steps.push({
    key: 'store',
    name: 'Dealership Information',
    route: routes.setup_subscription_store,
  });
  steps.push({
    key: 'plan',
    name: 'Subscription Summary',
    route: routes.setup_subscription_plan,
  });
  if (!paymentPlan?.isFree) {
    steps.push({
      key: 'payment',
      name: 'Payment Information',
      route: routes.setup_subscription_payment,
    });
  }
  steps.push({
    key: 'overview',
    name: 'Subscription Overview',
    route: routes.setup_subscription_overview,
  });

  const goToStep = index => navigate(route(steps[index].route));

  const currentIndex = () => steps.findIndex(s => s.key == currentStep);

  return <React.Fragment>
    <div className={classnames('wizard clearfix', className)}>
      <div className="steps clearfix">
        <ul>
          {steps.map((step, index) => {
            return <NavItem className={classnames({ current: currentStep === step.key })} key={index}>
              <NavLink className={classnames({ active: currentStep === step.key })} onClick={() => goToStep(index)} disabled={currentIndex() <= index}>
                <span className="number">{index + 1}</span> {step.name}
              </NavLink>
            </NavItem>
          })}
        </ul>
      </div>
    </div>
  </React.Fragment>
}

FormSteps.propTypes = {
  currentStep: PropTypes.string.isRequired,
  setNavRoutes: PropTypes.func,
  className: PropTypes.string,
  paymentPlan: PropTypes.object,
}

export default FormSteps;