import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DocViewerLayout from './Layout';
import DocViewerContext from './Context';

const DocViewer = props => {

  const { docId } = props;

  // Although this is not a list item, we still want to use a key
  // to force the viewer component to be destroyed and recreated each time a new doc is loaded
  // therefore resetting its state (sizes and zoom calculations, etc)
  // https://twitter.com/sebmarkbage/status/1262937670444974081
  return <DocViewerContext key={'docvwr' + docId} {...props}>
    <DndProvider backend={HTML5Backend}>
      <DocViewerLayout />
    </DndProvider>
  </DocViewerContext>
}

DocViewer.propTypes = {
  docId: PropTypes.number.isRequired,
  closeHandler: PropTypes.func,
}

export default DocViewer;