import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";
import FormBillingEdit from '../Form/Billing/Edit';
import ViewBilling from '../View/Billing';

const SectionBilling = props => {

  const { isLoadBusy, id, dealerStore, refreshHandler } = props;

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  const forceValues = {
    // billing plan must not be edited from here
    // because this is too error prone
    // each store has a dedicated screen for managing its own subscription
    paymentPlanId: dealerStore.paymentPlanId,
  };

  return <React.Fragment>
    {isEditModeActive && <Card>
      <CardBody className="pt-3">
        <FormBillingEdit id={id} defaultValues={dealerStore} finishedHandler={toggleEditMode} forceValues={forceValues} />
      </CardBody>
    </Card>}
    {!isEditModeActive && <ViewBilling dealerStore={dealerStore} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

SectionBilling.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  dealerStore: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default SectionBilling;