import { combineReducers } from "redux";
import {
  GET_CUSTOMER_CALL_DT,
  GET_CUSTOMER_CALL_DT_OK,
  GET_CUSTOMER_CALL_DT_ERR,
  DO_CUSTOMER_CALL_DT_CLEANUP,
  APPLY_CUSTOMER_CALL_DT_FILTERS,
  CLEAR_CUSTOMER_CALL_DT_FILTERS,
} from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  customerCalls: [],
  customerCallsError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 50,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_CALL_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        customerCallsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_CUSTOMER_CALL_DT_OK:
      state = {
        ...state,
        customerCalls: action.payload.response.customerCalls,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_CUSTOMER_CALL_DT_ERR:
      state = {
        ...state,
        customerCalls: [],
        totalCount: 0,
        customerCallsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_CUSTOMER_CALL_DT_CLEANUP:
      state = {
        ...state,
        customerCalls: [],
        customerCallsError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

const defaultDtFiltersState = {
  userName: '',
  customerName: '',
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_CUSTOMER_CALL_DT_FILTERS:
      state = action.payload.filters;
      break
    case CLEAR_CUSTOMER_CALL_DT_FILTERS:
      state = { ...defaultDtFiltersState };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
  DtFilters,
});