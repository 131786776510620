import { createMessage, getMessages } from 'helpers/backendHelper';
import { takeEvery, put, call } from 'redux-saga/effects';
import { createMessageErr, createMessageOk, getMessagesErr, getMessagesOk } from './actions';
import { CREATE_MESSAGE, GET_MESSAGES } from './actionTypes';
import EventEmitter from 'helpers/eventsHelper';

function* onGetMessages({ payload: { params } }) {
  try {
    const response = yield call(getMessages, params);
    yield put(getMessagesOk(response))
  } catch (error) {
    yield put(getMessagesErr(error))
  }
}

function* onCreateMessage({ payload: { data } }) {
  let config = null;
  if (data instanceof FormData) {
    config = {
      onUploadProgress: ev => EventEmitter.emit('message.uploadProgress', { ev, fileUid: data.get('uid') })
    };
  }
  try {
    const response = yield call(createMessage, data, config);
    yield put(createMessageOk(response));
  } catch (error) {
    yield put(createMessageErr(error));
  }
}

function* messagesSaga() {
  yield takeEvery(GET_MESSAGES, onGetMessages);
  yield takeEvery(CREATE_MESSAGE, onCreateMessage);
}

export default messagesSaga;