import React, { useEffect, useState } from 'react';
import { useSubscription } from './subscription';
import Preloader from 'components/Shared/Preloader';
import { omit } from 'underscore';

const SubscriptionSetupContext = React.createContext();

const SubscriptionSetupProvider = props => {

  // dealer store subscription info
  const { dealerStore, paymentPlan, refreshSubscription, isPaymentSetupRequired } = useSubscription();

  const [setupDealerStore, setSetupDealerStore] = useState(undefined);
  const [setupPaymentPlan, setSetupPaymentPlan] = useState(undefined);

  useEffect(() => {
    if (dealerStore.paymentSetup) {
      setSetupDealerStore(({
        ...dealerStore,
        ...omit(dealerStore.paymentSetup, 'id', 'dealerStoreId', 'createdTs', 'updatedTs'),
      }));
      setSetupPaymentPlan(dealerStore.paymentSetup.paymentPlan);
    } else {
      setSetupDealerStore(dealerStore);
      setSetupPaymentPlan(paymentPlan);
    }
  }, [dealerStore]);

  // wait untill we decide the selected payment plan
  if (setupDealerStore !== undefined) {
    return <SubscriptionSetupContext.Provider value={{ dealerStore: setupDealerStore, paymentPlan: setupPaymentPlan, refreshSubscription, isPaymentSetupRequired }} {...props} />
  }
  // until then show a preloader
  return <Preloader />
}

// helper hook that makes context data available
export const useSubscriptionSetup = () => React.useContext(SubscriptionSetupContext);

export default SubscriptionSetupProvider;