import React, { useEffect } from "react";
import { Row, Col, Card, CardBody, Progress, CardTitle, Alert, CardHeader } from "reactstrap";
import { formatDate, formats } from "helpers/dateHelper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserLeaderboard, doLeaderboardCleanup } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useAuth } from "context/auth";
import { route, routes } from "helpers/routeHelper";

const Leaderboard = () => {

  const dispatch = useDispatch();

  const { user } = useAuth();

  /********** STATE **********/

  const { leaderboard, leaderboardError, isLoadInProgress } = useSelector(state => state.Dashboard.Leaderboard);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get top 4 agents
    dispatch(getUserLeaderboard({ limit: 4 }));
    return () => {
      // state cleanup on component unmount
      dispatch(doLeaderboardCleanup());
    }
  }, []);

  const colors = ["primary", "warning", "info", "success", "danger"]

  return (
    <Card className="top-card mobile-slide">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col xl={8}>
            <CardTitle>{`TOP PERFORMERS ${formatDate(new Date(), formats.MONTH).toUpperCase()}`}</CardTitle>
          </Col>
          <Col xl={4}><CardTitle className="font-size-13">{user.dealerRegionName}</CardTitle></Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-0">
        <Row>
          <div >
            <span className="stats-icon primary-top-icon-color ">
              <i className="bx bxs-map-pin font-size-50 text-primary pb-2" />
            </span>
          </div>
        </Row>
        <Row>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h4 className="font-size-20">{leaderboard[0]?.orders ? parseInt(leaderboard[0].orders).toLocaleString() : "--"}</h4>
            <h6 className="pb-0 font-size-13 font-weight-700 mb-0">{leaderboard[0]?.fullName || "--"}</h6>
            <p className="mb-0 font-size-13">{leaderboard[0]?.dealerStoreName || "--"}</p>
          </div>
        </Row>
        <table className="table mb-1">
          <tbody>
            {!isLoadInProgress && !leaderboardError && leaderboard.map((agent, index) => <tr key={agent.id} className="align-middle">
              <td className="py-2 td-name">
                <div className=" d-flex flex-column">
                  <p className="top-store-name my-auto">{agent.fullName}</p>
                  <h5 className="font-size-12 font-weight-700 ">{agent.dealerStoreName}</h5>
                </div>
              </td>
              <td className="py-2">
                <h5 className="my-auto d-inline">{parseInt(agent.orders).toLocaleString()}</h5>
              </td>
              <td className="progress-col py-2">
                <Progress color={colors[index]} value={index === 0 ? 100 : agent.orders / leaderboard[0].orders * 100}></Progress>
              </td>
            </tr>)}
            {leaderboard.length === 0 && !leaderboardError && !isLoadInProgress && <tr>
              <td className="table-empty" colSpan="3">No agents found</td>
            </tr>}
            {!!leaderboardError && <tr>
              <td className="table-empty" colSpan="3">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load agents
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="3">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
        <div className="d-flex justify-content-end mt-2">
          <Link to={route(routes.list_leaderboard)}>View all</Link>
        </div>
      </CardBody>
    </Card>
  )
}

export default Leaderboard;