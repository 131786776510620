import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';

/**
 * This component enables the editing of an element's text
 * @param {object} props 
 */
const EditableContent = props => {

  /**
   * Component props:
   * defaultText {string} the initial text to display
   * onEditFinished {func} callback to call when user finishes editing
   */
  const { defaultText, onEditFinished } = props;

  /**
   * Ref to the textarea
   */
  const editField = useRef(null);

  /**
   * Bool flag that turns editing on and off
   * Edit input will be visible or not based on this value
   */
  const [isEditActive, setIsEditActive] = useState(false);

  /**
   * The text that has been saved by the user
   */
  const [savedText, setSavedText] = useState(defaultText);

  /**
   * The text that is being typed by the user
   * This may or may not be saved (press ENTER vs ESC)
   */
  const [editingText, setEditingText] = useState(defaultText);

  /**
   * This effect runs whenever editing mode is turned on or off
   * Which happens when the user clicks on the content to edit, saves or cancels the edit
   * We use it to auto-focus the edit field
   */
  useEffect(() => {
    // abort if editing has been turned off
    if (!isEditActive) {
      return;
    }
    // move cursor at the end
    editField.current.setSelectionRange(editingText.length, editingText.length);
    // auto-focus the edit input
    editField.current.focus();
  }, [isEditActive]);

  /**
   * Event handler called whenever the user types into the edit box
   * We use it to track the ENTER/ESC keys and apply/cancel the new value
   * @param {event} e 
   */
  const onKeyDownHandler = e => {
    if (e.key === 'Escape') {
      // user pressed ESC
      // revert back to the saved text
      setEditingText(savedText);
      // stop editing
      setIsEditActive(false);
    } else if (e.key === 'Enter') {
      // user pressed ENTER
      // save the new text
      setSavedText(editingText);
      // stop editing
      setIsEditActive(false);
      // notify listeners
      onEditFinished(editingText);
    }
  }

  /**
   * Event handler called whenever the user clicks out of the edit box
   * We use it to apply the new value
   * @param {event} e
   */
  const onBlurHandler = e => {
    // save the new text
    setSavedText(editingText);
    // stop editing
    setIsEditActive(false);
    // notify listeners
    onEditFinished(editingText);
  }

  return <div className="editable-content">
    {isEditActive && <TextareaAutosize ref={editField} className="editable-content-field" value={editingText} onChange={e => setEditingText(e.target.value)} onKeyDown={onKeyDownHandler} onBlur={onBlurHandler} />}
    {!isEditActive && <div className="editable-content-text" onClick={() => setIsEditActive(true)}>{savedText}</div>}
  </div>
}

EditableContent.propTypes = {
  defaultText: PropTypes.string,
  onEditFinished: PropTypes.func,
}

export default EditableContent;