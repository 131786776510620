import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, } from "react-router-dom";
import { Card, CardBody, Row, Col, Alert, Input } from "reactstrap";
import {
  getPaymentMethod,
  getSharedPaginationOptions,
  getSharedTableOptions,
} from "helpers/utilHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { getDtFooterRowCount } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import config from 'config';
import { useDispatch, useSelector } from "react-redux";
import { doStatementDtCleanup, getStatementDt } from "store/actions";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import DataTableFilters from "../Partial/DataTableFilters";
import Statement from "../../../../model/statement";
import DataTableGroupFilters from "./DataTableGroupFilters";
import DataTableRegionFilters from "./DataTableRegionFilters";
import { perms, useAccess } from "context/access";
import { useDebounce } from "hooks/debounce";


const DataTable = () => {

  const dispatch = useDispatch();
  const { iAmGranted } = useAccess();

  /********** STATE **********/
  const { statements: rows, statementsError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.Statement.Dt);
  const filters = useSelector(state => state.Statement.DtFilters);
  const [searchTerm, setSearchTerm] = useState("");

  // Debounce the value of the search
  const debouncedSearch = useDebounce(searchTerm, config.DATA_TABLE_SEARCH_DELAY);

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /*********** FILTERS *********/

  let FiltersComponent;
  if (iAmGranted(perms.view_dealer_groups)) {
    FiltersComponent = DataTableGroupFilters;
  } else if (iAmGranted(perms.view_own_region_dealer_stores)) {
    FiltersComponent = DataTableRegionFilters;
  } else {
    FiltersComponent = DataTableFilters;
  }

  /********** EFFECTS **********/
  // runs once on component mount
  useEffect(() => {
    // we do not get the list data here
    // instead we listen for changes on 'filters' state var and do it there (see below)
    // this is to avoid fetching the data twice (both on component mount and on filters changed)
    return () => {
      // state cleanup on component unmount
      dispatch(doStatementDtCleanup());
    }
  }, []);

  // runs whenever 'totalCount' changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs whenever 'filters' changes
  // which happens after 'apply-filters' or 'clear-filters'
  // but also on component mount
  useEffect(() => {
    // refresh the list data based on the new filters
    dispatch(getStatementDt({
      ...listParams,
      filters: filters,
      // reset the page number when filtering
      // otherwise the current page number might be higher than the total number of pages after the filtering
      page: 1,
    }));
    // update the pagination with the new page number
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [filters]);

  // search is done manually, due to debouncing
  useEffect(() => {
    // '_set' is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty('_set') && !filters._set) {
      return;
    }
    // reset the page number when searching
    // otherwise the current page number might be higher than the total number of pages after the search
    dispatch(getStatementDt({
      ...listParams,
      page: 1,
      search: searchTerm,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [debouncedSearch]);

  /********** EVENT HANDLERS **********/

  // runs whenever table params change (sorting, pagination, etc)
  const handleTableChange = (type, newState) => {
    // refresh the list data based on the new table params
    dispatch(getStatementDt({
      ...listParams,
      sortBy: newState.sortField,
      sortDir: newState.sortOrder,
      pageSize: newState.sizePerPage,
      page: newState.page,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return <React.Fragment>
    <Card className="paginated-table-card statements">
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField='id'
              columns={getColumns()}
              data={rows}>
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col>
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <Input type="text" onChange={e => setSearchTerm(e.target.value)} placeholder="Search" />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div className="text-end">
                        <FiltersComponent />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive statements-table">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No statements found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load statements
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                        <div className="d-inline ms-2">{getDtFooterRowCount(paginationProps, rows.length, totalCount)}</div>
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  </React.Fragment>
}

const getColumns = () => {

  return [{
    dataField: 'dummy',
    text: 'No.',
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row, rowIndex) => <Link to={route(routes.view_dealer_statement, row.id)}>{rowIndex + 1}</Link>,
  }, {
    dataField: 'id',
    text: 'Statement ID',
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => <Link to={route(routes.view_dealer_statement, row.id)}>{cellContent}</Link>,
  }, {
    dataField: 'qbDocId',
    text: 'Invoice ID',
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: cellContent => <span className='invoice-number'>{cellContent}</span>,
  }, {
    dataField: 'type',
    text: 'Type',
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: cellContent => Statement.getStatementTypeName(cellContent),
  }, {
    dataField: 'amount',
    text: 'Amount',
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: cellContent => `$${cellContent}`,
  }, {
    dataField: 'createdTs',
    text: 'Date',
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: cellContent => formatTimestamp(cellContent, formats.SHORT_DATE_FORMAT),
  }, {
    dataField: 'paymentMethod',
    text: 'Payment method',
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: cellContent => getPaymentMethod(cellContent),
  }]
}

export default DataTable;
