import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Row, CardHeader, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Col from "components/Shared/Col";
import { perms, useAccess } from 'context/access';
import { route, routes } from 'helpers/routeHelper';
import Confirmation from "components/Shared/Confirmation";
import { showSuccess, showError, formatPhone, getGranularStatusName } from "helpers/utilHelper";
import { formats, formatTimestamp } from 'helpers/dateHelper';
import shield from 'assets/images/shield-blue.svg';
import { ORDER_STATUS_INVALID_FOR_SIGNERS_RELOAD, ServerErrorException } from 'helpers/errorHelper';
import { reloadOrderDealerSigners, deleteOrder } from "helpers/backendHelper";
import Order from 'model/order';

const SectionOrderInfo = props => {

  const { id, order, isLocked } = props;

  // do not allow editing if order is still draft
  const isReadOnly = order.status == Order.STATUS_DRAFT;

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmGrantedAny, iAmDealerUpperManager } = useAccess();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);

  // used to show/hide the reload signers confirmation
  const [isReloadConfirmationVisible, setIsReloadConfirmationVisible] = useState(false);
  const [isReloadInProgress, setIsReloadInProgress] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  // delete-order event handler
  // make the remote call to delete the order
  const removeOrder = () => {
    setIsDeleteInProgress(true);
    // make the initial remote call to get the user data
    deleteOrder(id)
      .then(response => {
        showSuccess(`Order "#${order.id}" has been deleted`);
        navigate(route(routes.list_orders));
      })
      .catch(ex => {
        showError('Unable to delete order');
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  };

  // reload-signers event handler
  const reloadSigners = () => {
    setIsReloadInProgress(true);
    // make the initial remote call to get the user data
    reloadOrderDealerSigners(id)
      .then(response => {
        showSuccess(`Signers have been reloaded`);
        navigate(route(routes.view_order, order.id));
      })
      .catch(ex => {
        if (ex instanceof ServerErrorException) {
          if (ex.code == ORDER_STATUS_INVALID_FOR_SIGNERS_RELOAD) {
            showError('Signers can no longer be reloaded at this stage');
            return;
          }
        }
        showError("Unable to reload signers");
      })
      .finally(() => {
        setIsReloadInProgress(false);
      });
  };

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Order Information</div>
          </Col>
          {!isLocked && iAmGrantedAny([perms.delete_orders, perms.edit_orders]) && !isReadOnly && <Col xs="auto" className="text-end">
            <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
              <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                <i className="bx bx-dots-horizontal-rounded" />
              </DropdownToggle>
              <DropdownMenu end>
                {iAmGranted(perms.delete_orders) && <DropdownItem onClick={() => setIsDeleteConfirmationVisible(true)} disabled={isDeleteInProgress}>Delete</DropdownItem>}
                {iAmGranted(perms.edit_orders) && <DropdownItem onClick={() => setIsReloadConfirmationVisible(true)} disabled={isReloadInProgress}>Reload Signers</DropdownItem>}
              </DropdownMenu>
            </Dropdown>
          </Col>}
          {isLocked && <Col xs="auto" className="text-end">
            <img src={shield} className="mt-1" />
          </Col>}
        </Row>
      </CardHeader>
      <CardBody>
        <Table className="section-rows table bt-1 bb-0">
          <tbody>
            <tr>
              <td className="section-row-label">Order ID</td>
              <td className="section-row-value">{order.id}</td>
            </tr>
            <tr>
              <td className="section-row-label">Order Status</td>
              <td className="section-row-value">{getGranularStatusName(order.granularStatus)}</td>
            </tr>
            <tr>
              <td className="section-row-label">Date ordered</td>
              <td className="section-row-value">{formatTimestamp(order.createdTs, formats.DATETIME)}</td>
            </tr>
            <tr>
              <td className="section-row-label">Person submitting order</td>
              <td className="section-row-value">{order.userFullName}</td>
            </tr>
            <tr>
              <td className="section-row-label">T&C date and time of agreement</td>
              <td className="section-row-value">{order.termsAcceptedTs ? formatTimestamp(order.termsAcceptedTs, formats.DATETIME) : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Phone number<br />of person submitting order</td>
              <td className="section-row-value">{order.userPhone ? formatPhone(order.userPhone) : '--'}</td>
            </tr>
            {iAmDealerUpperManager() && <>
              <tr>
                <td className="section-row-label">Group</td>
                <td className="section-row-value">{order.dealerGroupName}</td>
              </tr>
              <tr>
                <td className="section-row-label">Dealership</td>
                <td className="section-row-value">{order.dealerStoreName}</td>
              </tr>
            </>}
            <tr>
              <td className="section-row-label">Dealership phone number</td>
              <td className="section-row-value">{formatPhone(order.dealerStorePhone)}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
    {isDeleteConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsDeleteConfirmationVisible(false);
        removeOrder();
      }}
      reverseButtons={false}
      onCancel={() => setIsDeleteConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete order &quot;#{order.id}&quot;?</span>
    </Confirmation>}
    {isReloadConfirmationVisible && <Confirmation
      confirmBtnText="Reload"
      onConfirm={() => {
        setIsReloadConfirmationVisible(false);
        reloadSigners();
      }}
      reverseButtons={false}
      onCancel={() => setIsReloadConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to reload the signers of this order? All e-signature fields will be reset!</span>
    </Confirmation>}
  </React.Fragment>
}

SectionOrderInfo.propTypes = {
  id: PropTypes.number,
  order: PropTypes.object,
  isLocked: PropTypes.bool,
};

export default SectionOrderInfo;
