import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { Button, Row, Col, Card, CardHeader, CardTitle, CardBody, Alert, Label, Input } from "reactstrap";
import { useSubscriptionSetup } from "context/subscriptionSetup";
import { route, routes } from "helpers/routeHelper";
import classnames from "classnames";
import checkIcon from "assets/images/check-payment.svg";
import { confirmOfflinePaymentMethod } from "helpers/backendHelper";
import useFirstRender from "hooks/firstRender";
import { showSuccess } from "helpers/utilHelper";

const FormPaymentInfoCheck = props => {

  const { navRoutes, toggleEditMode, togglePayWithCheck } = props;

  // router hook that helps redirect
  const navigate = useNavigate();
  // hook that gets the subscription info from context
  const { dealerStore, refreshSubscription, isPaymentSetupRequired } = useSubscriptionSetup();
  // hooks that helps determine if a component render is the first render or not
  const { isNotFirstRender } = useFirstRender();

  // flag signaling that a subscription context refresh is in progress
  const [isSubRefInProgress, setIsSubRefInProgress] = useState(false);

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [screenError, setScreenError] = useState();

  // runs when the subscription info from context changes
  // this happens twice:
  // 1. on the first render (we do not care about this case)
  // 2. when the user clicks NEXT and the store payment info is updated
  useEffect(() => {
    if (isNotFirstRender) {
      navigate(route(navRoutes.next));
    }
  }, [dealerStore]);

  const confirmPaymentInformation = () => {
    setIsSaveInProgress(true);
    confirmOfflinePaymentMethod()
      .then(response => {
        showSuccess('Payment information has been saved');
        // since we have changed the dealer store info (payment method)
        // we have to reload the subscription context
        // else the new info will not be available in the next step
        doSubscriptionRefresh();
      })
      .catch(ex => {
        setScreenError('Oops. An error has occurred. Unable to confirm payment information');
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  /**
   * Changes the in-progress flag and triggers a context refresh
   */
  const doSubscriptionRefresh = () => {
    setIsSubRefInProgress(true);
    refreshSubscription();
  }

  /**
   * Returns TRUE if a loading icon should be shown
   * @returns bool
   */
  const isBusy = () => isSaveInProgress || isSubRefInProgress;

  const hasPaymentMethod = () => !!dealerStore.stripePaymentMethodId;

  return <React.Fragment>
    <Row>
      <Col xl="6">
        <Card className="no-shadow mb-0">
          <CardHeader className="bg-transparent pt-3 pb-0">
            <Row>
              <Col>
                <CardTitle>Payment Information</CardTitle>
              </Col>
              {/* Show the cancel button only if the store already has a payment method defined
              otherwise there is nothing to return to */}
              {hasPaymentMethod() && <Col xs="auto">
                <div className="text-end">
                  <Button type="button" color="light" className="btn-sm" onClick={() => toggleEditMode(false)}>
                    <i className="mdi mdi-pencil me-1" />Cancel
                  </Button>
                </div>
              </Col>}
            </Row>
          </CardHeader>
          <CardBody>
            {!!screenError && <Alert color="danger" className="fade show text-center mt-1 mb-5">
              <i className="mdi mdi-alert-circle-outline me-2"></i>{screenError}
            </Alert>}
            <div className="mb-4">
              <div className="pay-with-check-label">Pay with Check</div>
              <div className="form-check form-switch form-switch-lg d-inline-block">
                <Input type="checkbox" className="form-check-input" id="payWithCheckSwitch" onChange={e => togglePayWithCheck(e.target.checked)} defaultChecked={true} />
                <Label className="form-check-label" htmlFor="payWithCheckSwitch" />
              </div>
            </div>
            <Alert color="default" className="fade show pay-with-check-info">
              <img src={checkIcon} alt="" height="30" className="me-4" />
              <span>You&apos;ve chosen the check payment method. If you prefer to pay with a credit card, please remember to <strong>deselect</strong> the check payment option.</span>
            </Alert>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col className="text-end">
        {!isPaymentSetupRequired() && <Link to={route(routes.view_subscription)} className="btn btn-secondary btn-faded">Quit</Link>}
        {navRoutes.prev && <Link to={route(navRoutes.prev)} className={classnames('btn btn-primary btn-faded ms-2', { disabled: isSaveInProgress })}>Previous</Link>}
        <Button color="primary" className="ms-2" onClick={confirmPaymentInformation} disabled={isBusy()}>
          {isBusy() && <i className="mdi mdi-spin mdi-loading me-1" />}
          Next
        </Button>
      </Col>
    </Row>
  </React.Fragment>
}

FormPaymentInfoCheck.propTypes = {
  navRoutes: PropTypes.object,
  toggleEditMode: PropTypes.func,
  togglePayWithCheck: PropTypes.func,
}

export default FormPaymentInfoCheck;