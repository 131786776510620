import { getStatementDt } from "helpers/backendHelper";
import { getStatementDtErr, getStatementDtOk } from "./actions";
import { GET_STATEMENT_DT } from "./actionTypes";
import { takeEvery, put, call } from "redux-saga/effects";

/********** DATATABLE **********/

function* onGetStatementDt({ payload: { params } }) {
  try {
    const response = yield call(getStatementDt, params);
    yield put(getStatementDtOk(response));
  } catch (error) {
    yield put(getStatementDtErr(error));
  }
}

function* statementSaga() {
  yield takeEvery(GET_STATEMENT_DT, onGetStatementDt);
}

export default statementSaga;