import { combineReducers } from "redux";
import {
  GET_DEALER_STORE_DT,
  GET_DEALER_STORE_DT_OK,
  GET_DEALER_STORE_DT_ERR,
  DO_DEALER_STORE_DT_CLEANUP,
  APPLY_DEALER_STORE_DT_FILTERS,
  CLEAR_DEALER_STORE_DT_FILTERS,

  GET_DEALER_STORE_LIST,
  GET_DEALER_GROUP_STORE_LIST,
  GET_DEALER_REGION_STORE_LIST,
  GET_DEALER_STORE_LIST_OK,
  GET_DEALER_STORE_LIST_ERR,
  DO_DEALER_STORE_LIST_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  dealerStores: [],
  dealerStoresError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 50,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_DEALER_STORE_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        dealerStoresError: null,
        isLoadInProgress: true,
      };
      break
    case GET_DEALER_STORE_DT_OK:
      state = {
        ...state,
        dealerStores: action.payload.response.dealerStores,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_DEALER_STORE_DT_ERR:
      state = {
        ...state,
        dealerStores: [],
        totalCount: 0,
        dealerStoresError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_DEALER_STORE_DT_CLEANUP:
      state = {
        ...state,
        dealerStores: [],
        dealerStoresError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

const defaultDtFiltersState = {
  name: '',
  region: '',
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_DEALER_STORE_DT_FILTERS:
      state = action.payload.filters;
      break
    case CLEAR_DEALER_STORE_DT_FILTERS:
      state = { ...defaultDtFiltersState };
      break
  }
  return state;
}

/********* LIST *********/

const defaultListState = {
  stores: [],
  storesError: null,
  isLoadInProgress: false,
};

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_DEALER_GROUP_STORE_LIST:
    case GET_DEALER_REGION_STORE_LIST:
    case GET_DEALER_STORE_LIST:
      state = {
        ...state,
        storesError: null,
        isLoadInProgress: true,
      }
      break
    case GET_DEALER_STORE_LIST_OK:
      state = {
        ...state,
        stores: action.payload.response.dealerStores,
        isLoadInProgress: false,
      }
      break
    case GET_DEALER_STORE_LIST_ERR:
      state = {
        ...state,
        stores: [],
        storesError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_DEALER_STORE_LIST_CLEANUP:
      state = { ...defaultListState };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
  DtFilters,
  List,
});