import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Button, CardTitle, Table } from "reactstrap";
import Col from "components/Shared/Col";
import DealerStore from 'model/dealerStore';

const ViewPaymentInfo = props => {

  const { dealerStore, toggleEditMode, hasEdit } = props;

  const hasPaymentMethod = () => !!dealerStore.paymentMethodType;

  return <React.Fragment>
    <Card className="no-shadow mb-0">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Payment Method</CardTitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {hasEdit && hasPaymentMethod() && <Button type="button" color="light" className="btn-sm" onClick={() => toggleEditMode(true)}>
                <i className="mdi mdi-pencil me-1" />Change
              </Button>}
              {hasEdit && !hasPaymentMethod() && <Button type="button" color="light" className="btn-sm" onClick={() => toggleEditMode(true)}>
                <i className="mdi mdi-pencil me-1" />Add
              </Button>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {hasPaymentMethod() && <Table className="section-rows bt-1 bb-0">
          <tbody>
            {dealerStore.paymentMethodType === DealerStore.PAYMENT_METHOD_TYPE_CARD && <React.Fragment>
              <tr>
                <td>Card brand</td>
                <td className="font-weight-500">{dealerStore.cardBrand.toUpperCase()}</td>
              </tr>
              <tr>
                <td>Card number</td>
                <td className="font-weight-500">{'*'.repeat(12)}{dealerStore.cardNum}</td>
              </tr>
              <tr>
                <td>Card expiration</td>
                <td className="font-weight-500">{dealerStore.cardExpMonth}/{dealerStore.cardExpYear}</td>
              </tr>
            </React.Fragment>}
            {dealerStore.paymentMethodType === DealerStore.PAYMENT_METHOD_TYPE_BANK_ACCOUNT && <React.Fragment>
              <tr>
                <td>Bank no.</td>
                <td className="font-weight-500">{'*'.repeat(8)}{dealerStore.bankNum}</td>
              </tr>
              <tr>
                <td>Bank name</td>
                <td className="font-weight-500">{dealerStore.bankName}</td>
              </tr>
            </React.Fragment>}
            {dealerStore.paymentMethodType === DealerStore.PAYMENT_METHOD_TYPE_CHECK && <React.Fragment>
              <tr>
                <td colSpan="2">Check</td>
              </tr>
            </React.Fragment>}
          </tbody>
        </Table>}
        {!hasPaymentMethod() && <p>No payment method selected</p>}
      </CardBody>
    </Card>
  </React.Fragment>
}

ViewPaymentInfo.propTypes = {
  dealerStore: PropTypes.object,
  toggleEditMode: PropTypes.func,
  hasEdit: PropTypes.bool,
};

export default ViewPaymentInfo;
