import { useSubscription } from 'context/subscription';
import React from 'react';

const LimitedTimePlanBar = () => {

  const { paymentPlan, planDaysLeft, isPlanActive, isPlanExpired } = useSubscription();

  return <React.Fragment>
    {isPlanActive() && <div className="free-trial-bar">{paymentPlan.name} ({planDaysLeft()} days remaining)</div>}
    {isPlanExpired() && <div className="free-trial-bar is-free-trial-expired">{paymentPlan.name} expired</div>}
  </React.Fragment>
}

export default LimitedTimePlanBar;