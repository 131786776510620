import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { useSubscriptionSetup } from 'context/subscriptionSetup';

const useBillingCheck = () => {

  const navigate = useNavigate();

  const { dealerStore } = useSubscriptionSetup();

  useEffect(() => {
    if (!dealerStore.paymentSetup) {
      // redirect back to the step that will create the payment setup record
      navigate(route(routes.setup_subscription_store));
      return;
    }
  }, []);
}

export default useBillingCheck;