import { combineReducers } from "redux";
import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_OK,
  GET_SUBSCRIPTION_ERR,
  INIT_PAYMENT_METHOD,
  INIT_PAYMENT_METHOD_OK,
  INIT_PAYMENT_METHOD_ERR,
  DO_INIT_PAYMENT_METHOD_CLEANUP,
} from "./actionTypes";

/********** GET SUBSCRIPTION **********/

const defaultGetSubscriptionState = {
  // we use a default value of 'undefined' to distinguish between the case when:
  // 1. the dealer store has not been fetched yet (undefined)
  // 2. the dealer store has been fetched but it does not exist (null)
  dealerStore: undefined,
  dealerStoreError: null,
  isLoadInProgress: false,
}

const DealerStore = (state = defaultGetSubscriptionState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION:
      state = {
        ...state,
        dealerStoreError: null,
        isLoadInProgress: true,
      };
      break
    case GET_SUBSCRIPTION_OK:
      state = {
        ...state,
        dealerStore: action.payload.response.dealerStore,
        isLoadInProgress: false,
      };
      break
    case GET_SUBSCRIPTION_ERR:
      state = {
        ...state,
        dealerStore: null,
        dealerStoreError: action.payload.error,
        isLoadInProgress: false,
      };
      break
  }
  return state;
}

/********** COLLECT PAYMENT INFO **********/

const defaultInitPaymentMethodState = {
  setupIntent: null,
  done: null,
  error: null,
  isInProgress: false,
};

const InitPaymentMethod = (state = defaultInitPaymentMethodState, action) => {
  switch (action.type) {
    case INIT_PAYMENT_METHOD:
      state = {
        ...state,
        setupIntent: null,
        done: null,
        error: null,
        isInProgress: true,
      };
      break
    case INIT_PAYMENT_METHOD_OK:
      state = {
        ...state,
        setupIntent: action.payload.response.setupIntent,
        done: true,
        isInProgress: false,
      };
      break
    case INIT_PAYMENT_METHOD_ERR:
      state = {
        ...state,
        done: false,
        error: action.payload.error,
        isInProgress: false,
      };
      break
    case DO_INIT_PAYMENT_METHOD_CLEANUP:
      state = { ...defaultInitPaymentMethodState };
      break
  }
  return state;
}

export default combineReducers({
  DealerStore,
  InitPaymentMethod,
})
