import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Preloader from "components/Shared/Preloader";
import { getAuthUser } from "store/actions";
import { initTimezone } from 'helpers/dateHelper';
import { NetworkErrorException } from 'helpers/errorHelper';
import Error from 'pages/Error';

const AuthContext = React.createContext();

const AuthProvider = props => {

  const dispatch = useDispatch();

  // warning
  // useSelector() will cause a re-render whenever the result of its callback function changes
  // for example: const { user, isAuth } = useSelector(state => state.Auth.Login);
  // you might expect to get a re-render only when 'user' or 'isAuth' change
  // in fact you will get a re-render when anything inside 'state.Auth.Login' changes, not just 'user' and 'isAuth'
  // so if that object contains other properties, unwanted re-renders will happen when they change
  // that is why, instead of destructuring, we target specific state properties one by one
  // https://9oelm.github.io/2020-09-13--How-to-make-useSelector-not-a-disaster/#function-component
  const user = useSelector(state => state.Auth.Login.user);
  const isAuth = useSelector(state => state.Auth.Login.isAuth);
  const userError = useSelector(state => state.Auth.Login.userError);

  const isLoggedIn = () => !!user;

  const isImpersonation = () => user.isImpersonated;

  const getUserRoleClass = () => user.userRoleId;

  useEffect(() => {
    if (isAuth === null) {
      refreshAuthUser();
    }
  }, [isAuth]);

  if (!!user) {
    initTimezone(user.timezone);
  }

  const refreshAuthUser = () => dispatch(getAuthUser());

  // wait until we decide whether the user is authenticated or not
  if (isAuth !== null) {
    if (userError instanceof NetworkErrorException) {
      return <Error error={userError} />
    }
    return <AuthContext.Provider value={{ user, isLoggedIn, isImpersonation, refreshAuthUser, getUserRoleClass }} {...props} />
  }
  // until then show a preloader
  return <Preloader />
}

// helper hook that makes context data available
export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;