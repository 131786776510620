/********** LIST **********/

export const GET_ORDER_DOC_LIST = 'GET_ORDER_DOC_LIST'
export const GET_UPLOADED_ORDER_DOC_LIST = 'GET_UPLOADED_ORDER_DOC_LIST'
export const GET_SHIPPED_ORDER_DOC_LIST = 'GET_SHIPPED_ORDER_DOC_LIST'
export const GET_ORDER_DOC_LIST_OK = 'GET_ORDER_DOC_LIST_OK'
export const GET_ORDER_DOC_LIST_ERR = 'GET_ORDER_DOC_LIST_ERR'

export const DO_ORDER_DOC_LIST_CLEANUP = 'DO_ORDER_DOC_LIST_CLEANUP'

/********** NORMALIZED LIST **********/

export const GET_ORDER_DOC_NORM_LIST = 'GET_ORDER_DOC_NORM_LIST'
export const GET_UPLOADED_ORDER_DOC_NORM_LIST = 'GET_UPLOADED_ORDER_DOC_NORM_LIST'
export const GET_INK_SIGN_ORDER_DOC_NORM_LIST = 'GET_INK_SIGN_ORDER_DOC_NORM_LIST'
export const GET_E_SIGN_ORDER_DOC_NORM_LIST = 'GET_E_SIGN_ORDER_DOC_NORM_LIST'
export const GET_FINANCE_ORDER_DOC_NORM_LIST = 'GET_FINANCE_ORDER_DOC_NORM_LIST'
export const GET_SUPPORTING_ORDER_DOC_NORM_LIST = 'GET_SUPPORTING_ORDER_DOC_NORM_LIST'
export const GET_ORDER_DOC_NORM_LIST_OK = 'GET_ORDER_DOC_NORM_LIST_OK'
export const GET_ORDER_DOC_NORM_LIST_ERR = 'GET_ORDER_DOC_NORM_LIST_ERR'

export const GET_ORDER_DOC_NORM_LIST_ITEM = 'GET_ORDER_DOC_NORM_LIST_ITEM'
export const GET_ORDER_DOC_NORM_LIST_ITEM_OK = 'GET_ORDER_DOC_NORM_LIST_ITEM_OK'
export const GET_ORDER_DOC_NORM_LIST_ITEM_ERR = 'GET_ORDER_DOC_NORM_LIST_ITEM_ERR'

export const DELETE_ORDER_DOC_NORM_LIST_ITEM = 'DELETE_ORDER_DOC_NORM_LIST_ITEM'
export const DELETE_ORDER_DOC_NORM_LIST_ITEM_OK = 'DELETE_ORDER_DOC_NORM_LIST_ITEM_OK'
export const DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR = 'DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR'

export const REPROCESS_ORDER_DOC_NORM_LIST_ITEM = 'REPROCESS_ORDER_DOC_NORM_LIST_ITEM'
export const REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK = 'REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK'
export const REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR = 'REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR'

export const ACCEPT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM = 'ACCEPT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM'
export const ACCEPT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM = 'ACCEPT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM'
export const ACCEPT_ORDER_DOC_NORM_LIST_ITEM_OK = 'ACCEPT_ORDER_DOC_NORM_LIST_ITEM_OK'
export const ACCEPT_ORDER_DOC_NORM_LIST_ITEM_ERR = 'ACCEPT_ORDER_DOC_NORM_LIST_ITEM_ERR'

export const REJECT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM = 'REJECT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM'
export const REJECT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM = 'REJECT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM'
export const REJECT_ORDER_DOC_NORM_LIST_ITEM_OK = 'REJECT_ORDER_DOC_NORM_LIST_ITEM_OK'
export const REJECT_ORDER_DOC_NORM_LIST_ITEM_ERR = 'REJECT_ORDER_DOC_NORM_LIST_ITEM_ERR'

export const ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM = 'ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM'
export const ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_OK = 'ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_OK'
export const ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_ERR = 'ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_ERR'

export const ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE = 'ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE'
export const ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK = 'ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK'
export const ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR = 'ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR'

export const REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE = 'REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE'
export const REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK = 'REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK'
export const REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR = 'REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR'

export const DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE = 'DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE'
export const DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK = 'DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK'
export const DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR = 'DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR'

export const ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE = 'ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE'
export const ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK = 'ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK'
export const ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR = 'ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR'

export const DO_ORDER_DOC_NORM_LIST_CLEANUP = 'DO_ORDER_DOC_NORM_LIST_CLEANUP'

/********** SINGLE **********/

export const GET_DOC_VIEWER_ORDER_DOC = 'GET_DOC_VIEWER_ORDER_DOC'
export const GET_DOC_VIEWER_ORDER_DOC_OK = 'GET_DOC_VIEWER_ORDER_DOC_OK'
export const GET_ORDER_DOC_ERR = 'GET_ORDER_DOC_ERR'
export const DO_ORDER_DOC_SINGLE_CLEANUP = 'DO_ORDER_DOC_SINGLE_CLEANUP'

/********** FORM **********/

export const UPLOAD_ORDER_DOC = 'UPLOAD_ORDER_DOC'
export const UPLOAD_ORDER_DOC_OK = 'UPLOAD_ORDER_DOC_OK'
export const UPLOAD_ORDER_DOC_ERR = 'UPLOAD_ORDER_DOC_ERR'

export const ADD_ORDER_DOC = 'ADD_ORDER_DOC'
export const ADD_ORDER_DOC_OK = 'ADD_ORDER_DOC_OK'
export const ADD_ORDER_DOC_ERR = 'ADD_ORDER_DOC_ERR'

export const DO_ORDER_DOC_FORM_CLEANUP = 'DO_ORDER_DOC_FORM_CLEANUP'

/********** FIELDS **********/

export const UPDATE_ORDER_DOC_FIELDS = 'UPDATE_ORDER_DOC_FIELDS'
export const SAVE_ORDER_DOC_FIELDS_OK = 'SAVE_ORDER_DOC_FIELDS_OK'
export const SAVE_ORDER_DOC_FIELDS_ERR = 'SAVE_ORDER_DOC_FIELDS_ERR'
export const DO_ORDER_DOC_FIELDS_CLEANUP = 'DO_ORDER_DOC_PAGE_CLEANUP'

/*********** ACCEPT ALL **********/

export const ACCEPT_ALL_INK_SIGN_DOCS = 'ACCEPT_ALL_INK_SIGN_DOCS'
export const ACCEPT_ALL_INK_SIGN_DOCS_OK = 'ACCEPT_ALL_INK_SIGN_DOCS_OK'
export const ACCEPT_ALL_INK_SIGN_DOCS_ERR = 'ACCEPT_ALL_INK_SIGN_DOCS_ERR'
export const DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP = 'DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP'