import React from 'react';
import { Button } from 'reactstrap';
import { useDocViewer } from './Context';

const DocViewerFooter = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { isSaveInProgress, isReadyToSignInProgress, saveFields, saveFieldsAndClose, tryCloseViewer, docIsSigned } = useDocViewer();

  return <div className="doc-viewer-footer">
    <Button type="button" color="primary" className="btn-faded" onClick={tryCloseViewer}>Close</Button>
    {!docIsSigned() && <React.Fragment>
      <Button type="button" color="primary" className="ms-2" onClick={() => saveFields()} disabled={isSaveInProgress}>
        {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
        Save
      </Button>
      <Button type="button" color="success" className="ms-2" onClick={() => saveFieldsAndClose(true)} disabled={isReadyToSignInProgress}>
        {isReadyToSignInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
        Ready to sign
      </Button>
    </React.Fragment>}
  </div>
}

export default DocViewerFooter;