import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Card, CardBody, CardHeader, Alert } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import FormSteps from "../Partial/Form/Steps";
import usePaymentSetupCheck from "hooks/subscription/paymentSetupCheck";
import useBillingCheck from "hooks/subscription/billingCheck";
import { route, routes } from "helpers/routeHelper";
import { useStripe } from '@stripe/react-stripe-js';
import SpinnerChase from "components/Shared/SpinnerChase";
import withStripeElements from "hoc/stripeElements";
import useFirstRender from "hooks/firstRender";
import { showSuccess } from "helpers/utilHelper";
import usePaymentSkipCheck from "hooks/subscription/paymentSkipCheck";
import withSubscriptionSetup from "hoc/subscriptionSetup";
import { useSubscriptionSetup } from "context/subscriptionSetup";
import { confirmPaymentMethod } from "helpers/backendHelper";

const SubscriptionSetupPaymentConf = () => {

  // check if payment setup is possible
  usePaymentSetupCheck();
  // check if billing has been set up
  useBillingCheck();
  // check if payment method is needed
  usePaymentSkipCheck();

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // hook that gets the subscription info from context
  const { dealerStore, refreshSubscription, isPaymentSetupRequired } = useSubscriptionSetup();
  // hooks that helps determine if a component render is the first render or not
  const { isNotFirstRender } = useFirstRender();

  const stripe = useStripe();

  /********** STATE **********/

  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({});

  // flag signaling that a subscription context refresh is in progress
  const [isSubRefInProgress, setIsSubRefInProgress] = useState(false);

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isStripeInProgress, setIsStripeInProgress] = useState(false);
  const [setupIntent, setSetupIntent] = useState();
  const [screenError, setScreenError] = useState();

  /********** EFFECTS **********/

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');
    if (!clientSecret) {
      setScreenError('Oops! An error has occurred. Bad request');
      return;
    }
    setIsStripeInProgress(true);
    stripe
      .retrieveSetupIntent(clientSecret)
      .then(({ setupIntent }) => {
        setIsStripeInProgress(false);
        switch (setupIntent.status) {
          case 'succeeded':
            setSetupIntent(setupIntent);
            break;
          case 'processing':
            setScreenError('Oops! An error has occurred. Unable to confirm result');
            break;
          case 'requires_payment_method':
            setScreenError('Oops. An error has occurred. Unable to save payment information');
            break;
        }
      });
  }, [stripe]);

  useEffect(() => {
    if (!setupIntent) {
      return;
    }
    confirmPaymentInformation({
      paymentMethodId: setupIntent.payment_method,
    });
  }, [setupIntent]);

  // runs when the subscription info from context changes
  // this happens twice:
  // 1. on the first render (we do not care about this case)
  // 2. when the user clicks NEXT and the store payment plan is updated
  useEffect(() => {
    if (isNotFirstRender) {
      navigate(route(navRoutes.next));
    }
  }, [dealerStore]);

  /********** OTHER **********/

  const confirmPaymentInformation = values => {
    setIsSaveInProgress(true);
    confirmPaymentMethod(values)
      .then(response => {
        showSuccess('Payment information has been saved');
        // since we have changed the dealer store info (payment method)
        // we have to reload the subscription context
        // else the new info will not be available in the next step
        doSubscriptionRefresh();
      })
      .catch(ex => {
        setScreenError('Oops. An error has occurred. Unable to confirm payment information');
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  /**
   * Changes the in-progress flag and triggers a context refresh
   */
  const doSubscriptionRefresh = () => {
    setIsSubRefInProgress(true);
    refreshSubscription();
  }

  /**
   * Returns TRUE if a loading icon should be shown
   * @returns bool
   */
  const isBusy = () => isStripeInProgress || isSaveInProgress || isSubRefInProgress;

  return <React.Fragment>
    {iAmGranted(perms.set_up_payment) && <div className="page-content">
      <React.Fragment>
        <MetaTitle>Subscription - Payment confirmation</MetaTitle>
        <Container fluid className="subscription-wizard">
          <Breadcrumbs title="Manage Subscription" />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title pt-1 mb-0">Dealership: {dealerStore.name}</div>
                      <p className="mt-2 mb-4">Billed: <strong>Monthly</strong></p>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <FormSteps currentStep="payment" setNavRoutes={setNavRoutes} paymentPlan={dealerStore.paymentPlan} className="mb-5" />
                  {isBusy() && <SpinnerChase className="mt-5 mb-5" />}
                  {!!screenError && <Alert color="danger" className="fade show text-center mt-5 mb-5">
                    <i className="mdi mdi-alert-circle-outline me-2"></i>{screenError}
                  </Alert>}
                  <Row className="mb-2">
                    <Col className="text-end">
                      {!isBusy() && !isPaymentSetupRequired() && <Link to={route(routes.view_subscription)} className="btn btn-secondary btn-faded">Quit</Link>}
                      {!isBusy() && navRoutes.prev && <Link to={route(navRoutes.prev)} className="btn btn-primary btn-faded">Previous</Link>}
                      {!isBusy() && !!screenError && <Link to={route(routes.setup_subscription_payment)} className="btn btn-dark ms-2">Retry</Link>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>}
    {iAmNotGranted(perms.set_up_payment) && <AccessDenied />}
  </React.Fragment>
}

export default withSubscriptionSetup(withStripeElements(SubscriptionSetupPaymentConf));