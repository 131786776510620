import React from "react";
import { Container, Card, CardBody, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";

const List = () => {

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** OTHER **********/

  return <React.Fragment>
    {iAmGranted(perms.create_orders) && <div className="page-content">
      <React.Fragment>
        <MetaTitle>Common Red Flags</MetaTitle>
        <Container fluid>
          <Breadcrumbs title="COMMON RED FLAGS" />
          <Row>
            <Col xs="12" lg="10" xl="8" xxl="8">
              <Card className="expand-v p-2">
                <CardBody>
                  <p className="mt-2">
                    <b>Change of signing location: </b>
                    {"When a customer requests to sign at a different location than what is submitted by the dealership. "}
                    <b>Unfortunate Fact: location changes occur on 99%+ of all fraudulent automotive transactions.</b>
                    {"Signing in a verifiable home location is recommended with a customer's place of work as a secondary. Any other signing locations are strongly discouraged. Please make sure the address provided in the MavSign order is verified and approved by the dealership and confirmed with the customer"}
                    <b> before </b>
                    {"placing the MavSign order. Location changes may be subject to delays and/or cancellation."}
                  </p>
                  <p className="pt-4">
                    <b>If the signer</b>
                    {" asks/mentions changing the vehicle delivery location to a non-verifiable location, delivery location different than their verifiable home/workplace or an alternate person to pick up the vehicle. "}
                  </p>
                  <p className="pt-2">
                    <b>Thumbprint refusal on the MavSign Affidavit of Identity.</b>
                    {" General Manager approval is required on all thumbprint refusals.  "}
                  </p>
                  <p className="pt-2">
                    <b>Behavioral Issues </b>
                    <i>i.e. wishy washy, pushy, wanting to avoid meeting with the notary or annoyance with a notaries involvement, overly rude or aggravated about the process. </i>
                    {" If the signer avoids making an appt/signing or If the signer is aggressively pushy to sign: some fraudulent actors will exhibit bullying behavior tactics with the goal of completing the signing less MavSign, less a notary, on their own and at their chosen non-variable location. Both can be indicative of fraudulent behavior "}
                  </p>
                  <p className="pt-2">
                    <b>Paper ID </b>
                    {" (temp), "}
                    <b>expired ID</b>
                    {"(only), "}
                    <b>unable to produce a primary or 2nd ID </b>
                    {"in a timely manner "}
                  </p>
                  <p className="pt-2">
                    <b>Avoiding completing the MavSign VerifyID </b>
                    {" fraudulent actors will give every reason not to complete the VerifyID such as incompatible device, insufficient cell service, insufficient knowledge of tech practices etc.   "}
                  </p>
                  <p className="pt-2">
                    <b>Non-responsible financial party </b>
                    {'to the vehicle purchase takes over coordinating on “behalf” of the signer and intercepting communication on “behalf” of the financially responsible party.'}
                  </p>
                  <p className="pt-2">
                    <b>Please keep in mind that the elderly are recognized as a nationwide protected class.</b>
                  </p>
                  <p className="pt-4">
                    <b>Suggested Extra Steps: </b>
                  </p>
                  <ul>
                    <li className="mt-2">VerifyID: Require the customer to complete the MavSign VerifyID</li>
                    <li className="mt-2">Employment Verification: Calling a publicly listed employer phone number. Relying solely on a contact number provided by a purchaser. Fraudulent actors will provide phone numbers for counterparts to pose as contacts.</li>
                    <li className="mt-2">Insurance Verification: Calling to verify a previous/current insurance policy..</li>
                    <li className="mt-2">Residence Verification: Collecting two+ forms of proof of residency in the way of utility bill, mortgage agreement, rental agreement etc.</li>
                    <li className="mt-2">Ask 3 on-the-spot personal questions: Asking the customer on-the-spot questions, personal to them and not allowing time to find the answers.</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
    }
    {iAmNotGranted(perms.create_orders) && <AccessDenied />}
  </React.Fragment >
}

export default List;
