import { combineReducers } from "redux"
import {
  GET_USER_ROLE_LIST,
  GET_USER_ROLE_LIST_OK,
  GET_USER_ROLE_LIST_ERR,
  DO_USER_ROLE_LIST_CLEANUP,
} from "./actionTypes";

/********** LIST **********/

const defaultListState = {
  roles: [],
  rolesError: null,
  isLoadInProgress: false,
};

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_USER_ROLE_LIST:
      state = {
        ...state,
        rolesError: null,
        isLoadInProgress: true,
      };
      break
    case GET_USER_ROLE_LIST_OK:
      state = {
        ...state,
        roles: action.payload.response.userRoles,
        isLoadInProgress: false,
      };
      break
    case GET_USER_ROLE_LIST_ERR:
      state = {
        ...state,
        roles: [],
        rolesError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_USER_ROLE_LIST_CLEANUP:
      state = { ...defaultListState };
      break
  }
  return state;
}

export default combineReducers({
  List,
});