import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logoSimple from "assets/images/logo-simple-white.svg";
import logo from "assets/images/mavsign-logo-white.svg";

import { route, routes } from "helpers/routeHelper";

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to={route(routes.home)} className="logo logo-light">
            <span className="logo-sm">
              <img src={logoSimple} alt="" width="25" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" width="136" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(
  mapStatetoProps,
  {}
)(Sidebar)
