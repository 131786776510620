import React, { useEffect, useState } from "react";
import { Input, Label, Accordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { doOrderSupportingDocsCleanup, getOrderSupportingDocs } from "store/actions";

const SupportingForm = props => {

  const { orderId, onDocsSelectionChange } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/
  const [open, setOpen] = useState(false);
  const [checkAllSupportingDocs, setCheckAllSupportingDocs] = useState(true);
  const [selectedSupportingDocs, setSelectedSupportingDocs] = useState({});

  // get redux state from the store
  const orderDocs = useSelector(state => state.Order.SupportingDocs.docs);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    dispatch(getOrderSupportingDocs(orderId));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSupportingDocsCleanup());
    }
  }, [orderId]);

  // Initialize selected docs when orderDocs change
  useEffect(() => {
    if (orderDocs) {
      const initialSelectedDocs = {};
      orderDocs.forEach(doc => {
        initialSelectedDocs[doc.id] = true;
      });
      setSelectedSupportingDocs(initialSelectedDocs);
      onDocsSelectionChange(initialSelectedDocs);
    }
  }, [orderDocs]);


  /********** OTHER **********/

  const getDocsAsArray = () => {
    if (!orderDocs) {
      return [];
    }
    return Object.entries(orderDocs);
  }

  const toggle = (id) => {
    setOpen(prevOpen => prevOpen === id ? false : id);
  };

  // Handle checkbox change for all documents
  const handleCheckAllDocs = (event) => {
    const checked = event.target.checked;
    const newSelectedDocs = {};
    if (checked) {
      orderDocs.forEach(doc => {
        newSelectedDocs[doc.id] = true;
      });
    }
    setSelectedSupportingDocs(newSelectedDocs);
    onDocsSelectionChange(newSelectedDocs);
    setCheckAllSupportingDocs(checked);
  };


  // Handle checkbox change for individual document
  const handleCheckDocItem = (id) => {
    setSelectedSupportingDocs(prevState => {
      const newSelectedDocs = { ...prevState, [id]: !prevState[id] };
      onDocsSelectionChange(newSelectedDocs);
      // Check if all documents are selected
      const allSelected = orderDocs.every(doc => newSelectedDocs[doc.id]);
      setCheckAllSupportingDocs(allSelected); // Update checkAllSupportingDocs based on allSelected
      return newSelectedDocs;
    });
  };

  // Check if all documents are selected
  useEffect(() => {
    if (orderDocs && Object.keys(selectedSupportingDocs).length > 0) {
      const allSelected = orderDocs.every(doc => selectedSupportingDocs[doc.id]);
      setCheckAllSupportingDocs(allSelected);
    }
  }, [orderDocs, selectedSupportingDocs]);

  return <React.Fragment>
    {!!orderDocs?.length &&
      <div className="collapse-container">
        <Accordion open={open} toggle={toggle}>
          <AccordionItem className="mt-1 mb-1">
            <AccordionHeader targetId="1">
              <div className="form-check">
                <Input
                  type="checkbox"
                  name="checkAllSuppotingDocs"
                  id={"checkAllSuppotingDocs"}
                  className="form-check-input-lg"
                  checked={checkAllSupportingDocs}
                  onChange={handleCheckAllDocs}
                />
                <Label className="checkbox-label-seal mt-1" htmlFor="checkAllDocs">Supporting Documents</Label>
              </div>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              {getDocsAsArray().map(([index, doc]) =>
                <div className="accordion-body-element mx-3" key={index}>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      name={`checkSupportingDocItem-${doc.id}`}
                      id={`checkSupportingDocItem-${doc.id}`}
                      className="form-check-input-lg me-2"
                      checked={!!selectedSupportingDocs[doc.id]}
                      onChange={() => handleCheckDocItem(doc.id)}
                    />
                    <Label className="checkbox-label-seal" htmlFor={`checkSupportingDocItem-${doc.id}`}>{doc.name}</Label>
                  </div>
                  <div>
                    <div className="badge badge-lg rounded-pill doc-item-badge">
                      {doc.numOfPages}
                      <i className="mdi mdi-file-multiple-outline ms-1" />
                    </div>
                  </div>
                </div>
              )}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
    }
  </React.Fragment>
}

SupportingForm.propTypes = {
  orderId: PropTypes.number.isRequired,
  onDocsSelectionChange: PropTypes.func.isRequired,
};

export default SupportingForm;
