import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useSubscription } from 'context/subscription';
import { formatPaymentPlanDescription, formatPaymentPlanPrice } from 'helpers/jsxHelper';
import { route, routes } from 'helpers/routeHelper';
import Confirmation from 'components/Shared/Confirmation';
import useFirstRender from 'hooks/firstRender';
import { showError, showSuccess } from 'helpers/utilHelper';
import { cancelSubscription, deletePaymentSetup } from "helpers/backendHelper";
import checkedCircleIcon from 'assets/images/checked-circle.svg';

const SectionActivePlan = () => {

  // router hook that helps redirect
  const navigate = useNavigate();
  // hook that gets the subscription info from context
  const { dealerStore, paymentPlan, refreshSubscription } = useSubscription();
  // hooks that helps determine if a component render is the first render or not
  const { isNotFirstRender } = useFirstRender();

  /********** STATE **********/

  // is the confirmation dialog visible or not
  // used to show/hide the cancel warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);

  // flag signaling that a subscription context refresh is in progress
  const [isSubRefInProgress, setIsSubRefInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs when the subscription info from context changes
  // this happens twice:
  // 1. on the first render (we do not care about this case)
  // 2. after the subscription is cancelled
  useEffect(() => {
    if (isNotFirstRender) {
      navigate(route(routes.home));
    }
  }, [dealerStore]);

  /********** OTHER **********/

  /**
   * Changes the in-progress flag and triggers a context refresh
   */
  const doSubscriptionRefresh = () => {
    setIsSubRefInProgress(true);
    refreshSubscription();
  }

  /**
   * Calls backend to cancel store subscription
   */
  const doCancelSubscription = () => {
    setIsInProgress(true);
    cancelSubscription()
      .then(response => {
        showSuccess('Subscription has been canceled');
        // since we have changed the dealer store info (payment method)
        // we have to reload the subscription context
        // else the new info will not be available in the next step
        doSubscriptionRefresh();
      })
      .catch(ex => {
        showError('Unable to cancel subscription');
      })
      .finally(() => {
        setIsInProgress(false);
      });
  }

  const changeSubscription = () => {
    // delete any PaymentSetup records
    // in case the user started the wizard and abandoned it before finish
    deletePaymentSetup();
    navigate(route(routes.setup_subscription_store));
  }

  /**
   * Returns TRUE if a loading icon should be shown
   * @returns bool
   */
  const isBusy = () => isInProgress || isSubRefInProgress;

  return <React.Fragment>
    <div className="payment-plan-selection-item-selected">
      <div className='payment-plan-selection-item-box'>
        <div className='payment-plan-item-header-selected'>
          <h3 className="payment-plan-selection-item-name text-white">{paymentPlan.name}</h3>
          <img src={checkedCircleIcon} />
        </div>
        <div className="payment-plan-card-info">
          <div>
            <h3 className="mt-4 mb-2 ms-4 payment-plan-selection-starts-from">Starts from </h3>
            <h2 className="mt-0 ms-4 payment-plan-selection-item-price">{formatPaymentPlanPrice(paymentPlan)}</h2>
            <div className="w-100 p-3 mb-3">
              <Button type="button" color="primary" className="btn-lg d-block mt-5 w-100 text-center" onClick={changeSubscription}>Change your subscription</Button>
            </div>
            <div className="payment-plan-selection-item-description">
              <ul>
                {formatPaymentPlanDescription(paymentPlan.description)}
              </ul>
            </div>
            <div className="w-100 p-3 mt-5 mb-3">
              <Button type="button" color="danger" className="btn-lg d-block mt-4 w-100" onClick={() => setIsConfirmationVisible(true)} disabled={isBusy()}>
                {isBusy() && <i className="mdi mdi-spin mdi-loading me-1" />}
                Cancel your subscription
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Cancel subscription"
      onConfirm={() => {
        setIsConfirmationVisible(false);
        doCancelSubscription();
      }}
      reverseButtons={false}
      onCancel={() => setIsConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>We are sorry to see you go. Are you sure you want to cancel your subscription?</span>
    </Confirmation>}
  </React.Fragment>
}

export default SectionActivePlan;
