import React from 'react';
import PropTypes from 'prop-types';
import { formats, formatTimestamp } from 'helpers/dateHelper';

const EventTime = props => {

  const { log } = props;

  return <span className="text-muted event-time">{formatTimestamp(log.timestamp, formats.DATETIME)}</span>
}

EventTime.propTypes = {
  log: PropTypes.object,
}

export default EventTime;