import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback, CardTitle } from "reactstrap";
import { isObject, nullsToEmptyStrings, phoneHasNoOfDigits, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import regx from "constants/regx";
import { updateDealerStoreBilling } from "helpers/backendHelper";
import TextareaAutosize from "react-textarea-autosize";
import classnames from "classnames";
import { perms, useAccess } from "context/access";

const FormBillingEdit = props => {

  const { iAmGranted } = useAccess();

  const { defaultValues, id, finishedHandler } = props;

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  // billing email is an array of email addresses or null
  // however to fit into the textarea it must become a string
  let defaultBillingEmail = defaultValues.billingEmail;
  if (!!defaultBillingEmail && Array.isArray(defaultBillingEmail)) {
    defaultBillingEmail = defaultBillingEmail.join(', ');
  }

  const formInitialValues = {
    paymentPlanId: '',
    billingName: '',
    billingPhone: '',
    billingPhone2: '',
    billingAccountNo: '',
    ...nullsToEmptyStrings(defaultValues),
    billingEmail: defaultBillingEmail || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      paymentPlanId: Yup.number().required('Field is required'),
      // user may submit one or multiple comma-separated email addresses
      // so we leave the complex validation to the backend
      billingEmail: Yup.string().trim(),
      billingPhone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone1',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      billingPhone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone2',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
    }),
    onSubmit: values => saveDealerStoreBilling(values, id),
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveDealerStoreBilling = (values, id) => {
    setIsSaveInProgress(true);
    updateDealerStoreBilling(values, id)
      .then(response => {
        showSuccess(`Store "${values.name}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save store');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <CardTitle>Billing</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Billing contact</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="billingName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingName} invalid={!!formik.errors.billingName} />
              {!!formik.errors.billingName && <FormFeedback type="invalid">{formik.errors.billingName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Email</Label>
            <Col sm={8}>
              <TextareaAutosize className={classnames('form-control', { 'is-invalid': !!formik.errors.billingEmail })} placeholder="ex. john@domain.com" name="billingEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingEmail} />
              {!!formik.errors.billingEmail && <FormFeedback type="invalid">{isObject(formik.errors.billingEmail) ? Object.values(formik.errors.billingEmail)[0] : formik.errors.billingEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="billingPhone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingPhone} invalid={!!formik.errors.billingPhone} />
              {!!formik.errors.billingPhone && <FormFeedback type="invalid">{formik.errors.billingPhone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Alternate phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="billingPhone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingPhone2} invalid={!!formik.errors.billingPhone2} />
              {!!formik.errors.billingPhone2 && <FormFeedback type="invalid">{formik.errors.billingPhone2}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Account number</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 94205617" name="billingAccountNo" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingAccountNo} invalid={!!formik.errors.billingAccountNo} />
              {!!formik.errors.billingAccountNo && <FormFeedback type="invalid">{formik.errors.billingAccountNo}</FormFeedback>}
            </Col>
          </Row>
          {iAmGranted(perms.edit_coupa_information) && defaultValues.usesCoupa && <>
            <Row className="mb-4">
              <Label className="col-sm-5 col-form-label">Coupa Name (Accounting name)</Label>
              <Col sm={7}>
                <Input
                  type="text"
                  className="form-control"
                  name="coupaName"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.coupaName}
                  invalid={!!formik.errors.coupaName}
                />
                {!!formik.errors.coupaName && <FormFeedback type="invalid">{formik.errors.coupaName}</FormFeedback>}
              </Col>
            </Row>
            <Row className="mb-4">
              <Label className="col-sm-5 col-form-label">Coupa Account (Default account)</Label>
              <Col sm={7}>
                <Input
                  type="text"
                  className="form-control"
                  name="coupaAccount"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.coupaAccount}
                  invalid={!!formik.errors.coupaAccount}
                />
                {!!formik.errors.coupaAccount && <FormFeedback type="invalid">{formik.errors.coupaAccount}</FormFeedback>}
              </Col>
            </Row>
            <Row className="mb-4">
              <Label className="col-sm-5 col-form-label">Hyperion number (Store ID)</Label>
              <Col sm={7}>
                <Input
                  type="text"
                  className="form-control"
                  name="coupaStoreId"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.coupaStoreId}
                  invalid={!!formik.errors.coupaStoreId}
                />
                {!!formik.errors.coupaStoreId && <FormFeedback type="invalid">{formik.errors.coupaStoreId}</FormFeedback>}
              </Col>
            </Row></>}
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormBillingEdit.propTypes = {
  defaultValues: PropTypes.object,
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
};

export default FormBillingEdit;