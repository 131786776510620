import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { useSubscriptionSetup } from 'context/subscriptionSetup';

const usePaymentMethodCheck = () => {

  const navigate = useNavigate();

  const { dealerStore } = useSubscriptionSetup();

  useEffect(() => {
    // if the dealer store does not have a payment method
    // and the dealer store is not on a free plan
    if (!dealerStore.paymentMethodType && !dealerStore.paymentPlan.isFree) {
      // redirect back to the step that will create the stripe payment method
      navigate(route(routes.setup_subscription_payment));
      return;
    }
  }, [dealerStore]);
}

export default usePaymentMethodCheck;