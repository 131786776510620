import React, { useState, useEffect } from 'react';
import { useDocViewer } from '../Context';
import { Input } from 'reactstrap';

const DocViewerPageNav = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { orderDoc, activePageNum, setActivePageNum } = useDocViewer();

  /**
   * Local state var that holds the value entered in the text box
   * We use this to allow the user to type into the text box without actually changing the page
   * The change will be applied only when he presses ENTER
   * If he changes focus without pressing ENTER then we can revert
   */
  const [localPageNum, setLocalPageNum] = useState(activePageNum);

  /**
   * This effect runs whenever the context active page changes
   * Which happens when the user clicks on the left bar thumbnails or types into the header box
   * We use it to refresh the text box value to reflect the real data that has been propagated
   * Mainly because some values that the user may enter locally will be passed to the context as different values
   * For example local values that make invalid page numbers will be translated into min/max valid page numbers
   */
  useEffect(() => {
    setLocalPageNum(activePageNum);
  }, [activePageNum]);

  /**
   * Event handler called whenever the user types into the text box
   * We use it to track the ENTER key and apply the value
   * @param {event} e
   */
  const onKeyDownHandler = e => {
    // if pressed key is 'enter'
    if (e.key === 'Enter') {
      // check if the input value is a valid number
      // alse default to 1
      const val = isNaN(localPageNum) ? 1 : localPageNum;
      // check if the requested page is a valid page
      // else default to the max/min page if the number is too high/low
      const pageNum = Math.max(Math.min(val, orderDoc.numOfPages), 1);
      // propagate the new value
      setActivePageNum(pageNum);
    }
  }

  /**
   * Event handler called whenever the user leaves the text box without pressing ENTER
   * We use it to revert the text box value to the context value
   * @returns {void}
   */
  const revertActivePage = () => setLocalPageNum(activePageNum);

  return <div className="doc-viewer-page-nav">
    <Input type="text" className="doc-viewer-header-input doc-viewer-page-input" onChange={e => setLocalPageNum(e.target.value)} value={localPageNum} onKeyDown={onKeyDownHandler} onBlur={revertActivePage} />
    <span className="align-middle">/ {orderDoc.numOfPages}</span>
  </div>
}

export default DocViewerPageNav;