import React from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback } from "reactstrap";
import { nullsToEmptyStrings, phoneHasNoOfDigits } from "helpers/utilHelper";
import regx from "constants/regx";

const FormFinanceNew = props => {

  const { defaultValues, tabId, nextHandler, prevHandler } = props;

  /********** FORM CONFIG **********/

  const formInitialValues = {
    financeManagerName: '',
    financeManagerEmail: '',
    financeManagerPhone: '',
    financeManagerPhone2: '',
    financeAssistantName: '',
    financeAssistantEmail: '',
    financeAssistantPhone: '',
    financeAssistantPhone2: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      financeManagerEmail: Yup.string().trim().email('Invalid email address'),
      financeAssistantEmail: Yup.string().trim().email('Invalid email address'),
      financeManagerPhone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone1',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      financeManagerPhone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone2',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      financeAssistantPhone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone3',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      financeAssistantPhone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone4',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
    }),
    onSubmit: values => {
      formik.setSubmitting(false);
      nextHandler(tabId, values);
    },
  });

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <div className="text-end">
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={prevHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Back
            </Button>
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              <i className="mdi mdi-check me-1" />
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Finance manager</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="financeManagerName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeManagerName} invalid={!!formik.errors.financeManagerName} />
              {!!formik.errors.financeManagerName && <FormFeedback type="invalid">{formik.errors.financeManagerName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Email</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="financeManagerEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeManagerEmail} invalid={!!formik.errors.financeManagerEmail} />
              {!!formik.errors.financeManagerEmail && <FormFeedback type="invalid">{formik.errors.financeManagerEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="financeManagerPhone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeManagerPhone} invalid={!!formik.errors.financeManagerPhone} />
              {!!formik.errors.financeManagerPhone && <FormFeedback type="invalid">{formik.errors.financeManagerPhone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Alternate phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="financeManagerPhone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeManagerPhone2} invalid={!!formik.errors.financeManagerPhone2} />
              {!!formik.errors.financeManagerPhone2 && <FormFeedback type="invalid">{formik.errors.financeManagerPhone2}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Finance assistant</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="financeAssistantName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeAssistantName} invalid={!!formik.errors.financeAssistantName} />
              {!!formik.errors.financeAssistantName && <FormFeedback type="invalid">{formik.errors.financeAssistantName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Email</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="financeAssistantEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeAssistantEmail} invalid={!!formik.errors.financeAssistantEmail} />
              {!!formik.errors.financeAssistantEmail && <FormFeedback type="invalid">{formik.errors.financeAssistantEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="financeAssistantPhone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeAssistantPhone} invalid={!!formik.errors.financeAssistantPhone} />
              {!!formik.errors.financeAssistantPhone && <FormFeedback type="invalid">{formik.errors.financeAssistantPhone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Alternate phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="financeAssistantPhone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeAssistantPhone2} invalid={!!formik.errors.financeAssistantPhone2} />
              {!!formik.errors.financeAssistantPhone2 && <FormFeedback type="invalid">{formik.errors.financeAssistantPhone2}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormFinanceNew.propTypes = {
  defaultValues: PropTypes.object,
  tabId: PropTypes.number,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
};

export default FormFinanceNew;