import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, CardHeader, Table, Alert } from "reactstrap";
import Col from "components/Shared/Col";
import { route, routes } from 'helpers/routeHelper';
import { formats, formatTimestamp } from 'helpers/dateHelper';
import SpinnerChase from 'components/Shared/SpinnerChase';
import classnames from "classnames";
import OrderDoc from 'model/orderDoc';
import shield from 'assets/images/shield-blue.svg';
import { getOrderESignStatus } from "helpers/backendHelper";

const SectionESignedDocs = props => {

  const { id, isLocked } = props;

  /********** STATE **********/

  const [eSignStatus, setESignStatus] = useState(null);
  const [eSignStatusError, setESignStatusError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    refreshOrderESignStatus();
  }, [id]);

  /********** OTHER **********/

  const refreshOrderESignStatus = () => {
    setIsLoadInProgress(true);
    getOrderESignStatus(id)
      .then(response => {
        setESignStatus(response.eSign);
      })
      .catch(ex => {
        setESignStatusError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">E-Signed Documents</div>
          </Col>
          {isLocked && <Col xs="auto" className="text-end">
            <img src={shield} className="mt-1" />
          </Col>}
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        {eSignStatus && <Table className="section-rows table bt-1">
          <tbody>
            <tr>
              <td className="section-row-label">Status</td>
              <td className={`section-row-value text-uppercase text-${OrderDoc.getStatusColor(eSignStatus.status)}`}>{eSignStatus.statusName || '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Date</td>
              <td className="section-row-value">{eSignStatus.date ? formatTimestamp(eSignStatus.date, formats.DATETIME) : '--'}</td>
            </tr>
          </tbody>
        </Table>}
        {eSignStatusError && <div>
          <Alert color="danger" className="fade show text-center">
            <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load data
          </Alert>
        </div>}
        {isLoadInProgress && <SpinnerChase className="sm mt-2 mb-2" />}
        <div className="text-end mt-4">
          <Link to={route(routes.view_order_e_sign, id)} className={classnames('btn btn-primary', { disabled: !eSignStatus || eSignStatus.status === null })}>View More Details <i className="mdi mdi-arrow-right ms-1"></i></Link>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionESignedDocs.propTypes = {
  id: PropTypes.number,
  isLocked: PropTypes.bool,
};

export default SectionESignedDocs;
