import { LocalException } from './errorHelper';
import { isObject, valueIsEmpty } from './utilHelper';

export const routes = {
  home: '/',

  list_leaderboard: '/dashboard/leaderboard',

  list_users: '/users',
  view_user: '/user/:id',
  new_user: '/user',

  view_dealer_group: '/group/:id',

  list_dealer_regions: '/regions',
  new_dealer_region_manager: '/region/:id/manager',
  view_dealer_region: '/region/:id',
  new_dealer_region: '/region',

  list_dealer_stores: '/stores',
  new_dealer_store_manager: '/store/:id/manager',
  new_dealer_store_signer: '/store/:id/signer',
  view_dealer_store: '/store/:id',
  new_dealer_store: '/store',

  view_dealer_store_signer: '/store-signer/:id',

  list_orders: '/orders',
  view_order: '/order/:id',
  view_order_vid: '/order/:id/vid',
  view_order_ink_sign: '/order/:id/ink-sign',
  view_order_e_sign: '/order/:id/e-sign',
  view_order_finance: '/order/:id/finance',
  view_order_supporting: '/order/:id/supporting',
  view_order_activity: '/order/:id/activity',
  view_order_messages: '/order/:id/messages/:channel',
  view_order_channel: '/order/:id/channels/:channel/',
  new_order: '/order',
  new_order_instructions: '/order/instructions',
  new_order_customer: '/order/:id/customer',
  new_order_vehicle: '/order/:id/vehicle',
  new_order_contract: '/order/:id/contract',
  new_order_documents: '/order/:id/docs',
  new_order_confirm: '/order/:id/confirm',

  view_seal: '/order/:id/seal',

  list_dealers_statements: '/dealer-statements',
  view_dealer_statement: '/dealer-statement/:id',

  list_notifications: '/notifications',
  view_notification: '/notification/:id',

  list_customer_calls: '/customer-calls',
  new_customer_call: '/customer-call',

  setup_subscription_store: '/subscription/setup/store',
  setup_subscription_plan: '/subscription/setup/plan',
  setup_subscription_payment: '/subscription/setup/payment',
  setup_subscription_payment_conf: '/subscription/setup/payment/confirm',
  setup_subscription_overview: '/subscription/setup/overview',
  setup_subscription_forbidden: '/subscription/setup/forbidden',

  view_subscription: '/subscription',
  view_red_flag: '/order/red-flags',

  list_payments: '/payments',

  view_group_usage_report: '/group-usage-report',
  view_dealer_sales_report: '/dealer-sales-report',

  list_help: '/help',
};

export const route = (route, params, query) => {
  if (!Object.values(routes).includes(route)) {
    throw new LocalException(`Unknown route '${route}'`);
  }
  if (!valueIsEmpty(params)) {
    if (Array.isArray(params)) {
      params.forEach(param => {
        // handle optional params
        if (valueIsEmpty(param)) {
          route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
        } else {
          route = route.replace(new RegExp(':([a-zA-Z]+)'), param);
        }
      });
    } else if (isObject(params)) {
      for (let key in params) {
        // handle optional params
        if (valueIsEmpty(params[key])) {
          route = route.replace(new RegExp(`/:${key}`), '');
        } else {
          route = route.replace(new RegExp(`:${key}`), params[key]);
        }
      }
    } else {
      // handle optional params
      if (valueIsEmpty(params)) {
        route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
      } else {
        route = route.replace(new RegExp(':([a-zA-Z]+)'), params);
      }
    }
  }
  if (query) {
    if (isObject(query)) {
      let parts = [];
      for (let key in query) {
        if (Array.isArray(query[key])) {
          query[key].forEach(value => {
            parts.push(`${key}[]=${value}`);
          });
        } else if (isObject(query[key])) {
          for (let key2 in query[key]) {
            parts.push(`${key}[${key2}]=${query[key][key2]}`);
          }
        } else {
          parts.push(`${key}=${query[key]}`);
        }
      }
      route += `?${parts.join('&')}`;
    } else {
      route += `?${query}`;
    }
  }
  return route;
};