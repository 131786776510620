import PropTypes from 'prop-types'
import React, { useEffect } from "react"

import { Routes, Route, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

// Import Routes all
import { protectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "routes/route"

// layouts Format
import VerticalLayout from "components/VerticalLayout/"
import NonAuthLayout from "components/NonAuthLayout"
import NotFound from "pages/Error/NotFound"

// Import scss
import "./assets/scss/theme.scss"
import ActivityStatusMonitor from 'components/Shared/ActivityStatusMonitor';
import { initializeTagManager } from 'hooks/gtm';
import HelpSidebar from 'components/VerticalLayout/HelpSidebar';

const App = () => {

  useEffect(() => {
    initializeTagManager();
  }, [])

  return (
    <ActivityStatusMonitor>
      <ReactNotifications />
      <HelpSidebar />
      <Router>
        <Routes>
          {publicRoutes.map((route, idx) => <Route key={idx} path={route.path} element={<Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            componentProps={route.props}
            key={idx}
            isProtected={false}
            isStrict={route.isStrict}
          />} />)}
          {protectedRoutes.map((route, idx) => <Route key={idx} path={route.path} element={<Authmiddleware
            path={route.path}
            layout={route.layout || VerticalLayout}
            component={route.component}
            componentProps={route.props}
            key={idx}
            isProtected={true}
            isSetupRoute={route.isSetupRoute}
          />} />)}
          <Route element={<NotFound title="Page not found" />} />
        </Routes>
      </Router>
    </ActivityStatusMonitor>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
