import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { useSubscriptionSetup } from 'context/subscriptionSetup';

const usePaymentSkipCheck = () => {

  const navigate = useNavigate();

  const { paymentPlan } = useSubscriptionSetup();

  useEffect(() => {
    // if the payment plan is free
    if (paymentPlan.isFree) {
      // redirect to the next step
      navigate(route(routes.setup_subscription_overview));
      return;
    }
  }, []);
}

export default usePaymentSkipCheck;