import { perms, useAccess } from 'context/access';
import { route, routes } from 'helpers/routeHelper';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import { Col, Card, CardBody, Row, CardHeader, CardTitle, Alert, Button } from "reactstrap";
import SpinnerChase from 'components/Shared/SpinnerChase';
import config from 'config';
import { formatPhone } from 'helpers/utilHelper';
import { getDealerStoreSigners } from "helpers/backendHelper";

const SectionSigners = props => {

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const [signers, setSigners] = useState([]);
  const [signersError, setSignersError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get signers list
    getDealerStoreSignerList(props.id);
  }, [props.id]);

  /********** EVENT HANDLERS **********/

  const toggleExpanded = () => setIsExpanded(expanded => !expanded);

  /********** OTHER **********/

  const getTableRows = () => isExpanded ? signers : signers.slice(0, config.COLLAPSED_TBL_ROWS);

  const canExpand = () => !isExpanded && signers.length > config.COLLAPSED_TBL_ROWS;

  const canCollapse = () => isExpanded;

  const getDealerStoreSignerList = (id) => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the signers data
    getDealerStoreSigners(id)
      .then(response => {
        setSigners(response.signers);
      })
      .catch(ex => {
        setSignersError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Signers</CardTitle>
          </Col>
          <Col md="auto">
            <div className="text-end">
              {iAmGranted(perms.create_dealer_store_signers) && <Link to={route(routes.new_dealer_store_signer, props.id)} className="btn btn-outline-primary">
                <i className="mdi mdi-plus me-1" />Add signer
              </Link>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            {getTableRows().length > 0 && getTableRows().map((entry, index) => (
              <tr key={index}>
                {iAmGranted(perms.view_dealer_store_signers) ? <td>
                  <Link to={route(routes.view_dealer_store_signer, entry.id)}>{entry.fullName}</Link></td> :
                  <td>{entry.fullName}</td>
                }
                <td>{entry.email}</td>
                <td>{formatPhone(entry.phone)}</td>
              </tr>
            ))}
            {getTableRows().length == 0 && !signersError && !isLoadInProgress && <tr>
              <td className="table-empty" colSpan="3">No signers found</td>
            </tr>}
            {!!signersError && <tr>
              <td className="table-empty" colSpan="3">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load signers
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="3">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
        <div className="text-center">
          {canExpand() && <Button type="button" color="link" size="sm" onClick={toggleExpanded}><i className="mdi mdi-chevron-down me-1"></i>View more</Button>}
          {canCollapse() && <Button type="button" color="link" size="sm" onClick={toggleExpanded}><i className="mdi mdi-chevron-up me-1"></i>View less</Button>}
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionSigners.propTypes = {
  id: PropTypes.number.isRequired,
}

export default SectionSigners;