import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Table, Button } from "reactstrap";
import Col from "components/Shared/Col";
import { startSchedulerMeeting, resetOrderActionFlag } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { openInNewWindow, showError } from 'helpers/utilHelper';

const SectionSchedulerInfo = props => {

  const { order } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // get redux state from the store
  const { schedulerMeetingStarted, schedulerMeetingLink } = useSelector(state => state.Order.Single);

  // runs whenever the 'schedulerMeetingStarted' flag changes
  // which happens after a start meeting attempt
  useEffect(() => {
    if (schedulerMeetingStarted === true) {
      openInNewWindow(schedulerMeetingLink);
    } else if (schedulerMeetingStarted === false) {
      showError('Unable to start meeting');
    }
    if (schedulerMeetingStarted !== null) {
      dispatch(resetOrderActionFlag('meetingStarted'));
    }
  }, [schedulerMeetingStarted]);

  const startMeeting = () => dispatch(startSchedulerMeeting(order.id));

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Scheduler</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        <Table className="section-rows table bt-1 text-break">
          <tbody>
            <tr>
              <td className="section-row-label">Name</td>
              <td className="section-row-value">{order.schedulerFullName || '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Phone Number</td>
              <td className="section-row-value">{order.schedulerPhone || '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Email</td>
              <td className="section-row-value">{order.schedulerEmail || '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Video Call</td>
             {!!order.schedulerAssignedTs ? <td className="section-row-value">
                <Button color="primary" className="call-btn" onClick={startMeeting}>
                  Start Video Call <i className="bx bx-video" />
                </Button>
              </td> : <td className="section-row-value">{'--'}</td>}
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionSchedulerInfo.propTypes = {
  id: PropTypes.number,
  order: PropTypes.object,
};

export default SectionSchedulerInfo;