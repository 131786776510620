import React, { useEffect } from "react";
import { Col, Card, CardBody, Alert } from "reactstrap";
import { formatDate, formats } from "helpers/dateHelper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { doOrderStatsCleanup, getOrderStats } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import { route, routes } from "helpers/routeHelper";
import { flattenFilters } from "helpers/utilHelper";
import Order from "model/order";
import moment from "moment";

const Stats = () => {
  const dispatch = useDispatch();

  /********** STATE **********/

  const { workInProgress, remote, instore, remoteVid, sealed, statsError, isLoadInProgress } = useSelector(state => state.Dashboard.Stats);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get order stats
    dispatch(getOrderStats());
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderStatsCleanup());
    }
  }, []);

  return (!isLoadInProgress && !!statsError) ? <Alert color="danger" className="fade show text-center">
    <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load stats
  </Alert> : <>
    <h5 className="mb-3 font-weight-600">{currentMonth} Activity Summary</h5>
    <Col xs={12} md={6} xl={2} className="mb-4">
      <Card className="h-100 mb-0 stats-card">
        <CardBody className="d-flex">
          {isLoadInProgress && <SpinnerChase className="sm" />}
          {!isLoadInProgress &&
            <div className="d-flex w-100">
              <div className="icon-circle avatar-sm align-self-center">
                <span className="stats-icon primary-icon-color">
                  <i className="fas fa-users font-size-24" />
                </span>
              </div>
              <div className="d-flex flex-column w-100">
                <p className="font-size-14 font-weight-500 mb-2">Total remote customer</p>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">{remote?.toLocaleString() ?? '--'}</h4>
                  <Link to={getViewAllLink("remote")}>View all</Link>
                </div>
              </div>
            </div>}
        </CardBody>
      </Card>
    </Col>
    <Col xs={12} md={6} xl={2} className="mb-4">
      <Card className="h-100 mb-0 stats-card">
        <CardBody className="d-flex">
          {isLoadInProgress && <SpinnerChase className="sm" />}
          {!isLoadInProgress &&
            <div className="d-flex w-100">
              <div className="icon-circle avatar-sm align-self-center">
                <span className="stats-icon primary-icon-color">
                  <i className="mdi mdi-clipboard-text font-size-24" />
                </span>
              </div>
              <div className="d-flex flex-column w-100">
                <p className="font-size-14 font-weight-500 mb-2">Total In-store Customers</p>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">{instore?.toLocaleString() ?? '--'}</h4>
                  <Link to={getViewAllLink("instore")}>View all</Link>
                </div>
              </div>
            </div>}
        </CardBody>
      </Card>
    </Col>
    <Col xs={12} md={6} xl={2} className="mb-4">
      <Card className="h-100 mb-0 stats-card">
        <CardBody className="d-flex">
          {isLoadInProgress && <SpinnerChase className="sm" />}
          {!isLoadInProgress &&
            <div className="d-flex w-100">
              <div className="icon-circle avatar-sm align-self-center">
                <span className="stats-icon primary-icon-color">
                  <i className="mdi mdi-seal font-size-24" />
                </span>
              </div>
              <div className="d-flex flex-column w-100">
                <p className="font-size-14 font-weight-500 mb-2">Total Sealed Signings</p>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">{sealed?.toLocaleString() ?? '--'}</h4>
                  <Link to={getViewAllLink("sealed")}>View all</Link>
                </div>
              </div>
            </div>}
            <div>
            </div>
        </CardBody>
      </Card>
    </Col>
    <Col xs={12} md={6} xl={3} className="mb-4">
      <Card className="h-100 mb-0">
        <CardBody className="d-flex">
          {isLoadInProgress && <SpinnerChase className="sm" />}
          {!isLoadInProgress &&
            <div className="d-flex w-100">
              <div className="icon-circle avatar-sm align-self-center">
                <span className="stats-icon primary-icon-color">
                  <i className="mdi mdi-file-clock font-size-24" />
                </span>
              </div>
              <div className="d-flex flex-column w-100">
                <p className="font-size-14 font-weight-500 mb-2">Work In Progress</p>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">{workInProgress?.toLocaleString() ?? '--'}</h4>
                  <Link to={getViewAllLink("workInProgress")}>View all</Link>
                </div>
              </div>
            </div>}
        </CardBody>
      </Card>
    </Col>
    <Col xs={12} md={6} xl={3} className="mb-4">
      <Card className="h-100 mb-0 stats-card">
        <CardBody className="d-flex">
          {isLoadInProgress && <SpinnerChase className="sm" />}
          {!isLoadInProgress &&
            <div className="d-flex w-100">
              <div className="icon-circle avatar-sm align-self-center">
                <span className="stats-icon primary-icon-color">
                  <i className="mdi mdi-card-account-details font-size-24" />
                </span>
              </div>
              <div className="d-flex flex-column w-100">
                <p className="font-size-14 font-weight-500 mb-2">Remote VID</p>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">{remoteVid?.toLocaleString() ?? '--'}</h4>
                  <Link to={getViewAllLink('remoteVid')}>View all</Link>
                </div>
              </div>
            </div>}
        </CardBody>
      </Card>
    </Col>
  </>
}

const currentMonth = formatDate(new Date(), formats.MONTH);

// generate a redirect link to orders screen with predefined filters set
const getViewAllLink = stat => {
  const filters = {
    createdBetween: moment().startOf("month").unix() + "-" + moment().endOf("month").unix(),
    ...(stat === "workInProgress") && { excStatus: [Order.STATUS_CANCELLED, Order.STATUS_DRAFT, Order.STATUS_COMPLETED].join(',') },
    ...(stat === "remoteVid") && { isVidRequired: 1 },
    ...(stat === "remote") && { customerLocation: Order.CUSTOMER_LOCATION_REMOTE },
    ...(stat === "instore") && { customerLocation: Order.CUSTOMER_LOCATION_IN_STORE },
    ...(stat === "sealed" && { sealStatus: Order.SEAL_STATUS_SEALED }),
  };

  const search = new URLSearchParams(flattenFilters({ filters })).toString();

  return { pathname: route(routes.list_orders), search };
}

export default Stats;