/********** STATS **********/

export const GET_ORDERS_STATS = "GET_ORDERS_STATS"

export const GET_ORDERS_STATS_OK = "GET_ORDERS_STATS_OK"

export const GET_ORDER_STATS_ERR = "GET_ORDER_STATS_ERR"

export const DO_ORDER_STATS_CLEANUP = "DO_ORDER_STATS_CLEANUP"

/********** LEADERBOARD **********/

export const GET_USER_LEADERBOARD = "GET_USER_LEADERBOARD"

export const GET_STORE_LEADERBOARD = "GET_STORE_LEADERBOARD"

export const GET_USER_LEADERBOARD_OK = "GET_USER_LEADERBOARD_OK"

export const GET_STORE_LEADERBOARD_OK = "GET_STORE_LEADERBOARD_OK"

export const GET_LEADERBOARD_ERR = "GET_LEADERBOARD_ERR"

export const DO_LEADERBOARD_CLEANUP = "DO_LEADERBOARD_CLEANUP"

/********** LEADERBOARD LIST **********/

export const GET_USER_LEADERBOARD_LIST = "GET_USER_LEADERBOARD_LIST"

export const GET_STORE_LEADERBOARD_LIST = "GET_STORE_LEADERBOARD_LIST"

export const GET_USER_LEADERBOARD_LIST_OK = "GET_USER_LEADERBOARD_LIST_OK"

export const GET_STORE_LEADERBOARD_LIST_OK = "GET_STORE_LEADERBOARD_LIST_OK"

export const GET_LEADERBOARD_LIST_ERR = "GET_LEADERBOARD_LIST_ERR"

export const DO_LEADERBOARD_LIST_CLEANUP = "DO_LEADERBOARD_LIST_CLEANUP"

/********** CUSTOMER PERFORMANCE **********/

export const GET_CUSTOMER_PERFORMANCE = "GET_CUSTOMER_PERFORMANCE"

export const GET_CUSTOMER_PERFORMANCE_OK = "GET_CUSTOMER_PERFORMANCE_OK"

export const GET_CUSTOMER_PERFORMANCE_ERR = "GET_CUSTOMER_PERFORMANCE_ERR"

export const DO_CUSTOMER_PERFORMANCE_CLEANUP = "DO_CUSTOMER_PERFORMANCE_CLEANUP"

/********** TIME TO COMPLETION **********/

export const GET_TIME_TO_COMPLETION = "GET_TIME_TO_COMPLETION"

export const GET_TIME_TO_COMPLETION_OK = "GET_TIME_TO_COMPLETION_OK"

export const GET_TIME_TO_COMPLETION_ERR = "GET_TIME_TO_COMPLETION_ERR"

export const DO_TIME_TO_COMPLETION_CLEANUP = "DO_TIME_TO_COMPLETION_CLEANUP"