export default class OrderDoc {

  static TYPE_E_SIGNED = 1;
  static TYPE_INK_SIGNED = 2;
  static TYPE_PREVIEW = 3;
  static TYPE_SUPPORTING = 4;

  static STATUS_PENDING_INITIAL_PROCESSING = 0;
  static STATUS_PENDING_SIGNATURES_PLACEMENT = 1;
  static STATUS_PENDING_CUSTOMER_SIGNATURE = 2;
  static STATUS_PENDING_DEALER_REVIEW = 3;
  static STATUS_REJECTED = 4;
  static STATUS_PENDING_DEALER_SIGNATURE = 5;
  static STATUS_PENDING_FINAL_PROCESSING = 6;
  static STATUS_COMPLETE = 7;
  static STATUS_INITIAL_PROCESSING_ERROR = -1;
  static STATUS_FINAL_PROCESSING_ERROR = -2;

  static PAGE_STATUS_NOT_SUBMITTED = -1;
  static PAGE_STATUS_PENDING_REVIEW = 0;
  static PAGE_STATUS_REJECTED = 1;
  static PAGE_STATUS_ACCEPTED = 2;

  static FIELD_TYPE_SIGNATURE = 1;
  static FIELD_TYPE_INITIALS = 2;
  static FIELD_TYPE_NAME = 3;

  static getStatusColor(status) {
    switch (status) {
      case this.STATUS_REJECTED:
      case this.STATUS_INITIAL_PROCESSING_ERROR:
      case this.STATUS_FINAL_PROCESSING_ERROR:
        return 'danger';
      case this.STATUS_PENDING_SIGNATURES_PLACEMENT:
      case this.STATUS_PENDING_DEALER_REVIEW:
      case this.STATUS_PENDING_DEALER_SIGNATURE:
        return 'warning';
      case this.STATUS_PENDING_CUSTOMER_SIGNATURE:
        return 'primary';
      case this.STATUS_PENDING_INITIAL_PROCESSING:
      case this.STATUS_PENDING_FINAL_PROCESSING:
        return 'processing';
      case this.STATUS_COMPLETE:
        return 'success';
      default:
        return 'dark';
    }
  }

  static getTypes() {
    return {
      [this.TYPE_E_SIGNED]: 'E-Signed document',
      [this.TYPE_INK_SIGNED]: 'Ink-Signed document',
      [this.TYPE_PREVIEW]: 'Preview document',
      [this.TYPE_SUPPORTING]: 'Supporting document',
    };
  }

  static getFields() {
    return {
      [this.FIELD_TYPE_SIGNATURE]: {
        text: 'Signature',
        widthPx: 250,
        heightPx: 70,
      },
      [this.FIELD_TYPE_INITIALS]: {
        text: 'Initials',
        widthPx: 200,
        heightPx: 35,
      },
      [this.FIELD_TYPE_NAME]: {
        text: 'Full name',
        widthPx: 250,
        heightPx: 70,
      },
    };
  }

  static getStatusMap() {
    return {
      [this.STATUS_PENDING_INITIAL_PROCESSING]: 'Pending initial processing',
      [this.STATUS_PENDING_SIGNATURES_PLACEMENT]: 'Pending signatures placement',
      [this.STATUS_PENDING_CUSTOMER_SIGNATURE]: 'Pending customer signature',
      [this.STATUS_PENDING_DEALER_REVIEW]: 'Pending dealer review',
      [this.STATUS_REJECTED]: 'Rejected',
      [this.STATUS_PENDING_DEALER_SIGNATURE]: 'Pending dealer signature',
      [this.STATUS_PENDING_FINAL_PROCESSING]: 'Pending final processing',
      [this.STATUS_COMPLETE]: 'Completed',
    };
  }

  static getStatusName(id) {
    return this.getStatusMap()[id];
  }
}