import React from "react"
import PropTypes from "prop-types"
import { Navigate } from "react-router-dom"
import { route, routes } from "helpers/routeHelper"
import { useAuth } from "context/auth"
import ExternalRedirect from "components/Shared/ExternalRedirect"
import { getSsoAppUrl } from "helpers/utilHelper"
import { useAccess } from "context/access"
import { useSubscription } from "context/subscription"

const Authmiddleware = ({
  component: Component,
  componentProps,
  layout: Layout,
  isProtected,
  isSetupRoute,
}) => {

  const { isLoggedIn } = useAuth();
  const { iAmDealerType, getUserAppUrl } = useAccess();
  const { isPaymentSetupRequired } = useSubscription();

  if (isProtected && !isLoggedIn()) {
    // this route is protected and the user is not logged in
    // so redirect to login
    return <ExternalRedirect to={getSsoAppUrl(`login?ret=${encodeURIComponent(window.location.href)}`)} />
  } else if (isProtected && !iAmDealerType()) {
    // this user type is not allowed access in this app
    // so redirect to his own app
    return <ExternalRedirect to={getUserAppUrl()} />
  } else if (!isProtected && isLoggedIn()) {
    // this route is public and the user is logged in
    // so redirect to homepage
    return <Navigate to={route(routes.home)} />
  } else if (isPaymentSetupRequired() && !isSetupRoute) {
    // this user must complete payment setup immediately
    // and this route is not part of the payment setup flow
    // so redirect to payment setup flow
    return <Navigate to={route(routes.setup_subscription_store)} />
  }
  return <Layout>
    <Component {...componentProps} />
  </Layout>
}

Authmiddleware.propTypes = {
  isProtected: PropTypes.bool,
  isSetupRoute: PropTypes.bool,
  component: PropTypes.any,
  componentProps: PropTypes.object,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
