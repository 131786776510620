import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { addPageToOrderDocNormListItem } from 'store/actions';
import { showError } from 'helpers/utilHelper';
import plusIcon from "assets/images/plus-large-outline.svg";

const InkSignAddPageBtn = props => {

  const { docId } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const doc = useSelector(state => state.OrderDoc.NormList.orderDocs[docId]);

  /********** EFFECTS **********/

  // runs whenever the page is added
  useEffect(() => {
    if (doc.isPageAdded === false) {
      showError('Unable to add page');
    }
  }, [doc.isPageAdded]);

  /********** EVENT HANDLERS **********/

  const addPage = () => dispatch(addPageToOrderDocNormListItem(docId));

  return <React.Fragment>
    <div className="ink-sign-page ink-sign-add-page" onClick={addPage}>
      <div className="ink-sign-page-title">&nbsp;</div>
      <div className="ink-sign-page-thumb">
        {doc.isPageAddInProgress && <i className="mdi mdi-spin mdi-loading" />}
        {!doc.isPageAddInProgress && <img src={plusIcon} alt="" width="90" />}
      </div>
      <Button type="button" color="primary" className="w-100 mt-2">
        Add Page
        <i className="bx bx-plus-circle ms-2 align-middle"></i>
      </Button>
    </div>
  </React.Fragment>
}

InkSignAddPageBtn.propTypes = {
  docId: PropTypes.number,
};

export default InkSignAddPageBtn;