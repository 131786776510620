import { useEffect } from 'react';

const useBeforeUnload = shouldAdd => {
  useEffect(() => {
    if (shouldAdd) {
      window.addEventListener('beforeunload', onBeforeUnload, { capture: true });
    } else {
      window.removeEventListener('beforeunload', onBeforeUnload, { capture: true });
    }
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload, { capture: true });
    }
  }, [shouldAdd]);
}

const onBeforeUnload = e => {
  e.preventDefault();
  e.returnValue = true;
}

export default useBeforeUnload;