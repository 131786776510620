import { takeEvery, put, call } from "redux-saga/effects";

import { getDealerSalesReport, getGroupUsageReport } from "helpers/backendHelper";

import { GET_DEALER_SALES_REPORT, GET_GROUP_USAGE_REPORT } from "./actionTypes";

import {
  getGroupUsageReportErr,
  getGroupUsageReportOk,

  getDealerSalesReportErr,
  getDealerSalesReportOk,
} from "./actions";

function* onGetGroupUsageReport({ payload: { params } }) {
  try {
    const response = yield call(getGroupUsageReport, params);
    yield put(getGroupUsageReportOk(response));
  } catch (error) {
    yield put(getGroupUsageReportErr(error));
  }
};

function* onGetDealerSalesReport({ payload: { params } }) {
  try {
    const response = yield call(getDealerSalesReport, params);
    yield put(getDealerSalesReportOk(response));
  } catch (error) {
    yield put(getDealerSalesReportErr(error));
  }
};

function* reportSaga() {
  yield takeEvery(GET_GROUP_USAGE_REPORT, onGetGroupUsageReport);
  yield takeEvery(GET_DEALER_SALES_REPORT, onGetDealerSalesReport);
};

export default reportSaga;