import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Preloader from "components/Shared/Preloader";
import { getSubscription } from "store/actions";
import { perms, useAccess } from './access';

const SubscriptionContext = React.createContext();

const SubscriptionProvider = props => {

  const dispatch = useDispatch();
  const { iAmNotGranted } = useAccess();

  const { dealerStore } = useSelector(state => state.Subscription.DealerStore);

  // some users do not belong to a dealer store (ex. dealer upper management)
  // therefore 'dealerStore' may be NULL
  const paymentPlan = dealerStore?.paymentPlan;

  // returns TRUE if the dealer store is on a free plan
  const isFreePlan = () => !!paymentPlan && paymentPlan.isFree;

  // returns TRUE if the dealer store is on a limited-time plan
  const isLimitedTimePlan = () => !!paymentPlan && !!paymentPlan.maxDays;

  // returns TRUE if the dealer store is on a limited-time plan and the grace period has expired
  const isPlanExpired = () => isLimitedTimePlan() && paymentPlan.isExpired;

  // returns TRUE if the dealer store is NOT on a limited-time plan or the grace period has NOT expired yet
  const isPlanActive = () => !isLimitedTimePlan() || !isPlanExpired();

  // returns the number of days left in a limited-time plan
  const planDaysLeft = () => isLimitedTimePlan() ? paymentPlan.daysLeft : null;

  // returns TRUE if the user is required to pay for the services and the payment is not yet set up
  // or the user has a limited payment plan which has expired
  // some users (ex. dealer upper managers) are not required to pay
  const isPaymentSetupRequired = () => iAmNotGranted(perms.free_of_charge_access) && (!dealerStore.isPaymentSetupComplete || isPlanExpired());

  useEffect(() => {
    // fetch subscription details from backend
    refreshSubscription();
  }, []);

  const refreshSubscription = () => dispatch(getSubscription());

  // wait until we fetch subscription data
  if (dealerStore !== undefined) {
    return <SubscriptionContext.Provider value={{
      dealerStore, paymentPlan, isPaymentSetupRequired, refreshSubscription,
      isFreePlan, isLimitedTimePlan, isPlanExpired, isPlanActive, planDaysLeft,
    }} {...props} />
  }
  // until then show a preloader
  return <Preloader />
}

// helper hook that makes context data available
export const useSubscription = () => React.useContext(SubscriptionContext);

export default SubscriptionProvider;