import { combineReducers } from 'redux';
import {
  GET_ORDER_DOC_LIST,
  GET_UPLOADED_ORDER_DOC_LIST,
  GET_SHIPPED_ORDER_DOC_LIST,
  GET_ORDER_DOC_LIST_OK,
  GET_ORDER_DOC_LIST_ERR,
  DO_ORDER_DOC_LIST_CLEANUP,

  GET_ORDER_DOC_NORM_LIST,
  GET_UPLOADED_ORDER_DOC_NORM_LIST,
  GET_INK_SIGN_ORDER_DOC_NORM_LIST,
  GET_E_SIGN_ORDER_DOC_NORM_LIST,
  GET_FINANCE_ORDER_DOC_NORM_LIST,
  GET_SUPPORTING_ORDER_DOC_NORM_LIST,
  GET_ORDER_DOC_NORM_LIST_OK,
  GET_ORDER_DOC_NORM_LIST_ERR,
  GET_ORDER_DOC_NORM_LIST_ITEM,
  GET_ORDER_DOC_NORM_LIST_ITEM_OK,
  GET_ORDER_DOC_NORM_LIST_ITEM_ERR,
  DELETE_ORDER_DOC_NORM_LIST_ITEM,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_OK,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR,
  ACCEPT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  ACCEPT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_OK,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_ERR,
  REJECT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  REJECT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_OK,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_ERR,
  ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM,
  ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_OK,
  ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_ERR,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK,
  ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR,
  DO_ORDER_DOC_NORM_LIST_CLEANUP,

  GET_DOC_VIEWER_ORDER_DOC,
  GET_DOC_VIEWER_ORDER_DOC_OK,
  GET_ORDER_DOC_ERR,
  DO_ORDER_DOC_SINGLE_CLEANUP,

  UPLOAD_ORDER_DOC,
  UPLOAD_ORDER_DOC_OK,
  UPLOAD_ORDER_DOC_ERR,
  ADD_ORDER_DOC,
  ADD_ORDER_DOC_OK,
  ADD_ORDER_DOC_ERR,
  DO_ORDER_DOC_FORM_CLEANUP,

  UPDATE_ORDER_DOC_FIELDS,
  SAVE_ORDER_DOC_FIELDS_OK,
  SAVE_ORDER_DOC_FIELDS_ERR,
  DO_ORDER_DOC_FIELDS_CLEANUP,
  ACCEPT_ALL_INK_SIGN_DOCS,
  ACCEPT_ALL_INK_SIGN_DOCS_OK,
  ACCEPT_ALL_INK_SIGN_DOCS_ERR,
  DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP,
} from './actionTypes';

/********* LIST *********/

const defaultListState = {
  orderDocs: [],
  orderDocsError: null,
  isLoadInProgress: false,
};

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_ORDER_DOC_LIST:
    case GET_UPLOADED_ORDER_DOC_LIST:
    case GET_SHIPPED_ORDER_DOC_LIST:
      state = {
        ...state,
        orderDocsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_DOC_LIST_OK:
      state = {
        ...state,
        orderDocs: action.payload.response.orderDocs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_DOC_LIST_ERR:
      state = {
        ...state,
        orderDocs: [],
        orderDocsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_ORDER_DOC_LIST_CLEANUP:
      state = { ...defaultListState };
      break
  }
  return state;
}

/********** NORMALIZED LIST **********/

const defaultNormListState = {
  orderDocIds: [],
  orderDocs: [],
  orderDocsError: null,
  isLoadInProgress: false,
};

const NormList = (state = defaultNormListState, action) => {
  switch (action.type) {
    // get list
    case GET_ORDER_DOC_NORM_LIST:
    case GET_UPLOADED_ORDER_DOC_NORM_LIST:
    case GET_INK_SIGN_ORDER_DOC_NORM_LIST:
    case GET_E_SIGN_ORDER_DOC_NORM_LIST:
    case GET_FINANCE_ORDER_DOC_NORM_LIST:
    case GET_SUPPORTING_ORDER_DOC_NORM_LIST:
      state = {
        ...state,
        orderDocsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_DOC_NORM_LIST_OK:
      const ids = [];
      const docs = action.payload.response.orderDocs.reduce((prev, curr) => {
        ids.push(curr.id);
        return { ...prev, [curr.id]: curr };
      }, {});
      state = {
        ...state,
        orderDocIds: ids,
        orderDocs: docs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_DOC_NORM_LIST_ERR:
      state = {
        ...state,
        orderDocs: [],
        orderDocsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // get doc
    case GET_ORDER_DOC_NORM_LIST_ITEM: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isFetchInProgress = true;
      doc.fetchError = null;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case GET_ORDER_DOC_NORM_LIST_ITEM_OK: {
      const docId = action.payload.id;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: action.payload.response.orderDoc,
        },
      };
      break;
    }
    case GET_ORDER_DOC_NORM_LIST_ITEM_ERR: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isFetchInProgress = false;
      doc.fetchError = action.payload.error;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // delete doc
    case DELETE_ORDER_DOC_NORM_LIST_ITEM: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isDeleted = null;
      doc.isDeleteInProgress = true;
      doc.deleteError = null;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case DELETE_ORDER_DOC_NORM_LIST_ITEM_OK: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isDeleted = true;
      doc.isDeleteInProgress = false;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isDeleted = false;
      doc.isDeleteInProgress = false;
      doc.deleteError = action.payload.error;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // reprocess doc
    case REPROCESS_ORDER_DOC_NORM_LIST_ITEM: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isReprocessed = null;
      doc.isReprocessInProgress = true;
      doc.reprocessError = null;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isReprocessed = true;
      doc.isReprocessInProgress = false;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isReprocessed = false;
      doc.isReprocessInProgress = false;
      doc.reprocessError = action.payload.error;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // accept doc
    case ACCEPT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM:
    case ACCEPT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isAccepted = null;
      doc.isAcceptInProgress = true;
      doc.acceptError = null;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case ACCEPT_ORDER_DOC_NORM_LIST_ITEM_OK: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isAccepted = true;
      doc.isAcceptInProgress = false;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case ACCEPT_ORDER_DOC_NORM_LIST_ITEM_ERR: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isAccepted = false;
      doc.isAcceptInProgress = false;
      doc.acceptError = action.payload.error;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // reject doc
    case REJECT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM:
    case REJECT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isRejected = null;
      doc.isRejectInProgress = true;
      doc.rejectError = null;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case REJECT_ORDER_DOC_NORM_LIST_ITEM_OK: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isRejected = true;
      doc.isRejectInProgress = false;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case REJECT_ORDER_DOC_NORM_LIST_ITEM_ERR: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isRejected = false;
      doc.isRejectInProgress = false;
      doc.rejectError = action.payload.error;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // add page to doc
    case ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isPageAdded = null;
      doc.isPageAddInProgress = true;
      doc.pageAddError = null;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_OK: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isPageAdded = true;
      doc.isPageAddInProgress = false;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM_ERR: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isPageAdded = false;
      doc.isPageAddInProgress = false;
      doc.pageAddError = action.payload.error;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // accept page
    case ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      const page = doc.pages[pageNum];
      page.isAccepted = null;
      page.isAcceptInProgress = true;
      page.acceptError = null;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      const page = doc.pages[pageNum];
      page.isAccepted = true;
      page.isAcceptInProgress = false;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      const page = doc.pages[pageNum];
      page.isAccepted = false;
      page.isAcceptInProgress = false;
      page.acceptError = action.payload.error;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // reject page
    case REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      const page = doc.pages[pageNum];
      page.isRejected = null;
      page.isRejectInProgress = true;
      page.rejectError = null;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      const page = doc.pages[pageNum];
      page.isRejected = true;
      page.isRejectInProgress = false;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      const page = doc.pages[pageNum];
      page.isRejected = false;
      page.isRejectInProgress = false;
      page.rejectError = action.payload.error;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // delete page
    case DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      // make sure pages object is defined
      doc.pages = doc.pages || {};
      // pages that are not yet submitted for review do not exist in the 'doc.pages' property
      const page = doc.pages[pageNum] || {};
      page.isDeleted = null;
      page.isDeleteInProgress = true;
      page.deleteError = null;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      const page = doc.pages[pageNum];
      page.isDeleted = true;
      page.isDeleteInProgress = false;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      const page = doc.pages[pageNum];
      page.isDeleted = false;
      page.isDeleteInProgress = false;
      page.deleteError = action.payload.error;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // add page before
    case ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      // pages that are not yet submitted for review do not exist in the 'doc.pages' property
      const page = doc.pages[pageNum] || {};
      page.isAddedBefore = null;
      page.isAddBeforeInProgress = true;
      page.addBeforeError = null;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_OK: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      const page = doc.pages[pageNum];
      page.isAddedBefore = true;
      page.isAddBeforeInProgress = false;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE_ERR: {
      const docId = action.payload.orderDocId;
      const doc = state.orderDocs[docId];
      const pageNum = action.payload.pageNum;
      const page = doc.pages[pageNum];
      page.isAddedBefore = false;
      page.isAddBeforeInProgress = false;
      page.addBeforeError = action.payload.error;
      doc.pages[pageNum] = page;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // cleanup
    case DO_ORDER_DOC_NORM_LIST_CLEANUP:
      state = { ...defaultNormListState };
      break
  }
  return state;
}

/********** SINGLE **********/

const defaultSingleState = {
  orderDoc: null,
  orderDocError: null,
  isLoadInProgress: false,
};

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    // load
    case GET_DOC_VIEWER_ORDER_DOC:
      state = {
        ...state,
        orderDocError: null,
        isLoadInProgress: true,
      };
      break
    case GET_DOC_VIEWER_ORDER_DOC_OK:
      state = {
        ...state,
        orderDoc: {
          ...action.payload.docResponse.orderDoc,
          signers: action.payload.signersResponse.signers,
          signings: action.payload.signingsResponse.signings,
        },
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_DOC_ERR:
      state = {
        ...state,
        orderDoc: null,
        orderDocError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_DOC_SINGLE_CLEANUP:
      state = { ...defaultSingleState };
      break
  }
  return state;
}

/********** FORM **********/

const defaultFormState = {
  orderDoc: null,
  uploaded: null,
  uploadError: null,
  isUploadInProgress: false,
};

const Form = (state = defaultFormState, action) => {
  switch (action.type) {
    case UPLOAD_ORDER_DOC:
    case ADD_ORDER_DOC:
      state = {
        ...state,
        uploaded: null,
        uploadError: null,
        isUploadInProgress: true,
      };
      break
    case UPLOAD_ORDER_DOC_OK:
    case ADD_ORDER_DOC_OK:
      state = {
        ...state,
        orderDoc: {
          ...state.orderDoc,
          id: action.payload.response.id,
        },
        uploaded: true,
        isUploadInProgress: false,
      };
      break
    case UPLOAD_ORDER_DOC_ERR:
    case ADD_ORDER_DOC_ERR:
      state = {
        ...state,
        uploaded: false,
        uploadError: action.payload.error,
        isUploadInProgress: false,
      };
      break
    case DO_ORDER_DOC_FORM_CLEANUP:
      state = { ...defaultFormState };
      break
  }
  return state;
}

/********** FIELDS **********/

const defaultFieldsState = {
  saved: null,
  saveError: null,
  isSaveInProgress: false,
  isReadyToSignInProgress: false,
};

const Fields = (state = defaultFieldsState, action) => {
  switch (action.type) {
    // save
    case UPDATE_ORDER_DOC_FIELDS:
      state = {
        ...state,
        saved: null,
        saveError: null,
        isSaveInProgress: !action.payload.data.isReady,
        isReadyToSignInProgress: !!action.payload.data.isReady,
      };
      break
    case SAVE_ORDER_DOC_FIELDS_OK:
      state = {
        ...state,
        saved: true,
        isSaveInProgress: false,
        isReadyToSignInProgress: false,
      };
      break
    case SAVE_ORDER_DOC_FIELDS_ERR:
      state = {
        ...state,
        saved: false,
        saveError: action.payload.error,
        isSaveInProgress: false,
        isReadyToSignInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_DOC_FIELDS_CLEANUP:
      state = { ...defaultFieldsState };
      break
  }
  return state;
}

/********** ACCEPT ALL **********/

const defaultAcceptAllState = {
  accepted: null,
  acceptError: null,
  isAcceptInProgress: false,
};

const AcceptAll = (state = defaultAcceptAllState, action) => {
  switch (action.type) {
    // save
    case ACCEPT_ALL_INK_SIGN_DOCS:
      state = {
        ...state,
        accepted: null,
        acceptError: null,
        isAcceptInProgress: true,
      };
      break
    case ACCEPT_ALL_INK_SIGN_DOCS_OK:
      state = {
        ...state,
        accepted: true,
        isAcceptInProgress: false,
      };
      break
    case ACCEPT_ALL_INK_SIGN_DOCS_ERR:
      state = {
        ...state,
        accepted: false,
        acceptError: action.payload.error,
        isAcceptInProgress: false,
      };
      break
    case DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP:
      state = { ...defaultAcceptAllState };
      break
  }
  return state;
}


export default combineReducers({
  List,
  NormList,
  Single,
  Form,
  Fields,
  AcceptAll,
})