import React from "react";
import { Container, Row, Col } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import FormNew from "./Partial/Form/New";

const New = () => {

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  return <React.Fragment>
    {iAmGranted(perms.create_customer_calls) && <div className="page-content">
      <MetaTitle>New call</MetaTitle>
      <Container fluid>
        <Breadcrumbs breadcrumbItems={breadcrumbs} />
        <Row>
          <Col xl="8" xxl="6">
            <FormNew />
          </Col>
        </Row>
      </Container>
    </div>}
    {iAmNotGranted(perms.create_customer_calls) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = [{
  title: 'CALLS',
  url: route(routes.list_customer_calls),
}, {
  title: 'New call',
}];

export default New;
