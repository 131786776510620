import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getBeUrl } from 'helpers/utilHelper';
import { useDocViewer } from '../../Context';
import SpinnerChase from 'components/Shared/SpinnerChase';

const DocViewerPageThumb = props => {

  /**
   * Component props:
   * pageNum {int} the number of the page to render
   */
  const { pageNum } = props;

  /**
   * Context vars (see: Context.js)
   */
  const { orderDoc, activePageNum, setActivePageNum } = useDocViewer();

  /**
   * Bool flag that tells whether the image has loaded or not
   * The spinner will be visible or not based on this value
   */
  const [pageLoaded, setPageLoaded] = useState(false);

  return <div className={classnames('doc-viewer-page-thumb', { loaded: pageLoaded, active: activePageNum === pageNum })}>
    {!pageLoaded && <SpinnerChase />}
    <img src={getBeUrl(`order-doc/${orderDoc.id}/e-sign/page/${pageNum}.png`)} className="doc-viewer-page-image" onLoad={() => setPageLoaded(true)} onError={() => setPageLoaded(true)} onClick={() => setActivePageNum(pageNum)} />
    <div className="doc-viewer-page-num">{pageNum}</div>
  </div>
}

DocViewerPageThumb.propTypes = {
  pageNum: PropTypes.number,
}

export default DocViewerPageThumb;