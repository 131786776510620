import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { Container, NavItem, NavLink, TabContent, TabPane, Card, CardBody, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import FormInfoNew from "../Partial/Form/Info/New";
import FormContactNew from "../Partial/Form/Contact/New";
import FormContact2New from "../Partial/Form/Contact2/New";
import FormFinanceNew from "../Partial/Form/Finance/New";
import FormBillingNew from "../Partial/Form/Billing/New";
import { ValidationException } from "helpers/errorHelper";
import { showError, showSuccess } from "helpers/utilHelper";
import Col from "components/Shared/Col";
import { useAuth } from "context/auth";
import { createDealerStore } from "helpers/backendHelper";
import { isEmpty } from 'lodash';

const New = () => {

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // authenticated user
  const { user: authUser } = useAuth();

  /********** STATE **********/

  // the index of the active tab
  const [activeTab, setActiveTab] = useState(0);
  // list of tab indexes that have been completed so far
  // includes the active tab
  const [passedSteps, setPassedSteps] = useState([0]);
  // stores all field values gathered across the wizard tabs
  const [fieldValues, setFieldValues] = useState({});
  // when the remote call fails due to validation
  // this is the list of tabs (indexes) that have errors
  const [tabsWithErrors, setTabsWithErrors] = useState([]);
  // backend error after save
  const [saveError, setSaveError] = useState(null);

  /********** EFFECTS /**********/
  useEffect(() => {
    if (isEmpty(fieldValues)) {
      // nothing has been saved yet so we are in the first wizard step
      return;
    }
    // if we have reached the end of the wizard
    if (activeTab == tabs.length - 1) {
      // save the data
      saveWizard();
    } else {
      // activate the next tab
      showTab(activeTab + 1);
    }
  }, [fieldValues]);

  /********** TAB CONFIG **********/

  const formInfoProps = {
    forceValues: {
      dealerGroupId: authUser.dealerGroupId,
    },
  };
  if (iAmGranted(perms.view_own_region_dealer_stores)) {
    formInfoProps.forceValues.dealerRegionId = authUser.dealerRegionId;
  }

  // the list of tabs to display
  const tabs = [
    {
      name: 'Store info',
      component: FormInfoNew,
      props: formInfoProps,
    }, {
      name: 'Contact',
      component: FormContactNew,
    }, {
      name: 'Alternate contact',
      component: FormContact2New,
    }, {
      name: 'Finance',
      component: FormFinanceNew,
    }, {
      name: 'Billing',
      component: FormBillingNew,
    },
  ];

  // the component to be rendered for the active tab
  const ActiveTabComponent = tabs[activeTab].component;
  const activeTabProps = tabs[activeTab].props;

  /********** EVENT HANDLERS **********/

  // makes the specified tab visible
  const showTab = tab => {
    // if tab is not already active
    if (activeTab !== tab) {
      // add the tab to the completed tabs list
      const prevSteps = [...passedSteps, tab];
      setPassedSteps(prevSteps);
      // activate the tab
      setActiveTab(tab);
    }
  }

  // triggered when a tab form is successfully submitted
  const onTabNext = (tabId, values) => {
    // add the field values to the global object under the tab id
    const merged = {
      ...fieldValues,
      [tabId]: values,
    };
    setFieldValues(merged);
  }

  // triggered when the user clicks the 'back' button in a tab
  const onTabPrev = () => {
    if (activeTab == 0) {
      // the user pressed the 'quit' button so close the wizard
      navigate(route(routes.list_dealer_stores));
    } else {
      // activate the previous tab
      showTab(activeTab - 1);
    }
  }

  /********** OTHER **********/

  // makes the remote call to save the dealer store
  const saveWizard = () => {
    // flatten the values object
    // because the fields are stored by tab id
    const values = Object.values(fieldValues).reduce((prev, curr) => ({ ...prev, ...curr }), {});
    // clear the tab errors
    setTabsWithErrors([]);
    // fire the request
    saveDealerStore(values);
  }

  // get the stored field values for the active tab
  // we use this to populate the form fields when navigating back to a completed tab
  const getTabDefaultValues = () => fieldValues[activeTab] || {};

  const saveDealerStore = values => {
    createDealerStore(values)
      .then(response => {
        showSuccess(`Store "${values.name}" has been saved`);
        navigate(route(routes.list_dealer_stores));
      })
      .catch(ex => {
        setSaveError(ex);
        showError('Unable to save store');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // see which tabs have errors
          let errTabs = [];
          // check each erroneous field
          for (const fieldName of Object.keys(ex.fields)) {
            // get the field's tab index
            for (const [tabId, fields] of Object.entries(fieldValues)) {
              if (fields.hasOwnProperty(fieldName)) {
                // mark the tab as having errors
                errTabs.push(parseInt(tabId));
              }
            }
          }
          // remove duplicates
          errTabs = errTabs.filter((x, i, a) => a.indexOf(x) == i);
          // show error marker on tabs
          setTabsWithErrors(errTabs);
          // if the active tab does not have errors
          if (errTabs.length && !errTabs.includes(activeTab)) {
            // activate the first tab with errors
            showTab(errTabs[0]);
          }
        }
      })
  }

  return <React.Fragment>
    {iAmGranted(perms.create_dealer_stores) && <div className="page-content">
      <MetaTitle>New store</MetaTitle>
      <Container fluid>
        <Breadcrumbs breadcrumbItems={breadcrumbs()} />
        <Row>
          <Col xl="12" xxxl="10" xxxxl="8">
            <Card>
              <CardBody>
                <div className="vertical-wizard wizard clearfix vertical">
                  <div className="steps clearfix pb-4">
                    <ul>
                      {tabs.map((tab, index) => {
                        return <NavItem className={classnames({ current: activeTab === index, 'is-invalid': tabsWithErrors.includes(index) })} key={index}>
                          <NavLink className={classnames({ active: activeTab === index })} onClick={() => showTab(index)} disabled={!passedSteps.includes(index)}>
                            <span className="number">{index + 1}</span> {tab.name}
                          </NavLink>
                        </NavItem>
                      })}
                    </ul>
                  </div>
                  <div className="content clearfix p-0 ps-xl-5">
                    <TabContent activeTab={0} className="body">
                      <TabPane tabId={0}>
                        <ActiveTabComponent tabId={activeTab} nextHandler={onTabNext} prevHandler={onTabPrev} defaultValues={getTabDefaultValues()} {...activeTabProps} saveError={saveError} />
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>}
    {iAmNotGranted(perms.create_dealer_stores) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = () => [{
  title: 'STORES',
  url: route(routes.list_dealer_stores),
}, {
  title: 'New store',
}];

export default New;
