import React, { useEffect, useState } from "react";
import { Input, Label, Accordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { doOrderESignDocsCleanup, getOrderESignDocs } from "store/actions";

const ESignForm = props => {

  const { orderId, onDocsSelectionChange } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  const [open, setOpen] = useState(false);
  const [checkAllEsignDocs, setCheckAllEsignDocs] = useState(true);
  const [selectedEsignDocs, setSelectedEsignDocs] = useState({});

  // get redux state from the store
  const orderDocs = useSelector(state => state.Order.ESignDocs.docs);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    dispatch(getOrderESignDocs(orderId));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderESignDocsCleanup());
    }
  }, [orderId]);

  // Initialize selected docs when orderDocs change
  useEffect(() => {
    if (orderDocs) {
      const initialSelectedDocs = {};
      orderDocs.forEach(doc => {
        initialSelectedDocs[doc.id] = true;
      });
      setSelectedEsignDocs(initialSelectedDocs);
      onDocsSelectionChange(initialSelectedDocs);
    }
  }, [orderDocs]);


  /********** OTHER **********/

  const getDocsAsArray = () => {
    if (!orderDocs) {
      return [];
    }
    return Object.entries(orderDocs);
  }

  const toggle = (id) => {
    setOpen(prevOpen => prevOpen === id ? false : id);
  };

  // Handle checkbox change for all documents
  const handleCheckAllDocs = (event) => {
    const checked = event.target.checked;
    const newSelectedDocs = {};
    if (checked) {
      orderDocs.forEach(doc => {
        newSelectedDocs[doc.id] = true;
      });
    }
    setSelectedEsignDocs(newSelectedDocs);
    onDocsSelectionChange(newSelectedDocs);
    setCheckAllEsignDocs(checked);
  };

  // Handle checkbox change for individual document
  const handleCheckDocItem = (id) => {
    setSelectedEsignDocs(prevState => {
      const newSelectedDocs = { ...prevState, [id]: !prevState[id] };
      onDocsSelectionChange(newSelectedDocs);
      // Check if all documents are selected
      const allSelected = orderDocs.every(doc => newSelectedDocs[doc.id]);
      setCheckAllEsignDocs(allSelected); // Update checkAllEsignDocs based on allSelected
      return newSelectedDocs;
    });
  };

  // Check if all documents are selected
  useEffect(() => {
    if (orderDocs && Object.keys(selectedEsignDocs).length > 0) {
      const allSelected = orderDocs.every(doc => selectedEsignDocs[doc.id]);
      setCheckAllEsignDocs(allSelected);
    }
  }, [orderDocs, selectedEsignDocs]);

  return <React.Fragment>
    {!!orderDocs?.length &&
      <div className="collapse-container">
        <Accordion open={open} toggle={toggle}>
          <AccordionItem className="mt-1 mb-1">
            <AccordionHeader targetId="1">
              <div className="form-check">
                <Input
                  type="checkbox"
                  name="checkAllEsignDocs"
                  id={"checkAllEsignDocs"}
                  className="form-check-input-lg"
                  checked={checkAllEsignDocs}
                  onChange={handleCheckAllDocs}
                />
                <Label className="checkbox-label-seal mt-1" htmlFor="checkAllEsignDocs">E-Signed Documents</Label>
              </div>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              {getDocsAsArray().map(([index, doc]) =>
                <div className="accordion-body-element mx-3" key={index}>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      name={`checkEsignDocItem-${doc.id}`}
                      id={`checkEsignDocItem-${doc.id}`}
                      className="form-check-input-lg me-2"
                      checked={!!selectedEsignDocs[doc.id]}
                      onChange={() => handleCheckDocItem(doc.id)}
                    />
                    <Label className="checkbox-label-seal" htmlFor={`checkEsignDocItem-${doc.id}`}>{doc.name}</Label>
                  </div>
                  <div>
                    <div className="badge badge-lg rounded-pill doc-item-badge">
                      {doc.numOfPages}
                      <i className="mdi mdi-file-multiple-outline ms-1" />
                    </div>
                  </div>
                </div>
              )}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
    }
  </React.Fragment>
}

ESignForm.propTypes = {
  orderId: PropTypes.number.isRequired,
  onDocsSelectionChange: PropTypes.func.isRequired,
};

export default ESignForm;
