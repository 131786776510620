import React from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Button } from "reactstrap";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { perms, useAccess } from "context/access";
import Col from "components/Shared/Col";
import CardPreloader from "components/Shared/CardPreloader";
import UsStates from "constants/usState";
import { formatPhone } from "helpers/utilHelper";

const ViewInfo = props => {

  const { isRefreshing, dealerGroup, toggleEditMode } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{dealerGroup.name}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_dealer_groups) && <Button type="button" color="primary" className="ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit group
              </Button>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="mb-3">
          <label className="mb-0">Address</label>
          <div>{dealerGroup.address}, {dealerGroup.city}, {UsStates.find(option => option.id === dealerGroup.state).id}, {dealerGroup.zip}</div>
        </Row>
        <Row className="mb-3">
          <label className="mb-0">Contact</label>
          <Col>{dealerGroup.email} <span className="value-sep">|</span> {formatPhone(dealerGroup.phone)}</Col>
        </Row>
        <Row className="mt-4">
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Created</h5>
            <p className="text-muted mb-0">{formatTimestamp(dealerGroup.createdTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Updated</h5>
            <p className="text-muted mb-0">{formatTimestamp(dealerGroup.updatedTs, formats.DATETIME)}</p>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  dealerGroup: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewInfo;
