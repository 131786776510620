import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import { injectStore } from "helpers/apiHelper";
import AuthContext from "context/auth";
import AccessContext from "context/access";
import SubscriptionContext from "context/subscription";
import SocketContext from "context/socket";
import GoogleApiContext from "context/google";

injectStore(store);

const app = (
  <Provider store={store}>
    <AuthContext>
      <AccessContext>
        <SubscriptionContext>
          <SocketContext>
            <GoogleApiContext>
              <App />
            </GoogleApiContext>
          </SocketContext>
        </SubscriptionContext>
      </AccessContext>
    </AuthContext>
  </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);
