import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_DEALER_REGION_DT,
  GET_DEALER_GROUP_REGION_LIST,
} from "./actionTypes";

import {
  getDealerRegionDtOk,
  getDealerRegionDtErr,
  getDealerRegionListOk,
  getDealerRegionListErr,
} from "./actions";

import {
  getDealerRegionDt,
  getDealerGroupRegions,
} from "helpers/backendHelper";

/********** DATATABLE **********/

function* onGetDealerRegionDt({ payload: { params } }) {
  try {
    const response = yield call(getDealerRegionDt, params);
    yield put(getDealerRegionDtOk(response));
  } catch (error) {
    yield put(getDealerRegionDtErr(error));
  }
}

/********* LIST *********/

function* onGetDealerGroupRegionList({ payload: { groupId } }) {
  try {
    const response = yield call(getDealerGroupRegions, groupId);
    yield put(getDealerRegionListOk(response));
  } catch (error) {
    yield put(getDealerRegionListErr(error));
  }
}

function* dealerRegionSaga() {
  yield takeEvery(GET_DEALER_REGION_DT, onGetDealerRegionDt);
  yield takeEvery(GET_DEALER_GROUP_REGION_LIST, onGetDealerGroupRegionList);
}

export default dealerRegionSaga;