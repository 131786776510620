import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col } from "reactstrap";
import logo from "assets/images/mavsign-logo-blue.svg";
import StoreInformation from "./StoreInformation";
import TransactionTable from "./TransactionTable";
import PaymentMethod from "./PaymentMethod";
import PaymentReceivedTable from "./PaymentsReceivedTable";
import DealerStore from "model/dealerStore";
import { getBeUrl } from "helpers/utilHelper";
import downloadDocumentIcon from 'assets/images/download-icon.svg';

const SectionInfo = ({ statement }) => {

  const hasTransactions = !!statement.lines.length;
  const isCheckMethod = statement.paymentMethod === DealerStore.PAYMENT_METHOD_TYPE_CHECK;
  const hasPayments = !!statement.payments.length;
  const unableDownloadStatementPdf = isCheckMethod && (!hasPayments || !statement.payments[0].refId)

  return <Row>
    <Col lg="12">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col sm={6}>
              <img src={logo} alt="logo" height="30" />
            </Col>
            {isCheckMethod && <Col sm={6} className="text-end">
              <div className="qb-id">QB ID: {statement.qbDocId}</div>
            </Col>}
          </Row>
          <hr />
          <Row className="mb-4">
            <Col sm={12} className="mb-4">
              <StoreInformation statement={statement} />
            </Col>

            {hasTransactions && <Col sm={12} className="mb-4">
              <TransactionTable statement={statement} />
            </Col>}

            <Col sm={12} className="mb-4">
              <PaymentMethod paymentMethod={statement.paymentMethod} paymentRef={statement.paymentRefId} />
            </Col>

            {hasPayments && <Col sm={12} className="mb-4">
              <PaymentReceivedTable statement={statement} />
            </Col>}
          </Row>
          {!unableDownloadStatementPdf &&
            <a href={getBeUrl(`statement/${statement.id}/download`)} target="_blank" rel="noreferrer" className="btn btn-primary float-end">
              <span>Download</span>
              <img className='ps-2' src={downloadDocumentIcon} />
            </a>
          }
        </CardBody>
      </Card>
    </Col>
  </Row>
}

SectionInfo.propTypes = {
  statement: PropTypes.object,
};

export default SectionInfo;