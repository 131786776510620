import React, { useState, useEffect } from 'react';
import { useDocViewer } from '../Context';
import { Button, Input } from 'reactstrap';

const DocViewerZoom = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { zoomLevel, setZoomLevel, maxZoomLevel, minZoomLevel, zoomStep } = useDocViewer();

  /**
   * Local state var that holds the value entered in the text box
   * We use this to allow the user to type into the text box without actually changing the zoom level
   * The change will be applied only when he presses ENTER
   * If he changes focus without pressing ENTER then we can revert
   */
  const [localZoomLevel, setLocalZoomLevel] = useState(zoomLevel);

  /**
   * This effect runs whenever the context zoom level changes
   * Which happens when the user clicks on the zoom in/out buttons or types into the header box
   * We use it to refresh the text box value to reflect the real data that has been propagated
   * Mainly because some values that the user may enter locally will be passed to the context as different values
   * For example local values that make invalid zoom levels will be translated into min/max valid zoom levels
   */
  useEffect(() => {
    setLocalZoomLevel(zoomLevel);
  }, [zoomLevel]);

  /**
   * Event handler called when the user presses the zoom-in button
   * @returns {void}
   */
  const zoomIn = () => {
    // check if we can zoom in (ex. if we are not at max zoom level already)
    if (!canZoomIn()) {
      return;
    }
    // settle at the max value if zooming in one more time would exceed the max zoom level
    const step = Math.min(zoomStep, maxZoomLevel - zoomLevel);
    // propagate the new value
    setZoomLevel(level => level + step);
  }

  /**
   * Event handler called when the user presses the zoom-out button
   * @returns {void}
   */
  const zoomOut = () => {
    // check if we can zoom out (ex. if we are not at min zoom level already)
    if (!canZoomOut()) {
      return;
    }
    // settle at the min value if zooming out one more time would exceed the min zoom level
    const step = Math.min(zoomStep, zoomLevel - minZoomLevel);
    // propagate the new value
    setZoomLevel(level => level - step);
  }

  /**
   * Event handler called whenever the user types into the text box
   * We use it to track the ENTER key and apply the value
   * @param {event} e 
   */
  const onKeyDownHandler = e => {
    // if pressed key is 'enter'
    if (e.key === 'Enter') {
      // check if the input value is a valid number
      // alse default to the min zoom level
      const val = isNaN(localZoomLevel) ? minZoomLevel : localZoomLevel;
      // check if the requested zoom level is a valid one
      // else default to the max/min zoom level if the number is too high/low
      const level = Math.max(Math.min(val, maxZoomLevel), minZoomLevel);
      // propagate the new value
      setZoomLevel(level);
    }
  }

  /**
   * Event handler called whenever the user leaves the text box without pressing ENTER
   * We use it to revert the text box value to the context value
   * @returns {void}
   */
  const revertZoomLevel = () => setLocalZoomLevel(zoomLevel);

  /**
   * Checks if we can zoom in (ex. if we are not at max zoom level already)
   * @returns {boolean}
   */
  const canZoomIn = () => zoomLevel < maxZoomLevel;

  /**
   * Checks if we can zoom out (ex. if we are not at min zoom level already)
   * @returns {boolean}
   */
  const canZoomOut = () => zoomLevel > minZoomLevel;

  /**
   * Zoom level can be a decimal number but we only want to show integers in the text box
   * So here we return a formatted value
   * @returns {string}
   */
  const getFormattedZoomLevel = () => isNaN(localZoomLevel) ? localZoomLevel : Math.floor(localZoomLevel);

  return <div className="doc-viewer-zoom">
    <Button type="button" color="default" onClick={zoomOut} disabled={!canZoomOut()}><i className="bx bx-minus"></i></Button>
    <Input type="text" className="doc-viewer-header-input doc-viewer-zoom-input" onChange={e => setLocalZoomLevel(e.target.value)} value={getFormattedZoomLevel()} onKeyDown={onKeyDownHandler} onBlur={revertZoomLevel} />
    <span className="doc-viewer-zoom-percent">%</span>
    <Button type="button" color="default" onClick={zoomIn} disabled={!canZoomIn()}><i className="bx bx-plus"></i></Button>
  </div>
}

export default DocViewerZoom;