import { takeEvery, put, call, all, take, race } from 'redux-saga/effects';
import EventEmitter from 'helpers/eventsHelper';

import {
  GET_ORDER_DOC_LIST,
  GET_UPLOADED_ORDER_DOC_LIST,
  GET_SHIPPED_ORDER_DOC_LIST,

  GET_ORDER_DOC_NORM_LIST,
  GET_UPLOADED_ORDER_DOC_NORM_LIST,
  GET_INK_SIGN_ORDER_DOC_NORM_LIST,
  GET_E_SIGN_ORDER_DOC_NORM_LIST,
  GET_FINANCE_ORDER_DOC_NORM_LIST,
  GET_SUPPORTING_ORDER_DOC_NORM_LIST,
  GET_ORDER_DOC_NORM_LIST_ITEM,
  DELETE_ORDER_DOC_NORM_LIST_ITEM,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM,
  ACCEPT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  ACCEPT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  REJECT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  REJECT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM,
  ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM,
  ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE,
  DO_ORDER_DOC_NORM_LIST_CLEANUP,

  GET_DOC_VIEWER_ORDER_DOC,

  UPLOAD_ORDER_DOC,
  ADD_ORDER_DOC,

  UPDATE_ORDER_DOC_FIELDS,
  ACCEPT_ALL_INK_SIGN_DOCS,
} from './actionTypes';

import {
  getOrderDocListOk,
  getOrderDocListErr,

  getOrderDocNormListOk,
  getOrderDocNormListErr,
  getOrderDocNormListItemOk,
  getOrderDocNormListItemErr,
  deleteOrderDocNormListItemOk,
  deleteOrderDocNormListItemErr,
  reprocessOrderDocNormListItemOk,
  reprocessOrderDocNormListItemErr,
  acceptOrderDocNormListItemOk,
  acceptOrderDocNormListItemErr,
  rejectOrderDocNormListItemOk,
  rejectOrderDocNormListItemErr,
  addPageToOrderDocNormListItemOk,
  addPageToOrderDocNormListItemErr,
  acceptOrderDocNormListItemPageOk,
  acceptOrderDocNormListItemPageErr,
  rejectOrderDocNormListItemPageOk,
  rejectOrderDocNormListItemPageErr,
  deleteOrderDocNormListItemPageOk,
  deleteOrderDocNormListItemPageErr,
  addPageBeforeOrderDocNormListItemPageOk,
  addPageBeforeOrderDocNormListItemPageErr,

  getDocViewerOrderDocOk,
  getOrderDocErr,

  uploadOrderDocOk,
  uploadOrderDocErr,
  addOrderDocOk,
  addOrderDocErr,

  saveOrderDocFieldsOk,
  saveOrderDocFieldsErr,
  acceptAllInkSignDocumentsOk,
  acceptAllInkSignDocumentsErr,
} from './actions';

import {
  getAllOrderDocs,
  getUploadedOrderDocs,
  getShippedOrderDocs,

  getInkSignOrderDocs,
  getESignOrderDocs,
  getFinanceOrderDocs,
  getSupportingOrderDocs,
  acceptOrderDocPage,
  rejectOrderDocPage,
  deleteOrderDocPage,
  addOrderDocPage,

  getOrderDoc,
  deleteOrderDoc,
  reprocessOrderDoc,
  acceptESignDoc,
  acceptInkSignDoc,
  rejectESignDoc,
  rejectInkSignDoc,
  getOrderDocSigners,
  getOrderDocSignings,

  uploadOrderDoc,
  addOrderDoc,

  updateOrderDocFields,
  acceptAllInkSignDocs,
} from 'helpers/backendHelper';

/********* LIST *********/

function* onGetOrderDocList({ payload: { orderId } }) {
  try {
    const response = yield call(getAllOrderDocs, orderId);
    yield put(getOrderDocListOk(response));
  } catch (error) {
    yield put(getOrderDocListErr(error));
  }
}

function* onGetUploadedOrderDocList({ payload: { orderId } }) {
  try {
    const response = yield call(getUploadedOrderDocs, orderId);
    yield put(getOrderDocListOk(response));
  } catch (error) {
    yield put(getOrderDocListErr(error));
  }
}

function* onGetShippedOrderDocList({ payload: { orderId } }) {
  try {
    const response = yield call(getShippedOrderDocs, orderId);
    yield put(getOrderDocListOk(response));
  } catch (error) {
    yield put(getOrderDocListErr(error));
  }
}

/********** NORMALIZED LIST **********/

function* onGetOrderDocNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getAllOrderDocs, orderId);
    yield put(getOrderDocNormListOk(response));
  } catch (error) {
    yield put(getOrderDocNormListErr(error));
  }
}

function* onGetUploadedOrderDocNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getUploadedOrderDocs, orderId);
    yield put(getOrderDocNormListOk(response));
  } catch (error) {
    yield put(getOrderDocNormListErr(error));
  }
}

function* onGetInkSignOrderDocNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getInkSignOrderDocs, orderId);
    yield put(getOrderDocNormListOk(response));
  } catch (error) {
    yield put(getOrderDocNormListErr(error));
  }
}

function* onGetESignOrderDocNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getESignOrderDocs, orderId);
    yield put(getOrderDocNormListOk(response));
  } catch (error) {
    yield put(getOrderDocNormListErr(error));
  }
}

function* onGetFinanceOrderDocNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getFinanceOrderDocs, orderId);
    yield put(getOrderDocNormListOk(response));
  } catch (error) {
    yield put(getOrderDocNormListErr(error));
  }
}

function* onGetSupportingOrderDocNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getSupportingOrderDocs, orderId);
    yield put(getOrderDocNormListOk(response));
  } catch (error) {
    yield put(getOrderDocNormListErr(error));
  }
}

function* onGetOrderDocNormListItem({ payload: { id } }) {
  try {
    const response = yield call(getOrderDoc, id);
    yield put(getOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(getOrderDocNormListItemErr(id, error));
  }
}

function* onDeleteOrderDocNormListItem({ payload: { id } }) {
  try {
    const response = yield call(deleteOrderDoc, id);
    yield put(deleteOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(deleteOrderDocNormListItemErr(id, error));
  }
}

function* onReprocessOrderDocNormListItem({ payload: { id } }) {
  try {
    const response = yield call(reprocessOrderDoc, id);
    yield put(reprocessOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(reprocessOrderDocNormListItemErr(id, error));
  }
}

function* onAcceptESignOrderDocNormListItem({ payload: { id } }) {
  try {
    const response = yield call(acceptESignDoc, id);
    yield put(acceptOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(acceptOrderDocNormListItemErr(id, error));
  }
}

function* onAcceptInkSignOrderDocNormListItem({ payload: { id } }) {
  try {
    const response = yield call(acceptInkSignDoc, id);
    yield put(acceptOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(acceptOrderDocNormListItemErr(id, error));
  }
}

function* onRejectESignOrderDocNormListItem({ payload: { id, data } }) {
  try {
    const response = yield call(rejectESignDoc, id, data);
    yield put(rejectOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(rejectOrderDocNormListItemErr(id, error));
  }
}

function* onRejectInkSignOrderDocNormListItem({ payload: { id, data } }) {
  try {
    const response = yield call(rejectInkSignDoc, id, data);
    yield put(rejectOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(rejectOrderDocNormListItemErr(id, error));
  }
}

function* onAddPageToOrderDocNormListItem({ payload: { id } }) {
  try {
    const response = yield call(addOrderDocPage, id);
    yield put(addPageToOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(addPageToOrderDocNormListItemErr(id, error));
  }
}

// wrapper overview saga that cancels task when a conflicting action is dispatched
function cancelOnCleanup(cleanupActionType, task) {
  return function* startOverviewSaga(params) {
    yield race({
      task: call(task, params), // call task to mutate state
      cancel: take(cleanupActionType), // cancel task when cleanup action is dispatched
    });
  }
}

function* onAcceptOrderDocNormListItemPage({ payload: { orderDocId, pageNum } }) {
  try {
    const response = yield call(acceptOrderDocPage, orderDocId, pageNum);
    yield put(acceptOrderDocNormListItemPageOk(orderDocId, pageNum, response));
  } catch (error) {
    yield put(acceptOrderDocNormListItemPageErr(orderDocId, pageNum, error));
  }
}

function* onRejectOrderDocNormListItemPage({ payload: { orderDocId, pageNum, data } }) {
  try {
    const response = yield call(rejectOrderDocPage, orderDocId, pageNum, data);
    yield put(rejectOrderDocNormListItemPageOk(orderDocId, pageNum, response));
  } catch (error) {
    yield put(rejectOrderDocNormListItemPageErr(orderDocId, pageNum, error));
  }
}

function* onDeleteOrderDocNormListItemPage({ payload: { orderDocId, pageNum } }) {
  try {
    const response = yield call(deleteOrderDocPage, orderDocId, pageNum);
    yield put(deleteOrderDocNormListItemPageOk(orderDocId, pageNum, response));
  } catch (error) {
    yield put(deleteOrderDocNormListItemPageErr(orderDocId, pageNum, error));
  }
}

function* onAddPageBeforeOrderDocNormListItemPage({ payload: { orderDocId, pageNum } }) {
  try {
    const response = yield call(addOrderDocPage, orderDocId, pageNum);
    yield put(addPageBeforeOrderDocNormListItemPageOk(orderDocId, pageNum, response));
  } catch (error) {
    yield put(addPageBeforeOrderDocNormListItemPageErr(orderDocId, pageNum, error));
  }
}

/********** SINGLE **********/

function* onGetDocViewerOrderDoc({ payload: { id } }) {
  try {
    const [docResponse, signersResponse, signingsResponse] = yield all([
      call(getOrderDoc, id),
      call(getOrderDocSigners, id),
      call(getOrderDocSignings, id),
    ]);
    yield put(getDocViewerOrderDocOk(docResponse, signersResponse, signingsResponse));
  } catch (error) {
    yield put(getOrderDocErr(error));
  }
}

/********** FORM **********/

function* onUploadOrderDoc({ payload: { data, orderId } }) {
  try {
    const response = yield call(uploadOrderDoc, data, orderId, {
      onUploadProgress: ev => EventEmitter.emit('orderDoc.uploadProgress', ev)
    });
    yield put(uploadOrderDocOk(response));
  } catch (error) {
    yield put(uploadOrderDocErr(error));
  }
}

function* onAddOrderDoc({ payload: { data, orderId } }) {
  try {
    const response = yield call(addOrderDoc, data, orderId);
    yield put(addOrderDocOk(response));
  } catch (error) {
    yield put(addOrderDocErr(error));
  }
}

/********** FIELDS **********/

function* onUpdateOrderDocFields({ payload: { data, id } }) {
  try {
    const response = yield call(updateOrderDocFields, data, id);
    yield put(saveOrderDocFieldsOk(response));
  } catch (error) {
    yield put(saveOrderDocFieldsErr(error));
  }
}

/********** ACCEPT ALL **********/

function* onAcceptAllInkSignDocs({ payload: { orderId } }) {
  try {
    const response = yield call(acceptAllInkSignDocs, orderId);
    yield put(acceptAllInkSignDocumentsOk(response));
  } catch (error) {
    yield put(acceptAllInkSignDocumentsErr(error));
  }
}

function* orderDocSaga() {
  yield takeEvery(GET_ORDER_DOC_LIST, onGetOrderDocList);
  yield takeEvery(GET_UPLOADED_ORDER_DOC_LIST, onGetUploadedOrderDocList);
  yield takeEvery(GET_SHIPPED_ORDER_DOC_LIST, onGetShippedOrderDocList);

  yield takeEvery(GET_ORDER_DOC_NORM_LIST, onGetOrderDocNormList);
  yield takeEvery(GET_UPLOADED_ORDER_DOC_NORM_LIST, onGetUploadedOrderDocNormList);
  yield takeEvery(GET_INK_SIGN_ORDER_DOC_NORM_LIST, onGetInkSignOrderDocNormList);
  yield takeEvery(GET_E_SIGN_ORDER_DOC_NORM_LIST, onGetESignOrderDocNormList);
  yield takeEvery(GET_FINANCE_ORDER_DOC_NORM_LIST, onGetFinanceOrderDocNormList);
  yield takeEvery(GET_SUPPORTING_ORDER_DOC_NORM_LIST, onGetSupportingOrderDocNormList);
  yield takeEvery(GET_ORDER_DOC_NORM_LIST_ITEM, onGetOrderDocNormListItem);

  // the actions below need orderDocs to be loaded in order to mutate the state
  // we wrap them in overview sagas that will cancel the ongoing mutations
  // anytime a state cleanup happens (for example, when navigating to another page)
  yield takeEvery(DELETE_ORDER_DOC_NORM_LIST_ITEM, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onDeleteOrderDocNormListItem));
  yield takeEvery(REPROCESS_ORDER_DOC_NORM_LIST_ITEM, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onReprocessOrderDocNormListItem));
  yield takeEvery(ACCEPT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onAcceptESignOrderDocNormListItem));
  yield takeEvery(ACCEPT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onAcceptInkSignOrderDocNormListItem));
  yield takeEvery(REJECT_E_SIGN_ORDER_DOC_NORM_LIST_ITEM, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onRejectESignOrderDocNormListItem));
  yield takeEvery(REJECT_INK_SIGN_ORDER_DOC_NORM_LIST_ITEM, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onRejectInkSignOrderDocNormListItem));
  yield takeEvery(ADD_PAGE_TO_ORDER_DOC_NORM_LIST_ITEM, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onAddPageToOrderDocNormListItem));
  yield takeEvery(ACCEPT_ORDER_DOC_NORM_LIST_ITEM_PAGE, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onAcceptOrderDocNormListItemPage));
  yield takeEvery(REJECT_ORDER_DOC_NORM_LIST_ITEM_PAGE, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onRejectOrderDocNormListItemPage));
  yield takeEvery(DELETE_ORDER_DOC_NORM_LIST_ITEM_PAGE, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onDeleteOrderDocNormListItemPage));
  yield takeEvery(ADD_PAGE_BEFORE_ORDER_DOC_NORM_LIST_ITEM_PAGE, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onAddPageBeforeOrderDocNormListItemPage));

  yield takeEvery(GET_DOC_VIEWER_ORDER_DOC, onGetDocViewerOrderDoc);

  yield takeEvery(UPLOAD_ORDER_DOC, onUploadOrderDoc);
  yield takeEvery(ADD_ORDER_DOC, onAddOrderDoc);

  yield takeEvery(UPDATE_ORDER_DOC_FIELDS, onUpdateOrderDocFields);

  yield takeEvery(ACCEPT_ALL_INK_SIGN_DOCS, onAcceptAllInkSignDocs);
}

export default orderDocSaga;