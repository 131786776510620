import React from 'react';
import PropTypes from 'prop-types';
import subscribedIcon from "assets/images/subscribed-icon.svg";
import { Card, CardBody } from "reactstrap";
import { formats, formatTimestamp } from 'helpers/dateHelper';
import { getLogAuthorFullName } from 'helpers/utilHelper';

const SubscribedBy = ({ dealerStore }) => {
  return <Card className='mb-3'>
    <CardBody className='d-flex'>
      <img src={subscribedIcon} />
      <div className='ms-3'>
        <div className='font-weight-500'>Subscribed by</div>
        <div><strong>{getLogAuthorFullName(dealerStore.paymentPlanChangedBy)}</strong> on <strong>{formatTimestamp(dealerStore.updatedTs, formats.US_DATE)}</strong></div>
      </div>
    </CardBody>
  </Card>
}

SubscribedBy.propTypes = {
  dealerStore: PropTypes.object,
}

export default SubscribedBy;