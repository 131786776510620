import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, CardHeader, Table, Alert } from "reactstrap";
import Col from "components/Shared/Col";
import { formats, formatTimestamp } from 'helpers/dateHelper';
import PropTypes from 'prop-types';
import { route, routes } from 'helpers/routeHelper';
import SpinnerChase from 'components/Shared/SpinnerChase';
import classnames from "classnames";
import shield from 'assets/images/shield-blue.svg';
import { getOrderSupportingStatus } from "helpers/backendHelper";
import infoIcon from "assets/images/blue-info-icon.svg";

const SectionSupportingDocs = props => {

  const { id, isLocked } = props;

  /********** STATE **********/

  const [supportingStatus, setSupportingStatus] = useState(null);
  const [supportingStatusError, setSupportingStatusError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    refreshOrderSupportingStatus();
  }, [id]);

  /********** OTHER **********/

  const refreshOrderSupportingStatus = () => {
    setIsLoadInProgress(true);
    getOrderSupportingStatus(id)
      .then(response => {
        setSupportingStatus(response.supporting);
      })
      .catch(ex => {
        setSupportingStatusError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Supporting Documents</div>
          </Col>
          {isLocked && <Col xs="auto" className="text-end">
            <img src={shield} className="mt-1" />
          </Col>}
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        <div className='supporting-docs-label d-flex'>
          <img src={infoIcon} />
          <p className='m-0 ms-2 supporting-docs-text'>Upload 2 forms of ID when required</p>
        </div>
        {supportingStatus && <Table className="section-rows table bt-1">
          <tbody>
            <tr>
              <td className="section-row-label">No of Documents</td>
              <td className={classnames('section-row-value ps-4')}>{supportingStatus.num || '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Date</td>
              <td className="section-row-value ps-4">{supportingStatus.date ? formatTimestamp(supportingStatus.date, formats.DATETIME) : '--'}</td>
            </tr>
          </tbody>
        </Table>}
        {supportingStatusError && <div>
          <Alert color="danger" className="fade show text-center">
            <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load data
          </Alert>
        </div>}
        {isLoadInProgress && <SpinnerChase className="sm mt-2 mb-2" />}
        <div className="text-end mt-4">
          <Link to={route(routes.view_order_supporting, id)} className={classnames('btn btn-primary', { disabled: !supportingStatus || supportingStatus.status === null })}>View More Details <i className="mdi mdi-arrow-right ms-1"></i></Link>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionSupportingDocs.propTypes = {
  id: PropTypes.number,
  isLocked: PropTypes.bool,
};

export default SectionSupportingDocs;