import DealerStore from "../model/dealerStore";

export default [
  {
    id: DealerStore.PAYMENT_METHOD_TYPE_CARD,
    name: 'Credit Card'
  },
  {
    id: DealerStore.PAYMENT_METHOD_TYPE_BANK_ACCOUNT,
    name: 'Bank Account'
  },
  {
    id: DealerStore.PAYMENT_METHOD_TYPE_CHECK,
    name: 'Check'
  },
];