import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback, CardTitle } from "reactstrap";
import { nullsToEmptyStrings, phoneHasNoOfDigits, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import regx from "constants/regx";
import { updateDealerStoreFinance } from "helpers/backendHelper";

const FormFinanceEdit = props => {

  const { defaultValues, id, finishedHandler } = props;

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    financeManagerName: '',
    financeManagerEmail: '',
    financeManagerPhone: '',
    financeManagerPhone2: '',
    financeAssistantName: '',
    financeAssistantEmail: '',
    financeAssistantPhone: '',
    financeAssistantPhone2: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      financeManagerEmail: Yup.string().trim().email('Invalid email address'),
      financeAssistantEmail: Yup.string().trim().email('Invalid email address'),
      financeManagerPhone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone1',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      financeManagerPhone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone2',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      financeAssistantPhone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone3',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      financeAssistantPhone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone4',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
    }),
    onSubmit: values => saveDealerStoreFinance(values, id),
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveDealerStoreFinance = (values, id) => {
    setIsSaveInProgress(true);
    updateDealerStoreFinance(values, id)
      .then(response => {
        showSuccess(`Store "${values.name}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save store');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <CardTitle>Finance</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Finance manager</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="financeManagerName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeManagerName} invalid={!!formik.errors.financeManagerName} />
              {!!formik.errors.financeManagerName && <FormFeedback type="invalid">{formik.errors.financeManagerName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Email</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="financeManagerEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeManagerEmail} invalid={!!formik.errors.financeManagerEmail} />
              {!!formik.errors.financeManagerEmail && <FormFeedback type="invalid">{formik.errors.financeManagerEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="financeManagerPhone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeManagerPhone} invalid={!!formik.errors.financeManagerPhone} />
              {!!formik.errors.financeManagerPhone && <FormFeedback type="invalid">{formik.errors.financeManagerPhone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Alternate phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="financeManagerPhone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeManagerPhone2} invalid={!!formik.errors.financeManagerPhone2} />
              {!!formik.errors.financeManagerPhone2 && <FormFeedback type="invalid">{formik.errors.financeManagerPhone2}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Finance assistant</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="financeAssistantName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeAssistantName} invalid={!!formik.errors.financeAssistantName} />
              {!!formik.errors.financeAssistantName && <FormFeedback type="invalid">{formik.errors.financeAssistantName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Email</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="financeAssistantEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeAssistantEmail} invalid={!!formik.errors.financeAssistantEmail} />
              {!!formik.errors.financeAssistantEmail && <FormFeedback type="invalid">{formik.errors.financeAssistantEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="financeAssistantPhone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeAssistantPhone} invalid={!!formik.errors.financeAssistantPhone} />
              {!!formik.errors.financeAssistantPhone && <FormFeedback type="invalid">{formik.errors.financeAssistantPhone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Alternate phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="financeAssistantPhone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.financeAssistantPhone2} invalid={!!formik.errors.financeAssistantPhone2} />
              {!!formik.errors.financeAssistantPhone2 && <FormFeedback type="invalid">{formik.errors.financeAssistantPhone2}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormFinanceEdit.propTypes = {
  defaultValues: PropTypes.object,
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
};

export default FormFinanceEdit;