import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import FormInfoNew from "pages/DealerStoreSigner/Partial/Form/Info/New";
import MetaTitle from "components/Shared/MetaTitle";
import { Row, Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Col from "components/Shared/Col";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import { getDealerStore } from "helpers/backendHelper";

const NewAny = () => {

  // read route parameters
  const { id } = useParams();

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [dealerStore, setDealerStore] = useState(null);
  const [dealerStoreError, setDealerStoreError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the signer data
    refreshDealerStore();
  }, [id]);

  /********** OTHER **********/

  const refreshDealerStore = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getDealerStore(id)
      .then(response => {
        setDealerStore(response.dealerStore);
      })
      .catch(ex => {
        setDealerStoreError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.create_dealer_store_signers) && <div className="page-content">
      {!!dealerStore && <React.Fragment>
        <MetaTitle>New signer</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(dealerStore, iAmGranted)} />
          <Row>
            <Col xl="8" xxl="6">
              <FormInfoNew id={parseInt(id)} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {isLoadInProgress && <Preloader className="inner" />}
      {!!dealerStoreError && <Error error={dealerStoreError} title404="Dealer store not found" />}
    </div>}
    {iAmNotGranted(perms.create_dealer_store_signers) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (dealerStore, iAmGranted) => {
  if (iAmGranted(perms.view_own_dealer_store)) {
    return [{
      title: 'STORE',
      url: route(routes.view_dealer_store, dealerStore.id),
    }, {
      title: 'New signer',
    }];
  }
  return [{
    title: 'STORES',
    url: route(routes.list_dealer_stores),
  }, {
    title: dealerStore.name,
    url: route(routes.view_dealer_store, dealerStore.id),
  }, {
    title: 'New signer',
  }];
}

export default NewAny;
