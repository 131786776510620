/********** DATATABLE **********/

export const GET_STATEMENT_DT = 'GET_STATEMENT_DT'
export const GET_STATEMENT_DT_OK = 'GET_STATEMENT_DT_OK'
export const GET_STATEMENT_DT_ERR = 'GET_STATEMENT_DT_ERR'
export const DO_STATEMENT_DT_CLEANUP = 'DO_STATEMENT_DT_CLEANUP'

/********** DATATABLE FILTERS **********/

export const APPLY_STATEMENT_DT_FILTERS = 'APPLY_STATEMENT_DT_FILTERS'
export const CLEAR_STATEMENT_DT_FILTERS = 'CLEAR_STATEMENT_DT_FILTERS'