import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Alert, Table } from "reactstrap";
import { doOrderDocNormListCleanup, getESignOrderDocNormList } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import ESignDoc from "./Doc";

const ESignDocs = props => {

  const { order, orderIsLocked } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const orderDocIds = useSelector(state => state.OrderDoc.NormList.orderDocIds);
  const orderDocsError = useSelector(state => state.OrderDoc.NormList.orderDocsError);
  const isLoadInProgress = useSelector(state => state.OrderDoc.NormList.isLoadInProgress);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    refreshOrderDocs();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderDocNormListCleanup());
    }
  }, [order.id]);

  /********** OTHER **********/

  const refreshOrderDocs = () => dispatch(getESignOrderDocNormList(order.id));

  return <div className="order-docs-manager">
    <Table className="table order-docs-table mb-0 bb-0">
      <thead>
        <tr>
          <th>#</th>
          <th>Document name</th>
          <th>Status</th>
          <th>Uploaded by</th>
          <th>Upload date</th>
          <th>No of pages</th>
          <th>Signed date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {!!orderDocIds.length && <React.Fragment>
          {orderDocIds.map((docId, index) => <ESignDoc
            key={docId}
            id={docId}
            num={index + 1}
            order={order}
            refreshListHandler={refreshOrderDocs}
            orderIsLocked={orderIsLocked} />)}
        </React.Fragment>}
        {!isLoadInProgress && !orderDocsError && !orderDocIds.length && <tr>
          <td colSpan="8" className="text-center">No contracts uploaded</td>
        </tr>}
        {isLoadInProgress && <tr>
          <td colSpan="8">
            <SpinnerChase className="sm mb-4" />
          </td>
        </tr>}
        {orderDocsError && <tr>
          <td colSpan="8">
            <Alert color="danger" className="fade show text-center mb-0">
              <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load contracts
            </Alert>
          </td>
        </tr>}
      </tbody>
    </Table>
  </div>
}

ESignDocs.propTypes = {
  order: PropTypes.object.isRequired,
  orderIsLocked: PropTypes.bool,
};

export default ESignDocs;
