import React, { useState } from "react";
import PropTypes from 'prop-types';
import FormPaymentInfoStripe from './Stripe';
import FormPaymentInfoCheck from "./Check";
import { useSubscriptionSetup } from "context/subscriptionSetup";
import DealerStore from "model/dealerStore";

const FormPaymentInfo = props => {

  const { options, navRoutes, toggleEditMode } = props;

  // hook that gets the subscription info from context
  const { dealerStore } = useSubscriptionSetup();

  // temporarily block selecting Stripe payment methods for new stores
  const [isPayWithCheckSelected, setIsPayWithCheckSelected] = useState(DealerStore.isOfflinePaymentMethod(dealerStore.paymentMethodType));

  return <React.Fragment>
    {isPayWithCheckSelected && <FormPaymentInfoCheck navRoutes={navRoutes} toggleEditMode={toggleEditMode} togglePayWithCheck={setIsPayWithCheckSelected} />}
    {!isPayWithCheckSelected && <FormPaymentInfoStripe options={options} navRoutes={navRoutes} toggleEditMode={toggleEditMode} togglePayWithCheck={setIsPayWithCheckSelected} />}
  </React.Fragment>
}

FormPaymentInfo.propTypes = {
  options: PropTypes.object,
  navRoutes: PropTypes.object,
  toggleEditMode: PropTypes.func,
}

export default FormPaymentInfo;