import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Card, CardBody, CardHeader, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import FormSteps from "../Partial/Form/Steps";
import usePaymentSetupCheck from "hooks/subscription/paymentSetupCheck";
import useBillingCheck from "hooks/subscription/billingCheck";
import { route, routes } from "helpers/routeHelper";
import usePaymentMethodCheck from "hooks/subscription/paymentMethodCheck";
import ViewInfo from "../Partial/View/Info";
import ViewContact from "../Partial/View/Contact";
import ViewBilling from "../Partial/View/Billing";
import ViewPaymentInfo from "../Partial/View/PaymentInfo";
import ViewSubscriptionSummary from "../Partial/View/SubscriptionSummary";
import useFirstRender from "hooks/firstRender";
import { showError, showSuccess } from "helpers/utilHelper";
import withSubscriptionSetup from "hoc/subscriptionSetup";
import { useSubscriptionSetup } from "context/subscriptionSetup";
import { finishPaymentSetup } from "helpers/backendHelper";

const SubscriptionSetupOverview = () => {

  // check if payment setup is possible
  usePaymentSetupCheck();
  // check if billing has been set up
  useBillingCheck();
  // check if payment method has been set up
  usePaymentMethodCheck();

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // hook that gets the subscription info from context
  const { dealerStore, refreshSubscription, isPaymentSetupRequired } = useSubscriptionSetup();
  // hooks that helps determine if a component render is the first render or not
  const { isNotFirstRender } = useFirstRender();

  /********** STATE **********/

  const [isPaymentSetupFinishInProgress, setIsPaymentSetupFinishInProgress] = useState(false);
  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({});

  // flag signaling that a subscription context refresh is in progress
  const [isSubRefInProgress, setIsSubRefInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs when the subscription info from context changes
  // this happens twice:
  // 1. on the first render (we do not care about this case)
  // 2. when the user clicks FINISH and the subscription is created
  useEffect(() => {
    if (isNotFirstRender) {
      navigate(route(routes.view_subscription));
    }
  }, [dealerStore]);

  /********** OTHER **********/

  /**
   * Changes the in-progress flag and triggers a context refresh
   */
  const doSubscriptionRefresh = () => {
    setIsSubRefInProgress(true);
    refreshSubscription();
  }

  /**
   * Handler called when the user presses NEXT
   */
  const handleSubmit = () => {
    setIsPaymentSetupFinishInProgress(true);
    finishPaymentSetup()
      .then(response => {
        showSuccess('Payment setup complete');
        // since we have added new information to the dealer store (stripeSubscriptionId)
        // we have to reload the subscription context
        // else the new info will not be available in the next steps
        doSubscriptionRefresh();
      })
      .catch(ex => {
        showError('Unable to finish payment setup');
      })
      .finally(() => {
        setIsPaymentSetupFinishInProgress(false);
      });
  }

  /**
   * Returns TRUE if the submit button should show a loading icon
   * @returns bool
   */
  const isSubmitBusy = () => isPaymentSetupFinishInProgress || isSubRefInProgress;

  return <React.Fragment>
    {iAmGranted(perms.set_up_payment) && <div className="page-content">
      {!!dealerStore && <React.Fragment>
        <MetaTitle>Subscription - Store information</MetaTitle>
        <Container fluid className="subscription-wizard">
          <Breadcrumbs title="Manage Subscription" />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title pt-1 mb-0">Dealership: {dealerStore.name}</div>
                      <p className="mt-2 mb-4">Billed: <strong>Monthly</strong></p>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <FormSteps currentStep="overview" setNavRoutes={setNavRoutes} paymentPlan={dealerStore.paymentPlan} className="mb-4" />
                  <Row>
                    <Col xl="4">
                      <ViewInfo dealerStore={dealerStore} />
                    </Col>
                    <Col xl="4">
                      <ViewContact dealerStore={dealerStore} />
                    </Col>
                    <Col xl="4">
                      <ViewBilling dealerStore={dealerStore} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="4">
                      <ViewSubscriptionSummary dealerStore={dealerStore} />
                    </Col>
                    {!!dealerStore.paymentMethodType && <Col xl="4">
                      <ViewPaymentInfo dealerStore={dealerStore} />
                    </Col>}
                  </Row>
                  <Row className="mb-2">
                    <Col className="text-end">
                      {!isPaymentSetupRequired() && <Link to={route(routes.view_subscription)} className="btn btn-secondary btn-faded">Quit</Link>}
                      {navRoutes.prev && <Link to={route(navRoutes.prev)} className="btn btn-primary btn-faded ms-2">Previous</Link>}
                      <Button type="button" color="primary" className="ms-2" onClick={handleSubmit} disabled={isSubmitBusy()}>
                        {isSubmitBusy() && <i className="mdi mdi-spin mdi-loading me-1" />}
                        Finish
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
    </div>}
    {iAmNotGranted(perms.set_up_payment) && <AccessDenied />}
  </React.Fragment>
}

export default withSubscriptionSetup(SubscriptionSetupOverview);