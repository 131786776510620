import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Card, CardBody, CardHeader, Button, FormFeedback } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import FormSteps from "../Partial/Form/Steps";
import { route, routes } from "helpers/routeHelper";
import { formatPaymentPlanDescription, formatPaymentPlanPrice, formatPaymentPlanPriceHorizontal } from "helpers/jsxHelper";
import classnames from "classnames";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { cloneDeep } from 'lodash';
import * as Yup from "yup";
import { useFormik } from "formik";
import { nullsToEmptyStrings, showError, showSuccess } from "helpers/utilHelper";
import usePaymentSetupCheck from "hooks/subscription/paymentSetupCheck";
import useBillingCheck from "hooks/subscription/billingCheck";
import { ValidationException } from "helpers/errorHelper";
import useFirstRender from "hooks/firstRender";
import withSubscriptionSetup from "hoc/subscriptionSetup";
import { useSubscriptionSetup } from "context/subscriptionSetup";
import { selectPaymentPlan, getDealerStorePaymentPlans } from "helpers/backendHelper";
import emptyCircleIcon from 'assets/images/empty-circle.svg';
import checkedCircleIcon from 'assets/images/checked-circle.svg';
import blueCheckedCircleIcon from 'assets/images/blue-checked-circle.svg';
import Confirmation from "components/Shared/Confirmation";

const SubscriptionSetupPlan = () => {

  // check if payment setup is possible
  usePaymentSetupCheck();
  // check if billing has been set up
  useBillingCheck();

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // hook that gets the subscription info from context
  const { dealerStore, refreshSubscription, isPaymentSetupRequired } = useSubscriptionSetup();
  // hooks that helps determine if a component render is the first render or not
  const { isFirstRender, isNotFirstRender } = useFirstRender();

  /********** STATE **********/

  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({});

  // flag signaling that a subscription context refresh is in progress
  const [isSubRefInProgress, setIsSubRefInProgress] = useState(false);

  const [paymentPlans, setPaymentPlans] = useState([]);
  const [paymentPlansError, setPaymentPlansError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isChooseInProgress, setIsChooseInProgress] = useState(false);
  const [confirmationChangeVisible, setConfirmationChangeVisible] = useState({
    open: false,
    plan: null
  });

  /********** FORM CONFIG **********/

  const formInitialValues = {
    paymentPlanId: '',
    ...nullsToEmptyStrings({ paymentPlanId: dealerStore.paymentPlan.id }),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      paymentPlanId: Yup.number().required('Please select a payment plan'),
    }),
    onSubmit: values => {
      // check with the server if the payment plan can be chosen
      choosePaymentPlan(values);
    },
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make a call to bakend to fetch the list of payment plans
    getPaymentPlansList();
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      // the list is empty before plans are loaded
      // and we do not want to clear the form field yet (see below)
      return;
    }
    // check if the active plan (the one the store is currently on) is in the list of available plans
    // for whatever reasons, the plan a store used to have might no longer be available
    // say that a store was on a free trial plan, cancelled the subscription and is now trying to subscribe again
    // the free trial plan however will no longer be available for selection
    const currentPlanIsAvailable = paymentPlans.some(pp => pp.id == dealerStore.paymentPlan.id);
    if (!currentPlanIsAvailable) {
      // clear the default form field value if it is not a valid one
      formik.setFieldValue('paymentPlanId', '');
    }
  }, [paymentPlans]);

  // runs when the subscription info from context changes
  // this happens twice:
  // 1. on the first render (we do not care about this case)
  // 2. when the user clicks NEXT and the store payment plan is updated
  useEffect(() => {
    if (isNotFirstRender) {
      navigate(route(navRoutes.next));
    }
  }, [dealerStore]);

  /********** OTHER **********/

  const choosePaymentPlan = (values) => {
    setIsChooseInProgress(true);
    // make the initial remote call to get the user data
    selectPaymentPlan(values)
      .then(response => {
        const savedPlan = paymentPlans.find(pp => pp.id == formik.values.paymentPlanId);
        showSuccess(`Payment plan "${savedPlan.name}" has been selected`);
        // since we might have changed the dealer store info (paymentPlan)
        // we have to reload the subscription context
        // else the new info will not be available in the next steps
        doSubscriptionRefresh();
      })
      .catch(ex => {
        showError('Unable to select payment plan');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsChooseInProgress(false)
      })
  };

  const getPaymentPlansList = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getDealerStorePaymentPlans(dealerStore.id)
      .then(response => {
        setPaymentPlans(response.paymentPlans);
      })
      .catch(ex => {
        setPaymentPlansError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false)
      })
  };

  const getPaymentPlans = () => {
    // create a clone of the paymentPlans so we do not modify the original object
    const plans = cloneDeep(paymentPlans);
    // determine the index of the selected plan
    const selectedIndex = plans.findIndex(pp => pp.id == formik.values.paymentPlanId);
    // sometimes selectedIndex is not found due to formik.values not being initialized
    if (selectedIndex >= 0) {
      plans[selectedIndex].isSelected = true;
    }
    return plans;
  }

  const formatPaymentPlanBtnTxt = plan => {
    if (plan.isFree) {
      return 'Try it free';
    }
    return 'Buy Now';
  }

  const selectPlan = planId => {
    formik.setFieldValue('paymentPlanId', planId);
    const errors = formik.errors;
    delete errors.paymentPlanId;
    formik.setStatus(errors);
  }

  const getSelectedPlan = () => {
    if (!paymentPlans.length) {
      return dealerStore.paymentPlan;
    }
    const selected = paymentPlans.find(pp => pp.id == formik.values.paymentPlanId);
    if (!!selected) {
      return selected;
    }
    return null;
  }

  /**
   * Changes the in-progress flag and triggers a context refresh
   */
  const doSubscriptionRefresh = () => {
    setIsSubRefInProgress(true);
    refreshSubscription();
  }

  /**
   * Returns TRUE if the submit button should show a loading icon
   * @returns bool
   */
  const isSubmitBusy = () => formik.isSubmitting || isChooseInProgress || isSubRefInProgress;

  return <React.Fragment>
    {iAmGranted(perms.set_up_payment) && <div className="page-content">
      {!!paymentPlans.length && <React.Fragment>
        <MetaTitle>Subscription - Payment plan</MetaTitle>
        <Container fluid className="subscription-wizard">
          <Breadcrumbs title="Manage Subscription" />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title pt-1 mb-0">Dealership: {dealerStore.name}</div>
                      <p className="mt-2 mb-4">Billed: <strong>Monthly</strong></p>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <FormSteps currentStep="plan" setNavRoutes={setNavRoutes} paymentPlan={getSelectedPlan()} className="mb-4" />
                  <div className="payment-plan-selection-list mb-4">
                    {!!formik.errors.paymentPlanId && <FormFeedback type="invalid" className="d-block mb-3">{formik.errors.paymentPlanId}</FormFeedback>}
                    {getPaymentPlans().map(plan => {
                      return <div className={classnames({ 'payment-plan-selection-item': !plan.isSelected, 'payment-plan-selection-item-selected': plan.isSelected })} key={plan.id}>
                        <div className='payment-plan-selection-item-box'>
                          <div className={classnames({ 'payment-plan-item-header': !plan.isSelected, 'payment-plan-item-header-selected': plan.isSelected })}>
                            <h3 className={classnames("payment-plan-selection-item-name", { "text-white": plan.isSelected })}>{plan.name}</h3>
                            {
                              plan.isSelected
                                ?
                                <img src={checkedCircleIcon} />
                                :
                                <img src={emptyCircleIcon} />
                            }
                          </div>
                          <div className="payment-plan-card-info">
                            <div>
                              <h3 className="mt-4 mb-2 ms-4 payment-plan-selection-starts-from">Starts from </h3>
                              <h2 className="mt-0 ms-4 payment-plan-selection-item-price">{formatPaymentPlanPrice(plan)}</h2>
                              <div className="payment-plan-selection-item-description">
                                <ul>
                                  {formatPaymentPlanDescription(plan.description)}
                                </ul>
                              </div>
                            </div>
                            <div className="payment-plan-selection-actions">
                              <div className="payment-plan-selection-item-description mt-3">
                                <img className="me-3" src={blueCheckedCircleIcon} />
                                Remote transactions included: <span><strong>{plan.numOfFreeRemote}</strong></span>
                              </div>
                              <div className="payment-plan-selection-item-description mt-3">
                                <img className="me-3" src={blueCheckedCircleIcon} />
                                InStore transactions included: <span><strong>{plan.numOfFreeInstore}</strong></span>
                              </div>
                              <div className="w-100 p-3 mt-5 mb-3">
                                {
                                  !plan.isSelected &&
                                  <Button type="button" color="primary" className={classnames('btn-lg btn-primary w-100')} onClick={() => setConfirmationChangeVisible({ open: true, plan: plan })}>{formatPaymentPlanBtnTxt(plan)}</Button>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                    {!!formik.errors.paymentPlanId && <FormFeedback type="invalid" className="d-block mt-3">{formik.errors.paymentPlanId}</FormFeedback>}
                  </div>
                  <Row className="mb-2">
                    <Col className="text-end">
                      {!isPaymentSetupRequired() && <Link to={route(routes.view_subscription)} className="btn btn-secondary btn-faded">Quit</Link>}
                      {navRoutes.prev && <Link to={route(navRoutes.prev)} className="btn btn-primary btn-faded ms-2">Previous</Link>}
                      <Button type="button" color="primary" className="ms-2" onClick={formik.handleSubmit} disabled={isSubmitBusy()}>
                        {isSubmitBusy() && <i className="mdi mdi-spin mdi-loading me-1" />}
                        Next
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* Confirm Subscription Modal */}
        {confirmationChangeVisible.open && <Confirmation
          confirmBtnText="Confirm"
          onConfirm={() => {
            selectPlan(confirmationChangeVisible.plan.id);
            setConfirmationChangeVisible({ open: false, plan: null })
          }}
          style={{ backgroundColor: '#fff', width: "600px" }}
          onCancel={() => setConfirmationChangeVisible({ open: false, plan: null })}
          cancelBtnText="Cancel"
          reverseButtons={false}
          closeOnClickOutside={false}
        >
          <h3>Confirm Subscription Plan</h3>
          <div className="w-100 p-4">
            <div className="plan-confirmation-box">
              <div className="left-confirmation-box">
                Current Plan
              </div>
              <div className="right-confirmation-box">
                {
                  getPaymentPlans().find((plan) => plan.isSelected).name
                }
                <h2 className="mt-0 vertical-item-price">{formatPaymentPlanPriceHorizontal(getPaymentPlans().find((plan) => plan.isSelected))}</h2>
              </div>
            </div>
            <div className="plan-confirmation-box">
              <div className="left-confirmation-box">
                New Plan
              </div>
              <div className="right-confirmation-box">
                {confirmationChangeVisible.plan.name}
                <h2 className="mt-0 vertical-item-price">{formatPaymentPlanPriceHorizontal(confirmationChangeVisible.plan)}</h2>
              </div>
            </div>
          </div>
        </Confirmation>
        }
      </React.Fragment>}
      {isLoadInProgress && <Preloader className="inner" />}
      {paymentPlansError && <Error error={paymentPlansError} />}
    </div>
    }
    {iAmNotGranted(perms.set_up_payment) && <AccessDenied />}
  </React.Fragment >
}

export default withSubscriptionSetup(SubscriptionSetupPlan);