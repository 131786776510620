import React, { useRef } from "react";
import PropTypes from "prop-types";
import { InputGroup } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { timezoneTsToLocalDate, localDateToTimezoneTs } from "helpers/dateHelper";

const DatePicker = ({ onChange, invalid, value, minDate, enableTime, disabled, ...rest }) => {

  // Datepicker ref for opening it on button click
  const datePickerRef = useRef();

  // the date  we receive from flatpickr is an ISO date string in local time zone
  // we need to keep the values selected and set the user time zone
  const handleChange = ([date]) => {
    // if the date was erased by typing, the field will be set to null, so that the `required` validation can be triggered
    if (!date) return onChange(null);

    // calls received onChange with the selected date as unix
    onChange(localDateToTimezoneTs(new Date(date)));
  };

  // handler to open the picker from the calendar button
  const handleButtonOpen = () => {
    datePickerRef.current.flatpickr.open();
  };

  const dateFormat = rest.format || 'F j, Y';

  return <InputGroup className={classnames({ "is-invalid": invalid })}>
    <Flatpickr
      ref={datePickerRef}
      placeholder="Pick a date"
      options={{
        dateFormat,
        allowInput: true,
        minDate,
        enableTime,
      }}
      value={timezoneTsToLocalDate(value)} // we need to send the date to flatpickr in local timezone
      onChange={handleChange}
      className={classnames({ "is-invalid": invalid }, "form-control d-block flatpickr-input")}
      disabled={disabled}
      {...rest}
    />
    <div className="input-group-append">
      <button
        type="button"
        className={classnames({ "is-invalid": invalid }, "btn btn-outline-secondary datepicker-trigger")}
        onClick={handleButtonOpen}
      >
        <i
          className="fa fa-calendar"
          aria-hidden="true"
        />
      </button>
    </div>
  </InputGroup>
}

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  value: PropTypes.number,
  minDate: PropTypes.number,
  enableTime: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default DatePicker;