import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Button, CardTitle, Table } from "reactstrap";
import Col from "components/Shared/Col";
import { useSubscription } from 'context/subscription';
import transactionImg from "assets/images/transaction.svg";

const SectionRemainingTransactions = () => {

  const { dealerStore } = useSubscription();

  const hasPaymentMethod = () => !!dealerStore.paymentMethodType;

  const hasIncludedTransactions = () => !!dealerStore.paymentPlan?.numOfFreeRemote || !!dealerStore.paymentPlan?.numOfFreeInstore;

  return <React.Fragment>
    {hasPaymentMethod() && hasIncludedTransactions() && <Row className="subscription-transactions-included">
      <Col xs="1" className="d-flex align-items-center p-0">
        <img src={transactionImg} alt="" height="40" />
      </Col>
      <Col xs="10">
        <div className="transaction-label">{dealerStore.instoreTransactionsLeft} Remaining Instore Transactions Included</div>
        <div className="transaction-label">{dealerStore.remoteTransactionsLeft} Remaining Remote Transactions Included</div>
      </Col>
    </Row>}
  </React.Fragment>
}

export default SectionRemainingTransactions;
