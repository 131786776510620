import React from "react";
import PropTypes from "prop-types";
import Statement from "model/statement";

const StoreInformation = ({ statement }) => {
  const { dealerStoreName, dealerStoreAddress, dealerStoreCity, dealerStoreState, dealerStoreZip, dealerStoreContactName, dealerStoreContactEmail, dealerGroupName, dealerRegionName } = statement;

  const isNotaryStatement = statement.type === Statement.TYPE_DEALER_SINGLE_ORDER;

  return !!statement && <React.Fragment>
    <div className='mb-3'><strong>Store Information</strong></div>
    <div className='font-weight-600'>Store Name</div>
    <div className='mb-2'>{dealerStoreName || '--'}</div>

    <div className='font-weight-600'>Store Address</div>
    <div>{dealerStoreAddress}</div>
    <div className='mb-2'>{`${dealerStoreCity || '--'}, ${dealerStoreState || '--'} ${dealerStoreZip || '--'}`}</div>

    <div className='font-weight-600'>Contact Person</div>
    <div className='mb-2'>{dealerStoreContactName || '--'}</div>

    <div className='font-weight-600'>Email</div>
    <div className='mb-2'>{dealerStoreContactEmail || '--'}</div>

    {isNotaryStatement && <>
      <div className='font-weight-600'>Region</div>
      <div className='mb-2'>{dealerRegionName || '--'}</div>

      <div className='font-weight-600'>Group</div>
      <div className='mb-2'>{dealerGroupName || '--'}</div>
    </>}
  </React.Fragment>
}

StoreInformation.propTypes = {
  statement: PropTypes.object,
};

export default StoreInformation;