import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from "react-bootstrap-sweetalert";
import popUpIcon from "../../assets/images/icon-pop-up.png";

const Confirmation = props => {

  return <SweetAlert
    title=""
    custom
    showCancel
    cancelBtnText="Close"
    confirmBtnBsStyle="primary"
    confirmBtnCssClass="button-alert me-2"
    cancelBtnCssClass="button-alert ms-2"
    cancelBtnBsStyle="secondary"
    btnSize="default"
    customIcon={ popUpIcon }
    style={{ backgroundColor:'#CED4F2'}}
    reverseButtons={true}
    {...props}>
    {props.children}
  </SweetAlert>
}

Confirmation.propTypes = {
  children: PropTypes.any,
}

export default Confirmation;