import { combineReducers } from "redux";
import {
  APPLY_STATEMENT_DT_FILTERS,
  CLEAR_STATEMENT_DT_FILTERS,
  DO_STATEMENT_DT_CLEANUP,
  GET_STATEMENT_DT,
  GET_STATEMENT_DT_ERR,
  GET_STATEMENT_DT_OK,
} from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  statements: [],
  statementsError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 50,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_STATEMENT_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        statementsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_STATEMENT_DT_OK:
      state = {
        ...state,
        statements: action.payload.response.statements,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_STATEMENT_DT_ERR:
      state = {
        ...state,
        statements: [],
        totalCount: 0,
        statementsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_STATEMENT_DT_CLEANUP:
      state = {
        ...state,
        statements: [],
        invoicesError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

const defaultDtFiltersState = {
  id: '',
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_STATEMENT_DT_FILTERS:
      state = action.payload.filters;
      break
    case CLEAR_STATEMENT_DT_FILTERS:
      state = { ...defaultDtFiltersState };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
  DtFilters,
});