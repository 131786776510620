import React, { useEffect, useState } from "react";
import { Input, Label, Accordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { doOrderInkSignDocsCleanup, getOrderInkSignDocs } from "store/actions";

const InkSignForm = props => {

  const { orderId, onDocsSelectionChange } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  const [open, setOpen] = useState(false);
  const [checkAllInkSignDocs, setCheckAllInkSignDocs] = useState(true);
  const [selectedInkSignDocs, setSelectedInkSignDocs] = useState({});

  // get redux state from the store
  const orderDocs = useSelector(state => state.Order.InkSignDocs.docs);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    dispatch(getOrderInkSignDocs(orderId));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderInkSignDocsCleanup());
    }
  }, [orderId]);


  // Initialize selected docs when orderDocs change
  useEffect(() => {
    if (orderDocs) {
      const initialSelectedDocs = {};
      orderDocs.forEach(doc => {
        initialSelectedDocs[doc.id] = true;
      });
      setSelectedInkSignDocs(initialSelectedDocs);
      onDocsSelectionChange(initialSelectedDocs);
    }
  }, [orderDocs]);


  /********** OTHER **********/

  const getDocsAsArray = () => {
    if (!orderDocs) {
      return [];
    }
    return Object.entries(orderDocs);
  }

  const toggle = (id) => {
    setOpen(prevOpen => prevOpen === id ? false : id);
  };

  // Handle checkbox change for all documents
  const handleCheckAllDocs = (event) => {
    const checked = event.target.checked;
    const newSelectedDocs = {};
    if (checked) {
      orderDocs.forEach(doc => {
        newSelectedDocs[doc.id] = true;
      });
    }
    setSelectedInkSignDocs(newSelectedDocs);
    onDocsSelectionChange(newSelectedDocs);
    setCheckAllInkSignDocs(checked);
  };

  // Handle checkbox change for individual document
  const handleCheckDocItem = (id) => {
    setSelectedInkSignDocs(prevState => {
      const newSelectedDocs = { ...prevState, [id]: !prevState[id] };
      onDocsSelectionChange(newSelectedDocs);
      // Check if all documents are selected
      const allSelected = orderDocs.every(doc => newSelectedDocs[doc.id]);
      setCheckAllInkSignDocs(allSelected); // Update checkAllInkSignDocs based on allSelected
      return newSelectedDocs;
    });
  };

  // Check if all documents are selected
  useEffect(() => {
    if (orderDocs && Object.keys(selectedInkSignDocs).length > 0) {
      const allSelected = orderDocs.every(doc => selectedInkSignDocs[doc.id]);
      setCheckAllInkSignDocs(allSelected);
    }
  }, [orderDocs, selectedInkSignDocs]);

  return <React.Fragment>
    {!!orderDocs?.length &&
      <div className="collapse-container">
        <Accordion open={open} toggle={toggle}>
          <AccordionItem className="mt-1 mb-1">
            <AccordionHeader targetId="1">
              <div className="form-check">
                <Input
                  type="checkbox"
                  name="checkAllInksignDocs"
                  id={"checkAllInkSignDocs"}
                  className="form-check-input-lg me-2"
                  checked={checkAllInkSignDocs}
                  onChange={handleCheckAllDocs}
                />
                <Label className="checkbox-label-seal" htmlFor="checkAllInksignDocs">InkSign Documents</Label>
              </div>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              {getDocsAsArray().map(([index, doc]) =>
                <div className="accordion-body-element mx-3" key={index}>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      name={`checkInksignDocItem-${doc.id}`}
                      id={`checkInksignDocItem-${doc.id}`}
                      className="form-check-input-lg me-2"
                      checked={!!selectedInkSignDocs[doc.id]}
                      onChange={() => handleCheckDocItem(doc.id)}
                    />
                    <Label className="checkbox-label-seal" htmlFor={`checkInksignDocItem-${doc.id}`}>{doc.name}</Label>
                  </div>
                  <div>
                    <div className="badge badge-lg rounded-pill doc-item-badge">
                      {doc.numOfPages}
                      <i className="mdi mdi-file-multiple-outline ms-1" />
                    </div>
                  </div>
                </div>
              )}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
    }
  </React.Fragment>
}

InkSignForm.propTypes = {
  orderId: PropTypes.number.isRequired,
  onDocsSelectionChange: PropTypes.func.isRequired,
};

export default InkSignForm;
