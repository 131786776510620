import React from "react";
import { Container, Row, Card, CardBody, Alert } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";

const SubscriptionSetupForbidden = () => {

  return <div className="page-content">
    <MetaTitle>Subscription - Setup</MetaTitle>
    <Container fluid>
      <Breadcrumbs title="Manage Subscription" />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Alert color="danger" className="fade show text-center mb-0">
                <i className="mdi mdi-alert-circle-outline me-2"></i>Payment has not been set up for your store. Please contact your store manager
              </Alert>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
}

export default SubscriptionSetupForbidden;