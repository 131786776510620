import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, Label, Input, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { useFormik } from "formik";
import { toSelectOptions, hasNonEmpty, getYesNoOptions, extractDtFiltersFromUrl } from "helpers/utilHelper";
import { applyUserDtFilters, clearUserDtFilters, doUserRoleListCleanup, getUserRoleList, patchUserDtFilters, unsetUserDtFilters } from 'store/actions';
import UserRole from 'model/userRole';
import { isEmpty, omit } from 'lodash';

const FiltersOwnStore = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.User.DtFilters);
  const { roles, rolesError } = useSelector(state => state.UserRole.List);
  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // extract filters from url
    // they will be applied by default as the user enters the screen
    const filters = extractDtFiltersFromUrl();

    // if url filters are not empty
    if (!isEmpty(filters)) {
      // in this case, replace the old filters with the relevant ones
      applyFilters(filters);
    } else {
      // make sure to call this even if there are no filters in the url
      // because we need to switch the '_set' flag
      dispatch(patchUserDtFilters(filters));
    }

    // make the initial remote call to get the role filter options
    dispatch(getUserRoleList());
    return () => {
      // state cleanup on component unmount
      dispatch(doUserRoleListCleanup());
      // switch back the '_set' flag
      dispatch(unsetUserDtFilters());
    }
  }, []);

  // runs whenever 'rolesError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (rolesError) {
      // set an error on the form field
      formik.setFieldError('role', 'Unable to load roles');
    }
  }, [rolesError]);

  /********** EVENT HANDLERS **********/

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => dispatch(applyUserDtFilters(values));

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues(formik.initialValues);
    // reset state
    dispatch(clearUserDtFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(filters);

  /********** OTHER **********/

  const hasFilters = () => hasNonEmpty(omit(filters, "_set"));

  // adds 'All' to the list of roles
  // prepares the list to be used as select options
  const getRoleOptions = () => {
    const availableRoles = roles.filter(role => UserRole.isDealerType(role.id) && role.id > UserRole.ID_DEALER_REGIONAL_MANAGER);
    return [{ label: 'All', value: '' }, ...toSelectOptions(availableRoles)];
  }

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Name</Label>
            <Input type="text" className="form-control" name="name" onChange={formik.handleChange} value={formik.values.name} invalid={!!formik.errors.name} />
            {!!formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Email</Label>
            <Input type="text" className="form-control" name="email" onChange={formik.handleChange} value={formik.values.email} invalid={!!formik.errors.email} />
            {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Role</Label>
            <Select
              classNamePrefix="select2-selection"
              name="role"
              options={getRoleOptions()}
              onChange={selected => formik.setFieldValue('role', selected.value)}
              value={getRoleOptions().find(option => option.value === formik.values.role)}
              className={!!formik.errors.role && 'is-invalid'} />
            {!!formik.errors.role && <FormFeedback type="invalid">{formik.errors.role}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Active</Label>
            <Select
              classNamePrefix="select2-selection"
              name="isActive"
              options={getYesNoOptions(true)}
              onChange={selected => formik.setFieldValue('isActive', selected.value)}
              value={getYesNoOptions(true).find(option => option.value === formik.values.isActive)}
              className={!!formik.errors.isActive && 'is-invalid'} />
            {!!formik.errors.isActive && <FormFeedback type="invalid">{formik.errors.isActive}</FormFeedback>}
          </div>
          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default FiltersOwnStore;