import React, { useState } from 'react';
import MyProfile from './Section/MyProfile';
import Messages from './Section/Messages';
import { Row, Col } from 'reactstrap';
import Stats from './Section/Stats';
import Actions from './Section/ActionsFinanceManager';
import Carousel from './Section/ResponsiveCarousel';
import WIPOrders from './Section/WIPOrders';
import CustomerCall from './Form/CustomerCall';

const FinanceManager = () => {

  // show/hide the customer call form
  const [showCustomerCall, setShowCustomerCall] = useState(false);
  const toggleCustomerCall = () => setShowCustomerCall(current => !current);

  return <>
    <Row className="d-flex align-items-stretch mb-4">
      <Col xs={12} xl={4} className="mb-4 mb-xl-0"><MyProfile /></Col>
      <Col xs={12} xl={8} className="mb-4 mb-xl-0"><Messages /></Col>
    </Row>
    <Row className="d-flex align-items-stretch">
      <Stats />
    </Row>
    <Row className="d-flex align-items-stretch">
      <Col xs={12} xl={4}>{showCustomerCall ? <CustomerCall onClose={toggleCustomerCall} /> : <Actions onCallCustomer={toggleCustomerCall} />}</Col>
      <Col xs={12} xl={8}><Carousel /></Col>
    </Row>
    <Row>
      <Col xs={12} className="mb-4">
        <WIPOrders />
      </Col>
    </Row>
  </>
}

export default FinanceManager;
