import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Row, CardHeader, Button } from "reactstrap";
import Col from "components/Shared/Col";
import { perms, useAccess } from 'context/access';
import { route, routes } from 'helpers/routeHelper';
import { formatTimestamp, formats } from "helpers/dateHelper";
import Confirmation from "components/Shared/Confirmation";
import { showSuccess, showError, formatPhone } from "helpers/utilHelper";
import CardPreloader from 'components/Shared/CardPreloader';
import { deleteDealerStoreSigner } from "helpers/backendHelper";

const ViewInfo = props => {

  const { isRefreshing, id, signer, toggleEditMode } = props;

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  /********** EVENT HANDLERS **********/

  // delete-signer event handler
  // make the remote call to delete the signer
  const removeDealerStoreSigner = () => {
    setIsDeleteInProgress(true);
    deleteDealerStoreSigner(id)
      .then(response => {
        showSuccess(`Signer "${signer.fullName}" has been deleted`);
        navigate(route(routes.view_dealer_store, signer.dealerStoreId));
      })
      .catch(ex => {
        showError('Unable to delete signer');
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  };

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{signer.fullName}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_dealer_stores) && <Button type="button" color="primary" className="ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit signer
              </Button>}
              {iAmGranted(perms.edit_dealer_stores) && <Button type="button" color="danger" className="ms-2 mb-2" onClick={() => { setIsConfirmationVisible(true) }} disabled={isDeleteInProgress}>
                {isDeleteInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isDeleteInProgress && <i className="mdi mdi-delete me-1" />}
                Delete signer
              </Button>}
              <Link to={route(routes.view_dealer_store, signer.dealerStoreId)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="6" xl="4">
            <Row className="mb-3">
              <label className="mb-0">First name</label>
              <div>{signer.firstName}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Last name</label>
              <div>{signer.lastName}</div>
            </Row>
          </Col>
          <Col xs="6" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Email</label>
              <div>{signer.email}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Phone</label>
              <div>{formatPhone(signer.phone)}</div>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Created</h5>
            <p className="text-muted mb-0">{formatTimestamp(signer.createdTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Updated</h5>
            <p className="text-muted mb-0">{formatTimestamp(signer.updatedTs, formats.DATETIME)}</p>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsConfirmationVisible(false);
        removeDealerStoreSigner();
      }}
      reverseButtons={false}
      onCancel={() => setIsConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete signer &quot;{signer.firstName} {signer.lastName}&quot;?</span>
    </Confirmation>}
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  id: PropTypes.number,
  signer: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewInfo;
