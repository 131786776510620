import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_OK,
  GET_SUBSCRIPTION_ERR,
  INIT_PAYMENT_METHOD,
  INIT_PAYMENT_METHOD_OK,
  INIT_PAYMENT_METHOD_ERR,
  DO_INIT_PAYMENT_METHOD_CLEANUP,
} from './actionTypes';

/********** GET SUBSCRIPTION **********/

export const getSubscription = () => ({
  type: GET_SUBSCRIPTION,
});

export const getSubscriptionOk = response => ({
  type: GET_SUBSCRIPTION_OK,
  payload: { response },
});

export const getSubscriptionErr = error => ({
  type: GET_SUBSCRIPTION_ERR,
  payload: { error },
});

/********** COLLECT PAYMENT INFO **********/

export const initPaymentMethod = () => ({
  type: INIT_PAYMENT_METHOD,
});

export const initPaymentMethodOk = response => ({
  type: INIT_PAYMENT_METHOD_OK,
  payload: { response },
});

export const initPaymentMethodErr = error => ({
  type: INIT_PAYMENT_METHOD_ERR,
  payload: { error },
});

export const doInitPaymentMethodCleanup = () => ({
  type: DO_INIT_PAYMENT_METHOD_CLEANUP,
});