import React from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Card, CardBody } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Breadcrumbs from "components/Common/Breadcrumb2";
import AccessDenied from "pages/Error/AccessDenied";
import { useSelector } from "react-redux";
import { perms, useAccess } from "context/access";
import OrderCountReport from "./Partial/Section/OrderCount";
import DealerSalesOwnGroupFilters from "./Partial/DealerSalesOwnGroupFilters";
import DealerSalesOwnRegionFilters from "./Partial/DealerSalesOwnRegionFilters";
import DealerSalesOwnStoreFilters from "./Partial/DealerSalesOwnStoreFilters";

const DealerSalesReport = () => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  /*********** FILTERS *********/

  let FiltersComponent;
  if (iAmGranted(perms.view_dealer_groups)) {
    FiltersComponent = DealerSalesOwnGroupFilters;
  } else if (iAmGranted(perms.view_own_region_dealer_stores)) {
    FiltersComponent = DealerSalesOwnRegionFilters;
  } else {
    FiltersComponent = DealerSalesOwnStoreFilters;
  }

  /********** STATE **********/

  const filters = useSelector(state => state.Report.DealerSalesFilters);

  // range is ready if both start and end dates have been selected
  const isRangeSet = !!(filters.startTs && filters.endTs);

  return (
    <React.Fragment>
      {iAmGranted(perms.view_dealer_sales_reports) && (
        <div className="page-content">
          <MetaTitle>Dealer Sales Report</MetaTitle>
          <Container fluid>
            <Breadcrumbs title="DEALER SALES REPORT" />
            <Card>
              <CardBody>
                <FiltersComponent />
                {isRangeSet && <>
                  <OrderCountReport />
                </>}
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
      {iAmNotGranted(perms.view_dealer_sales_reports) && <AccessDenied />}
    </React.Fragment>
  )
}

export default DealerSalesReport;