/********** DATATABLE **********/

export const GET_CUSTOMER_CALL_DT = 'GET_CUSTOMER_CALL_DT'
export const GET_CUSTOMER_CALL_DT_OK = 'GET_CUSTOMER_CALL_DT_OK'
export const GET_CUSTOMER_CALL_DT_ERR = 'GET_CUSTOMER_CALL_DT_ERR'
export const DO_CUSTOMER_CALL_DT_CLEANUP = 'DO_CUSTOMER_CALL_DT_CLEANUP'

/********** DATATABLE FILTERS **********/

export const APPLY_CUSTOMER_CALL_DT_FILTERS = 'APPLY_CUSTOMER_CALL_DT_FILTERS'
export const CLEAR_CUSTOMER_CALL_DT_FILTERS = 'CLEAR_CUSTOMER_CALL_DT_FILTERS'