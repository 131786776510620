import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';
import DocViewerAddedField from './AddedField';
import DocViewerSignedField from './SignedField';
import { useDocViewer } from '../../Context';
import { mergeRefs } from 'helpers/utilHelper';

const DocViewerDropLayer = props => {

  /**
   * Component props:
   * pageNum {int} the number of this page
   */
  const { pageNum } = props;

  /**
   * Ref to the page dom element
   * Use to get page size in order to calculate dropped field position and size percentages
   */
  const pageRef = useRef(null);

  /**
   * Context vars (see: Context.js)
   */
  const { addedFields, updateField, selectedField, naturalPageListWidth, getNaturalSize, fieldIsSigned, getZoomedSize } = useDocViewer();

  /**
   * This hook wires in the component as a drop target
   * {@link https://react-dnd.github.io/react-dnd/docs/api/use-drop}
   */
  const [{ }, drop] = useDrop(() => ({
    // accepts dropping of a select few field types
    accept: ['order-doc-available-field', 'order-doc-added-field'],
    // event handler called when a field is dropped unto a page
    drop: (item, monitor) => {
      // get the position the field was dropped at
      const fieldPos = monitor.getSourceClientOffset();
      // get page size/position info
      const pageRect = pageRef.current.getBoundingClientRect();
      // add position info to the field
      item.leftPx = fieldPos.x;
      item.topPx = fieldPos.y;
      // pass to the next handler
      onDrop(item, pageRect);
    },
    collect: monitor => ({
      canDrop: monitor.canDrop(),
    }),
    // make sure to add as dependencies all vars/functions that 'onDrop' uses
    // so this hook gets recreated whenever they change
    // otherwise onDrop will get stale values
  }), [naturalPageListWidth, getNaturalSize]);

  /**
   * Event handler called when a field is dropped unto a page
   * Calculates position and size percentages
   * Updates the field with the new data
   * @param {object} field
   * @param {DOMRect} page
   * @param {boolean} byClick - TRUE if the field is added by click instead of drop
   */
  const onDrop = (field, page, byClick) => {
    const fieldLeftPx = parseInt(field.leftPx);
    const fieldTopPx = parseInt(field.topPx);
    const pageLeftPx = parseInt(page.left);
    const pageTopPx = parseInt(page.top);
    const relativeLeftPx = fieldLeftPx - pageLeftPx;
    let relativeTopPx = fieldTopPx - pageTopPx;
    if (byClick) {
      // when adding fields by click, we want the clicked spot to become the vertical center of the field
      // so we move the field up with half its size
      const zoomedFieldHeight = getZoomedSize(field.heightPx);
      relativeTopPx -= zoomedFieldHeight / 2;
    }
    const relativeLeftPc = relativeLeftPx / page.width * 100;
    const relativeTopPc = relativeTopPx / page.height * 100;
    // field.widthPx and field.heightPx are defined in the model
    // and are calculated for a zoom level of 100% (natural size)
    // be careful to calculate percentages relative to the natural width/height
    // else fields dropped while the content is zoomed will have wrong values
    const widthPc = field.widthPx / naturalPageListWidth * 100;
    const heightPc = field.heightPx / getNaturalSize(page.height) * 100;
    field = {
      ...field,
      leftPc: relativeLeftPc,
      topPc: relativeTopPc,
      widthPc: widthPc,
      heightPc: heightPc,
      page: pageNum,
    };
    updateField(field);
  }

  /**
   * Checks whether any field is selected or not
   * @returns {boolean}
   */
  const isFieldSelected = () => !!selectedField;

  /**
   * Event handler called when the user clicks on a page
   * Inserts a field at the respective position
   * @param {event} e 
   */
  const insertSelectedField = e => {
    // abort if no field is selected
    if (!isFieldSelected()) {
      return;
    }
    // do not allow dropping on top of another field
    if (e.target.closest('.doc-viewer-field') !== null) {
      return;
    }
    const pageRect = pageRef.current.getBoundingClientRect();
    const item = {
      ...selectedField,
      leftPx: e.clientX,
      topPx: e.clientY,
    }
    onDrop(item, pageRect, true);
  }

  return <div ref={mergeRefs(drop, pageRef)} className={classnames('doc-viewer-drop-target', { clickable: isFieldSelected() })} onClick={insertSelectedField}>
    {/* loop through all added fields */}
    {addedFields.map((field, i) => {
      // check if the field is intended for this page
      if (field.page == pageNum) {
        return <React.Fragment key={field.id}>
          {/* if the field is signed */}
          {fieldIsSigned(field) && <DocViewerSignedField field={field} />}
          {/* if the field is NOT signed */}
          {!fieldIsSigned(field) && <DocViewerAddedField field={field} pageRef={pageRef} />}
        </React.Fragment>
      }
    })}
  </div>
}

DocViewerDropLayer.propTypes = {
  pageNum: PropTypes.number,
}

export default DocViewerDropLayer;