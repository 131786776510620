import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Label, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";
import { hasNonEmpty, isNotAllOrNone, toSelectOptions } from "helpers/utilHelper";
import {
  applyStatementDtFilters,
  clearStatementDtFilters,
  doDealerRegionListCleanup,
  doDealerStoreListCleanup,
  getDealerGroupRegionList,
  getDealerGroupStoreList,
  getDealerRegionStoreList,
} from "store/actions";
import statementType from "constants/statementType";
import paymentMethod from "constants/paymentMethod";
import { useAuth } from "context/auth";

const paymentTypeOptions = toSelectOptions(statementType);
const paymentMethodOptions = toSelectOptions(paymentMethod);

const DataTableGroupFilters = () => {

  const dispatch = useDispatch();
  const { user: authUser } = useAuth();

  /********** STATE **********/

  const filters = useSelector(state => state.Statement.DtFilters);
  const { regions, regionsError } = useSelector(state => state.DealerRegion.List);
  const { stores, storesError } = useSelector(state => state.DealerStore.List);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getDealerGroupRegionList(authUser.dealerGroupId));
    return () => {
      // state cleanup on component unmount
      dispatch(doDealerRegionListCleanup());
      dispatch(doDealerStoreListCleanup());
    }
  }, [authUser.dealerGroupId]);

  useEffect(() => {
    if (regionsError) {
      // set an error on the form field
      formik.setFieldError("dealerRegionId", "Unable to load region");
    }
  }, [regionsError]);

  useEffect(() => {
    if (storesError) {
      // set an error on the form field
      formik.setFieldError("store", "Unable to load stores");
    }
  }, [storesError]);

  // runs whenever the region changes
  useEffect(() => {
    // clear the value store field
    //formik.setFieldValue('store', '');
    const regionId = formik.values.dealerRegionId;
    // if the selected region is not All or None
    if (isNotAllOrNone(regionId) && regionId != undefined) {
      // if region defined, search stores by region
      dispatch(getDealerRegionStoreList(regionId));
    } else if (regionId == '') {
      // if all regions selected, search stores by group
      dispatch(getDealerGroupStoreList(authUser.dealerGroupId));
    }
    else {
      dispatch(doDealerStoreListCleanup());
    }
  }, [formik.values.dealerRegionId]);

  /********** EVENT HANDLERS **********/

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => {
    dispatch(applyStatementDtFilters(values));
  }

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setFieldValue('dealerRegionId', '');
    formik.setFieldValue('dealerStoreId', '');
    formik.setValues(formik.initialValues);
    // reset state
    dispatch(clearStatementDtFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offCanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offCanvas
  const initLocalFilters = () => formik.setValues(filters);

  /********** OTHER **********/

  const hasFilters = () => hasNonEmpty(filters);

  // adds 'All' to the list of regions
  // prepares the list to be used as select options
  const getRegionOptions = () => [{ label: 'All', value: '' }, ...toSelectOptions(regions)];

  // prepares the list to be used as select options
  const getStoreOptions = () => [...toSelectOptions(stores)];

  const setStoresOptions = (storeList) => {
    const selectedValues = storeList.map((storeItem) => storeItem.value);
    formik.setFieldValue("dealerStoreId", selectedValues);
  }

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Type</Label>
            <Select
              classNamePrefix="select2-selection"
              name="type"
              options={paymentTypeOptions}
              onChange={selected => formik.setFieldValue("type", selected.value)}
              value={paymentTypeOptions.find(option => option.value === formik.values.type)}
            />
            {!!formik.errors.type && <FormFeedback type="invalid">{formik.errors.type}</FormFeedback>}
          </div>

          <div className="mb-3">
            <Label>Payment method</Label>
            <Select
              classNamePrefix="select2-selection"
              name="paymentMethod"
              options={paymentMethodOptions}
              onChange={selected => formik.setFieldValue("paymentMethod", selected.value)}
              value={paymentMethodOptions.find(option => option.value === formik.values.paymentMethod)}
            />
            {!!formik.errors.paymentMethod && <FormFeedback type="invalid">{formik.errors.paymentMethod}</FormFeedback>}
          </div>

          {regions.length > 0 &&
            <div className="mb-3">
              <Label>Region</Label>
              <Select
                key={formik.values.dealerRegionId || "select"}
                classNamePrefix="select2-selection"
                name="dealerRegionId"
                options={getRegionOptions()}
                onChange={selected => formik.setFieldValue('dealerRegionId', selected.value)}
                value={getRegionOptions().find(option => option.value === formik.values.dealerRegionId)}
                className={!!formik.errors.dealerRegionId && 'is-invalid'} />
              {!!formik.errors.dealerRegionId && <FormFeedback type="invalid">{formik.errors.dealerRegionId}</FormFeedback>}
            </div>}

          {regions.length > 0 && stores.length > 0 && <div className="mb-3">
            <Label>Store</Label>
            <Select
              key={formik.values.dealerStoreId || "select"}
              classNamePrefix="select2-selection"
              isMulti
              name="dealerStoreId"
              options={getStoreOptions()}
              onChange={selected => setStoresOptions(selected)}
              value={getStoreOptions().filter(option => formik.values.dealerStoreId ? formik.values.dealerStoreId.includes(option.value) : false)}
              className={!!formik.errors.dealerStoreId && 'is-invalid'} />
            {!!formik.errors.dealerStoreId && <FormFeedback type="invalid">{formik.errors.dealerStoreId}</FormFeedback>}
          </div>}

          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default DataTableGroupFilters;