import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Card, CardBody, Row, CardHeader, Table } from "reactstrap";
import Col from "components/Shared/Col";
import { formats, formatTimestamp } from 'helpers/dateHelper';
import classnames from "classnames";
import shield from 'assets/images/shield-blue.svg';
import Order from 'model/order';
import { route, routes } from 'helpers/routeHelper';

const SectionSealInfo = props => {

  const { order, isLocked } = props;

  const statusColorClass = {
    'text-success': order.sealStatus == Order.SEAL_STATUS_SEALED,
    'text-danger': order.sealStatus == Order.SEAL_STATUS_FAILED,
    'text-primary': order.sealStatus == Order.SEAL_STATUS_QUEUED,
  };

  const isReadyForSealing = () => order.status == Order.STATUS_COMPLETED;

  const isQueuedForSealing = () => order.sealStatus == Order.SEAL_STATUS_QUEUED;

  const isSealed = () => order.sealStatus == Order.SEAL_STATUS_SEALED;

  const sealFailed = () => order.sealStatus == Order.SEAL_STATUS_FAILED;

  const canViewSeal = () => isReadyForSealing() || isQueuedForSealing() || sealFailed() || isSealed();

  const canInitiateSeal = () => isReadyForSealing() && !isQueuedForSealing();

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Seal Order</div>
          </Col>
          {isLocked && <Col xs="auto" className="text-end">
            <img src={shield} className="mt-1" />
          </Col>}
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        <Table className="section-rows table bt-1">
          <tbody>
            <tr>
              <td className="section-row-label">Status</td>
              <td className={classnames('section-row-value text-uppercase', statusColorClass)}>{order.sealStatusName || 'Not initiated'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Sealed by</td>
              <td className="section-row-value">{order.sealerFullName || '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Date</td>
              <td className="section-row-value">{order.sealedTs ? formatTimestamp(order.sealedTs, formats.DATETIME) : '--'}</td>
            </tr>
          </tbody>
        </Table>
        <div className="text-end mt-4">
          {canViewSeal() && <Link to={route(routes.view_seal, order.id)} className="btn btn-primary">View Sealed Document<i className="mdi mdi-arrow-right ms-1"></i></Link>}
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionSealInfo.propTypes = {
  order: PropTypes.object,
  isLocked: PropTypes.bool,
};

export default SectionSealInfo;
