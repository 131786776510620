import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Card, CardBody, Row, CardHeader, Button, CardSubtitle } from "reactstrap";
import classnames from "classnames";
import Col from "components/Shared/Col";
import { perms, useAccess } from 'context/access';
import { route, routes } from 'helpers/routeHelper';
import { formatTimestamp, formats } from "helpers/dateHelper";
import CardPreloader from 'components/Shared/CardPreloader';

const ViewInfo = props => {

  const { isRefreshing, dealerStore, toggleEditMode, id } = props;

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className={classnames(dealerStore.isActive ? 'bg-transparent' : 'bg-warning bg-soft', 'pt-3')}>
        <Row>
          <Col>
            <h3>{dealerStore.name}</h3>
            <CardSubtitle className="text-muted">{dealerStore.isActive ? 'Active' : 'Inactive'}</CardSubtitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_dealer_stores) && <Button type="button" color="primary" className="mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit
              </Button>}
              {iAmNotGranted(perms.view_own_dealer_store) && <Link to={route(routes.list_dealer_stores)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          {iAmGranted(perms.view_own_group_dealer_stores) && <Col xs="12">
            <Row className="mb-3">
              <label className="mb-0">Region</label>
              <div>{dealerStore.dealerRegionName ? <Link to={route(routes.view_dealer_region, dealerStore.dealerRegionId)}>{dealerStore.dealerRegionName}</Link> : '--'}</div>
            </Row>
          </Col>}
          <Col xs="6" xl="4">
            <Row className="mb-3">
              <label className="mb-0">General manager</label>
              <div>{dealerStore.generalManagerName || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">General manager email</label>
              <div>{dealerStore.generalManagerEmail || '--'}</div>
            </Row>
          </Col>
          <Col xs="6" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Sales manager</label>
              <div>{dealerStore.salesManagerName || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Sales manager email</label>
              <div>{dealerStore.salesManagerEmail || '--'}</div>
            </Row>
          </Col>
          <Col xs="6" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Pre-owned manager</label>
              <div>{dealerStore.preoManagerName || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Pre-owned manager email</label>
              <div>{dealerStore.preoManagerEmail || '--'}</div>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Created</h5>
            <p className="text-muted mb-0">{formatTimestamp(dealerStore.createdTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Updated</h5>
            <p className="text-muted mb-0">{formatTimestamp(dealerStore.updatedTs, formats.DATETIME)}</p>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  id: PropTypes.number,
  dealerStore: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewInfo;
