import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { AccordionItem, AccordionHeader, AccordionBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import OrderDoc from "model/orderDoc";
import { getBeUrl, showError, showSuccess } from "helpers/utilHelper";
import { useSocketOn, useSubscribeToOrderDoc } from "hooks/socket";
import socketEvent from 'constants/socketEvent';
import { acceptInkSignOrderDocNormListItem, deleteOrderDocNormListItem, getOrderDocNormListItem, rejectInkSignOrderDocNormListItem, reprocessOrderDocNormListItem } from "store/actions";
import 'photoswipe/dist/photoswipe.css';
import { Gallery } from 'react-photoswipe-gallery';
import { formats, formatTimestamp } from "helpers/dateHelper";
import pdfIcon from 'assets/images/pdf-file.svg';
import notarizedIcon from 'assets/images/notarized-badge.svg';
import Confirmation from "components/Shared/Confirmation";
import InkSignPage from "./Page";
import InkSignPageContext from "./PageContext";
import { perms, useAccess } from "context/access";
import Order from "model/order";
import InkSignRejectModal from "./RejectModal";
import InkSignAddPageBtn from "./AddPageBtn";

const InkSignDoc = props => {

  const { id, num, order, refreshListHandler, orderIsLocked } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const orderDoc = useSelector(state => state.OrderDoc.NormList.orderDocs[id]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isDeleteConfVisible, setIsDeleteConfVisible] = useState(false);
  const [isDocError, setIsDocError] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isAcceptConfVisible, setIsAcceptConfVisible] = useState(false);
  const [isRejectConfVisible, setIsRejectConfVisible] = useState(false);

  /********** EFFECTS **********/

  // runs whenever order doc is changed
  useEffect(() => {
    // notary additional documents have 0 number of pages (no limit)
    // we need the actual number, in order to display pages
    setNumberOfPages(orderDoc.numOfPages ? orderDoc.numOfPages : orderDoc.pages ? Object.keys(orderDoc.pages).length : 0);
  }, [orderDoc]);

  // runs whenever an order doc is deleted
  useEffect(() => {
    if (orderDoc.isDeleted === true) {
      showSuccess(`Document "${orderDoc.name}" has been deleted`);
      refreshListHandler();
    } else if (orderDoc.isDeleted === false) {
      showError('Unable to delete document');
    }
  }, [orderDoc.isDeleted]);

  // runs whenever an order doc is accepted
  useEffect(() => {
    if (orderDoc.isAccepted === true) {
      showSuccess(`Document "${orderDoc.name}" has been accepted`);
      refreshListHandler();
    } else if (orderDoc.isAccepted === false) {
      showError('Unable to accept document');
    }
  }, [orderDoc.isAccepted]);

  /********** OTHER **********/

  const refreshOrderDoc = useCallback(() => dispatch(getOrderDocNormListItem(orderDoc.id)), [orderDoc.id]);

  /********** SOCKET **********/

  // start receiving updates about this particular document
  useSubscribeToOrderDoc(orderDoc.id);

  const onOrderDocChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple documents
    // therefore we need to check whether this update refers to the right document
    if (data.id == orderDoc.id) {
      refreshOrderDoc();
    }
  }, [orderDoc.id, refreshOrderDoc]);

  // listen for changes on order documents
  useSocketOn(socketEvent.orderDocChanged, onOrderDocChanged);

  /********** EVENT HANDLERS **********/

  const removeOrderDoc = () => dispatch(deleteOrderDocNormListItem(orderDoc.id));

  const restartProcessing = () => dispatch(reprocessOrderDocNormListItem(orderDoc.id));

  const acceptOrderDoc = () => dispatch(acceptInkSignOrderDocNormListItem(orderDoc.id));

  const rejectOrderDoc = reason => dispatch(rejectInkSignOrderDocNormListItem(orderDoc.id, { reason }));

  /********** OTHER **********/

  const onGalleryOpen = pswp => {
    // disable gallery focus trapping
    // so the user can type in the reject reason modal
    setTimeout(function () {
      const ev = pswp.events._pool.find(e => e.type == 'focusin');
      if (ev) {
        pswp.events.remove(ev.target, ev.type, ev.listener, ev.passive);
      }
    }, 1000);

    // register a hidden button that will help us to refresh current slide when the state changes
    // as React effects don't work on the rendered preview modal
    pswp.ui.registerElement({
      name: 'refreshSlide',
      className: 'd-none',
      order: 9,
      isButton: true,
      onInit: el => {
        el.id = 'refresh-slide';
      },
      onClick: () => {
        pswp.refreshSlideContent(pswp.currSlide.index);
      }
    });
  }

  const isReady = () => orderDoc.status > OrderDoc.STATUS_PENDING_INITIAL_PROCESSING;

  const isAdditionalDoc = () => orderDoc.numOfPages == 0;

  const isPendingReview = () => orderDoc.status == OrderDoc.STATUS_PENDING_DEALER_REVIEW;

  const canBeDeleted = () => iAmGranted(perms.edit_orders) && !orderIsLocked && !orderDoc.isStdDoc;

  const canBeReviewed = () => iAmGranted(perms.edit_orders) && isPendingReview();

  const canBeDownloaded = () => orderDoc.status === OrderDoc.STATUS_COMPLETE && !isDocError;

  const canBeViewed = () => (orderDoc.status === OrderDoc.STATUS_COMPLETE || orderDoc.isCapturedAsPdf || (order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD && !orderDoc.isStdDoc)) && !isDocError;

  const canAddPages = () => iAmGranted(perms.edit_orders) && orderDoc.status != OrderDoc.STATUS_COMPLETE && !orderDoc.isStdDoc && !isAdditionalDoc();

  const canBeReprocessed = () => iAmGranted(perms.edit_orders) && isProcessingFailed();

  const isProcessingFailed = () => orderDoc.isProcessingFailed;

  const hasError = () => orderDoc.conflictsWithDeliveryOption;

  return <React.Fragment>
    {orderDoc && <AccordionItem className={classnames('mb-2', { deleting: orderDoc.isDeleteInProgress })}>
      <AccordionHeader targetId={'doc' + orderDoc.id} className={classnames('bg-soft', `bg-${OrderDoc.getStatusColor(orderDoc.status)}`, { 'bg-danger': hasError() })}>
        <div className="doc-list-col doc-no" title={'Id: ' + orderDoc.id}>{num}</div>
        <div className="doc-list-col doc-name">
          <img src={pdfIcon} className="ms-n1 me-1" />
          {orderDoc.name}
          {!!orderDoc.isNotarizationRequired && <span className="notarized-badge ms-3">
            to be notarized <img src={notarizedIcon} className='ms-2' alt='notarized-icon' />
          </span>}
          {orderDoc.isCapturedAsPdf && <i className="mdi mdi-file-pdf-outline ms-2 captured-as-pdf-icon" title="Document was captured as pdf"></i>}
          {hasError() && <React.Fragment>
            <i className="mdi mdi-alert-circle ms-2 text-danger" id={'doc-err-' + orderDoc.id} />
            <UncontrolledTooltip placement="top" target={'doc-err-' + orderDoc.id}>This document is incompatible with the selected delivery option. Please remove or re-upload!</UncontrolledTooltip>
          </React.Fragment>}
        </div>
        <div className="doc-list-col doc-status">
          <span className={`badge badge-lg rounded-pill ps-3 pe-3 bg-${OrderDoc.getStatusColor(orderDoc.status)}`}>{orderDoc.statusName}</span>
        </div>
        <div className="doc-list-col doc-up-by">{orderDoc.creatorFullName}</div>
        <div className="doc-list-col doc-up-date">{formatTimestamp(orderDoc.createdTs, formats.US_DATE)}</div>
        <div className="doc-list-col doc-pages">{numberOfPages}</div>
        <div className="doc-list-col doc-sign-date">{orderDoc.signedTs ? formatTimestamp(orderDoc.signedTs, formats.US_DATE) : '--'}</div>
        <div className="doc-list-col doc-actions">
          {/* we need e.stopPropagation() to prevent toggling the accordion item when toggling the dropdown menu */}
          <Dropdown isOpen={menuIsOpen} toggle={e => { e.stopPropagation(); setMenuIsOpen(!menuIsOpen) }} className="btn-group">
            {/* we need tag="a" to prevent this error: Warning: validateDOMNesting(...): <button> cannot appear as a descendant of <button> */}
            <DropdownToggle tag="a" className="btn btn-default dropdown-toggle">
              {(orderDoc.isDeleteInProgress || orderDoc.isAcceptInProgress) && <i className="bx bx-loader-alt bx-spin" />}
              {!(orderDoc.isDeleteInProgress || orderDoc.isAcceptInProgress) && <i className="bx bx-dots-horizontal-rounded" />}
            </DropdownToggle>
            <DropdownMenu end>
              {orderDoc.isWithdrawn ? <React.Fragment>
                {/* Preview/download links of order documents become unavailable if isWithdrawn is true */}
                {canBeDownloaded() && <><DropdownItem tag="span" id='download-doc'>Download</DropdownItem><UncontrolledTooltip placement="top" target="download-doc">Document no longer available.</UncontrolledTooltip></>}
                {isReady() && canBeViewed() && <><DropdownItem tag="span" id="view-doc">View</DropdownItem><UncontrolledTooltip placement="top" target="view-doc">Document no longer available.</UncontrolledTooltip></>}
              </React.Fragment> :
                <React.Fragment>
                  {canBeDownloaded() && <DropdownItem tag="a" href={getBeUrl(`order-doc/${orderDoc.id}/pdf/download`)} target="_blank">Download</DropdownItem>}
                  {isReady() && canBeViewed() && <DropdownItem tag="a" href={getBeUrl(`order-doc/${orderDoc.id}/pdf`)} target="_blank">View</DropdownItem>}
                </React.Fragment>}
              {/* we need tag="a" to prevent this error: Warning: validateDOMNesting(...): <button> cannot appear as a descendant of <button> */}
              {canBeDeleted() && <DropdownItem tag="a" onClick={() => setIsDeleteConfVisible(true)} disabled={orderDoc.isDeleteInProgress}>Delete</DropdownItem>}
              {canBeReviewed() && <DropdownItem tag="a" onClick={() => setIsAcceptConfVisible(true)} disabled={orderDoc.isAcceptInProgress}>Accept Document</DropdownItem>}
              {canBeReviewed() && <DropdownItem tag="a" onClick={() => setIsRejectConfVisible(true)} disabled={orderDoc.isRejectInProgress}>Reject Document</DropdownItem>}
              {canBeReprocessed() && <DropdownItem tag="a" onClick={() => restartProcessing()} disabled={orderDoc.isReprocessInProgress}>Reprocess</DropdownItem>}
            </DropdownMenu>
          </Dropdown>
        </div>
      </AccordionHeader>
      <AccordionBody accordionId={'doc' + orderDoc.id} className={classnames('bg-soft mt-2', `bg-${OrderDoc.getStatusColor(orderDoc.status)}`)}>
        {!orderDoc.isCapturedAsPdf && <div className="ink-sign-pages pt-2">
          <Gallery options={{ mainClass: 'ink-sign-gallery' }} onOpen={onGalleryOpen}>
            {[...Array(numberOfPages).keys()].map(n => <InkSignPageContext key={n} docId={orderDoc.id} pageNum={n + 1} refreshDocHandler={refreshOrderDoc}>
              <InkSignPage order={order} onImageError={() => setIsDocError(true)} />
            </InkSignPageContext>)}
            {canAddPages() && <InkSignAddPageBtn docId={orderDoc.id} />}
          </Gallery>
        </div>}
      </AccordionBody>
      {isDeleteConfVisible && <Confirmation
        confirmBtnText="Delete"
        onConfirm={() => {
          setIsDeleteConfVisible(false);
          removeOrderDoc();
        }}
        reverseButtons={false}
        onCancel={() => setIsDeleteConfVisible(false)}>
        <span style={{ color: '#556EE6' }}>Are you sure you want to delete document &quot;{orderDoc.name}&quot;?</span>
      </Confirmation>}
      {isAcceptConfVisible && <Confirmation
        confirmBtnText="Accept"
        onConfirm={() => {
          setIsAcceptConfVisible(false);
          acceptOrderDoc();
        }}
        reverseButtons={false}
        onCancel={() => setIsAcceptConfVisible(false)}>
        <span style={{ color: '#556EE6' }}>Are you sure you want to accept document &quot;{orderDoc.name}&quot;?</span>
      </Confirmation>}
      <InkSignRejectModal isOpen={isRejectConfVisible} onCancel={() => setIsRejectConfVisible(false)} onReject={reason => {
        rejectOrderDoc(reason);
        setIsRejectConfVisible(false);
      }} mode="doc" order={order} orderDoc={orderDoc} />
    </AccordionItem>}
  </React.Fragment>
}

InkSignDoc.propTypes = {
  id: PropTypes.number.isRequired,
  num: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  refreshListHandler: PropTypes.func.isRequired,
  orderIsLocked: PropTypes.bool,
};

export default InkSignDoc;
