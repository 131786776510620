import { takeEvery, put, call } from "redux-saga/effects";

import {
  getUserLeaderboardOk,
  getStoreLeaderboardOk,
  getLeaderboardErr,

  getUserLeaderboardListOk,
  getLeaderboardListErr,
  getStoreLeaderboardListOk,

  getOrderStatsOk,
  getOrderStatsErr,

  getCustomerPerformanceOk,
  getCustomerPerformanceErr,

  getTimeToCompletionOk,
  getTimeToCompletionErr,
} from "./actions";

import {
  getOrderStats,
  getCustomerPerformance,
  getUserLeaderboard,
  getStoreLeaderboard,
  getTimeToCompletion
} from "helpers/backendHelper";

import {
  GET_ORDERS_STATS,
  GET_CUSTOMER_PERFORMANCE,
  GET_STORE_LEADERBOARD,
  GET_STORE_LEADERBOARD_LIST,
  GET_TIME_TO_COMPLETION,
  GET_USER_LEADERBOARD,
  GET_USER_LEADERBOARD_LIST
} from "./actionTypes";

/********** STATS **********/

function* onGetOrderStats() {
  try {
    const response = yield call(getOrderStats);
    yield put(getOrderStatsOk(response));
  } catch (error) {
    yield put(getOrderStatsErr(error));
  }
}

/********** LEADERBOARD **********/

function* onGetUserLeaderboard({ payload: { params } }) {
  try {
    const response = yield call(getUserLeaderboard, params);
    yield put(getUserLeaderboardOk(response));
  } catch (error) {
    yield put(getLeaderboardErr(error));
  }
}

function* onGetStoreLeaderboard({ payload: { params } }) {
  try {
    const response = yield call(getStoreLeaderboard, params);
    yield put(getStoreLeaderboardOk(response));
  } catch (error) {
    yield put(getLeaderboardErr(error));
  }
}

/********** LEADERBOARD LIST **********/

function* onGetUserLeaderboardList({ payload: { params } }) {
  try {
    const response = yield call(getUserLeaderboard, params);
    yield put(getUserLeaderboardListOk(response));
  } catch (error) {
    yield put(getLeaderboardListErr(error));
  }
}

function* onGetStoreLeaderboardList({ payload: { params } }) {
  try {
    const response = yield call(getStoreLeaderboard, params);
    yield put(getStoreLeaderboardListOk(response));
  } catch (error) {
    yield put(getLeaderboardListErr(error));
  }
}

/********** CUSTOMER PERFORMANCE **********/

function* onGetCustomerPerformance({ payload: { unit, customerLocation } }) {
  try {
    const response = yield call(getCustomerPerformance, unit, customerLocation);
    yield put(getCustomerPerformanceOk(response));
  } catch (error) {
    yield put(getCustomerPerformanceErr(error));
  }
}

/********** TIME TO COMPLETION **********/

function* onGetTimeToCompletion({ payload: { unit, params } }) {
  try {
    const response = yield call(getTimeToCompletion, unit, params);
    yield put(getTimeToCompletionOk(response));
  } catch (error) {
    yield put(getTimeToCompletionErr(error));
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_ORDERS_STATS, onGetOrderStats);
  yield takeEvery(GET_USER_LEADERBOARD, onGetUserLeaderboard);
  yield takeEvery(GET_STORE_LEADERBOARD, onGetStoreLeaderboard);
  yield takeEvery(GET_USER_LEADERBOARD_LIST, onGetUserLeaderboardList);
  yield takeEvery(GET_STORE_LEADERBOARD_LIST, onGetStoreLeaderboardList);
  yield takeEvery(GET_CUSTOMER_PERFORMANCE, onGetCustomerPerformance);
  yield takeEvery(GET_TIME_TO_COMPLETION, onGetTimeToCompletion);
}

export default dashboardSaga;