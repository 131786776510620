import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Row, Form, Label, Input, FormFeedback, Button, Card, CardHeader, CardBody } from "reactstrap";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import classnames from "classnames";
import * as Yup from "yup";
import { useFormik, FormikProvider, FieldArray } from "formik";
import Col from "components/Shared/Col";
import regx from "constants/regx";
import { capitalize, getAddressComponents, nullsToEmptyStrings, phoneHasNoOfDigits, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import Select from "react-select";
import UsStates from "constants/usState";
import { updateOrderCustomer } from "helpers/backendHelper";


const FormEditCustomer = props => {

  const { defaultValues, finishedHandler } = props;

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  // flags that keep track of whether certain location fields need to be readonly
  // generally the user should only edit the 'address' field
  // the other location fields (city, state, zip) will be auto-filled with values returned by the Google Places api
  // however occasionally the Google Places api does not return values for all fields
  // in which case we want to allow the user to manually edit the missing fields
  const [cityIsReadonly, setCityIsReadonly] = useState(true);
  const [stateIsReadonly, setStateIsReadonly] = useState(true);
  const [zipIsReadonly, setZipIsReadonly] = useState(true);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    signers: [],
    ...nullsToEmptyStrings(defaultValues, true),
  };

  let signerValidationSchema = {
    firstName: Yup.string().trim().required('Field is required'),
    lastName: Yup.string().trim().required('Field is required'),
    email: Yup.string().trim().required('Field is required').email('Invalid email address'),
    phone: Yup.string().trim().required('Field is required').matches(regx.phone, 'Invalid phone number').test('phone',
      'Field requires exactly 10 digits',
      ((value) => phoneHasNoOfDigits(value))
    ),
  };
  if (defaultValues.isNotaryRequired) {
    signerValidationSchema = {
      ...signerValidationSchema,
      address: Yup.string().trim().required('Field is required'),
      city: Yup.string().when([], {
        is: () => !cityIsReadonly,
        then: Yup.string().trim().required('Field is required'),
      }),
      state: Yup.string().when([], {
        is: () => !stateIsReadonly,
        then: Yup.string().trim().required('Field is required'),
      }),
      zip: Yup.string().when([], {
        is: () => !zipIsReadonly,
        then: Yup.string().trim().required('Field is required'),
      }),
      // throw an error if the 'address' field has a value but the 'latitude' field does not
      // this means that the user did not select from the autocomplete options but rather entered a custom address
      latitude: Yup.string().when('address', {
        is: val => !!val,
        then: Yup.string().required('Unable to detect geo-location. Please select one of the suggested addresses'),
      }),
      // throw an error if the 'latitude' field has a value but the 'longitude' field does not
      // this is an anomaly and should not happen
      longitude: Yup.string().when('latitude', {
        is: val => !!val,
        then: Yup.string().required('Unable to detect geo-location. Please select one of the suggested addresses'),
      }),
    };
  }

  const formValidationSchema = {
    signers: Yup.array().of(
      Yup.object().shape(signerValidationSchema)
    ).min(1, 'Please add at least one signer'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(formValidationSchema),
    onSubmit: values => saveOrderCustomer(values, props.id),
  });

  /********** EVENT HANDLERS **********/

  // on change event handler that capitalizes user input
  const capitalizeTextOnChange = event => {
    const { name, id } = event.target;
    formik.setFieldValue(name || id, capitalize(event.target.value))
  };

  // focus event handler
  // used to clear field errors
  const onArrayFieldFocused = (arrayName, index, fieldName) => {
    const errors = formik.errors;
    if (!!errors[arrayName] && !!errors[arrayName][index]) {
      delete errors[arrayName][index][fieldName];
      if (fieldName === 'address') {
        // multiple location errors are linked to the 'address' field
        // so clear any location errors when the 'address' field is focused
        delete errors[arrayName][index].city;
        delete errors[arrayName][index].state;
        delete errors[arrayName][index].zip;
        delete errors[arrayName][index].latitude;
        delete errors[arrayName][index].longitude;
      }
      formik.setStatus(errors);
    }
  }

  // adds or removes the add signer fields
  const toggleAddSigner = (e, arrayHelpers) => {
    if (e.target.checked) {
      arrayHelpers.push(getEmptySigner());
    } else {
      arrayHelpers.remove(1);
    }
  }

  const fillInAddress = index => place => {
    const addressComponents = getAddressComponents(place);
    formik.setFieldValue(`signers.${index}.zip`, addressComponents.zip);
    formik.setFieldValue(`signers.${index}.city`, addressComponents.city);
    formik.setFieldValue(`signers.${index}.state`, addressComponents.state);
    formik.setFieldValue(`signers.${index}.address`, addressComponents.address);
    formik.setFieldValue(`signers.${index}.latitude`, place.geometry.location.lat());
    formik.setFieldValue(`signers.${index}.longitude`, place.geometry.location.lng());
    // when Google Places returns values for these fields, the user must not be allowed to edit
    // else the user must be able to add values manually
    setCityIsReadonly(!!addressComponents.city);
    setStateIsReadonly(!!addressComponents.state);
    setZipIsReadonly(!!addressComponents.zip);
  }

  const onAddressTextChanged = (index, value) => {
    // this function is called when the user types in the address field
    // which he should do only to search and not to write the actual address
    // addresses must be selected only from the autocomplete options
    // because only then we are able to extract the geo-coordinates
    // so here we invalidate any previous geo-coordinates as the user types
    // later, when he selects an address, the new geo-coordinates will be used
    formik.setFieldValue(`signers.${index}.address`, value);
    formik.setFieldValue(`signers.${index}.latitude`, '');
    formik.setFieldValue(`signers.${index}.longitude`, '');
  }

  /********** OTHER **********/

  // returns an empty signer object
  const getEmptySigner = () => ({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    latitude: '',
    longitude: '',
    address2: ''
  });

  const saveOrderCustomer = (values, id) => {
    setIsSaveInProgress(true);
    updateOrderCustomer(values, id)
      .then(response => {
        showSuccess(`Customer information has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save customer information');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  };

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Customer Information</div>
          </Col>
          <Col xs="auto" className="text-end">
            <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2" onClick={() => finishedHandler()}>
              <i className="mdi mdi-chevron-left me-1" />Cancel
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        <Form className="pt-4">
          <FormikProvider value={formik}>
            <FieldArray name="signers" render={arrayHelpers => <React.Fragment>
              {formik.values.signers.map((signer, index) => <div className={classnames('card-section', { 'blue pt-3 pb-1': index == 1 })} key={index}>
                <Row className="mb-4">
                  <Col xl="6">
                    <Label>{index == 1 ? 'First name of additional signer' : 'First name'} *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`signers.${index}.firstName`}
                      onChange={capitalizeTextOnChange}
                      onFocus={() => onArrayFieldFocused('signers', index, 'firstName')}
                      value={formik.values.signers[index]?.firstName}
                      invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.firstName}
                    />
                    {!!formik.errors.signers && !!formik.errors.signers[index]?.firstName && <FormFeedback type="invalid">{formik.errors.signers[index].firstName}</FormFeedback>}
                  </Col>
                  <Col xl="6">
                    <Label>{index == 1 ? 'Last name of additional signer' : 'Last name'} *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`signers.${index}.lastName`}
                      onChange={capitalizeTextOnChange}
                      onFocus={() => onArrayFieldFocused('signers', index, 'lastName')}
                      value={formik.values.signers[index]?.lastName}
                      invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.lastName}
                    />
                    {!!formik.errors.signers && !!formik.errors.signers[index]?.lastName && <FormFeedback type="invalid">{formik.errors.signers[index].lastName}</FormFeedback>}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xl="6">
                    <Label>{index == 1 ? 'Phone of additional signer' : 'Phone'} *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`signers.${index}.phone`}
                      onChange={capitalizeTextOnChange}
                      onFocus={() => onArrayFieldFocused('signers', index, 'phone')}
                      value={formik.values.signers[index]?.phone}
                      invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.phone}
                    />
                    {!!formik.errors.signers && !!formik.errors.signers[index]?.phone && <FormFeedback type="invalid">{formik.errors.signers[index].phone}</FormFeedback>}
                  </Col>
                  <Col xl="6">
                    <Label>{index == 1 ? 'Email of additional signer' : 'Email'} *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`signers.${index}.email`}
                      onChange={formik.handleChange}
                      onFocus={() => onArrayFieldFocused('signers', index, 'email')}
                      value={formik.values.signers[index]?.email}
                      invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.email}
                    />
                    {!!formik.errors.signers && !!formik.errors.signers[index]?.email && <FormFeedback type="invalid">{formik.errors.signers[index].email}</FormFeedback>}
                  </Col>
                </Row>
                {(defaultValues?.isNotaryRequired && index == 0) && <React.Fragment>
                  <Row className="mb-4">
                    <Col xl="6">
                      <Label>Address where signing will take place *</Label>
                      <PlacesAutocomplete
                        type="text"
                        className={classnames("form-control", { "is-invalid": !!formik.errors.signers && !!formik.errors.signers[index]?.address })}
                        placeholder="ex. 2273 Muldrow Dr"
                        name={`signers.${index}.address`}
                        onChange={e => onAddressTextChanged(index, e.target.value)}
                        onPlaceChanged={fillInAddress(index)}
                        onFocus={() => onArrayFieldFocused("signers", index, "address")}
                        value={formik.values.signers[index]?.address}
                      />
                      {!!formik.errors.signers && !!formik.errors.signers[index]?.address && <FormFeedback type="invalid">{formik.errors.signers[index].address}</FormFeedback>}
                      {!!formik.errors.signers && !!formik.errors.signers[index]?.latitude && <FormFeedback type="invalid" className="d-block">{formik.errors.signers[index].latitude}</FormFeedback>}
                      {!!formik.errors.signers && !!formik.errors.signers[index]?.longitude && <FormFeedback type="invalid" className="d-block">{formik.errors.signers[index].longitude}</FormFeedback>}
                    </Col>
                    <Col xl="6">
                      <Label>City *</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name={`signers.${index}.city`}
                        onChange={capitalizeTextOnChange}
                        onFocus={() => onArrayFieldFocused('signers', index, 'city')}
                        value={formik.values.signers[index]?.city}
                        invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.city}
                        disabled={cityIsReadonly}
                      />
                      {!!formik.errors.signers && !!formik.errors.signers[index]?.city && <FormFeedback type="invalid">{formik.errors.signers[index].city}</FormFeedback>}
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xl="6">
                      <Label>State or province *</Label>
                      <Select
                        classNamePrefix="select2-selection"
                        name={`signers.${index}.state`}
                        options={usStates}
                        onChange={selected => formik.setFieldValue(`signers.${index}.state`, selected.value)}
                        onFocus={() => onArrayFieldFocused('signers', index, 'state')}
                        value={usStates.find(option => option.value === formik.values.signers[index]?.state)}
                        className={!!formik.errors.signers && !!formik.errors.signers[index]?.state && 'is-invalid'}
                        isDisabled={stateIsReadonly} />
                      {!!formik.errors.signers && !!formik.errors.signers[index]?.state && <FormFeedback type="invalid">{formik.errors.signers[index].state}</FormFeedback>}
                    </Col>
                    <Col xl="6">
                      <Label>Zip code *</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name={`signers.${index}.zip`}
                        onChange={formik.handleChange}
                        onFocus={() => onArrayFieldFocused('signers', index, 'zip')}
                        value={formik.values.signers[index]?.zip}
                        invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.zip}
                        disabled={zipIsReadonly}
                      />
                      {!!formik.errors.signers && !!formik.errors.signers[index]?.zip && <FormFeedback type="invalid">{formik.errors.signers[index].zip}</FormFeedback>}
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xl="6">
                      <Label>Additional info</Label>
                      <Input
                        type="text"
                        placeholder="apartment, unit, suite number"
                        className="form-control"
                        name={`signers.${index}.address2`}
                        onChange={capitalizeTextOnChange}
                        onFocus={() => onArrayFieldFocused('signers', index, 'address2')}
                        value={formik.values.signers[index]?.address2}
                      />
                    </Col>
                  </Row>
                </React.Fragment>}
                {index == 0 && <Row className="mb-4">
                  <Col>
                    <div className="form-check form-switch form-switch-lg d-inline-block">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="addSignerSwitch"
                        onChange={e => toggleAddSigner(e, arrayHelpers)}
                        defaultChecked={formik.values.signers.length == 2}
                      />
                      <Label className="form-check-label" htmlFor="addSignerSwitch" />
                    </div>
                    <Label className="mb-0" htmlFor="addSignerSwitch">Additional signer</Label>
                  </Col>
                </Row>}
              </div>)}
              {!!formik.errors.signers && typeof formik.errors.signers === 'string' && <div className="card-section">
                <FormFeedback type="invalid" className="d-block">{formik.errors.signers}</FormFeedback>
              </div>}
            </React.Fragment>} />
          </FormikProvider>
        </Form>
      </CardBody>
    </Card>
  </React.Fragment>
}

const usStates = toSelectOptions(UsStates);

FormEditCustomer.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  finishedHandler: PropTypes.func,
};

export default FormEditCustomer;